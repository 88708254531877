import React, { BaseSyntheticEvent, Component } from 'react'
// redux
import { compose } from 'redux'
import { connect } from 'react-redux'
import { modalIsLoadingSelector } from 'domain/loading'
// components
import Close from 'components/svgComponent/Close'
import Loader from 'components/Loader'
// types
import { ComposeType } from 'types/common'
import { Classes } from 'jss'
import { StateInterface } from 'types/state'
// styles
import injectSheet from 'react-jss'
import { sheet } from './sheet'
// utils
import cx from 'classnames'

interface ExternalProps {
  children: JSX.Element
  openCloseModal?: () => void
  auto?: boolean
  withIcon?: boolean
  hideClose?: boolean
  hideOverflow?: boolean
  overflowUnset?: boolean
  noCloseAway?: boolean
  big?: boolean
  isScroll?: boolean
}

interface OwnProps {
  classes: Classes
  modalIsLoading: boolean
}

type Props = ExternalProps & OwnProps

class Modal extends Component<Props> {
  modalNode?: HTMLDivElement

  onClose = (e: BaseSyntheticEvent) => {
    e.stopPropagation()
    if (this.props.openCloseModal) this.props.openCloseModal()
  }

  render() {
    const {
      modalIsLoading,
      children,
      classes,
      auto,
      hideClose,
      withIcon,
      hideOverflow = false,
      overflowUnset = false,
      big = false,
      isScroll = false
    } = this.props

    return (
      <div
        aria-modal="true"
        className={cx(classes.modalCover, { [classes.overflow]: overflowUnset })}
        tabIndex={-1}
        aria-label="modal"
      >
        <div
          className={cx(
            classes.modal,
            { [classes.modalWidth]: auto },
            { [classes.noOverflow]: withIcon },
            { [classes.hideOverflow]: hideOverflow },
            { [classes.overflowUnset]: overflowUnset },
            { [classes.bigModalWidth]: big }
          )}
          ref={(n: HTMLDivElement) => (this.modalNode = n)}
        >
          <div className={cx(classes.modalBody, { [classes.modalBodyScroll]: isScroll })}>
            {!hideClose && (
              <button
                className={classes.modalClose}
                aria-labelledby="close-modal"
                onClick={this.onClose}
                id="close-modal"
              >
                <Close />
              </button>
            )}
            <Loader show={modalIsLoading} />
            {children}
          </div>
        </div>
      </div>
    )
  }
}

export default compose<ComposeType<Props, ExternalProps>>(
  connect((state: StateInterface) => ({
    modalIsLoading: modalIsLoadingSelector(state)
  })),
  injectSheet(sheet)
)(Modal)
