import { compose } from 'redux'
import {
  rules,
  addError,
  HourlyError,
  SalaryError,
  MAX_VALUE_RATE,
  MAX_VALUE_RATE_ERROR
} from 'lib/validationRules'
import { JobsFilterCandidate } from 'types/job/jobTypesCandidate'
import { FormErrorsInterface } from 'types/common'

type Validator = (
  values: JobsFilterCandidate
) => (errors: FormErrorsInterface, fieldName: string) => {}
const validator: Validator = values => (errors, fieldName): {} => {
  const val = values[fieldName]
  switch (fieldName) {
    case 'rateFrom':
      return compose(
        addError('hourly', rules.moreNumber(val, MAX_VALUE_RATE, MAX_VALUE_RATE_ERROR)),
        addError('hourly', rules.moreNumber(val, values.rateTo, HourlyError))
      )(errors)
    case 'rateTo':
      return compose(
        addError('hourly', rules.moreNumber(val, MAX_VALUE_RATE, MAX_VALUE_RATE_ERROR)),
        addError('hourly', rules.moreNumber(values.rateFrom, val, HourlyError))
      )(errors)
    case 'salaryFrom':
      return compose(
        addError('salary', rules.moreNumber(val, MAX_VALUE_RATE, MAX_VALUE_RATE_ERROR)),
        addError('salary', rules.moreNumber(val, values.salaryTo, SalaryError))
      )(errors)
    case 'salaryTo':
      return compose(
        addError('salary', rules.moreNumber(val, MAX_VALUE_RATE, MAX_VALUE_RATE_ERROR)),
        addError('salary', rules.moreNumber(values.salaryFrom, val, SalaryError))
      )(errors)
    default:
      return errors
  }
}

export const validation = (values: JobsFilterCandidate) =>
  ['rateFrom', 'rateTo', 'salaryFrom', 'salaryTo'].reduce(validator(values), {})
