import { put, call } from 'redux-saga/effects'
import I from 'immutable'
import Api from 'domain/api'
import * as Actions from './actions'

export function* ensureGetConstants() {
  try {
    const {
      data: { data }
    } = yield call(Api.getConstants)
    yield put({ type: Actions.constants.success, payload: I.fromJS(data) })
  } catch (err) {
    yield put({
      type: Actions.constants.failure,
      err
    })
  }
}
