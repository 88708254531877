import React, { BaseSyntheticEvent, Component } from 'react'
// redux
import { connect } from 'react-redux'
import { compose } from 'redux'
import { push, Push } from 'connected-react-router'
import ReactDOM from 'react-dom'
// types
import { Classes } from 'jss'
import { ComposeType } from 'types/common'
// styles
import injectSheet from 'react-jss'
import { sheet } from './sheet'
// icons
import Close from 'components/svgComponent/Close'

interface ExternalProps {
  toggleModal?: () => void
  modalComponent: {
    component: React.ComponentClass | React.FunctionComponent
    props?: object
  }
}

interface OwnProps {
  classes?: Classes
  push?: Push
}

type Props = ExternalProps & OwnProps

class ModalAuth extends Component<Props> {
  closeButtonNode?: HTMLButtonElement
  modalNode?: HTMLDivElement
  el: HTMLElement | null = document.getElementById('modal-root')

  onClose = (e: BaseSyntheticEvent) => {
    e.stopPropagation()
    if (this.props.toggleModal) {
      this.props.toggleModal()
    } else if (this.props.push) {
      this.props.push('/')
    }
  }

  onClickAway = (e: BaseSyntheticEvent) => {
    e.stopPropagation()
    if (this.modalNode && this.modalNode.contains(e.target)) return
    this.onClose(e)
  }

  render() {
    const { modalComponent, classes = {} } = this.props
    const MyComponent = modalComponent.component

    return ReactDOM.createPortal(
      <aside
        aria-modal="true"
        className={classes.modalCover}
        tabIndex={-1}
        aria-label="modal"
        onClick={this.onClickAway}
      >
        <div className={classes.modal} ref={(n: HTMLDivElement) => (this.modalNode = n)}>
          <button
            className={classes.modalClose}
            aria-labelledby="close-modal"
            onClick={this.onClose}
            ref={(n: HTMLButtonElement) => (this.closeButtonNode = n)}
          >
            <Close />
          </button>
          <div className={classes.modalBody}>
            <MyComponent {...modalComponent.props} />
          </div>
        </div>
      </aside>,
      this.el as Element
    )
  }
}

export default compose<ComposeType<Props, ExternalProps>>(
  connect(
    null,
    {
      push
    }
  ),
  injectSheet(sheet)
)(ModalAuth)
