import { stopSubmit } from 'redux-form'
import moment from 'moment'
import Joi from 'joi'

export const REQUIRED = 'Required'
export const INVALID_EMAIL = 'Invalid email address'
export const MUST_BE_THE_SAME = 'Must be the same'
export const INVALID_PHONE = 'Invalid phone'
export const INVALID_PHONE10 = 'Invalid phone (must be XXX XXX XX XX)'
export const INVALID_PHONE9 = 'Invalid phone (must be XX XXX XX XX)'
export const INVALID_PHONE8 = 'Invalid phone (must be X XXX XX XX)'
export const MUST_BE_THE_MORE = 'Must be more than "From"'
export const MUST_BE_THE_LESS_CURRENT_DATE = 'Must be less than current year'
export const MUST_BE_THE_MORE_DATE = '"End date" Must be more than "Start date"'
export const MUST_BE_SAME_CURRENCY = 'Currency for Hourly and Salary rates must be same'
export const HourlyError = 'Max hourly rate must be more than min rate!'
export const SalaryError = 'Max salary rate must be more than min rate!'
export const MAX_VALUE_POINTS = '99999'
export const MAX_VALUE_RATE = '999999'
export const POINTS_MAX_VALUE_ERROR = `Must be less than ${MAX_VALUE_POINTS}!`
export const MAX_VALUE_RATE_ERROR = `Must be less than ${MAX_VALUE_RATE}!`
const currentYear = moment().year()

interface Rules {
  required: (v?: string | boolean) => string | undefined
  requiredCondition: (v?: string, V?: boolean | string) => string | undefined
  email: (v?: string) => string | undefined
  same: (v?: string, ...rest: [string | undefined]) => string | undefined
  more: (
    v?: { value: string; label: string },
    V?: { value: string; label: string } | undefined
  ) => string | undefined
  lessCurrentYear: (v?: { value: string; label: string }) => string | undefined
  moreMonth: (
    v?: { value: string; label: string } | undefined,
    fromMonth?: { value: string; label: string } | undefined,
    fromYear?: { value: string; label: string } | undefined,
    toYear?: { value: string; label: string } | undefined
  ) => string | undefined
  moreTime: (
    v?: Date | string,
    dataStart?: Date | string,
    dataEnd?: Date | string,
    timeStart?: Date | string
  ) => string | undefined
  strMinLength: (v: string | number, len: number, message: string) => string | undefined
  phoneNumber: (v: string, length: number) => string | undefined
  strMaxLength: (v: string | number, len: number, message: string) => string | undefined
  moreDate: (dataStart?: Date | string, dataEnd?: Date | string) => string | undefined
  moreNumber: (
    min: string | undefined | number | null,
    max: string | undefined | number | null,
    error: string
  ) => string | undefined
  moreAges: (min: number, max: number, error: string) => string | undefined
  sameCurrency: (
    hourly: { value: string; label: string } | undefined,
    salary: { value: string; label: string } | undefined
  ) => string | undefined
}

export const rules: Rules = {
  required(v) {
    return v ? undefined : REQUIRED
  },
  requiredCondition(v, V) {
    return v || V ? undefined : REQUIRED
  },
  email(v) {
    const schema = { email: Joi.string().email() }
    let error
    Joi.validate({ email: v }, schema, err => {
      if (err !== null) {
        error = INVALID_EMAIL
      }
    })
    return error
  },
  same(v, ...rest) {
    return rest.reduce((A, V) => {
      if (typeof A === 'undefined') {
        if (V !== v) return MUST_BE_THE_SAME
        return undefined
      }
      return A
    }, undefined)
  },
  more(v, V) {
    const value = v && Number(v.value) < 0 ? currentYear : v && v.value
    if (V && value && V.value > value) return MUST_BE_THE_MORE
    return undefined
  },
  lessCurrentYear(v) {
    const value = v && Number(v.value) < 0 ? currentYear : v && v.value
    if (value && value > currentYear) return MUST_BE_THE_LESS_CURRENT_DATE
    return undefined
  },
  moreMonth(v, fromMonth, fromYear, toYear) {
    if (
      v &&
      fromYear &&
      toYear &&
      fromMonth &&
      fromYear.value === toYear.value &&
      fromMonth.value > v.value
    ) {
      return MUST_BE_THE_MORE
    }
    return undefined
  },
  moreTime(v, dataStart, dataEnd, timeStart) {
    if (v && dataStart && dataEnd && timeStart) {
      const time1 = moment(dataStart).format('MM/DD/YYYY')
      const time2 = moment(dataEnd).format('MM/DD/YYYY')
      if (time2 === time1 && moment(v).isBefore(moment(timeStart))) {
        return MUST_BE_THE_MORE_DATE
      }
      return undefined
    }
    return undefined
  },
  strMinLength(v, len, message) {
    return (v || '').toString().length < len ? message : undefined
  },
  phoneNumber(v, length) {
    switch (length) {
      case 1:
      case 2:
        return /[0-9]{3}\s[0-9]{3}\s[0-9]{2}\s[0-9]{2}/i.test(v) ? undefined : INVALID_PHONE10
      case 3:
        return /[0-9]{2}\s[0-9]{3}\s[0-9]{2}\s[0-9]{2}/i.test(v) ? undefined : INVALID_PHONE9
      case 4:
        return /[0-9]\s[0-9]{3}\s[0-9]{2}\s[0-9]{2}/i.test(v) ? undefined : INVALID_PHONE8
      default:
        return INVALID_PHONE
    }
  },
  strMaxLength(v, len, message) {
    return (v || '').toString().length > len ? message : undefined
  },
  moreDate(dataStart, dataEnd) {
    return dataStart && dataEnd && moment(dataEnd).isBefore(moment(dataStart))
      ? MUST_BE_THE_MORE_DATE
      : undefined
  },
  moreNumber(min, max, error) {
    return min && max && Number(max) && Number(min) && Number(max) < Number(min) ? error : undefined
  },
  moreAges(min, max, error) {
    return (min || min === 0) && (max || max === 0) && min < max ? error : undefined
  },
  sameCurrency(hourly, salary) {
    const hourlyCurrency = hourly && hourly.value
    const salaryCurrency = salary && salary.value
    if (hourlyCurrency && salaryCurrency && hourlyCurrency !== salaryCurrency) {
      return MUST_BE_SAME_CURRENCY
    }
    return undefined
  }
}

export function addError(fieldName: string, err?: string): (errors: { [x: string]: string }) => {} {
  return errors => {
    if (errors[fieldName]) return errors
    if (typeof err !== 'undefined') {
      return { ...errors, [fieldName]: err }
    }
    return errors
  }
}

export function makeErrorAction(errors: string[] | string) {
  if (typeof errors === 'string') return { _error: errors }
  if (Array.isArray(errors)) {
    if (typeof errors[0] === 'string') {
      return { _error: errors[0] }
    } else {
      throw new Error('Incorrect format error message')
    }
  }
  const res = (r: string[]) => (Array.isArray(r) ? r[0] : r)
  return Object.keys(errors).reduce(
    (A, field) => Object.assign({}, A, { [field]: res(errors[field]) }),
    {}
  )
}

export function asyncErrorAction(formName: string, errors: string[] | string) {
  return stopSubmit(formName, makeErrorAction(errors))
}
