import React from 'react'
import Svg, { SvgType } from './Svg'

export default function(props: SvgType) {
  return (
    <Svg width="202" height="54" viewBox="0 0 202 54" fill="none" {...props}>
      <path
        d="M59.0857 42.2819L56.0059 45.4403V53.6342H51.2836V27.3561H56.0059V39.683L58.6191 36.5606L66.5705 27.3561H72.2821L62.1841 38.9971L72.8607 53.6342H67.2611L59.0857 42.2819Z"
        fill="#FAFAFA"
      />
      <path d="M75.6792 53.6342H80.3829V27.3561H75.6792V53.6342Z" fill="#FAFAFA" />
      <path
        d="M147.494 53.6342H142.771L130.657 34.9904V53.6342H125.935V27.3561H130.657L142.809 46.072V27.3561H147.494V53.6342Z"
        fill="#FAFAFA"
      />
      <path
        d="M163.676 31.0379H172.151V27.3561H150.592V31.0379H158.991V53.6342H163.676V31.0379Z"
        fill="#FAFAFA"
      />
      <path
        d="M41.1483 44.195C41.9944 44.8207 42.4175 45.711 42.4175 46.8661C42.4175 47.9249 42.0007 48.7792 41.1669 49.4289C40.784 49.7261 40.3121 49.9541 39.7505 50.113C39.4267 50.2044 39.073 50.273 38.6894 50.3187C38.5152 50.3392 38.3351 50.3548 38.1487 50.3656L37.8647 50.3795C37.7362 50.3837 37.605 50.3855 37.4712 50.3855C35.5673 50.3855 34.1052 49.9764 33.0848 49.1582C32.0645 48.34 31.3863 47.185 31.3863 45.693L26.9333 46.9965C27.1136 48.114 27.5339 49.1233 28.1945 50.0245C29.1153 51.2638 30.397 52.2384 32.0396 52.9483C33.6946 53.6462 35.5051 53.9951 37.4712 53.9951C40.4452 53.9951 42.797 53.3514 44.5267 52.064C46.2688 50.7645 47.1399 49.0198 47.1399 46.83C47.1399 45.4824 46.8412 44.3093 46.2439 43.3106C45.6466 42.2999 44.7071 41.4096 43.4254 40.6395C42.1438 39.8574 40.4452 39.1656 38.3298 38.564C37.0867 38.2036 36.0498 37.839 35.2192 37.4703C34.6365 37.211 34.1553 36.9499 33.7754 36.687C32.8546 36.0372 32.3942 35.2311 32.3942 34.2685C32.3942 33.1255 32.8173 32.2351 33.6635 31.5974C34.5221 30.9597 35.7229 30.6408 37.2659 30.6408C38.9209 30.6408 40.1901 31.0319 41.0736 31.814C41.9695 32.584 42.4175 33.6549 42.4175 35.0265L46.9041 33.2166C46.6989 32.3947 46.3482 31.6203 45.8519 30.8935C45.0058 29.6422 43.8423 28.6796 42.3615 28.0058C40.8807 27.332 39.1946 26.9951 37.3032 26.9951C34.4536 26.9951 32.1329 27.6809 30.341 29.0526C28.5616 30.4243 27.6719 32.1689 27.6719 34.2866C27.6719 36.705 28.91 38.6662 31.3863 40.1702C32.6804 40.9523 34.4225 41.6622 36.6126 42.2999C38.8027 42.9376 40.3145 43.5693 41.1483 44.195Z"
        fill="#FAFAFA"
      />
      <path
        d="M84.9136 53.6342H102.547L101.188 49.9884H89.6359V27.3561H84.9136V53.6342Z"
        fill="#FAFAFA"
      />
      <path
        d="M109.043 38.2932H120.205L119.33 41.9029H109.043V49.9884H121.561L122.766 53.6342H104.321V27.3561H121.959L120.545 31.0379H109.043V38.2932Z"
        fill="#FAFAFA"
      />
      <rect
        width="32.32"
        height="32.4"
        rx="16.16"
        transform="matrix(1 0 0 -1 0 32.4004)"
        fill="#286EFA"
      />
    </Svg>
  )
}
