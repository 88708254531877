import React from 'react'
import Svg, { SvgType } from 'components/svgComponent/Svg'

export default function(props: SvgType) {
  return (
    <Svg width="30" height="30" viewBox="0 0 30 30" fill="none" {...props}>
      <path d="M14.7208 21H17V10H16.7555L12 11.6296V13.4026L14.7208 12.5953V21Z" fill="#286EFA" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30ZM15 28C22.1797 28 28 22.1797 28 15C28 7.8203 22.1797 2 15 2C7.8203 2 2 7.8203 2 15C2 22.1797 7.8203 28 15 28Z"
        fill="#286EFA"
      />
    </Svg>
  )
}
