import { GroupChannel } from 'sendbird'
import I from 'immutable'
import { ServerRoles } from 'lib/roles'

export const isAcceptableRole = (role: string) => {
  return [ServerRoles.candidate, ServerRoles.recruiter].indexOf(role) >= 0
}

export const sbUserRedux = ({
  userId = '',
  nickname = '',
  profileUrl = '',
  role = '',
  metaData: { role: metaRole } = { role: '' }
}) => {
  return I.Map({ userId: userId.toString(), nickname, profileUrl, role: role || metaRole })
}

export const sbChannelRedux = (sbChannel: GroupChannel, messageLoader: any, recipient: any) => {
  return I.fromJS({
    url: sbChannel.url,
    members: sbChannel.members,
    customType: sbChannel.customType,
    lastMessage: I.Map({ ...sbChannel.lastMessage }),
    createdAt: sbChannel.createdAt,
    unreadMessageCount: sbChannel.unreadMessageCount,
    messageLoader,
    messages: I.List([]),
    recipient: sbUserRedux(recipient)
  })
}

export const emptyChannel = I.Map({})
