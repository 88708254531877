import { all, call, take, takeLatest, fork } from 'redux-saga/effects'
import * as A from 'domain/recruiter/calendar'
import { ensureCandidateJobsList } from 'domain/recruiter/calendar'

export function* calendar() {
  yield all([
    fork(getCandidatesList),
    fork(getEventsList),
    fork(addEvent),
    fork(getEvent),
    fork(deleteEvent),
    fork(updateEvent)
  ])
}

function* getCandidatesList() {
  yield call(A.ensureCandidatesList)
  yield takeLatest(A.recruiterCandidatesList.type, A.ensureCandidatesList)
  yield take(A.recruiterCandidatesList.success)
}

function* getEventsList() {
  yield call(A.ensureEventsList)
  yield takeLatest(A.recruiterEventsList.type, A.ensureEventsList)
  yield take(A.recruiterEventsList.success)
}

function* addEvent() {
  yield takeLatest(A.recruiterEventAdd.TRIGGER, A.ensureAddEvent)
  yield take(A.recruiterEventAdd.SUCCESS)
}

function* getEvent() {
  yield takeLatest(A.recruiterEvent.type, A.ensureEvent)
  while (true) {
    const data = yield take(A.recruiterEvent.success)
    yield call(ensureCandidateJobsList, {
      payload: { value: data.payload.get('candidateId') }
    })
  }
}

function* deleteEvent() {
  yield takeLatest(A.recruiterEventDelete.TRIGGER, A.ensureRemoveEvent)
  yield take(A.recruiterEventDelete.SUCCESS)
}

function* updateEvent() {
  yield takeLatest(A.recruiterEventUpdate.TRIGGER, A.ensureUpdateEvent)
  yield take(A.recruiterEventUpdate.SUCCESS)
}
