import { Styles } from 'react-jss'

export const sheet: Styles = {
  img: {
    width: 40,
    height: 40,
    borderRadius: '50%',
    objectFit: 'cover'
  }
}
