import * as A from './actions'
import * as Env from 'domain/env'
import { Action } from 'redux'
import { StateInterface } from 'types/state'
import I from 'immutable'

const API_URL = process.env.REACT_APP_API_PATH_DOWNLOADS

interface ActionType extends Action {
  payload: any
}

const State = I.fromJS({
  resume: '',
  progress: null
})

export const resumeSelector = (state: StateInterface) => state.resume.get('resume')

export const resumeProgressSelector = (state: StateInterface) => state.resume.get('progress')

export const reducer = {
  resume(state = State, action: ActionType) {
    switch (action.type) {
      case A.resume.success:
      case A.addResume.success:
        return state
          .set('resume', action.payload ? `${API_URL}/${action.payload}` : null)
          .set('progress', null)
      case A.removeResume.success:
        return state.set('resume', null)
      case A.addResumeProgress.type:
        return state.set('progress', action.payload)
      case Env.signOut.success:
        return state.set('resume', '')

      default:
        return state
    }
  }
}
