import React from 'react'
import Svg, { SvgType } from 'components/svgComponent/Svg'

export default function(props: SvgType) {
  return (
    <Svg width="30" height="30" viewBox="0 0 30 30" fill="none" {...props}>
      <circle cx="15" cy="15" r="14" stroke="#286EFA" strokeWidth="2" />
      <path
        d="M11.2339 17.3975H10.0088L9.55176 20H8.39014L8.84717 17.3975H7.40625V16.3057H9.0376L9.35498 14.5029H7.88232V13.3984H9.55176L10.0151 10.7578H11.1704L10.707 13.3984H11.9385L12.4019 10.7578H13.5635L13.1001 13.3984H14.4902V14.5029H12.9033L12.5859 16.3057H14.0078V17.3975H12.3955L11.9385 20H10.7769L11.2339 17.3975ZM10.1992 16.3057H11.4243L11.7417 14.5029H10.5103L10.1992 16.3057ZM20.2186 20H18.3841V12.9287L16.1942 13.6079V12.1162L20.0218 10.7451H20.2186V20Z"
        fill="#286EFA"
      />
    </Svg>
  )
}
