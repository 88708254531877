export default {
  loader: {
    fontSize: '10px',
    textIndent: '-9999em',
    width: '14px',
    height: '14px',
    borderRadius: '50%',
    background: 'linear-gradient(to right, #286EFA 10%, rgba(255, 255, 255, 0) 42%)',
    position: 'relative',
    animation: 'load3 1.4s infinite linear',
    transform: 'translateZ(0)',
    '&:before': {
      width: '50%',
      height: '50%',
      background: '#286EFA',
      borderRadius: '100% 0 0 0',
      position: 'absolute',
      top: '0',
      left: '0',
      content: "''"
    },
    '&:after': {
      background: '#ffffff',
      width: '75%',
      height: '75%',
      borderRadius: '50%',
      content: "''",
      margin: 'auto',
      position: 'absolute',
      top: '0',
      left: '0',
      bottom: '0',
      right: '0'
    }
  },
  '@keyframes load3': {
    from: {
      transform: 'rotate(0deg)'
    },
    to: {
      transform: 'rotate(360deg)'
    }
  }
}
