import { Styles } from 'react-jss'

export const sheet: Styles = {
  title: {},
  text: {},
  row: {},
  root: {},
  time: {},
  main: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: 343,
    minHeight: 110,
    background: '#FAFAFA',
    border: '1px solid #286EFA',
    boxSizing: 'border-box',
    padding: '10px 12px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 12,
    lineHeight: '16px',
    letterSpacing: '0.01em',
    color: '#565656',
    '& $text a': {
      whiteSpace: 'inherit',
      wordBreak: 'break-all'
    },
    '& > svg': {
      overflow: 'visible'
    },
    '& > $root': {
      marginLeft: 12,
      flex: 1,
      overflow: 'hidden',
      '& > $row': {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        '& > $title': {
          overflow: 'hidden',
          '& > a': {
            color: '#286EFA'
          }
        },
        '& > $time': {
          padding: '3px 8px',
          marginLeft: 30,
          background: '#E14A4A',
          borderRadius: '2px',
          fontWeight: '500',
          fontSize: 13,
          lineHeight: '18px',
          letterSpacing: '0.03em',
          color: '#FAFAFA',
          whiteSpace: 'nowrap',
          height: 'max-content'
        }
      },
      '& p': {
        margin: 0,
        '& > span': {
          color: '#286EFA'
        }
      }
    }
  },
  unread: {
    background: '#FEFAF1'
  }
}
