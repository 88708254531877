import { asyncAction } from 'lib/actionCreators'
import { createRoutine, promisifyRoutine } from 'redux-saga-routines'

export const candidates = asyncAction('recruiter/CANDIDATES')
export const candidatesNewList = asyncAction('recruiter/CANDIDATES_NEW_LIST')
export const candidateProfile = asyncAction('recruiter/CANDIDATE_PROFILE')
export const jobsToAssignCandidate = asyncAction('recruiter/JOBS_TO_ASSIGN_CANDIDATE')
export const candidateFavorite = asyncAction('recruiter/CANDIDATE_FAVORITE')
export const candidateFavoriteFromList = asyncAction('recruiter/CANDIDATE_FAVORITE_FROM_LIST')
export const jobsListForFilter = asyncAction('recruiter/JOBS_LIST_FOR_FILTER')
export const assignJobToCandidate = createRoutine('recruiter/ASSIGN_JOB_TO_CANDIDATE')
export const selfAssignJobCandidate = createRoutine('recruiter/SELF_ASSIGN_JOB_CANDIDATE')
export const acceptSelfAssignJobCandidate = asyncAction(
  'recruiter/ACCEPT_SELF_ASSIGN_JOB_CANDIDATE'
)
export const declineSelfAssignJobCandidate = asyncAction(
  'recruiter/DECLINE_SELF_ASSIGN_JOB_CANDIDATE'
)
export const unassignedJobToCandidate = createRoutine('recruiter/UNASSIGNED_JOB_TO_CANDIDATE')
export const assignJobToCandidatePromiseCreator = promisifyRoutine(assignJobToCandidate)
export const unassignedJobToCandidatePromiseCreator = promisifyRoutine(unassignedJobToCandidate)
export const candidateJobs = asyncAction('recruiter/CANDIDATE_JOBS')
export const addNoteToCandidateJob = asyncAction('recruiter/ADD_NOTE_TO_CANDIDATE_JOB')
export const deleteNoteToCandidateJob = asyncAction('recruiter/DELETE_NOTE_TO_CANDIDATE_JOB')
export const editNoteToCandidateJob = asyncAction('recruiter/EDIT_NOTE_TO_CANDIDATE_JOB')
