import { put, call, select } from 'redux-saga/effects'
import * as Actions from './actions'
import * as Router from 'domain/router'
import * as M from 'domain/env'
import Api from 'domain/api'
import I from 'immutable'

export function* ensureInviteOtherRecruiter() {
  const headers = yield select(M.userToken)
  if (!headers) return
  try {
    const {
      params: { id }
    } = yield select(Router.matchRoutes)
    const {
      data: { data }
    } = yield call(Api.inviteOtherRecruiter, {
      headers: { Authorization: `Bearer ${headers}` },
      recruiterId: id
    })
    yield put({
      type: Actions.inviteOtherRecruiter.success,
      payload: I.fromJS(data)
    })
  } catch (err) {
    yield put({
      type: Actions.inviteOtherRecruiter.failure,
      err
    })
  }
}

interface Props {
  payload: {
    managerId: number
    recruiterId: number
  }
  type: string
}

export function* ensureDeclineOtherRecruiter({ payload }: Props) {
  const headers = yield select(M.userToken)
  if (!headers || !payload) return
  try {
    const {
      data: { data }
    } = yield call(Api.declineInviteRecruiter, {
      headers: { Authorization: `Bearer ${headers}` },
      ...payload
    })
    yield put({
      type: Actions.declineOtherRecruiter.success,
      payload: I.fromJS(data)
    })
  } catch (err) {
    yield put({
      type: Actions.declineOtherRecruiter.failure,
      err
    })
  }
}

export function* ensureAcceptOtherRecruiter({ payload }: Props) {
  const headers = yield select(M.userToken)
  if (!headers || !payload) return
  try {
    const {
      data: { data }
    } = yield call(Api.acceptInviteRecruiter, {
      headers: { Authorization: `Bearer ${headers}` },
      ...payload
    })
    yield put({
      type: Actions.acceptOtherRecruiter.success,
      payload: I.fromJS(data)
    })
  } catch (err) {
    yield put({
      type: Actions.acceptOtherRecruiter.failure,
      err
    })
  }
}

export function* ensureCloseOtherRecruiter({ payload }: Props) {
  const headers = yield select(M.userToken)
  if (!headers) return
  try {
    const {
      data: { data }
    } = yield call(Api.closeInviteRecruiter, {
      headers: { Authorization: `Bearer ${headers}` },
      ...payload
    })
    yield put({
      type: Actions.closeOtherRecruiter.success,
      payload: I.fromJS(data)
    })
  } catch (err) {
    yield put({
      type: Actions.closeOtherRecruiter.failure,
      err
    })
  }
}
