import { all, call, take, takeLatest, fork, select } from 'redux-saga/effects'
import * as J from 'domain/recruiter/jobs'
import { query } from 'domain/router'

export function* jobsList() {
  yield all([fork(getJobsList)])
}

function* getJobsList() {
  const { status } = yield select(query)
  const props = status ? { payload: { status, page: 1 }, type: J.jobsList.request } : undefined
  yield call(J.ensureGetJobs, props)
  yield takeLatest(J.jobsList.type, J.ensureGetJobs)
  yield take(J.jobsList.success)
}
