import { peek, selector } from 'lib/selectors'
import { StateInterface } from 'types/state'

export const constantsSelector = (state: StateInterface) => state.constants
export const dateTimeRangeSelector = selector(constantsSelector, peek('dateTimeRange'))
export const positionTypeSelector = selector(constantsSelector, peek('positionType'))
export const citizenshipSelector = selector(constantsSelector, peek('citizenship'))
export const clientTypeSelector = selector(constantsSelector, peek('clientType'))
export const clientOrderNamesSelector = selector(constantsSelector, peek('clientOrderNames'))
export const notificationTypeSelector = selector(constantsSelector, peek('notificationType'))
export const managerJobsNamesSelector = selector(constantsSelector, peek('managerJobsNames'))
export const employmentTypeSelector = selector(constantsSelector, peek('employmentType'))
export const clientClosureRateOrderNamesSelector = selector(
  constantsSelector,
  peek('clientClosureRateOrderNames')
)
export const recruiterFilterJobStatusSelector = selector(
  constantsSelector,
  peek('recruiterFilterJobStatus')
)
export const jobStatusSelector = selector(constantsSelector, peek('jobStatus'))
export const clearanceTypeSelector = selector(constantsSelector, peek('clearanceType'))
export const w2TypeSelector = selector(constantsSelector, peek('w2Type'))
export const topRecruitersOrderNamesSelector = selector(
  constantsSelector,
  peek('topRecruitersOrderNames')
)
export const closedJobsOrderNamesSelector = selector(
  constantsSelector,
  peek('closedJobsOrderNames')
)
export const recruiterFilterOrderNamesSelector = selector(
  constantsSelector,
  peek('recruiterFilterOrderNames')
)
export const recruiterCandidatesFilterScopesSelector = selector(
  constantsSelector,
  peek('recruiterCandidatesFilterScopes')
)
export const candidateJobsFilterScopesSelector = selector(
  constantsSelector,
  peek('candidateJobsFilterScopes')
)
export const managerCandidatesFilterScopesSelector = selector(
  constantsSelector,
  peek('managerCandidatesFilterScopes')
)
export const managerRecruiterStatusSelector = selector(
  constantsSelector,
  peek('managerRecruiterStatus')
)
export const managerRecruiterScopeSelector = selector(
  constantsSelector,
  peek('managerRecruiterScope')
)
export const ownershipSelector = selector(constantsSelector, peek('ownership'))
