import I from 'immutable'
import { Action } from 'redux'
import * as A from './actions'

interface ActionType extends Action {
  payload: any
}

export const State = I.Map({
  dateTimeRange: I.Map(),
  positionType: I.Map(),
  candidateWebSocket: I.Map(),
  citizenship: I.Map(),
  clientType: I.Map(),
  clientOrderNames: I.Map(),
  notificationType: I.Map(),
  managerJobsNames: I.Map(),
  employmentType: I.Map(),
  clientClosureRateOrderNames: I.Map(),
  recruiterFilterJobStatus: I.Map(),
  jobStatus: I.Map(),
  w2Type: I.Map(),
  clearanceType: I.Map(),
  topRecruitersOrderNames: I.Map(),
  orderNames: I.Map(),
  managerWebSocket: I.Map(),
  closedJobsOrderNames: I.Map(),
  recruiterWebSocket: I.Map(),
  recruiterFilterOrderNames: I.Map(),
  recruiterCandidatesFilterScopes: I.Map(),
  candidateJobsFilterScopes: I.Map(),
  managerCandidatesFilterScopes: I.Map(),
  managerRecruiterScope: I.Map(),
  managerRecruiterStatus: I.Map(),
  ownership: I.Map()
})

export const reducer = {
  constants(state = State, action: ActionType) {
    switch (action.type) {
      case A.constants.success: {
        return action.payload
      }

      default:
        return state
    }
  }
}
