import { all, takeLatest, fork, call, put, select } from 'redux-saga/effects'
import { pageIsLoading } from 'domain/loading'
import * as D from 'domain/superManager/dashboard'
import * as M from 'domain/superManager/managers'
import * as R from 'domain/router'

export function* dashboard() {
  yield all([
    fork(clientClosureRate),
    fork(statistics),
    fork(topRecruiters),
    fork(topManagers),
    fork(closedJobs),
    fork(initialLoad),
    fork(getManagers)
  ])
}

function* topManagers() {
  yield takeLatest(D.superManagerTopManagers.type, D.ensureTopManagers)
}

function* getManagers() {
  yield takeLatest(M.managersList.type, M.ensureGetManagers)
}

function* clientClosureRate() {
  yield takeLatest(D.superManagerClientClosureRate.type, D.ensureClientClosureRate)
}
function* statistics() {
  yield takeLatest(D.superManagerStatistics.type, D.ensureStatistics)
}

function* topRecruiters() {
  yield takeLatest(D.superManagerTopRecruiters.type, D.ensureTopRecruiters)
}

function* closedJobs() {
  yield takeLatest(D.superManagerClosedJobs.type, D.ensureClosedJobs)
}

function* initialLoad() {
  yield put({ type: pageIsLoading, payload: true })
  const params = yield select(R.query)
  const topManagersOrRecruitersSaga = params.managerId ? D.ensureTopRecruiters : D.ensureTopManagers
  try {
    yield all([
      call(D.ensureClosedJobs, { payload: params }),
      call(topManagersOrRecruitersSaga, { payload: params }),
      call(D.ensureStatistics, { payload: params }),
      call(D.ensureClientClosureRate, { payload: params }),
      call(D.ensureClientsCounters, { payload: params }),
      call(D.ensureJobsCounters, { payload: params }),
      call(M.ensureGetManagers)
    ])
  } finally {
    yield put({ type: pageIsLoading, payload: false })
  }
}
