import { Styles } from 'react-jss'

export const style: Styles = {
  photo: {},
  infoBlock: {},
  photoDark: {},
  infoBlockDark: {},
  profileInfo: {
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    margin: '0 20px',
    '& > $photo': {
      display: 'block',
      width: 40,
      height: 40,
      borderRadius: '50%',
      backgroundColor: '#FFFFFF',
      '& > img, & > svg': {
        width: 40,
        height: 40,
        borderRadius: '50%'
      }
    },
    '& > $infoBlock': {
      margin: '0 20px 0 10px',
      display: 'flex',
      flexDirection: 'column',
      '& > div:first-child': {
        fontWeight: 'bold',
        lineHeight: '19px',
        fontSize: 15,
        letterSpacing: '0.01em',
        color: '#FAFAFA'
      },
      '& > div:last-child': {
        fontWeight: '500',
        lineHeight: '18px',
        fontSize: 13,
        letterSpacing: '0.03em',
        textTransform: 'capitalize',
        color: '#E6E6E6'
      }
    }
  },
  profileInfoDark: {
    margin: 0,
    '& > $photoDark': {
      width: 56,
      height: 56,
      border: '2px solid #286EFA',
      '& > img, & > svg': {
        width: 56,
        height: 56
      }
    },
    '& > $infoBlock': {
      '& > div:first-child': {
        fontWeight: '500',
        lineHeight: '21px',
        fontSize: 17,
        letterSpacing: '0.01em',
        color: '#080910'
      },
      '& > div:last-child': {
        textDecorationLine: 'underline',
        textTransform: 'none',
        color: '#989FA6'
      }
    }
  },
  '@media (max-width: 767px)': {
    hideOnXS: {},
    infoBlock: {
      '&$hideOnXS': {
        display: 'none'
      }
    }
  }
}
