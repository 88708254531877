import React from 'react'
import Svg, { SvgType } from './Svg'

export default function(props: SvgType) {
  return (
    <Svg width="30" height="30" viewBox="0 0 30 30" fill="none" {...props}>
      <path
        d="M14.377 5H3.41862C2.91236 5 2.50195 5.41041 2.50195 5.91667V26.5833C2.50195 27.0896 2.91236 27.5 3.41862 27.5H24.7103C25.2165 27.5 25.627 27.0896 25.627 26.5833V16.25"
        stroke="#286EFA"
        strokeWidth="1.83333"
      />
      <path
        d="M10.3429 20.9091L10.0384 17.3064C10.0153 17.0333 10.1157 16.7641 10.312 16.5728L21.2803 5.88169C22.4927 4.69999 24.4297 4.71236 25.6269 5.90945C26.8459 7.12844 26.8333 9.10875 25.5987 10.3121L14.677 20.9578C14.4866 21.1434 14.2249 21.2371 13.96 21.2147L10.3429 20.9091Z"
        stroke="#286EFA"
        strokeWidth="1.83333"
      />
      <path d="M20.627 6.875L25.002 11.25" stroke="#286EFA" strokeWidth="1.83333" />
    </Svg>
  )
}
