import { put, call, select, take } from 'redux-saga/effects'
import * as Actions from './actions'
import * as M from 'domain/env'
import Api from 'domain/api'
import { createUploadEventChanel } from 'domain/avatar/sagas'

export function* ensureResume() {
  const headers = yield select(M.userToken)
  if (!headers) return
  try {
    const {
      data: { data }
    } = yield call(Api.getResume, {
      headers: { Authorization: `Bearer ${headers}` }
    })
    yield put({ type: Actions.resume.success, payload: data.path })
  } catch (err) {
    yield put({
      type: Actions.resume.failure,
      err
    })
  }
}

export function* ensureAddResume({ payload }: { payload: FormData; type: string }) {
  const headers = yield select(M.userToken)
  if (!headers) return
  const channel = yield call(createUploadEventChanel, { headers, payload }, Api.postResume)

  while (true) {
    const { progress = 0, success, failure } = yield take(channel)
    if (success) {
      yield put({ type: Actions.addResume.success, payload: success.data.path })
      yield put(Actions.addResumeProgress(null))
      return
    }
    if (failure) {
      yield put({
        type: Actions.addResume.failure,
        err: failure.err
      })
      yield put(Actions.addResumeProgress(null))
      return
    }
    yield put(Actions.addResumeProgress(progress))
  }
}

export function* ensureRemoveResume() {
  const headers = yield select(M.userToken)
  if (!headers) return
  try {
    yield call(Api.deleteResume, {
      headers: { Authorization: `Bearer ${headers}` }
    })
    yield put({ type: Actions.removeResume.success, payload: null })
  } catch (err) {
    yield put({
      type: Actions.removeResume.failure,
      err
    })
  }
}
