import React, { Component } from 'react'
// components
import { icons } from './icons'
import { Button } from 'components/Button'
import { FieldInput } from 'components/Form/Fields/FieldInput'
import CircleBackground from 'components/common/CircleBackground'
import Input from 'components/Form/Input'
// redux
import { compose } from 'redux'
import { InjectedFormProps, reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import * as A from 'domain/env'
// types
import { ButtonHtmlTypes, ButtonTypes, ComposeType } from 'types/common'
import { Classes } from 'jss'
// styles
import injectSheet from 'react-jss'
import { sheet } from './sheet'
// utils
import { createPassword } from 'pages/Auth/validation'

interface OwnProps {
  classes: Classes
  updatePassword: (data: { password: string; confirmationPassword: string }) => void
}

type Props = InjectedFormProps<{ password: string; confirmationPassword: string }> & OwnProps

class CreatePassword extends Component<Props> {
  submit = (data: { password: string; confirmationPassword: string }) => {
    this.props.updatePassword(data)
  }

  render() {
    const { classes, handleSubmit } = this.props

    return (
      <form onSubmit={handleSubmit(this.submit)}>
        <div className={classes.root}>
          <div className={classes.content}>
            <div className={classes.textLeft}>
              <CircleBackground className="forLogIn" />
              <h3>Create a new password for your Skilent Account!</h3>
              <h4>Tips for strong password:</h4>
              <div className={classes.margin}>
                <div className={classes.iconBlock}>
                  {icons.map((item, i) => (
                    <div key={i}>
                      {item.icon}
                      <p className={classes.title}>{item.name}</p>
                      <p className={classes.text}>{item.text}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className={classes.form}>
              <h4>Create a new password</h4>
              <FieldInput
                name="password"
                type="password"
                component={Input}
                title="Password"
                isPassword={true}
                placeholder="must include at least 8 symbols"
              />
              <FieldInput
                name="confirmationPassword"
                type="password"
                component={Input}
                isPassword={true}
                title="Repeat password"
              />
              <div className={classes.buttonGroup}>
                <Button
                  type={ButtonTypes.Submit}
                  htmlType={ButtonHtmlTypes.Submit}
                  label="reset password"
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    )
  }
}

export default compose<ComposeType<Props>>(
  connect(
    null,
    {
      updatePassword: A.updatePassword
    }
  ),
  reduxForm({
    form: 'create-password',
    validate: createPassword,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true
  }),
  injectSheet(sheet)
)(CreatePassword)
