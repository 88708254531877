import { CandidateProfileType, ManagerProfileType, RecruiterProfileType } from 'types/profile'

const API_URL = process.env.REACT_APP_API_PATH_DOWNLOADS

export const transformAvatar = (data: ManagerProfileType | CandidateProfileType) => {
  return data.update('avatar', (val: string) => (val ? `${API_URL}/${val}` : undefined))
}

export const transformCandidate = (data: RecruiterProfileType) => {
  return data.update('avatar', (val: string) => (val ? `${API_URL}/${val}` : undefined))
}
