import { put, call, select } from 'redux-saga/effects'
import { modalIsLoading } from 'domain/loading'
import I from 'immutable'
import Api from 'domain/api'
import * as Actions from './actions'
import * as M from 'domain/env'
import * as Router from 'domain/router'

interface Props {
  payload: Array<{ email: string }>
  type: string
}

export function* ensureRecommendJob({ payload }: Props) {
  const headers = yield select(M.userToken)
  if (!headers) return
  const {
    params: { id }
  } = yield select(Router.matchRoutes)

  if (!payload) {
    yield put({ type: Actions.recommendJob.SUCCESS, payload: I.fromJS([]) })
    return
  }
  try {
    yield put({ type: modalIsLoading, payload: true })
    const {
      data: { data }
    } = yield call(Api.recommendJob, {
      headers: { Authorization: `Bearer ${headers}` },
      data: { emails: payload, jobId: id }
    })
    yield put({ type: Actions.recommendJob.SUCCESS, payload: I.fromJS(data) })
  } catch (err) {
    yield put({
      type: Actions.recommendJob.FAILURE,
      err
    })
  } finally {
    yield put({ type: modalIsLoading, payload: false })
  }
}
