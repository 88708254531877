import { asyncAction } from 'lib/actionCreators'
import { createRoutine, promisifyRoutine } from 'redux-saga-routines'

export const jobsList = asyncAction('candidate/JOBS_LIST')
export const newJobsList = asyncAction('candidate/NEW_JOBS_LIST')
export const jobData = asyncAction('candidate/JOB')
export const assignJob = asyncAction('candidate/ASSIGN_JOB')
export const acceptJob = asyncAction('candidate/ACCEPT_JOB')
export const cancelJob = createRoutine('candidate/CANCEL_JOB')
export const cancelJobPromiseCreator = promisifyRoutine(cancelJob)
export const saveJob = asyncAction('candidate/SAVE_JOB')
export const saveJobFromList = asyncAction('candidate/SAVE_JOB_FROM_LIST')
export const getJobsCount = asyncAction('candidate/GET_JOBS_COUNT')
