import { put, call, select } from 'redux-saga/effects'
import I from 'immutable'
import Api from 'domain/api'
import * as A from './actions'
import * as E from 'domain/env'
import { error403, pageIsLoading, skeleton } from 'domain/loading'
import * as Router from 'domain/router'
import { Sort } from 'types/common'
import * as M from 'domain/env'
import { jobNotesSelector } from 'domain/jobs'
import { NoteType } from 'types/common'

export interface ParamsI {
  page?: number
  order?: Sort
  order_by?: string | null
  status?: string
  search?: string
  managerId?: string
  clientId?: string
}

export function* ensureGetJobs(props: { payload: ParamsI; type: string }) {
  const headers = yield select(E.userToken)
  if (!headers) return
  if (props.payload.page === 1) {
    yield put({ type: skeleton, payload: true })
    yield put({ type: pageIsLoading, payload: true })
  }
  try {
    const { data } = yield call(Api.getSuperManagerJobs, {
      headers: { Authorization: `Bearer ${headers}` },
      params: props.payload
    })
    yield put({ type: A.superManagerJobsList.success, payload: I.fromJS(data) })
  } catch (err) {
    yield put({ type: A.superManagerJobsList.failure, err })
  } finally {
    yield put({ type: skeleton, payload: false })
    yield put({ type: pageIsLoading, payload: false })
  }
}

export function* ensureGetJob() {
  yield put({ type: A.jobData.success, payload: I.fromJS({}) })
  yield put({ type: error403, payload: false })

  const headers = yield select(E.userToken)
  if (!headers) return

  const {
    params: { id }
  } = yield select(Router.matchRoutes)

  try {
    const { data } = yield call(Api.getJobSuperManager, {
      headers: { Authorization: `Bearer ${headers}` },
      id
    })
    yield put({ type: A.jobData.success, payload: I.fromJS(data) })
  } catch (err) {
    const { statusCode } = err.response.data
    if (statusCode === 403 || statusCode === 404) {
      yield put({ type: error403, payload: true })
    }
    yield put({
      type: A.jobData.failure,
      err
    })
  }
}

export function* ensureGetJobNotes() {
  yield put({ type: pageIsLoading, payload: true })
  const headers = yield select(M.userToken)

  if (!headers) return

  const {
    params: { id }
  } = yield select(Router.matchRoutes)

  try {
    const { data } = yield call(Api.getSMJobNotes, {
      headers: { Authorization: `Bearer ${headers}` },
      id
    })

    yield put({ type: A.getJobNotes.success, payload: I.fromJS(data.data.reverse()) })
  } catch (err) {
    yield put({
      type: A.getJobNotes.failure,
      err
    })
  } finally {
    yield put({ type: pageIsLoading, payload: false })
  }
}

export function* ensurePostJobNotes(props: { payload: { note: string }; type: string }) {
  yield put({ type: pageIsLoading, payload: true })
  const headers = yield select(M.userToken)

  if (!headers) return

  const user = yield select(M.userSelector)
  const jobNotes = yield select(jobNotesSelector)

  const {
    params: { id }
  } = yield select(Router.matchRoutes)

  try {
    const { data } = yield call(Api.postSMJobNotes, {
      headers: { Authorization: `Bearer ${headers}` },
      id,
      data: props.payload
    })
    const newNotes = [{ ...data.data, createdBy: user.toJS() }, ...jobNotes.toJS()]
    yield put({ type: A.getJobNotes.success, payload: I.fromJS(newNotes) })
  } catch (err) {
    yield put({
      type: A.addNoteToJob.failure,
      err
    })
  } finally {
    yield put({ type: pageIsLoading, payload: false })
  }
}

export function* ensureDeleteJobNote(props: { payload: { noteId: number }; type: string }) {
  yield put({ type: pageIsLoading, payload: true })
  const headers = yield select(M.userToken)

  if (!headers) return
  const jobNotes = yield select(jobNotesSelector)

  const {
    params: { id }
  } = yield select(Router.matchRoutes)

  try {
    yield call(Api.deleteSMJobNotes, {
      headers: { Authorization: `Bearer ${headers}` },
      jobId: id,
      noteId: props.payload.noteId
    })

    const newJobNotes = jobNotes.toJS().filter((note: NoteType) => note.id !== props.payload.noteId)
    yield put({ type: A.getJobNotes.success, payload: I.fromJS(newJobNotes) })
  } catch (err) {
    yield put({
      type: A.deleteNoteInJob.failure,
      err
    })
  } finally {
    yield put({ type: pageIsLoading, payload: false })
  }
}

export function* ensureEditJobNote(props: {
  payload: { noteId: number; noteText: string }
  type: string
}) {
  yield put({ type: pageIsLoading, payload: true })
  const headers = yield select(M.userToken)

  if (!headers) return
  const jobNotes = yield select(jobNotesSelector)

  const {
    params: { id }
  } = yield select(Router.matchRoutes)

  try {
    const { data } = yield call(Api.putSMJobNotes, {
      headers: { Authorization: `Bearer ${headers}` },
      jobId: id,
      noteId: props.payload.noteId,
      data: { note: props.payload.noteText }
    })

    const newJobNotes = jobNotes
      .toJS()
      .map((item: NoteType) => (item.id === props.payload.noteId ? data.data : item))

    yield put({ type: A.getJobNotes.success, payload: I.fromJS(newJobNotes) })
  } catch (err) {
    yield put({
      type: A.editNoteInJob.failure,
      err
    })
  } finally {
    yield put({ type: pageIsLoading, payload: false })
  }
}
