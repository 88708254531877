import { EducationInterface } from 'pages/Recruiter/Candidates/AddEditProfile/types'

export const transformData = (data: EducationInterface) => {
  return {
    school: data.school,
    degree: data.degree,
    study: data.study,
    from: Number(data.from.value),
    to: !data.to.value || data.to.value < 0 ? null : Number(data.to.value)
  }
}
