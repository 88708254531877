import React from 'react'
// components
import { Button } from 'components/Button'
import NotificationTitle from 'pages/Notifications/NotificationTitle'
// types
import { Classes } from 'jss'
import { ButtonTypes } from 'types/common'
// styles
import injectSheet from 'react-jss'
import { sheet } from './sheet'
// utils
import cx from 'classnames'

interface Props {
  classes: Classes
  title: string
  jobName: string
  path: string
  isMenu: boolean
  viewedAt: boolean
  createdAt: string
}

const Index = ({ classes, title, jobName, path, isMenu, viewedAt, createdAt }: Props) => {
  return (
    <div className={cx(classes.root, { [classes.menu]: isMenu }, { [classes.unread]: viewedAt })}>
      <NotificationTitle title={title} />
      <p className={classes.text}>
        for <Button isLink={true} to={path} label={jobName} type={ButtonTypes.NotificationLink} />
      </p>
      <p className="createdAt">{createdAt}</p>
    </div>
  )
}

export default injectSheet(sheet)(Index)
