import { all, call, take, takeLatest, fork } from 'redux-saga/effects'
import { signInPage, signUpPage } from 'pages/Auth/sagas'
import * as Job from 'domain/guestJobs'

export function* jobsListGuest() {
  yield all([fork(getJobsList), fork(signInPage), fork(signUpPage)])
}

function* getJobsList() {
  yield call(Job.ensureGetJobsGuest)
  yield takeLatest(Job.guestJobsList.type, Job.ensureGetJobsGuest)
  yield take(Job.guestJobsList.success)
}
