import React from 'react'
import injectSheet from 'react-jss'
import { sheet } from './sheet'
import { Classes } from 'jss'

const Index = ({ classes }: { classes: Classes }) => {
  return (
    <div className={classes.root}>
      <p>Support</p>
    </div>
  )
}
export default injectSheet(sheet)(Index)
