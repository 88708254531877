import { Styles } from 'react-jss'

export const sheet: Styles = {
  button: {},
  location: {},
  error: {},
  phone: {},
  phoneLabel: {},
  phoneSelect: {},
  root: {
    overflow: 'hidden',
    padding: 30,
    '& $phone': {
      display: 'flex',
      justifyContent: 'space-between',
      '& > div:first-child': {
        flex: 1,
        marginRight: 10
      },
      '& > div:last-child': {
        flex: 3
      }
    },
    '& > h4': {
      margin: 0
    },
    '& > $location': {
      marginBottom: '2em'
    },
    '& > $button': {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: 40
    },
    '& > $error': {
      fontWeight: 400,
      letterSpacing: '0.01em',
      fontSize: 12,
      color: '#dc233b',
      margin: '0 0 0 2px',
      wordBreak: 'break-all'
    },
    '& $phoneLabel': {
      display: 'inline-block',
      color: '#080910',
      fontSize: 15,
      fontWeight: 700,
      margin: '0 0 10px 0'
    },
    '& $phoneSelect': {
      margin: 0
    }
  }
}
