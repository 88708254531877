import { rules } from 'lib/validationRules'

interface EmailType {
  email?: string
}

type EmailsArrayType = EmailType[]

type ValidatorType = (values: { [emails: string]: EmailsArrayType }) => {}

const validateEmails: ValidatorType = values => {
  let sameEmail: EmailType | undefined
  const errors: { emails?: EmailsArrayType } = {}
  const emailsArrayErrors: EmailsArrayType = []
  if (values.emails && values.emails.length) {
    values.emails.forEach((item: EmailType, emailIndex: number) => {
      const emailsErrors: EmailType = {}
      emailsErrors.email = rules.required(item.email) || rules.email(item.email)
      if (item.email && !emailsErrors.email) {
        sameEmail = checkSameValue(values.emails, item.email, emailIndex)
      }
      if (!emailsErrors.email && sameEmail) {
        emailsErrors.email = 'Try another email address'
      }
      emailsArrayErrors[emailIndex] = emailsErrors
      if (emailsArrayErrors.length) {
        errors.emails = emailsArrayErrors
      }
    })
  }
  return errors
}

type CheckSameValueType = (
  values: EmailsArrayType,
  item: string,
  ind: number
) => EmailType | undefined
const checkSameValue: CheckSameValueType = (values, item, ind) =>
  values.find((data: EmailType, index: number) => !!item && data.email === item && index !== ind)

export default validateEmails
