import { asyncAction } from 'lib/actionCreators'
import { createRoutine, promisifyRoutine } from 'redux-saga-routines'

export const postJob = createRoutine('manager/POST_JOB')
export const putJob = createRoutine('manager/PUT_JOB')
export const deleteJob = createRoutine('manager/DELETE_JOB')
export const closeJob = createRoutine('manager/CLOSE_JOB')
export const closeJobPromiseCreator = promisifyRoutine(closeJob)

export const getAllJobs = asyncAction('manager/GET_ALL_JOBS')
export const jobsList = asyncAction('manager/JOBS_LIST')
export const newJobsList = asyncAction('manager/NEW_JOBS_LIST')
export const jobData = asyncAction('manager/JOB')
export const holdJob = asyncAction('manager/HOLD_JOB')
export const candidatesForCloseJob = asyncAction('manager/CANDIDATES_FOR_CLOSE_JOB')
export const recruitersForCloseJob = asyncAction('manager/RECRUITERS_FOR_CLOSE_JOB')
export const getManagersList = asyncAction('manager/GET-MANAGERS_LIST')
