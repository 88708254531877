import I from 'immutable'
import * as A from './actions'
import * as Env from 'domain/env'
import { Action } from 'redux'

interface ActionType extends Action {
  payload: any
}

const State = I.fromJS({
  workExperiencesList: I.List(),
  workExperience: I.Map()
})

export const reducer = {
  experience(state = State, action: ActionType) {
    switch (action.type) {
      case A.workExperiencesList.success:
        return state.set('workExperiencesList', action.payload)
      case A.workExperience.success:
        return state.set('workExperience', action.payload)
      case Env.signOut.success:
        return State

      default:
        return state
    }
  }
}
