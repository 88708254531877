import React, { Component } from 'react'
// redux
import { InjectedFormProps, reduxForm, WrappedFieldArrayProps } from 'redux-form'
import { compose, Dispatch } from 'redux'
import { connect } from 'react-redux'
import { recruitersEmails } from 'domain/manager/selectors'
import { inviteRecruitersPromiseCreator, inviteRecruiters } from 'domain/manager/recruiters'
import { bindPromiseCreators, PromiseCreator } from 'redux-saga-routines'
// components
import { Button } from 'components/Button'
import { BorderColor, FieldInput } from 'components/Form/Fields/FieldInput'
import { FieldCustomArray } from 'components/Form/Fields/FieldCustomArray'
import Input from 'components/Form/Input'
// types
import { ButtonHtmlTypes, ButtonTypes, ComposeType } from 'types/common'
import { Classes } from 'jss'
import { InvitedRecruitersEmailsType } from 'types/managerTypes'
import { List } from 'immutable'
import { StateInterface } from 'types/state'
// styles
import { sheet } from './sheet'
import injectSheet from 'react-jss'
// utils
import validateEmails from './validation'
// icons
import PlusBlue from 'components/svgComponent/PlusBlue'
import Minus from 'components/svgComponent/Minus'

interface ExternalProps {
  toggleModal: () => void
}

interface OwnProps {
  classes: Classes
  inviteRecruiters: (data?: Array<{ email: string }>) => void
  recruiters: List<InvitedRecruitersEmailsType>
  inviteRecruitersPromiseCreator: PromiseCreator
  dispatch: Dispatch
}

type Props = InjectedFormProps<{ emails?: Array<{ email: string }> }> & OwnProps & ExternalProps

interface State {
  emailCount: number
  formError: string
}

type CheckEmailType = (i: number) => BorderColor | undefined
class InviteRecruiters extends Component<Props, State> {
  state = {
    emailCount: 1,
    formError: ''
  }

  submit = ({ emails }: { emails?: Array<{ email: string }> }) => {
    if (emails && emails.length) {
      const promise = this.props.inviteRecruitersPromiseCreator(emails, this.props.dispatch)
      promise.then(
        () => {
          this.setState({ formError: '' })
          const filter =
            this.props.recruiters && this.props.recruiters.filter(item => !item.get('available'))
          if (!filter || filter.isEmpty()) {
            this.props.inviteRecruiters()
            this.props.toggleModal()
          }
        },
        failurePayload => {
          const errorText =
            failurePayload &&
            failurePayload.err &&
            failurePayload.err.response &&
            failurePayload.err.response.data &&
            ((failurePayload.err.response.data.errors &&
              failurePayload.err.response.data.errors.length > 0 &&
              failurePayload.err.response.data.errors[0].message) ||
              failurePayload.err.response.data.message)
          this.setState({
            formError: errorText || 'Something went wrong!'
          })
        }
      )
    }
  }

  checkEmail: CheckEmailType = ind => {
    const { recruiters } = this.props
    const recruiter = recruiters && !recruiters.isEmpty() && recruiters.get(ind)
    if (recruiter && !recruiter.isEmpty()) {
      return recruiter.get('available') ? BorderColor.Success : BorderColor.ErrorEmail
    }
    return
  }

  inputArray = (
    props: WrappedFieldArrayProps<{ email: string }> & {
      recruiters: List<InvitedRecruitersEmailsType>
    }
  ) => {
    const { fields } = props
    const { classes } = this.props
    const addField = () => {
      fields.push({ email: '' })
    }
    if (fields.length < 1) fields.push({ email: '' })
    return (
      <div className={classes.arrayFields}>
        <p className={classes.title}>
          Enter email address <span>(1 or more)</span>
        </p>
        {fields.map((item, i) => {
          const remove = () => {
            fields.remove(i)
          }
          return (
            <div key={i} className={classes.field}>
              <FieldInput
                name={`${item}.email`}
                type="text"
                component={Input}
                placeholder="email@example.co"
                borderColor={this.checkEmail(i)}
              />
              {fields.length > 1 && <Minus onClick={remove} />}
            </div>
          )
        })}
        <Button
          label="add email address"
          type={ButtonTypes.BlueButton}
          Icon={PlusBlue}
          onClick={addField}
        />
      </div>
    )
  }

  render() {
    const { classes, handleSubmit, recruiters } = this.props
    const { formError } = this.state
    return (
      <form onSubmit={handleSubmit(this.submit)}>
        <div className={classes.root}>
          <h4>Invite recruiter</h4>
          {formError && <p className={classes.error}>{formError}</p>}
          <p>
            Only invited recruiters can work with you. To invite – type email of recruiter(s) you
            want to invite to the system. Once they receive mail with a link to Skilent they will
            able to register here and apply to jobs. You will see registered Candidates that you
            have invited in your Dashboard.
          </p>
          <FieldCustomArray name="emails" component={this.inputArray} recruiters={recruiters} />
          <div className={classes.button}>
            <Button
              type={ButtonTypes.Submit}
              htmlType={ButtonHtmlTypes.Submit}
              label="send invite"
            />
          </div>
        </div>
      </form>
    )
  }
}

export default compose<ComposeType<Props, ExternalProps>>(
  connect(
    (state: StateInterface) => ({
      recruiters: recruitersEmails(state).get('emails')
    }),
    dispatch => ({
      dispatch,
      inviteRecruiters: () => dispatch(inviteRecruiters()),
      ...bindPromiseCreators(
        {
          inviteRecruitersPromiseCreator
        },
        dispatch
      )
    })
  ),
  reduxForm({
    form: 'invite-recruiters',
    validate: validateEmails,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true
  }),
  injectSheet(sheet)
)(InviteRecruiters)
