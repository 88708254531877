import { citiesSelector, countriesSelector, phoneCodesSelector } from 'domain/locations'
import { loading } from 'domain/loading'
import { getFormValues } from 'redux-form'
import { clientSelector } from 'domain/manager/selectors'
import { SelectListT } from 'types/common'
import { StateInterface } from 'types/state'
import { ExternalProps } from './index'
import { getPhoneCodes } from 'pages/Profile/MainInfo/helpers'
import { normalizePhone } from 'lib/normalize'

export const collectiveInitialValues = (state: StateInterface, ownProps: ExternalProps) => {
  const countriesList: SelectListT = countriesSelector(state).toJS()
  const selectors = {
    isLoading: loading(state),
    formValues: getFormValues('client')(state),
    countriesList,
    citiesList: citiesSelector(state).toJS(),
    phoneCodes: phoneCodesSelector(state).toJS(),
    phonePrefix: getPhoneCodes(state)
  }
  if (ownProps.isNewClient) return selectors
  const client = clientSelector(state)
  const clientInitial = client && !client.isEmpty() && client.toJS()
  const initialCity = clientInitial &&
    clientInitial.city && {
      value: clientInitial.city.id,
      label: `${clientInitial.city.name}, ${
        clientInitial.state ? `${clientInitial.state.name}, ` : ''
      }${clientInitial.country.name}`
    }
  const countryId = clientInitial && clientInitial.country && clientInitial.country.id
  const phoneCodes: SelectListT = phoneCodesSelector(state).toJS()
  const phonePrefix = clientInitial
    ? phoneCodes.find(code => code.label === clientInitial.phonePrefix)
    : undefined
  const phone = clientInitial && clientInitial.phone
  return {
    ...selectors,
    client,
    initialValues: {
      ...clientInitial,
      phonePrefix,
      phone: normalizePhone(clientInitial && clientInitial.phonePrefix)(phone),
      country: countriesList.find(country => country.value === countryId),
      city: initialCity
    }
  }
}
