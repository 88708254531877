import { compose } from 'redux'
import { rules, addError } from 'lib/validationRules'

type AuthValidator = (values: { [x: string]: string }) => (errors: {}, fieldName: string) => {}
const authValidator: AuthValidator = values => (errors, fieldName): {} => {
  const val = values[fieldName]
  switch (fieldName) {
    case 'companyName':
      return compose(addError(fieldName, rules.required(val)))(errors)

    case 'city':
      return compose(addError(fieldName, rules.required(val)))(errors)

    case 'contactPerson':
      return compose(addError(fieldName, rules.required(val)))(errors)

    case 'contactPersonsPosition':
      return compose(addError(fieldName, rules.required(val)))(errors)

    case 'email':
      return compose(
        addError(fieldName, rules.email(val)),
        addError(fieldName, rules.required(val))
      )(errors)

    default:
      return errors
  }
}

export const validateClient = (values: { [x: string]: string }) =>
  ['companyName', 'city', 'contactPerson', 'contactPersonsPosition', 'email'].reduce(
    authValidator(values),
    {}
  )
