import React from 'react'
import Svg, { SvgType } from './Svg'

export default function(props: SvgType) {
  return (
    <Svg width="112" height="30" viewBox="0 0 112 30" fill="none" {...props}>
      <path
        d="M32.7604 23.4907L31.0528 25.2454V29.7975H28.4345V15.1986H31.0528V22.0468L32.5017 20.3122L36.9104 15.1986H40.0772L34.4783 21.6658L40.398 29.7975H37.2933L32.7604 23.4907Z"
        fill="#FAFAFA"
      />
      <path d="M41.9608 29.7975H44.5687V15.1986H41.9608V29.7975Z" fill="#FAFAFA" />
      <path
        d="M81.7786 29.7975H79.1603L72.4437 19.4399V29.7975H69.8254V15.1986H72.4437L79.181 25.5963V15.1986H81.7786V29.7975Z"
        fill="#FAFAFA"
      />
      <path
        d="M90.7513 17.244H95.4498V15.1986H83.4966V17.244H88.1537V29.7975H90.7513V17.244Z"
        fill="#FAFAFA"
      />
      <path
        d="M22.8149 24.5535C23.284 24.9011 23.5186 25.3958 23.5186 26.0375C23.5186 26.6257 23.2875 27.1003 22.8252 27.4613C22.6129 27.6264 22.3512 27.7531 22.0399 27.8413C21.8604 27.8921 21.6642 27.9302 21.4516 27.9556C21.355 27.967 21.2551 27.9757 21.1518 27.9817L20.9943 27.9894C20.9231 27.9917 20.8503 27.9927 20.7761 27.9927C19.7205 27.9927 18.9098 27.7654 18.3441 27.3109C17.7783 26.8563 17.4023 26.2146 17.4023 25.3857L14.9333 26.1099C15.0333 26.7307 15.2664 27.2915 15.6326 27.7922C16.1432 28.4807 16.8538 29.0221 17.7645 29.4165C18.6821 29.8042 19.686 29.998 20.7761 29.998C22.4251 29.998 23.7291 29.6404 24.6881 28.9252C25.654 28.2033 26.137 27.234 26.137 26.0174C26.137 25.2688 25.9714 24.617 25.6402 24.0622C25.309 23.5007 24.7881 23.0061 24.0775 22.5783C23.3668 22.1438 22.4251 21.7594 21.2522 21.4252C20.5629 21.225 19.988 21.0224 19.5275 20.8176C19.2044 20.6735 18.9376 20.5285 18.727 20.3824C18.2164 20.0214 17.9612 19.5736 17.9612 19.0388C17.9612 18.4038 18.1957 17.9091 18.6649 17.5549C19.141 17.2006 19.8067 17.0234 20.6623 17.0234C21.5799 17.0234 22.2836 17.2407 22.7735 17.6752C23.2703 18.103 23.5186 18.6979 23.5186 19.4599L26.0062 18.4544C25.8925 17.9978 25.698 17.5676 25.4229 17.1638C24.9537 16.4686 24.3086 15.9339 23.4876 15.5595C22.6666 15.1852 21.7317 14.998 20.683 14.998C19.103 14.998 17.8163 15.3791 16.8228 16.1411C15.8361 16.9031 15.3428 17.8724 15.3428 19.0488C15.3428 20.3924 16.0293 21.482 17.4023 22.3176C18.1198 22.7521 19.0858 23.1464 20.3001 23.5007C21.5144 23.855 22.3526 24.2059 22.8149 24.5535Z"
        fill="#FAFAFA"
      />
      <path
        d="M47.0808 29.7975H56.8578L56.1043 27.7721H49.6991V15.1986H47.0808V29.7975Z"
        fill="#FAFAFA"
      />
      <path
        d="M60.4595 21.2748H66.6482L66.1631 23.2801H60.4595V27.7721H67.4L68.0685 29.7975H57.8411V15.1986H67.621L66.8369 17.244H60.4595V21.2748Z"
        fill="#FAFAFA"
      />
      <rect width="17.92" height="18" rx="8.96" transform="matrix(1 0 0 -1 0 18)" fill="#286EFA" />
    </Svg>
  )
}
