import React from 'react'
import Svg, { SvgType } from './Svg'

export default function(props: SvgType) {
  return (
    <Svg width="20" height="15" viewBox="0 0 20 15" fill="none" {...props}>
      <rect width="20" height="15" rx="1" stroke="#E6E6E6" strokeWidth="4" />
      <path d="M1.25 2.76367L10 7.50051L19.1667 2.76367" stroke="#DEDEDE" />
    </Svg>
  )
}
