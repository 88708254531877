import React from 'react'
import Svg, { SvgType } from 'components/svgComponent/Svg'

export default function(props: SvgType) {
  return (
    <Svg width="11" height="13" viewBox="0 0 11 13" fill="none" {...props}>
      <path d="M5.5 2L10.3889 8.11109L0.611085 8.11109L5.5 2Z" fill="#FAFAFA" />
    </Svg>
  )
}
