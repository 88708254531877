import React from 'react'
// components
import Avatar from 'components/Avatar'
import NotificationTitle from 'pages/Notifications/NotificationTitle'
// redux
import { Button } from 'components/Button'
// types
import { Classes } from 'jss'
import { ButtonTypes } from 'types/common'
// styles
import injectSheet from 'react-jss'
import { sheet } from './sheet'
// utils
import cx from 'classnames'

interface Props {
  classes: Classes
  avatar?: string
  title: string
  pathTitle: string
  text: string
  pathButton: string
  buttonText: string
  viewedAt: boolean
  onlyText: boolean
  endText: string
  createdAt: string
}

const Index = ({
  classes,
  avatar,
  title,
  text,
  pathButton,
  pathTitle,
  buttonText,
  viewedAt,
  onlyText,
  endText,
  createdAt
}: Props) => {
  return (
    <div className={cx(classes.main, { [classes.unread]: viewedAt })}>
      {!endText && <Avatar className={classes.img} src={avatar} />}
      <div className={classes.root}>
        {!endText && <NotificationTitle title={title} path={pathTitle} />}
        <p className={classes.text}>
          {`${text} `}
          {!onlyText && (
            <Button
              isLink={true}
              to={pathButton}
              label={buttonText}
              type={ButtonTypes.NotificationLink}
            />
          )}
          {` ${endText}`}
        </p>
      </div>
      <p className="createdAt">{createdAt}</p>
    </div>
  )
}

export default injectSheet(sheet)(Index)
