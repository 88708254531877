import React, { FC, LegacyRef } from 'react'
// components
import UserInfo from 'pages/NavBar/UserInfo'
import { Button } from 'components/Button'
// types
import { Classes } from 'jss'
import { ButtonTypes, UserType } from 'types/common'
// styles
import { sheet } from './sheet'
import injectSheet from 'react-jss'
// icons
import Active from 'components/svgComponent/Active'
import NonActive from 'components/svgComponent/NonActive'
import Plus from 'components/svgComponent/Plus'
import LogOut from 'components/svgComponent/LogOut'
// utils
import { ServerRoles } from 'lib/roles'

interface Props {
  classes: Classes
  user: UserType
  signOut: () => void
  openCloseMenu: () => void
  setWrapperRef: LegacyRef<HTMLDivElement>
  toggleModalInvite: () => void
  toggleModalAddClient: () => void
}
const Menu: FC<Props> = ({
  classes,
  user,
  signOut,
  openCloseMenu,
  setWrapperRef,
  toggleModalInvite,
  toggleModalAddClient
}) => {
  const invite = () => {
    openCloseMenu()
    toggleModalInvite()
  }

  const addClient = () => {
    openCloseMenu()
    toggleModalAddClient()
  }

  return (
    <div className={classes.root} ref={setWrapperRef}>
      <div>
        <UserInfo
          email={user.get('email')}
          photo={user.get('avatar')}
          firstName={user.get('firstName')}
          lastName={user.get('lastName')}
          isPopUp={true}
        />
        <Button
          buttonStyle={true}
          isLink={true}
          label="profile settings"
          to={`/${user.get('role')}/profile`}
          type={ButtonTypes.BlueButton}
          onClick={openCloseMenu}
        />
      </div>
      {user.get('role') === ServerRoles.manager && (
        <div className={classes.linkGroup}>
          <Button Icon={Plus} label="Invite Recruiter" onClick={invite} />
          <Button
            buttonStyle={true}
            isLink={true}
            Icon={Active}
            label="Post new job"
            to="/manager/jobs/new"
            onClick={openCloseMenu}
          />
          <Button Icon={NonActive} label="Add Client" onClick={addClient} />
        </div>
      )}
      <Button Icon={LogOut} label="Log Out" className={classes.logOut} onClick={signOut} />
    </div>
  )
}

export default injectSheet(sheet)(Menu)
