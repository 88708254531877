import { asyncAction } from 'lib/actionCreators'

export const recruiterJobsInProgress = asyncAction('recruiter/DASHBOARD_JOBS_IN_PROGRESS')
export const recruiterJobsCounters = asyncAction('recruiter/DASHBOARD_JOBS_COUNTER')
export const recruiterClosedJobs = asyncAction('recruiter/DASHBOARD_CLOSED_JOBS')
export const recruiterNewClosedJobs = asyncAction('recruiter/DASHBOARD_NEW_CLOSED_JOBS')
export const recruiterRecruiterSkills = asyncAction('recruiter/DASHBOARD_RECRUITER_SKILLS')
export const recruiterClients = asyncAction('recruiter/DASHBOARD_CLIENTS')
export const recruiterStatistics = asyncAction('recruiter/DASHBOARD_STATISTICS')
export const recruiterTopRecruiters = asyncAction('recruiter/DASHBOARD_TOP_RECRUITERS')
