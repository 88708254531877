import React, { FC } from 'react'
// types
import { Classes } from 'jss'
import { WrappedFieldMetaProps } from 'redux-form'
// styles
import injectSheet, { Styles } from 'react-jss'
// utils
import cx from 'classnames'

const style: Styles = {
  errorText: {
    color: '#dc233b',
    fontSize: 12
  }
}

interface Props {
  meta: WrappedFieldMetaProps
  classes: Classes
  className?: string
}

const Message: FC<Props> = ({ meta, classes, className }) => {
  const isError = meta.touched && meta.error
  return isError ? <span className={cx(classes.errorText, className)}>{meta.error}</span> : null
}

export default injectSheet(style)(Message)
