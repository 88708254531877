import { asyncAction } from 'lib/actionCreators'

export const setUser = asyncAction('messages/SET_USER')
export const addChannel = asyncAction('messages/ADD_CHANNEL')
export const addChannels = asyncAction('messages/ADD_CHANNELS')
export const updateChannel = asyncAction('messages/UPDATE_CHANNEL')
export const removeChannel = asyncAction('messages/REMOVE_CHANNEL')
export const moveChannelUp = asyncAction('messages/MOVE_CHANNEL_UP')
export const setGroupChannelsExist = asyncAction('messages/SET_GROUP_CHANNELS_EXIST')
export const setUnreadChannelsCount = asyncAction('messages/SET_UNREAD_CHANNELS_COUNT')

export const addMessage = asyncAction('messages/ADD_MESSAGE')
export const addMessages = asyncAction('messages/ADD_MESSAGES')
export const setIsConnected = asyncAction('messages/SET_IS_CONNECTED')
export const setIsListening = asyncAction('messages/SET_IS_LISTENING')
export const setActiveChannelUrl = asyncAction('messages/SET_ACTIVE_CHANNEL_URL')
export const setUnreadMessagesCount = asyncAction('messages/SET_UNREAD_MESSAGES_COUNT')
export const updateMessage = asyncAction('messages/UPDATE_MESSAGE')

export const clear = asyncAction('messages/CLEAR')

export const startConversation = asyncAction('messages/START_CONVERSATION')
export const sendTextMessage = asyncAction('messages/SEND_TEXT_MESSAGE')
export const sendFileMessage = asyncAction('messages/SEND_FILE_MESSAGE')
export const markAsRead = asyncAction('messages/MARK_AS_READ')
