import { Styles } from 'react-jss'

export const sheet: Styles = {
  img: {},
  reverse: {},
  error: {},
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 30,
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: 17,
    lineHeight: '21px',
    letterSpacing: '0.01em',
    position: 'relative',
    color: '#989FA6',
    '& > $error': {
      fontWeight: 400,
      letterSpacing: '0.01em',
      fontSize: 12,
      color: '#dc233b',
      margin: '0 0 0 2px',
      wordBreak: 'break-all'
    },
    '& > p': {
      textAlign: 'center',
      margin: '4px 0'
    },
    '& > h3': {
      margin: '0 0 10px 0',
      textAlign: 'center'
    },
    '& > button': {
      marginTop: '3vw',
      width: 131
    },
    '&:before, &:after': {
      content: `""`,
      position: 'absolute',
      top: '0',
      bottom: '0',
      width: '30%',
      pointerEvents: 'none'
    },
    '&:before': {
      left: '0',
      background: `url("${require('components/svg/leftParticles.svg')}") no-repeat center center`
    },
    '&:after': {
      right: '0',
      background: ` url("${require('components/svg/rightParticles.svg')}") no-repeat center center`
    }
  },
  job: {
    fontSize: 24,
    lineHeight: '28px',
    color: '#080910'
  },
  relations: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '2vw 0 2vw 0',
    '& > svg': {
      margin: '0 12px'
    },
    '& > div': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      flex: 1,
      justifyContent: 'flex-start',
      '& > $img, & img': {
        marginRight: 12,
        width: 64,
        height: 64,
        borderRadius: '50%',
        boxShadow: 'inset 0px 0px 5px rgba(0, 0, 0, 0.25)'
      }
    },
    '& > $reverse': {
      flexDirection: 'row-reverse',
      '& > $img': {
        marginLeft: 12,
        marginRight: 0
      },
      '& img': {
        marginLeft: 0,
        marginRight: 0
      }
    }
  },
  role: {
    fontWeight: 'normal',
    fontSize: 15,
    lineHeight: '20px',
    color: '#989FA6',
    margin: 0
  },
  name: {
    color: '#080910',
    margin: 0
  }
}
