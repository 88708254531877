import React from 'react'
import Svg, { SvgType } from 'components/svgComponent/Svg'

export default function(props: SvgType) {
  return (
    <Svg width="30" height="30" viewBox="0 0 30 30" fill="none" {...props}>
      <path d="M11.2222 15.2227L15.1111 19.1115L21.7777 10.2227" stroke="#286EFA" strokeWidth="2" />
      <circle cx="15" cy="15" r="14" stroke="#286EFA" strokeWidth="2" />
    </Svg>
  )
}
