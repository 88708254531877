import React from 'react'
import Svg, { SvgType } from 'components/svgComponent/Svg'

export default function(props: SvgType) {
  const { iconActive } = props
  const fill = iconActive ? '#FAFAFA' : '#E6E6E6'
  const opacity = iconActive ? '1' : '0.5'
  return (
    <Svg width="22" height="22" viewBox="0 0 22 22" fill="none" {...props}>
      <g opacity={opacity}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.9141 6.41406C11.9141 6.89844 11.8398 7.36523 11.6992 7.80371C11.1094 9.65527 9.375 10.998 7.32812 10.998C4.79688 10.998 2.74609 8.94531 2.74609 6.41406C2.74609 3.88281 4.79688 1.83105 7.32812 1.83105C9.85938 1.83105 11.9141 3.88281 11.9141 6.41406ZM10.0781 6.41406C10.0781 7.93359 8.84766 9.16406 7.32812 9.16406C6.42578 9.16406 5.625 8.72754 5.12109 8.05371C4.98047 7.86426 4.86328 7.65625 4.77344 7.43457C4.64844 7.11914 4.57812 6.77441 4.57812 6.41406C4.57812 5.66504 4.87891 4.98633 5.36328 4.49023C5.86328 3.98047 6.55859 3.66406 7.32812 3.66406C8.84766 3.66406 10.0781 4.89551 10.0781 6.41406Z"
          fill={fill}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.6992 7.80371C12.2891 5.95215 14.0195 4.6123 16.0664 4.6123C18.5977 4.6123 20.6523 6.66406 20.6523 9.19531C20.6523 11.7266 18.5977 13.7793 16.0664 13.7793C13.5352 13.7793 11.4844 11.7266 11.4844 9.19531C11.4844 8.94238 11.5039 8.69336 11.543 8.45117C11.582 8.22949 11.6328 8.01367 11.6992 7.80371ZM16.0664 11.9453C17.5859 11.9453 18.8164 10.7148 18.8164 9.19531C18.8164 8.33008 18.418 7.55859 17.793 7.05469C17.3203 6.67383 16.7188 6.44531 16.0664 6.44531C15.2656 6.44531 14.543 6.78809 14.0391 7.33496C13.5898 7.82422 13.3164 8.47754 13.3164 9.19531C13.3164 9.55566 13.3867 9.90039 13.5156 10.2168C13.5977 10.4219 13.7031 10.6143 13.8281 10.791C14.3281 11.4893 15.1445 11.9453 16.0664 11.9453Z"
          fill={fill}
        />
        <path
          d="M15.4844 16.4971H18.3281C19.3398 16.4971 20.1602 17.3184 20.1602 18.3311C20.1602 19.3428 19.3398 20.1641 18.3281 20.1641H11.9102V21.9971H18.3281C19.6562 21.9971 20.8164 21.291 21.4609 20.2344C21.7969 19.6797 21.9922 19.0283 21.9922 18.3311C21.9922 16.3057 20.3516 14.6641 18.3281 14.6641H15.4844V16.4971Z"
          fill={fill}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.0781 11.915H4.57812C2.55078 11.915 0.910156 13.5566 0.910156 15.582C0.910156 17.6064 2.55078 19.248 4.57812 19.248H10.0781C12.1016 19.248 13.7422 17.6064 13.7422 15.582C13.7422 13.5566 12.1016 11.915 10.0781 11.915ZM2.74219 15.582C2.74219 14.5693 3.5625 13.748 4.57812 13.748H10.0781C11.0898 13.748 11.9102 14.5693 11.9102 15.582C11.9102 16.5938 11.0898 17.415 10.0781 17.415H4.57812C3.5625 17.415 2.74219 16.5938 2.74219 15.582Z"
          fill={fill}
        />
      </g>
    </Svg>
  )
}
