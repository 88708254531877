import React, { PureComponent } from 'react'
// components
import Layout from '../Layout'
// redux
import { WrappedFieldProps } from 'redux-form'
import ReactTags, { ReactTagsProps } from 'react-tag-autocomplete'
// types
import { Classes } from 'jss'
import { TagPropsType, TagType } from 'components/Form/Fields/FieldTags'
// styles
import { sheet } from './sheet'
import injectSheet from 'react-jss/lib/injectSheet'
// utils
import cx from 'classnames'
import debounce from 'lodash/debounce'
import get from 'lodash/get'
import Api from 'domain/api'

interface OwnProps {
  classes: Classes
}

type Props = OwnProps &
  ReactTagsProps &
  TagPropsType &
  React.InputHTMLAttributes<HTMLInputElement> &
  WrappedFieldProps

const MIN_TAG_LENGTH = 2
const MAX_TAG_LENGTH = 40

class Tags extends PureComponent<Props> {
  state = {
    suggestions: [],
    error: ''
  }

  debouncedRequest = debounce((q: string) => {
    if (q.length >= 2) {
      Api.getTags({ params: { q } }).then(response => {
        this.setState({
          suggestions: get(response, 'data.data')
            .filter((tag: any) => !this.checkTagInInput(tag))
            .sort((a: { name: string }, b: { name: string }) => a.name.length - b.name.length)
        })
      })
    }
  }, 500)

  checkTagInInput = (tag: TagType) => {
    const values = this.props.input.value || []
    return values.some((item: TagType) => item.id === tag.id)
  }

  regexValidate = (value: string) =>
    value
      .replace(/[^a-zA-Z0-9 !@#$%^&*()+_\-=|,\/.~]/g, '')
      .replace(/\r\n\t/g, '')
      .trim()
      .replace(/\s\s+/g, ' ')

  handleInputChange = (value: string) => {
    this.setState({ error: '' })
    const regexValue = this.regexValidate(value)
    this.debouncedRequest(regexValue)
  }

  handleDelete = (i: number) => {
    if (this.props.inputAttributes && this.props.inputAttributes.readOnly) return
    const tags = this.props.input.value.slice(0)
    if (tags) tags.splice(i, 1)
    this.props.input.onChange(tags)
  }

  handleAddition = (tag: { name: string }) => {
    const regexValue = this.regexValidate(tag.name)

    if (tag.name.length > MAX_TAG_LENGTH) {
      this.setState({ error: `Length must be less than ${MAX_TAG_LENGTH}` })
      return
    }

    if (regexValue.length < MIN_TAG_LENGTH) {
      this.setState({ error: 'Incorrect value' })
      return
    }
    const formatTag = { ...tag, name: regexValue }

    const filteredArray =
      this.props.input &&
      this.props.input.value &&
      this.props.input.value.filter((element: { name: string }) => {
        return element.name === formatTag.name
      })
    const tags = filteredArray.length
      ? [...this.props.input.value]
      : [...this.props.input.value, formatTag]
    this.props.input.onChange(tags)
    this.setState({
      suggestions: this.state.suggestions.filter(
        (item: { name: string }) => item.name !== formatTag.name
      )
    })
  }

  suggestionsFilter = () => true

  render() {
    const { input, classes, name, title, heightAuto, inputAttributes, allowNew = true } = this.props

    const { suggestions, error } = this.state
    const meta = error
      ? { ...this.props.meta, error, submitFailed: true, touched: true }
      : { ...this.props.meta }

    return (
      <Layout title={title} meta={meta} id={name}>
        <div className={classes.main}>
          <ReactTags
            minQueryLength={2}
            name={name}
            inputAttributes={inputAttributes}
            tags={input.value || []}
            handleDelete={this.handleDelete}
            handleAddition={this.handleAddition}
            handleInputChange={this.handleInputChange}
            suggestionsFilter={this.suggestionsFilter}
            suggestions={suggestions}
            autofocus={false}
            allowNew={allowNew}
            autoresize={false}
            placeholder=""
            classNames={{
              root: cx(
                classes.root,
                'react-tags',
                { [classes.heightAuto]: heightAuto },
                { [classes.error]: meta.touched && meta.error }
              ),
              rootFocused: classes.rootFocused,
              selected: classes.selected,
              selectedTag: classes.selectedTag,
              selectedTagName: 'react-tags__selected-tag-name',
              search: cx('react-tags__search', classes.search),
              searchInput: classes.searchInput,
              suggestions: cx('react-tags__suggestions', classes.suggestions),
              suggestionActive: cx('is-active'),
              suggestionDisabled: cx('is-disabled')
            }}
            tagComponent={({ tag, ...props }) => {
              const isLong = tag && tag.name.length > 40
              return (
                <button
                  onClick={e => {
                    e.preventDefault()
                    return props.onDelete(e)
                  }}
                  className={cx(classes.selectedTag, { [classes.longTag]: isLong })}
                >
                  {tag.name}
                  {isLong && <span style={{ color: 'red' }}> [{tag.name.length} symbols]</span>}
                </button>
              )
            }}
            {...input}
          />
        </div>
      </Layout>
    )
  }
}

export default injectSheet(sheet)(Tags)
