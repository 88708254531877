import { asyncAction } from 'lib/actionCreators'
import { createRoutine, promisifyRoutine } from 'redux-saga-routines'

export const recruiterEventsList = asyncAction('RECRUITER_EVENTS_LIST')
export const recruiterCandidatesList = asyncAction('RECRUITER_CANDIDATES_LIST')
export const recruiterEvent = asyncAction('RECRUITER_EVENT')
export const recruiterEventUpdate = createRoutine('RECRUITER_EVENT_UPDATE')
export const recruiterEventDelete = createRoutine('RECRUITER_EVENT_DELETE')
export const recruiterEventAdd = createRoutine('RECRUITER_EVENT_ADD')
export const recruiterEventUpdatePromisify = promisifyRoutine(recruiterEventUpdate)
export const recruiterEventDeletePromisify = promisifyRoutine(recruiterEventDelete)
export const recruiterEventAddPromisify = promisifyRoutine(recruiterEventAdd)
export const nearestEventId = asyncAction('NEAREST_EVENT_ID')
export const candidateJobsList = asyncAction('CANDIDATE_JOBS_LIST')
