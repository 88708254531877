import { all, takeLatest, take, fork } from 'redux-saga/effects'
import * as A from 'domain/manager/clients'

export function* clientsPage() {
  yield all([fork(getClient), fork(deleteClient), fork(updateClient)])
}

function* getClient() {
  yield takeLatest(A.getClient.type, A.ensureGetClient)
  yield take(A.getClient.success)
}

function* deleteClient() {
  yield takeLatest(A.deleteClient.TRIGGER, A.ensureDeleteClient)
  yield take(A.deleteClient.SUCCESS)
}

function* updateClient() {
  yield takeLatest(A.updateClient.TRIGGER, A.ensurePutClient)
  yield take(A.updateClient.SUCCESS)
}
