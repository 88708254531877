import React, { ReactNode, ReactNodeArray, FC } from 'react'
// libs
import cx from 'classnames'
// components
import Message from 'components/Form/Message'
// types
import { Classes } from 'jss'
import { WrappedFieldMetaProps } from 'redux-form'
// styles
import injectSheet from 'react-jss'
import { fieldsClasses } from './sheet'

interface Props {
  id: string
  children?: ReactNodeArray | ReactNode
  meta: WrappedFieldMetaProps
  title?: string
  classes: Classes
  customClasses?: Classes | string
}

const Layout: FC<Props> = ({ classes, children, id, meta, title, customClasses }) => (
  <div className={cx(classes.inputRow, customClasses)}>
    {title && (
      <div className={classes.wrapper}>
        <label htmlFor={id} className={classes.labelText}>
          {title}
        </label>
      </div>
    )}
    <div className={classes.inputRoot}>{children}</div>
    <Message meta={meta} />
  </div>
)

export default injectSheet(fieldsClasses)(Layout)
