import { combineReducers, createStore, applyMiddleware, compose, Store } from 'redux'
import { routerMiddleware } from 'connected-react-router'
import { initialState } from 'domain/env'
import { History } from 'history'
import createSagaMiddleware, { END } from 'redux-saga'

const sagaMiddleware = createSagaMiddleware()

interface MyStore extends Store {
  runSaga: typeof sagaMiddleware.run
  close: () => END
}

function configureStore(history: History) {
  const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
  const initialStates = { env: initialState() }
  const store: MyStore = createStore(
    combineReducers({
      ...require('./env').reducer,
      ...require('./guestJobs').reducer,
      ...require('./locations').reducer,
      ...require('./manager/reducer').reducer,
      ...require('./candidate/reducer').reducer,
      ...require('./resume').reducer,
      ...require('./educations').reducer,
      ...require('./workExperiences').reducer,
      ...require('./recruiter/reducer').reducer,
      ...require('./loading').reducer,
      ...require('./tags').reducer,
      ...require('./messages').reducer,
      ...require('./notifications').reducer,
      ...require('./constants').reducer,
      ...require('./superManager/reducer').reducer,
      ...require('./router/reducer').reducer,
      ...require('./jobs/reducer').reducer,
      // add other modules here
      form: require('redux-form').reducer
    }),
    initialStates,
    composeEnhancers(applyMiddleware(sagaMiddleware, routerMiddleware(history)))
  )

  store.runSaga = sagaMiddleware.run
  store.close = () => store.dispatch(END)
  return store
}

export default configureStore
