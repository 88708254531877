import { all, call, take, takeLatest, fork, put } from 'redux-saga/effects'
import * as Notifications from 'domain/notifications/index'
import { pageIsLoading } from 'domain/loading'

export function* notificationsPage() {
  yield all([
    fork(getNotifications),
    fork(getUnreadNotifications),
    fork(fetchNotificationsWithLoader)
  ])
}

function* getNotifications() {
  yield takeLatest(Notifications.notificationsList.type, Notifications.ensureGetNotifications)
  yield take(Notifications.notificationsList.success)
}

function* getUnreadNotifications() {
  yield takeLatest(
    Notifications.unreadNotifications.type,
    Notifications.ensureGetUnreadNotifications
  )
  yield take(Notifications.unreadNotifications.success)
}

function* markNotificationsAsViewed() {
  yield takeLatest(
    Notifications.notificationsAsViewed.type,
    Notifications.ensurePostNotificationsAsViewed
  )
  yield take(Notifications.notificationsAsViewed.success)
}

export function* fetchNotifications() {
  yield all([fork(markNotificationsAsViewed), fork(getInitialNotifications)])
}

export function* getInitialNotifications() {
  yield call(Notifications.ensureGetNotifications)
  yield call(Notifications.ensureGetUnreadNotifications)
}

function* fetchNotificationsWithLoader() {
  try {
    yield put({ type: pageIsLoading, payload: true })
    yield call(getInitialNotifications)
  } finally {
    yield put({ type: pageIsLoading, payload: false })
  }
}
