import React from 'react'
import Svg, { SvgType } from 'components/svgComponent/Svg'

export default function(props: SvgType) {
  return (
    <Svg width="30" height="30" viewBox="0 0 30 30" fill="none" {...props}>
      <path
        d="M11.9457 20H18.748V18.4214H14.4812L16.7373 16.0569C17.3553 15.3839 17.7915 14.7945 18.046 14.2886C18.3005 13.7827 18.4277 13.2859 18.4277 12.7981C18.4277 11.9083 18.1437 11.2195 17.5757 10.7317C17.0122 10.2439 16.2193 10 15.1969 10C14.529 10 13.9314 10.1423 13.4043 10.4268C12.8772 10.7069 12.4683 11.0953 12.1775 11.5921C11.8912 12.089 11.748 12.6378 11.748 13.2385H13.7247C13.7247 12.7416 13.8519 12.3419 14.1064 12.0393C14.3654 11.7322 14.7175 11.5786 15.1628 11.5786C15.5763 11.5786 15.8944 11.7051 16.1171 11.958C16.3397 12.2064 16.4511 12.5497 16.4511 12.9878C16.4511 13.3085 16.3443 13.6472 16.1307 14.0041C15.9217 14.3609 15.5968 14.7787 15.156 15.2575L11.9457 18.6585V20Z"
        fill="#286EFA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30 15C30 23.2843 23.2843 30 15 30C6.71573 30 0 23.2843 0 15C0 6.71573 6.71573 0 15 0C23.2843 0 30 6.71573 30 15ZM28 15C28 22.1797 22.1797 28 15 28C7.8203 28 2 22.1797 2 15C2 7.8203 7.8203 2 15 2C22.1797 2 28 7.8203 28 15Z"
        fill="#286EFA"
      />
    </Svg>
  )
}
