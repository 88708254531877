import { all, call, take, takeLatest, fork, put } from 'redux-saga/effects'
import { isLoading } from 'domain/loading'
import { clientsPage } from 'pages/Manager/Clients/List/AddEdit/sagas'
import * as A from 'domain/manager/clients'
import * as L from 'domain/locations'

export function* clients() {
  yield all([fork(getClientsList), fork(clientsPage), fork(initialLoad)])
}

function* getClientsList() {
  yield takeLatest(A.clientsList.type, A.ensureGetClientsList)
  yield take(A.clientsList.success)
}

function* initialLoad() {
  yield put({ type: isLoading, payload: true })
  try {
    yield all([call(A.ensureGetClientsList), call(L.ensureGetCounties)])
    yield put({ type: 'profile/CLEAR_CITIES' })
  } finally {
    yield put({ type: isLoading, payload: false })
  }
}
