import { Styles } from 'react-jss'

export const sheet: Styles = {
  circle: {},
  mobileMenu: {
    display: 'none'
  },
  root: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#202332',
    height: 52,
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '8px 11px',
    position: 'relative',
    width: '100%',
    maxWidth: 'inherit',
    boxSizing: 'border-box',
    gridArea: 'nav',
    '& p.createdAt': {
      width: '100%',
      textAlign: 'right',
      color: '#565656',
      fontSize: 12,
      margin: '5px 0 0',
      alignSelf: 'flex-end'
    }
  },
  leftHeader: {
    display: 'flex',
    paddingLeft: 30,
    alignItems: 'center',
    justifyContent: 'space-between',
    flex: 1,
    '& > a': {
      margin: 0,
      '&:hover, &:focus': {
        color: '#565656',
        textDecoration: 'none'
      }
    },
    '& > div': {
      maxWidth: 400
    }
  },
  rightHeader: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'flex-end'
  },
  arrow: {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    height: '100%',
    top: 0,
    right: 0
  },
  notifications: {
    position: 'relative',
    cursor: 'default',
    '& > $circle': {
      position: 'absolute',
      width: 24,
      height: 24,
      backgroundColor: '#286EFA',
      fontWeight: '500',
      lineHeight: '24px',
      fontSize: 13,
      textAlign: 'center',
      letterSpacing: '0.03em',
      textTransform: 'capitalize',
      color: '#F0F0F0',
      borderRadius: '50%',
      top: 0,
      right: 0
    }
  },
  clickArea: {
    cursor: 'default'
  },
  '@media (max-width: 767px)': {
    mobileMenu: {
      display: 'block'
    }
  }
}
