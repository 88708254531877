import { all, takeLatest, take, fork, call, put, select } from 'redux-saga/effects'
import I from 'immutable'
import { isLoading } from 'domain/loading'
import { jobStatusSelector } from 'domain/constants'
import * as A from 'domain/recruiter/candidates'
import * as S from 'domain/candidate/status'
import * as J from 'domain/jobs'

interface MatchI {
  params: {
    id?: string
  }
}
export function* profilePage(location: any, match: MatchI) {
  yield all([
    fork(getProfile),
    fork(getSelfAssignJobs),
    fork(getJobs),
    fork(assignCandidate),
    fork(unassignedCandidate),
    fork(savedCandidate),
    fork(acceptSelfAssign),
    fork(declineSelfAssign),
    fork(getCandidateJobs, match),
    fork(getStatus),
    fork(getNote),
    fork(addNote),
    fork(deleteNote),
    fork(editNote)
  ])
}

function* getCandidateJobs(match: MatchI) {
  const jobStatus = yield select(jobStatusSelector)
  yield call(A.ensureGetCandidateJobs, {
    payload: {
      id: match.params.id,
      params: { status: jobStatus.get('closed'), perPage: 10, page: 1 }
    },
    type: A.candidateJobs.type
  })
  yield takeLatest(A.candidateJobs.type, A.ensureGetCandidateJobs)
}

function* getProfile() {
  yield put({
    type: A.candidateProfile.success,
    payload: I.Map()
  })
  yield call(A.ensureGetCandidateProfile)
  yield takeLatest(A.candidateProfile.type, A.ensureGetCandidateProfile)
  yield take(A.candidateProfile.success)
}

function* getJobs() {
  yield takeLatest(A.jobsToAssignCandidate.type, A.ensureJobsToAssignedCandidate)
  yield take(A.jobsToAssignCandidate.success)
}

function* getSelfAssignJobs() {
  yield put({ type: isLoading, payload: true })
  yield call(A.ensureJobsSelfAssignedCandidate)
  yield takeLatest(A.selfAssignJobCandidate.TRIGGER, A.ensureJobsSelfAssignedCandidate)
  yield take(A.selfAssignJobCandidate.SUCCESS)
}

function* assignCandidate() {
  yield takeLatest(A.assignJobToCandidate.TRIGGER, A.ensureAssignedCandidate)
  yield take(A.assignJobToCandidate.SUCCESS)
}

function* unassignedCandidate() {
  yield takeLatest(A.unassignedJobToCandidate.TRIGGER, A.ensureUnassignedCandidate)
  yield take(A.unassignedJobToCandidate.SUCCESS)
}

function* savedCandidate() {
  yield takeLatest(A.candidateFavorite.type, A.ensureAddCandidateToFavorite)
  yield take(A.candidateFavorite.success)
}

function* acceptSelfAssign() {
  yield takeLatest(A.acceptSelfAssignJobCandidate.type, A.ensureAcceptSelfAssign)
  yield take(A.acceptSelfAssignJobCandidate.success)
}

function* declineSelfAssign() {
  yield takeLatest(A.declineSelfAssignJobCandidate.type, A.ensureDeclineSelfAssign)
  yield take(A.declineSelfAssignJobCandidate.success)
}

function* getStatus() {
  yield takeLatest(S.candidateStatus.type, S.ensureGetCandidateStatus)
  yield take(S.candidateStatus.success)
}

function* getNote() {
  yield takeLatest(J.getCandidateJobNotes.type, J.ensureGetCandidateJobNotes)
  yield take(J.getCandidateJobNotes.success)
}

function* addNote() {
  yield takeLatest(J.addCandidateJobNote.type, J.ensurePostCandidateJobNotes)
  yield take(J.addCandidateJobNote.success)
}

function* deleteNote() {
  yield takeLatest(J.deleteCandidateJobNote.type, J.ensureDeleteCandidateJobNote)
  yield take(J.deleteCandidateJobNote.success)
}

function* editNote() {
  yield takeLatest(J.editCandidateJobNote.type, J.ensureEditCandidateJobNote)
  yield take(J.editCandidateJobNote.success)
}
