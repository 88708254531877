import moment from 'moment'
import { EmploymentTypeT } from 'domain/constants'
import { FormValuesJobForManager } from 'types/job/jobTypesManager'

interface Rate {
  rateFrom?: number
  rateTo?: number
  currency?: string
}

export const transformJob = (
  {
    rateFrom,
    rateTo,
    salaryFrom,
    salaryTo,
    salaryCurrency,
    rateCurrency,
    ...values
  }: FormValuesJobForManager,
  employmentTypeConst: EmploymentTypeT
) => {
  const {
    country,
    city,
    travelPercent,
    travel,
    relocation,
    startAt,
    endAt,
    employeeType,
    client
  } = values
  const { w2Type, ...rest } = values
  const data =
    employeeType && employeeType[employmentTypeConst.get('w2')]
      ? { ...values, w2Checked: true }
      : rest
  const recruiters =
    data.recruiters && data.recruiters.length > 0
      ? data.recruiters.filter(item => item && item.value).map(item => item && item.value)
      : []
  const hourly: Rate = {}
  const salary: Rate = {}
  const hourlyRateFrom = rateFrom && Number(rateFrom)
  const hourlyRateTo = rateTo && Number(rateTo)
  const salaryRateFrom = salaryFrom && Number(salaryFrom)
  const salaryRateTo = salaryTo && Number(salaryTo)
  if (hourlyRateFrom) hourly.rateFrom = hourlyRateFrom
  if (hourlyRateTo) hourly.rateTo = hourlyRateTo
  if (salaryRateFrom) salary.rateFrom = salaryRateFrom
  if (salaryRateTo) salary.rateTo = salaryRateTo
  if (hourly.rateFrom || hourly.rateTo) {
    hourly.currency = rateCurrency && rateCurrency.label
  }
  if (salary.rateFrom || salary.rateTo) {
    salary.currency = salaryCurrency && salaryCurrency.label
  }

  return {
    ...data,
    startAt: moment(startAt).startOf('day'),
    endAt: moment(endAt).endOf('day'),
    countryId: 0, // TODO: remove after countryId
    cityId: city ? city.value : undefined,
    clientId: client ? client.value : '',
    employeeType:
      employeeType && Object.keys(employeeType).filter((key: string) => employeeType[key]),
    hourly: Object.keys(hourly).length > 0 ? hourly : undefined,
    salary: Object.keys(salary).length > 0 ? salary : undefined,
    salaryCurrency: salaryCurrency ? salaryCurrency.label : 0,
    rateCurrency: rateCurrency ? rateCurrency.label : 0,
    travel: travel === 'yes' ? true : travel === 'no' ? false : null,
    relocation: relocation === 'true' ? true : relocation === 'false' ? false : null,
    travelPercent: travelPercent ? travelPercent.value : null,
    backgroundCheck: true,
    clearanceType: Object.keys(data.clearanceType).filter(
      (key: string) => !!data.clearanceType[key] && key !== 'none'
    ),
    employmentType: Object.keys(data.employmentType).filter(
      (key: string) => !!data.employmentType[key] && key !== 'employmentTypeDesc'
    ),
    employmentTypeDesc: data.employmentType ? data.employmentType.employmentTypeDesc : null,
    citizenship: Object.keys(data.citizenship).filter(
      (key: string) => data.citizenship[key] && key !== 'citizenshipDesc'
    ),
    citizenshipDesc: data.citizenship ? data.citizenship.citizenshipDesc : null,
    recruiters
  }
}
