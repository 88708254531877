import { ExperienceTypeInterface } from 'pages/Recruiter/Candidates/AddEditProfile/types'

export const transformData = (data: ExperienceTypeInterface) => {
  return {
    title: data.title,
    company: data.company,
    location: data.location,
    isWorking: Boolean(data.isWorking),
    fromYear: Number(data.fromYear.value),
    fromMonth: Number(data.fromMonth.value),
    toYear:
      data.toYear && data.toYear.value && data.toYear.value > 0 ? Number(data.toYear.value) : null,
    toMonth: data.toMonth && data.toMonth.value ? Number(data.toMonth.value) : null
  }
}
