import React from 'react'
import Svg, { SvgType } from 'components/svgComponent/Svg'

export default function(props: SvgType) {
  return (
    <Svg width="28" height="29" viewBox="0 0 28 29" fill="none" {...props}>
      <mask id="path-1-inside-1" fill="white">
        <rect x="5.33325" y="16.3242" width="7.22222" height="7.2973" rx="1" />
      </mask>
      <rect
        x="5.33325"
        y="16.3242"
        width="7.22222"
        height="7.2973"
        rx="1"
        stroke="#286EFA"
        strokeWidth="4"
        mask="url(#path-1-inside-1)"
      />
      <path
        d="M1 11.9459V6.10811H8.22222M1 11.9459V28H27V11.9459M1 11.9459H27M27 11.9459V6.10811H19.7778M19.7778 6.10811V1M19.7778 6.10811H8.22222M8.22222 6.10811V1"
        stroke="#286EFA"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  )
}
