import { currency } from './selectOptions'
import {
  SpheresImmutableT,
  CountryI,
  CityI,
  ImmutableMap,
  SpheresExist,
  SpheresItemExist,
  SelectListT,
  SelectItemValueStringI
} from 'types/common'
import { JobsForFilter } from 'types/recruitersTypes'
import { List } from 'immutable'
import get from 'lodash/get'
import { Citizenship, CITIZENSHIP_KEYS, CitizenshipI, CitizenshipT } from '../domain/constants'

type Job = ImmutableMap<{
  city: CityI
  country: CountryI
  employeeType: List<string>
  employmentType: List<string>
  id: number
  rateCurrency: string
  rateFrom: number
  rateTo: number
  relocation: boolean
  salaryCurrency: string
  salaryFrom: number
  salaryTo: number
  spheres: SpheresImmutableT
  title: string
  travelPercent: number
  w2Type: string
}>

interface FailurePayloadI {
  err?: {
    response?: {
      data?: {
        message?: string
        error?: Array<{ message?: string }>
      }
    }
  }
}

export const getParamsForRequestCandidates = (job: Job, scope: string) => {
  let filter = {}
  if (job && !job.isEmpty()) {
    const skills =
      job.get('spheres') &&
      job.get('spheres').map((item: ImmutableMap<{ id: number }>) => `${item.get('id')}`)
    filter = {
      countryId: (job.get('country') && job.getIn(['country', 'id'])) || undefined,
      cityId: (job.get('city') && job.getIn(['city', 'id'])) || undefined,
      tagIds: skills,
      rateFrom: job.get('rateFrom') || undefined,
      rateTo: job.get('rateTo') || undefined,
      rateCurrency: job.get('rateCurrency') || undefined,
      salaryFrom: job.get('salaryFrom') || undefined,
      salaryTo: job.get('salaryTo') || undefined,
      salaryCurrency: job.get('salaryCurrency') || undefined,
      relocation: job.get('relocation'),
      job: job.get('id')
    }
  }

  return {
    page: 1,
    scope,
    filter
  }
}

export const clearanceTypeToObject = (clearanceTypes: string[] = []) =>
  clearanceTypes.reduce((p: { [key: string]: boolean }, c: string) => ({ ...p, [c]: true }), {})

export const citizenshipToObject = (citizenship: string[] = []) =>
  citizenship.reduce((p: { [key: string]: boolean }, c: string) => ({ ...p, [c]: true }), {} as any)

export const jobsForSearchCandidate = (
  job: JobsForFilter,
  countriesList: SelectListT,
  citizenshipConst: CitizenshipT
) => {
  const cityLabel = getLocationString(
    job.city && job.city.name,
    job.country && job.country.name,
    job.state && job.state.name
  )
  const countryId = job && job.country && job.country.id
  const currentCountry = countriesList.find(country => country.value === countryId)
  const city = job &&
    job.city && {
      value: job.city.id,
      label: cityLabel,
      lat: job.city.lat,
      lng: job.city.lng,
      name: job.city.name
    }
  const skills = job && job.spheres
  const rateFrom = job && job.rateFrom
  const rateTo = job && job.rateTo
  const rateCurrency = currency.find(item => item.label === job.rateCurrency) || currency[0]
  const salaryFrom = job && job.salaryFrom
  const salaryTo = job && job.salaryTo
  const salaryCurrency = currency.find(item => item.label === job.salaryCurrency) || currency[0]
  const relocation = job && !job.relocation ? 'false' : 'true'
  const clearanceType = job && clearanceTypeToObject(job.clearanceType)
  const citizenship =
    job && getCitizenshipValues(citizenshipConst, job.citizenship, job.citizenshipDesc)

  return {
    city,
    country: currentCountry,
    skills,
    rateFrom,
    rateTo,
    rateCurrency,
    salaryFrom,
    salaryTo,
    salaryCurrency,
    relocation,
    clearanceType,
    citizenship
  }
}

export const handleResponseErrors = (failurePayload: FailurePayloadI) => {
  return (
    get(failurePayload, 'err.response.data.error[0].message') ||
    get(failurePayload, 'err.response.data.message')
  )
}

export const getLocationString = (
  city?: string | null,
  country?: string | null,
  state?: string | null
) => {
  const location = []
  if (city) location.push(city)
  if (state) location.push(state)
  if (country) location.push(country)
  return location.join(', ')
}

interface UserData {
  city?: { id: number; name: string }
  state?: { name: string } | null
  country?: { name: string } | null
}
export function buildLocationName(userData: UserData) {
  const locationParts = []

  if (userData.city) {
    locationParts.push(userData.city.name)

    if (userData.state) {
      locationParts.push(userData.state.name)
    }

    if (userData.country) {
      locationParts.push(userData.country.name)
    }
  }

  return locationParts.join(', ')
}

export const getStateNameForUser = (userData: any) => {
  if (userData.state) {
    return userData.state.name
  } else if (userData.city && userData.city.state) {
    return userData.city.state.name
  }

  return
}

export const getCountryNameForUser = (userData: any) => {
  if (userData.country) {
    return userData.country.name
  } else if (userData.city && userData.city.country) {
    return userData.city.country.name
  }

  return
}

function extractDigits(label: string, meaningfulDigits: number = 4) {
  return label.replace(/[^\d]/g, '').substr(0, meaningfulDigits)
}

export const removeDuplicatesByLabel = (optionsList: Array<{ value: number; label: string }>) => {
  return optionsList.filter(
    (item, index, self) => index === self.findIndex(t => t.label === item.label)
  )
}

export const sortPhoneCodes = (phoneCodes: Array<{ value: number; label: string }>) =>
  phoneCodes.sort((itemA, itemB) => {
    const digitsA = extractDigits(itemA.label, 4)
    const digitsB = extractDigits(itemB.label, 4)

    if (digitsA > digitsB) return 1
    if (digitsA < digitsB) return -1
    return 0
  })

export const skillsComparatorByTagIds = (tagIds?: Array<{ id: number }>) => {
  if (!tagIds || !tagIds.length) {
    return () => 0
  }

  return (a: { id: number }, b: { id: number }) => {
    const orderMap = new Map()
    tagIds.forEach((tag: { id: number }, index: number) => {
      orderMap.set(tag.id, index)
    })

    const aIndex = orderMap.get(a.id)
    const bIndex = orderMap.get(b.id)

    if (aIndex !== undefined && bIndex !== undefined) {
      return aIndex - bIndex
    }

    if (aIndex !== undefined) {
      return -1
    }

    if (bIndex !== undefined) {
      return 1
    }

    return 0
  }
}

export const isFilteredTag = (filter: any, item: any) => {
  if (!filter || !filter.tagIds || !filter.tagIds.length) return false
  const filteredIds = filter.tagIds.map((tagItem: { id: any }) => tagItem.id)

  return filteredIds.includes(item.id)
}

export function getCitizenshipValues(
  citizenshipConst: CitizenshipT,
  citizenship?: string[] | null,
  citizenshipDesc?: string | null
) {
  if (!citizenship) return {}

  const result = {} as Record<Citizenship, boolean> & { citizenshipDesc?: string }

  for (const key of CITIZENSHIP_KEYS) {
    const citizenshipValue = citizenshipConst.get(key) as keyof CitizenshipI
    if (citizenshipValue) result[citizenshipValue] = citizenship.includes(citizenshipValue)
  }

  if (citizenshipDesc) {
    result.citizenshipDesc = citizenship.includes(citizenshipConst.get(Citizenship.optional))
      ? citizenshipDesc
      : undefined
  }

  return result
}

export function bytesToStr(input: string | number): string {
  let bytes: number

  if (typeof input === 'string') {
    bytes = parseInt(input, 10)
  } else {
    bytes = input
  }

  // Check if the parsed number is negative or not a number
  if (isNaN(bytes) || bytes < 0) {
    return ''
  }

  const KB: number = 1024
  const MB: number = KB ** 2

  if (bytes < MB) {
    return `${Math.round(bytes / KB)} Kb`
  } else {
    return `${Math.round((bytes / MB) * 10) / 10} Mb` // Round to 1 decimal place
  }
}
