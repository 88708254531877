import { asyncAction } from 'lib/actionCreators'

export const jobsList = asyncAction('recruiter/JOBS_LIST')
export const newJobsList = asyncAction('recruiter/NEW_JOBS_LIST')
export const jobData = asyncAction('recruiter/JOB')
export const getJobsCount = asyncAction('recruiter/GET_JOBS_COUNT')

export const getJobNotes = asyncAction('recruiter/GET_JOB_NOTES')
export const addNoteToJob = asyncAction('recruiter/ADD_NOTE_TO_JOB')
export const deleteNoteInJob = asyncAction('recruiter/DELETE_NOTE_IN_JOB')
export const editNoteInJob = asyncAction('recruiter/EDIT_NOTE_IN_JOB')
