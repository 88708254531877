import { put, call, select } from 'redux-saga/effects'
import I from 'immutable'
import Api from 'domain/api'
import * as Actions from './actions'
import * as M from 'domain/env'

export function* ensureHMList() {
  const headers = yield select(M.userToken)
  if (!headers) return
  try {
    const {
      data: { data }
    } = yield call(Api.getHM, {
      headers: { Authorization: `Bearer ${headers}` }
    })
    yield put({ type: Actions.HMList.success, payload: I.fromJS(data) })
  } catch (err) {
    yield put({
      type: Actions.HMList.failure,
      err
    })
  }
}
