import React, { ReactNode, ComponentClass } from 'react'
import makeCancelable from 'lib/makeCancelable'
import { Route } from 'react-router-dom'

interface Props {
  import: () => Promise<ReactNode>
  path: string
  exact?: boolean
}

interface State {
  Component: ComponentClass | null
}

interface CancelableType {
  promise: Promise<any>
  cancel: () => void
}

export default class AsyncRoute extends React.Component<Props, State> {
  state: { Component: ComponentClass | null } = { Component: null }
  cancelable?: CancelableType

  componentDidMount() {
    this.cancelable = makeCancelable(this.props.import())
    this.cancelable.promise
      .then(({ default: Component }) => {
        this.setState({ Component })
      })
      .catch(err => {
        if (!err.isCanceled) throw err
      })
  }

  componentWillUnmount() {
    if (this.cancelable) this.cancelable.cancel()
  }

  render() {
    const { Component } = this.state
    const { path, exact } = this.props
    return (
      <Route
        path={path}
        exact={exact}
        render={props => (Component ? <Component {...props} /> : null)}
      />
    )
  }
}
