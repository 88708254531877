import { all, call, take, takeLatest, fork } from 'redux-saga/effects'
import * as A from 'domain/manager/calendar'

export function* calendar() {
  yield all([
    fork(getCandidatesList),
    fork(getEventsList),
    fork(addEvent),
    fork(getEvent),
    fork(deleteEvent),
    fork(updateEvent),
    fork(addUnavailability),
    fork(getUnavailability),
    fork(deleteUnavailability),
    fork(updateUnavailability),
    fork(getCandidateJob)
  ])
}

function* getCandidatesList() {
  yield call(A.ensureCandidatesList)
  yield takeLatest(A.managerCandidatesList.type, A.ensureCandidatesList)
  yield take(A.managerCandidatesList.success)
}

function* getEventsList() {
  yield call(A.ensureEventsList)
  yield takeLatest(A.managerEventsList.type, A.ensureEventsList)
  yield take(A.managerEventsList.success)
}

function* addEvent() {
  yield takeLatest(A.managerEventAdd.TRIGGER, A.ensureAddEvent)
  yield take(A.managerEventAdd.SUCCESS)
}

function* getEvent() {
  yield takeLatest(A.managerEvent.type, A.ensureEvent)
  while (true) {
    const data = yield take(A.managerEvent.success)
    yield call(A.ensureCandidateJobsList, {
      payload: { value: data.payload.get('candidateId') }
    })
  }
}

function* getCandidateJob() {
  yield takeLatest((action: any) => {
    if (
      action.type === '@@redux-form/CHANGE' &&
      action.meta.form === 'add-event' &&
      action.meta.field === 'candidate'
    ) {
      return action
    }
  }, A.ensureCandidateJobsList)
}

function* deleteEvent() {
  yield takeLatest(A.managerEventDelete.TRIGGER, A.ensureRemoveEvent)
  yield take(A.managerEventDelete.SUCCESS)
}

function* updateEvent() {
  yield takeLatest(A.managerEventUpdate.TRIGGER, A.ensureUpdateEvent)
  yield take(A.managerEventUpdate.SUCCESS)
}

function* addUnavailability() {
  yield takeLatest(A.unavailabilityAdd.TRIGGER, A.ensureAddUnavailability)
  yield take(A.unavailabilityAdd.SUCCESS)
}

function* getUnavailability() {
  yield takeLatest(A.unavailability.type, A.ensureUnavailability)
  yield take(A.unavailability.success)
}

function* deleteUnavailability() {
  yield takeLatest(A.unavailabilityDelete.TRIGGER, A.ensureRemoveUnavailability)
  yield take(A.unavailabilityDelete.SUCCESS)
}

function* updateUnavailability() {
  yield takeLatest(A.unavailabilityUpdate.TRIGGER, A.ensureUpdateUnavailability)
  yield take(A.unavailabilityUpdate.SUCCESS)
}
