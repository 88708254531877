import { put, call, select } from 'redux-saga/effects'
import { error403, modalIsLoading, pageIsLoading, skeleton } from 'domain/loading'
import { candidatesSelector } from 'domain/manager/selectors'
import { transformCandidate } from 'domain/manager/helpers'
import I from 'immutable'
import Api from 'domain/api'
import * as Actions from './actions'
import * as M from 'domain/env'
import * as Router from 'domain/router'

interface Params {
  order?: string
  scope?: string
  page?: number
}

export function* ensureGetCandidateProfile() {
  yield put({ type: error403, payload: false })
  const headers = yield select(M.userToken)
  if (!headers) return
  const {
    params: { candidateId }
  } = yield select(Router.matchRoutes)
  try {
    yield put({ type: pageIsLoading, payload: true })
    const {
      data: { data }
    } = yield call(Api.getCandidateProfileM, {
      headers: { Authorization: `Bearer ${headers}` },
      id: candidateId
    })
    yield put({
      type: Actions.candidateProfile.success,
      payload: transformCandidate(I.fromJS(data))
    })
    if (data && !data.isViewed) {
      yield call(ensureCandidateAsViewed)
    }
  } catch (err) {
    yield put({
      type: Actions.candidateProfile.success,
      payload: I.fromJS({})
    })
    const { statusCode } = err.response.data
    if (statusCode === 403 || statusCode === 404) {
      yield put({ type: error403, payload: true })
    }
    yield put({
      type: Actions.candidateProfile.failure,
      err
    })
  } finally {
    yield put({ type: pageIsLoading, payload: false })
  }
}

type WithLoadingT = boolean | undefined

export function* ensureGetCandidates(
  props?: { payload?: Params; type?: string } | null,
  withLoading: WithLoadingT = true
) {
  const headers = yield select(M.userToken)
  if (!headers) return
  if (withLoading && (!props || !props.payload || props.payload.page === 1)) {
    yield put({ type: skeleton, payload: true })
    yield put({ type: pageIsLoading, payload: true })
  }
  const {
    params: { id }
  } = yield select(Router.matchRoutes)
  const params = (props && props.payload) || {}
  try {
    const { data } = yield call(Api.getCandidatesM, {
      headers: { Authorization: `Bearer ${headers}` },
      id,
      params
    })
    const page = params.page
    const total = data.meta.total
    const prevState = yield select(candidatesSelector)
    if (!params || !page || page === 1 || total === prevState.size) {
      yield put({ type: Actions.candidatesNewList.success, payload: I.fromJS(data) })
    } else {
      yield put({ type: Actions.candidates.success, payload: I.fromJS(data) })
    }
  } catch (err) {
    yield put({
      type: Actions.candidates.failure,
      err
    })
  } finally {
    yield put({ type: skeleton, payload: false })
    yield put({ type: pageIsLoading, payload: false })
  }
}

export function* ensureAddCandidateToFavorite() {
  const headers = yield select(M.userToken)
  if (!headers) return
  const {
    params: { candidateId }
  } = yield select(Router.matchRoutes)
  try {
    yield put({ type: pageIsLoading, payload: true })
    const {
      data: { data }
    } = yield call(Api.addJobToFavoriteCandidate, {
      headers: { Authorization: `Bearer ${headers}` },
      id: candidateId,
      type: 'candidate'
    })
    yield put({ type: Actions.candidateFavorite.success, payload: I.fromJS(data) })
    yield call(ensureGetCandidateProfile)
  } catch (err) {
    yield put({
      type: Actions.candidateFavorite.failure,
      err
    })
  } finally {
    yield put({ type: pageIsLoading, payload: false })
  }
}

export function* ensureAddCandidateToFavoriteFromList(props: {
  payload: { id: number }
  type: string
}) {
  const headers = yield select(M.userToken)
  if (!headers) return
  const id = props && props.payload && props.payload.id
  try {
    const {
      data: { data }
    } = yield call(Api.addJobToFavoriteCandidate, {
      headers: { Authorization: `Bearer ${headers}` },
      id,
      type: 'candidate'
    })
    yield put({
      type: Actions.candidateFavoriteFromList.success,
      payload: I.fromJS({ id, value: data })
    })
  } catch (err) {
    yield put({
      type: Actions.candidateFavorite.failure,
      err
    })
  }
}

export function* ensureCloseJobCandidateProfile({
  payload
}: {
  payload: {
    recruiterId: number
    candidateId: number
  }
  type: string
}) {
  const headers = yield select(M.userToken)
  if (!headers) return
  const {
    params: { candidateId }
  } = yield select(Router.matchRoutes)
  try {
    const {
      data: { data }
    } = yield call(Api.applyCandidateM, {
      headers: { Authorization: `Bearer ${headers}` },
      id: candidateId,
      data: payload
    })
    yield put({ type: Actions.closeJobCandidateProfile.SUCCESS, payload: I.fromJS(data) })
    yield call(ensureGetCandidateProfile)
  } catch (err) {
    yield put({
      type: Actions.closeJobCandidateProfile.FAILURE,
      err
    })
  }
}

export function* ensureDeclineCandidate() {
  const headers = yield select(M.userToken)
  if (!headers) return
  const {
    params: { candidateId }
  } = yield select(Router.matchRoutes)
  try {
    const {
      data: { data }
    } = yield call(Api.declineCandidateM, {
      headers: { Authorization: `Bearer ${headers}` },
      id: candidateId
    })
    yield put({ type: Actions.candidateDecline.SUCCESS, payload: I.fromJS(data) })
    yield call(ensureGetCandidateProfile)
  } catch (err) {
    yield put({
      type: Actions.candidateDecline.FAILURE,
      err
    })
  }
}

export function* ensureJobsForCloseJobCandidateProfile() {
  const headers = yield select(M.userToken)
  if (!headers) return
  const {
    params: { candidateId }
  } = yield select(Router.matchRoutes)
  try {
    yield put({ type: modalIsLoading, payload: true })
    yield put({ type: Actions.jobsForCloseJobCandidateProfile.success, payload: I.List() })
    const {
      data: { data }
    } = yield call(Api.getJobsForCandidateCloseJobM, {
      headers: { Authorization: `Bearer ${headers}` },
      id: candidateId
    })
    yield put({ type: Actions.jobsForCloseJobCandidateProfile.success, payload: I.fromJS(data) })
  } catch (err) {
    yield put({
      type: Actions.jobsForCloseJobCandidateProfile.failure,
      err
    })
  } finally {
    yield put({ type: modalIsLoading, payload: false })
  }
}

export function* ensureRecruitersForCloseJobCandidateProfile({
  payload
}: {
  payload: number
  type: string
}) {
  const headers = yield select(M.userToken)
  if (!headers) return
  const {
    params: { candidateId }
  } = yield select(Router.matchRoutes)
  try {
    yield put({ type: modalIsLoading, payload: true })
    const {
      data: { data }
    } = yield call(Api.getRecruitersForCandidateAndJobCloseJobM, {
      headers: { Authorization: `Bearer ${headers}` },
      id: candidateId,
      jobId: payload
    })
    yield put({
      type: Actions.recruitersForCloseJobCandidateProfile.success,
      payload: I.fromJS(data)
    })
  } catch (err) {
    yield put({
      type: Actions.recruitersForCloseJobCandidateProfile.failure,
      err
    })
  } finally {
    yield put({ type: modalIsLoading, payload: false })
  }
}

export function* ensureCandidateAsViewed() {
  const headers = yield select(M.userToken)
  if (!headers) return
  const {
    params: { id, candidateId }
  } = yield select(Router.matchRoutes)
  if (!id) return
  try {
    const {
      data: { data }
    } = yield call(Api.candidateAsViewedM, {
      headers: { Authorization: `Bearer ${headers}` },
      id: candidateId,
      data: {
        jobId: id
      }
    })
    yield put({ type: Actions.candidateAsViewed.success, payload: I.fromJS(data) })
  } catch (err) {
    yield put({
      type: Actions.candidateAsViewed.failure,
      err
    })
  }
}
