import React, { Component } from 'react'
// components
import CircleBackground from 'components/common/CircleBackground'
import SquareBackground from 'components/common/SquareBackground'
import Input from 'components/Form/Input'
import CheckBox from 'components/Form/CheckBox'
import { Button } from 'components/Button'
import { FieldInput } from 'components/Form/Fields/FieldInput'
import { FieldCheckBox } from 'components/Form/Fields/FieldCheckBox'
import { icons } from './icons'
// redux
import { compose } from 'redux'
import { InjectedFormProps, reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import { push, Push } from 'connected-react-router'
import { stateRouter } from 'domain/router/reducer'
import * as A from 'domain/env'
// types
import { ButtonHtmlTypes, ButtonTypes, ComposeType } from 'types/common'
import { Classes } from 'jss'
import { StateInterface } from 'types/state'
// styles
import injectSheet from 'react-jss'
import { sheet } from './sheet'
// utils
import { signUpValidate } from 'pages/Auth/validation'
import { upper } from 'lib/normalize'
import * as Router from 'domain/router'

interface FormValuesInterface {
  firstName: string
  lastName: string
  email: string
  password: string
  confirmationPassword: string
  accept: boolean
}

enum StateSignUp {
  Candidate
}

interface OwnProps {
  classes: Classes
  signUpCandidate: (data: FormValuesInterface) => void
  push?: Push
  stateFromLocation?: { isCandidate: boolean }
  initialValues?: { token: string; email: string }
}

type Props = InjectedFormProps<FormValuesInterface> & OwnProps

class SignUp extends Component<Props> {
  onClick = (path: string) => {
    if (this.props.push) this.props.push(path)
  }

  changeSelectedOptionCandidate = () => this.setState({ selectedOption: StateSignUp.Candidate })

  submit = (data: FormValuesInterface) => {
    this.props.signUpCandidate(data)
  }

  logIn = () => {
    this.onClick('/log-in')
  }

  render() {
    const { classes, handleSubmit } = this.props

    return (
      <form onSubmit={handleSubmit(this.submit)}>
        <div className={classes.content}>
          <div className={classes.textLeft}>
            <CircleBackground className="forLogIn" />
            <SquareBackground className="forSignUp" />
            <div className={classes.margin}>
              {icons.data.map((item, i) => (
                <div className={classes.iconBlock} key={i}>
                  {item.map((el, j) => (
                    <div key={j}>
                      {el.icon}
                      <p className={classes.title}>{el.name}</p>
                      <p className={classes.text}>{el.text}</p>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
          <div className={classes.parent}>
            <div className={classes.form}>
              <h4>Sign up as Candidate</h4>
              <div className={classes.name}>
                <FieldInput
                  name="firstName"
                  type="text"
                  component={Input}
                  title="First name"
                  normalize={upper}
                />
                <FieldInput
                  name="lastName"
                  type="text"
                  component={Input}
                  title="Last name"
                  normalize={upper}
                />
              </div>
              <FieldInput
                name="email"
                type="text"
                component={Input}
                title="Email"
                placeholder="email@example.co"
                disabled={true}
              />
              <FieldInput
                name="password"
                type="password"
                component={Input}
                title="Password"
                placeholder="must include at least 8 symbols"
                isPassword={true}
              />
              <FieldInput
                name="confirmationPassword"
                type="password"
                component={Input}
                title="Repeat password"
                isPassword={true}
              />
              <FieldCheckBox
                name="accept"
                component={CheckBox}
                label={
                  <span className={classes.linkText}>
                    I accept{' '}
                    <a href="/terms_and_conditions" target="_blank">
                      Terms and Conditions
                    </a>
                  </span>
                }
              />
              <div className={classes.buttonGroup}>
                <Button label="Log in" onClick={this.logIn} />
                <Button
                  type={ButtonTypes.Submit}
                  htmlType={ButtonHtmlTypes.Submit}
                  label=" Create account"
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    )
  }
}

export default compose<ComposeType<Props>>(
  connect(
    (state: StateInterface) => ({
      stateFromLocation: stateRouter(state),
      initialValues: Router.query(state)
    }),
    {
      signUpCandidate: A.signUpCandidate,
      push
    }
  ),
  reduxForm({
    form: 'sign-up',
    validate: signUpValidate,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true
  }),
  injectSheet(sheet)
)(SignUp)
