import I, { List } from 'immutable'
import * as J from 'domain/manager/jobs'
import * as C from 'domain/manager/clients'
import * as R from 'domain/manager/recruiters'
import * as M from 'domain/manager/managerProfileView'
import * as D from 'domain/manager/dashboard'
import * as Candidate from 'domain/manager/candidates'
import * as Calendar from 'domain/manager/calendar'
import * as Env from 'domain/env'
import * as CR from 'domain/manager/collaborationRequests'
import * as CommonJobs from 'domain/jobs'
import { Action } from 'redux'
import {
  CandidatesListType,
  RecruitersListI,
  RecruiterI,
  InvitedRecruitersType,
  InvitedRecruitersListType,
  CollaborationRequestType,
  CollaborationRequestsType
} from 'types/managerTypes'
import { JobManagerImmutable } from 'types/job/jobTypesManager'
import { ClientListT } from 'types/clientTypes'
import { ImmutableMap } from 'types/common'
import { RecruiterProfileJobs, RecruiterProfileCandidates } from 'types/profile'
import { IClientClosureRate, IClosedJobs } from 'pages/Dashboard/types'
import { filterListDuplicatesBy } from 'lib/reducer'

interface ActionType extends Action {
  payload: any
}

const State = I.fromJS({
  invitedRecruiters: I.List(),
  recruiters: I.List(),
  jobsListData: I.List(),
  clientsListDataForJob: I.List(),
  allRecruiters: I.List(),
  clientsListData: I.List(),
  candidatesListData: I.List(),
  dashboard: I.Map({
    jobsCounters: I.Map({}),
    clientsCounters: I.Map({}),
    clientClosureRate: I.Map({}),
    statistics: I.Map({}),
    topRecruiters: I.Map({}),
    closedJobs: I.Map({})
  }),
  calendar: {
    eventsList: I.List(),
    candidatesList: I.List(),
    event: I.Map(),
    nearestEventId: null,
    managerCandidateJobsList: I.List()
  },
  invitedRecruitersOnProfile: I.List(),
  collaborationRequests: [],
  recruiterProfileSkills: [],
  recruiterProfileJobs: {
    data: [],
    meta: {}
  },
  recruiterProfileCandidates: {
    data: [],
    meta: {}
  },
  recruiterClients: {
    data: [],
    meta: {}
  },
  jobData: {},
  managersList: []
})

export const reducer = {
  manager(state = State, action: ActionType) {
    switch (action.type) {
      case CommonJobs.updateJobRecruiters.success:
        return state.setIn(['jobData', 'recruiters'], action.payload.get('recruiters'))
      case CR.rejectManagerCollaborationRequest.SUCCESS:
        return state.update('collaborationRequests', (u: CollaborationRequestsType) =>
          u.filter((i: CollaborationRequestType) => i.get('id') !== action.payload)
        )
      case CR.acceptManagerCollaborationRequest.SUCCESS:
        return state.update('collaborationRequests', (u: CollaborationRequestsType) =>
          u.map((i: CollaborationRequestType) =>
            i.get('id') === action.payload ? i.set('isAccepted', true) : i
          )
        )
      case CR.managerCollaborationRequests.success:
        return state.set('collaborationRequests', action.payload)
      case R.deleteRecruiter.SUCCESS:
        return state.update('recruiters', (u: RecruitersListI) =>
          u.filter((i: RecruiterI) => i.get('id') !== action.payload)
        )
      case R.declineInvitationToRecruiter.SUCCESS:
        return state.update('invitedRecruitersOnProfile', (u: InvitedRecruitersListType) =>
          u.filter((i: InvitedRecruitersType) => i.get('id') !== action.payload)
        )
      case R.invitedRecruitersList.success:
        return state.set('invitedRecruitersOnProfile', action.payload)
      case R.inviteRecruiters.SUCCESS:
        return state.set('invitedRecruiters', action.payload)
      case R.recruitersList.success:
        return state.set('recruiters', action.payload)
      case R.recruiterProfile.success:
        return state.set('recruiterProfile', action.payload)
      case R.allRecruitersNewList.success:
        return state
          .set('allRecruiters', action.payload.get('data'))
          .set('allRecruitersMeta', action.payload.get('meta'))
      case R.allRecruiters.success:
        return state
          .update('allRecruiters', (u: RecruitersListI) => u.concat(action.payload.get('data')))
          .set('allRecruitersMeta', action.payload.get('meta'))
      case R.recruiterProfileSkills.success:
        return state.set('recruiterProfileSkills', action.payload)
      case R.recruiterProfileJobs.success:
        return state
          .updateIn(['recruiterProfileJobs', 'data'], (u: RecruiterProfileJobs) =>
            action.payload.getIn(['meta', 'page']) === 1
              ? action.payload.get('data')
              : u.concat(action.payload.get('data'))
          )
          .setIn(['recruiterProfileJobs', 'meta'], action.payload.get('meta'))
      case R.recruiterProfileCandidates.success:
        return state
          .updateIn(['recruiterProfileCandidates', 'data'], (u: RecruiterProfileCandidates) =>
            action.payload.getIn(['meta', 'page']) === 1
              ? action.payload.get('data')
              : u.concat(action.payload.get('data'))
          )
          .setIn(['recruiterProfileCandidates', 'meta'], action.payload.get('meta'))
      case J.jobsList.success:
        const newJobs = action.payload.get('data')
        const existingJobs = state.get('jobsListData')
        const filteredNewJobs = filterListDuplicatesBy(existingJobs, newJobs, 'id')

        return state
          .update('jobsListData', (u: List<JobManagerImmutable>) =>
            action.payload.getIn(['meta', 'page']) === 1 ? newJobs : u.concat(filteredNewJobs)
          )
          .set('jobsListMeta', action.payload.get('meta'))
      case J.getAllJobs.success:
        return state
          .update('jobsListData', (u: List<JobManagerImmutable>) =>
            action.payload.getIn(['meta', 'page']) === 1
              ? action.payload.get('data')
              : u.concat(action.payload.get('data'))
          )
          .set('jobsListMeta', action.payload.get('meta'))
      case J.jobData.success:
        return state.set('jobData', action.payload)
      case J.candidatesForCloseJob.success:
        return state.set('jobCandidates', action.payload)
      case J.recruitersForCloseJob.success:
        return state.set('jobRecruiters', action.payload)
      case C.clientsListForJob.success:
        return state.set('clientsListDataForJob', action.payload)
      case M.managerProfileView.success:
        return state.set('managerView', action.payload)
      case Candidate.candidatesNewList.success:
        return state
          .set('candidatesListData', action.payload.get('data'))
          .set('candidatesListMeta', action.payload.get('meta'))
      case Candidate.candidates.success:
        return state
          .update('candidatesListData', (u: CandidatesListType) =>
            u.concat(action.payload.get('data'))
          )
          .set('candidatesListMeta', action.payload.get('meta'))
      case Candidate.candidateFavoriteFromList.success:
        return state.update('candidatesListData', (u: CandidatesListType) =>
          u.map(item => {
            if (item.get('id') === action.payload.get('id')) {
              return item.update('isFavorite', () => action.payload.get('value'))
            }
            return item
          })
        )
      case Candidate.candidateProfile.success:
        return state.set('candidateProfile', action.payload)
      case Candidate.jobsForCloseJobCandidateProfile.success:
        return state.set('jobsCloseJob', action.payload)
      case Candidate.recruitersForCloseJobCandidateProfile.success:
        return state.set('recruitersCloseJob', action.payload)
      case C.newClientsList.success:
        return state
          .set('clientsListData', action.payload.get('data'))
          .set('clientsListMeta', action.payload.get('meta'))
      case C.clientsList.success:
        return state
          .update('clientsListData', (u: ClientListT) => u.concat(action.payload.get('data')))
          .set('clientsListMeta', action.payload.get('meta'))
      case C.recruiterClients.success:
        return state.set('recruiterClients', action.payload)
      case C.getClient.success:
        return state.set('client', action.payload)
      case D.managerNewClientClosureRate.success:
        return state.setIn(['dashboard', 'clientClosureRate'], action.payload)
      case D.managerClientClosureRate.success:
        return state.updateIn(
          ['dashboard', 'clientClosureRate', 'data'],
          (u: List<ImmutableMap<IClientClosureRate>>) => u.concat(action.payload.get('data'))
        )
      case D.managerClientsCounters.success:
        return state.setIn(['dashboard', 'clientsCounters'], action.payload)
      case D.managerNewClosedJobs.success:
        return state.setIn(['dashboard', 'closedJobs'], action.payload)
      case D.managerClosedJobs.success:
        return state.updateIn(
          ['dashboard', 'closedJobs', 'data'],
          (u: List<ImmutableMap<IClosedJobs>>) => u.concat(action.payload.get('data'))
        )
      case D.managerStatistics.success:
        return state.setIn(['dashboard', 'statistics'], action.payload)
      case D.managerTopRecruiters.success:
        return state.setIn(['dashboard', 'topRecruiters'], action.payload)
      case D.managerJobsCounters.success:
        return state.setIn(['dashboard', 'jobsCounters'], action.payload)
      case Calendar.managerEventsList.success:
        return state.setIn(['calendar', 'eventsList'], action.payload)
      case Calendar.managerCandidatesList.success:
        return state.setIn(['calendar', 'candidatesList'], action.payload)
      case Calendar.managerEvent.success:
      case Calendar.unavailability.success:
        return state.setIn(['calendar', 'event'], action.payload)
      case Calendar.nearestEventId.success:
        return state.setIn(['calendar', 'nearestEventId'], action.payload)
      case Calendar.managerCandidateJobsList.success:
        return state.setIn(['calendar', 'managerCandidateJobsList'], action.payload)
      case Env.signOut.success:
        return State
      case J.getManagersList.success:
        return state.set('managersList', action.payload)
      default:
        return state
    }
  }
}
