import React from 'react'
import Svg, { SvgType } from 'components/svgComponent/Svg'

export default function(props: SvgType) {
  return (
    <Svg width="34" height="25" viewBox="0 0 34 25" fill="none" {...props}>
      <path d="M18.6001 10.5176H26.6001" stroke="#286EFA" strokeWidth="2" />
      <path d="M18.6001 14.4824H29.8001" stroke="#286EFA" strokeWidth="2" />
      <path d="M18.6001 19.2412H29.8001" stroke="#286EFA" strokeWidth="2" />
      <mask id="path-4-inside-1" fill="white">
        <rect x="4.2002" y="9.72363" width="9.6" height="9.51724" rx="1" />
      </mask>
      <rect
        x="4.2002"
        y="9.72363"
        width="9.6"
        height="9.51724"
        rx="1"
        stroke="#286EFA"
        strokeWidth="4"
        mask="url(#path-4-inside-1)"
      />
      <path
        d="M11.4 4.17241H2C1.44772 4.17241 1 4.62013 1 5.17241V23C1 23.5523 1.44772 24 2 24H32C32.5523 24 33 23.5523 33 23V5.17241C33 4.62013 32.5523 4.17241 32 4.17241H22.6V2C22.6 1.44772 22.1523 1 21.6 1H12.4C11.8477 1 11.4 1.44771 11.4 2V4.17241Z"
        stroke="#286EFA"
        strokeWidth="2"
      />
    </Svg>
  )
}
