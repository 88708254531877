import React from 'react'
import Svg, { SvgType } from 'components/svgComponent/Svg'

export default function(props: SvgType) {
  return (
    <Svg width="33" height="31" viewBox="0 0 33 31" fill="none" {...props}>
      <path
        d="M8.75 23.1316H2C1.44772 23.1316 1 22.6839 1 22.1316V2C1 1.44772 1.44772 1 2 1H20.15C20.7023 1 21.15 1.44772 21.15 2V4.05263"
        stroke="#286EFA"
        strokeWidth="2"
      />
      <path d="M5.6499 12.4473H9.5249M5.6499 17.7894H9.5249" stroke="#286EFA" strokeWidth="2" />
      <path
        d="M10.3 29.0002V6.5791C10.3 6.02682 10.7478 5.5791 11.3 5.5791H31C31.5523 5.5791 32 6.02682 32 6.5791V29.0002C32 29.5524 31.5523 30.0002 31 30.0002H11.3C10.7478 30.0002 10.3 29.5524 10.3 29.0002Z"
        stroke="#286EFA"
        strokeWidth="2"
      />
      <path
        d="M15.7251 14.5003V11.1582C15.7251 10.6059 16.1728 10.1582 16.7251 10.1582H26.3501C26.9024 10.1582 27.3501 10.6059 27.3501 11.1582V14.5003C27.3501 15.0526 26.9024 15.5003 26.3501 15.5003H16.7251C16.1728 15.5003 15.7251 15.0526 15.7251 14.5003Z"
        stroke="#286EFA"
        strokeWidth="2"
      />
      <path d="M16.5 20.0791H26.575" stroke="#286EFA" strokeWidth="2" />
      <path d="M16.5 23.8945H26.575" stroke="#286EFA" strokeWidth="2" />
    </Svg>
  )
}
