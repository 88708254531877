import { Styles } from 'react-jss'

export const sheet: Styles = {
  title: {},
  text: {},
  content: {
    display: 'flex',
    flexDirection: 'row',
    '& h4': {
      color: '#080910',
      fontWeight: 500,
      fontSize: 24
    },
    '& p': {
      lineHeight: '20px',
      fontSize: 15,
      letterSpacing: '0.01em',
      color: '#989FA6'
    }
  },
  textLeft: {
    position: 'relative',
    overflow: 'hidden',
    padding: '3em',
    flex: 1,
    zIndex: 1,
    backgroundColor: '#FCFCFC',
    border: '1px solid #DEDEDE',
    '& h3': {
      color: '#286EFA',
      lineHeight: '66px'
    }
  },
  form: {
    padding: '2em',
    position: 'relative',
    flex: 0.6,
    backgroundColor: '#FFFFFF',
    '& > button': {
      fontWeight: 400,
      fontSize: 15,
      letterSpacing: '0.01em',
      color: '#888888',
      padding: 0,
      textTransform: 'none'
    }
  },
  buttonGroup: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: '3em 0',
    '& > button:first-child': {
      fontWeight: 500,
      fontSize: 14,
      letterSpacing: '0.03em',
      color: '#286EFA',
      padding: 0
    }
  },
  iconBlock: {
    display: 'flex',
    '& > div': {
      position: 'relative',
      padding: '20px 0 20px 30px',
      flex: 1,
      '& > $title': {
        fontWeight: 500,
        fontSize: 17,
        color: '#080910'
      },
      '& > svg': {
        position: 'absolute',
        top: 0,
        left: 0
      }
    }
  },
  margin: {
    marginTop: '10%'
  },
  '@media (min-width: 992px) and (max-width: 1199.98px)': {
    textLeft: {
      padding: '2em'
    },
    form: {
      padding: '2em',
      '& > h4': {
        whiteSpace: 'nowrap'
      }
    }
  },
  '@media (min-width: 768px) and (max-width: 991.98px)': {
    textLeft: {
      padding: '1em',
      '& > h3': {
        margin: '0.5em 0 0',
        lineHeight: 'normal'
      }
    },
    form: {
      padding: '1em'
    },
    inputRow: {
      margin: '1.5em 0'
    },
    buttonGroup: {
      margin: '1.5em 0'
    }
  }
}
