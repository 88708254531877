import { all, call, fork, put, take, takeLatest, select, takeEvery } from 'redux-saga/effects'
import { ensureSign, ensureSignOut, rootAfterSignIn } from 'pages/Auth/sagas'
import { routinePromiseWatcherSaga } from 'redux-saga-routines'
import { isLoading } from 'domain/loading'
import { ensureGetConstants } from 'domain/constants/sagas'
import { messagesWatcher } from 'pages/Messages/sagas'
import watchNavigate from './navigator'
import * as Env from 'domain/env'
import * as L from 'domain/locations'
import * as M from 'domain/messages'
import { Task } from 'redux-saga'

let rootAfterSignInTask: Task

export default function* root() {
  yield put({ type: isLoading, payload: true })
  yield all([
    fork(ensureGetConstants),
    fork(applyRootAfterSignIn),
    fork(stopRootAfterSignIn),
    fork(watchNavigate),
    fork(ensureSignOut),
    fork(uploadingCountry),
    fork(uploadingCities),
    fork(routinePromiseWatcherSaga),
    fork(startConversation),
    fork(messagesWatcher)
  ])
  yield call(ensureSign)
  const isAuthorized = yield select(Env.isAuthorizedSelector)
  if (isAuthorized) {
    rootAfterSignInTask = yield fork(rootAfterSignIn)
  }
}

function* uploadingCountry() {
  yield call(L.ensureGetCounties)
  yield takeLatest(L.countries.type, L.ensureGetCounties)
  yield take(L.countries.success)
}

function* uploadingCities() {
  yield takeLatest(L.cities.type, L.ensureGetCities)
  yield take(L.cities.success)
}

function* startConversation() {
  yield takeLatest(M.startConversation.type, M.ensureStartConversation)
  yield take(M.startConversation.success)
}

function* applyRootAfterSignIn() {
  yield takeEvery(Env.signIn.success, function*() {
    if (rootAfterSignInTask) {
      yield rootAfterSignInTask.cancel()
    }
    rootAfterSignInTask = yield fork(rootAfterSignIn)
  })
}

function* stopRootAfterSignIn() {
  yield takeEvery(Env.signOut.success, function*() {
    if (rootAfterSignInTask) {
      yield rootAfterSignInTask.cancel()
    }
  })
}
