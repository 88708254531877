import { Styles } from 'react-jss'

export const sheet: Styles = {
  linkGroup: {},
  root: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    right: 0,
    top: 52,
    backgroundColor: '#FCFCFC',
    boxShadow: '0px 2px 9px rgba(0, 0, 0, 0.15), 0px 1px 3px rgba(0, 0, 0, 0.08)',
    padding: 16,
    zIndex: 999,
    '& > div': {
      borderBottom: '1px solid #DEDEDE',
      paddingBottom: 10,
      '& > div': {
        marginBottom: 20
      }
    },
    '& > $linkGroup': {
      display: 'flex',
      flexDirection: 'column',
      '& > a, & > button': {
        margin: '15px 0 0 0'
      },
      '& > a:first-child, & > button:first-child': {
        '& > svg': {
          marginRight: 17
        }
      }
    }
  },
  logOut: {
    color: '#989FA6',
    margin: '16px 0 0'
  }
}
