import I, { List } from 'immutable'
import * as A from './actions'
import { Action } from 'redux'
import { StateInterface } from 'types/state'
import { ImmutableMap, SpheresItemExist } from 'types/common'

interface ActionType extends Action {
  payload: List<ImmutableMap<SpheresItemExist>>
}

export const tagsSelector = (state: StateInterface) => state.tags

const State = I.List([])

export const reducer = {
  tags(state = State, action: ActionType) {
    if (action.type === A.tagsList.success) {
      return action.payload
    } else {
      return state
    }
  }
}
