import React from 'react'
import Svg, { SvgType } from 'components/svgComponent/Svg'

export default function(props: SvgType) {
  return (
    <Svg width="22" height="22" viewBox="0 0 22 22" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.61633 12.3846L2.1893 12.3846V9.61538L9.61633 9.61538L12.3856 9.61539L19.8126 9.61538V12.3846L12.3856 12.3846L9.61633 12.3846Z"
        fill="#286EFA"
      />
    </Svg>
  )
}
