import React from 'react'
import Svg, { SvgType } from 'components/svgComponent/Svg'

export default function(props: SvgType) {
  const { iconActive } = props
  const fill = iconActive ? '#286EFA' : '#E6E6E6'
  return (
    <Svg width="24" height="25" viewBox="0 0 24 25" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 7.5V3.5H2V21.5H22V7.5H12ZM6 19.5H4V17.5H6V19.5ZM6 15.5H4V13.5H6V15.5ZM6 11.5H4V9.5H6V11.5ZM6 7.5H4V5.5H6V7.5ZM10 19.5H8V17.5H10V19.5ZM10 15.5H8V13.5H10V15.5ZM10 11.5H8V9.5H10V11.5ZM10 7.5H8V5.5H10V7.5ZM20 19.5H12V17.5H14V15.5H12V13.5H14V11.5H12V9.5H20V19.5ZM18 11.5H16V13.5H18V11.5ZM18 15.5H16V17.5H18V15.5Z"
        fill={fill}
      />
    </Svg>
  )
}
