import { all, takeLatest, take, fork, call } from 'redux-saga/effects'
import * as A from 'domain/educations'

export function* educationsPage() {
  yield all([
    fork(getEducations),
    fork(addEducation),
    fork(getEducation),
    fork(deleteEducation),
    fork(updateEducation)
  ])
}

function* getEducations() {
  yield call(A.ensureEducationsList)
  yield takeLatest(A.educationsList.type, A.ensureEducationsList)
  yield take(A.educationsList.success)
}

function* addEducation() {
  yield takeLatest(A.addEducation.TRIGGER, A.ensureAddEducation)
  yield take(A.addEducation.SUCCESS)
}

function* getEducation() {
  yield takeLatest(A.education.type, A.ensureEducation)
  yield take(A.education.success)
}

function* deleteEducation() {
  yield takeLatest(A.removeEducation.TRIGGER, A.ensureRemoveEducation)
  yield take(A.removeEducation.SUCCESS)
}

function* updateEducation() {
  yield takeLatest(A.updateEducation.TRIGGER, A.ensureUpdateEducation)
  yield take(A.updateEducation.SUCCESS)
}
