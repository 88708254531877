import React from 'react'
import Svg, { SvgType } from 'components/svgComponent/Svg'

export default function(props: SvgType) {
  const { iconActive } = props
  const fill = iconActive ? '#FAFAFA' : '#DEDEDE'
  const opacity = iconActive ? '1' : '0.5'
  return (
    <Svg width="22" height="22" viewBox="0 0 22 22" fill="none" {...props}>
      <g clipPath="url(#clip0)">
        <g opacity={opacity}>
          <path
            d="M-0.00390625 1.83203C-0.00390625 0.820312 0.816406 -0.000976562 1.82812 -0.000976562H12.8281C13.8438 -0.000976562 14.6641 0.820312 14.6641 1.83203V2.74902H12.8281V1.83203H1.82812V14.666H5.95312V16.499H1.82812C0.816406 16.499 -0.00390625 15.6777 -0.00390625 14.666V1.83203Z"
            fill={fill}
          />
          <path d="M5.95312 10.3115H3.66016V8.93652H5.95312V10.3115Z" fill={fill} />
          <path d="M3.66016 12.6025H5.95312V11.2275H3.66016V12.6025Z" fill={fill} />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.332 6.70605C10.5 6.52734 10.7383 6.41504 11.0039 6.41504H17.418C17.9258 6.41504 18.3359 6.8252 18.3359 7.33203V10.082C18.3359 10.5879 17.9258 10.998 17.418 10.998H11.0039C10.4961 10.998 10.0859 10.5879 10.0859 10.082V7.33203C10.0859 7.08984 10.1797 6.87012 10.332 6.70605ZM11.4609 9.62305V7.79004H16.9609V9.62305H11.4609Z"
            fill={fill}
          />
          <path d="M17.4141 13.9775H10.9961V12.6025H17.4141V13.9775Z" fill={fill} />
          <path d="M10.9961 16.2705H17.4141V14.8955H10.9961V16.2705Z" fill={fill} />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.25391 3.66504C7.74609 3.66504 7.33594 4.0752 7.33594 4.58203V18.332C7.33594 18.8379 7.74609 19.248 8.25391 19.248H20.168C20.2656 19.248 20.3594 19.2324 20.4453 19.2041C20.5703 19.1641 20.6875 19.0967 20.7852 19.0088C20.9688 18.8418 21.0859 18.6006 21.0859 18.332V4.58203C21.0859 4.0752 20.6758 3.66504 20.168 3.66504H8.25391ZM9.16797 5.49805V17.415H19.2539V5.49805H9.16797Z"
            fill={fill}
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="22" height="22" fill="white" />
        </clipPath>
      </defs>
    </Svg>
  )
}
