import React from 'react'
import Svg, { SvgType } from 'components/svgComponent/Svg'

export default function(props: SvgType) {
  const { iconActive } = props
  const fill = iconActive ? '#FAFAFA' : '#DEDEDE'
  const opacity = iconActive ? '1' : '0.5'
  return (
    <Svg width="22" height="22" viewBox="0 0 22 22" fill="none" {...props}>
      <g opacity={opacity}>
        <path d="M11 12.6931H4.30469V11.2595H11V12.6931Z" fill={fill} />
        <path d="M4.30469 15.5574H11V14.1238H4.30469V15.5574Z" fill={fill} />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.8125 1.17065C7.15234 1.47144 6.69531 2.13647 6.69531 2.90991V6.72827L0.957031 6.72632C0.429688 6.72632 0 7.15405 0 7.6814V20.9685C0 21.7693 0.925781 22.2146 1.55078 21.7146L4.51953 19.3445C4.62891 19.2585 4.75391 19.198 4.88281 19.1648C4.96094 19.1453 5.03906 19.1355 5.11719 19.1355H14.3477C14.875 19.1355 15.3047 18.7078 15.3047 18.1794V13.4099H16.7578L18.5938 15.7029C19.7266 17.1111 21.9961 16.3132 21.9961 14.5076V2.90991C21.9961 1.85425 21.1406 0.998779 20.0859 0.998779H8.60547C8.32422 0.998779 8.05469 1.0603 7.8125 1.17065ZM15.3047 11.4988H16.7578C16.9414 11.4988 17.1211 11.5251 17.293 11.5759C17.668 11.6843 18 11.905 18.25 12.2146L20.0859 14.5076V2.90991H8.60547V6.72827L14.3477 6.72632C14.875 6.72632 15.3047 7.15405 15.3047 7.6814V11.4988ZM1.91016 18.9822V8.63745H13.3945V17.2244H5.11719C4.46875 17.2244 3.83594 17.4451 3.32812 17.8503L1.91016 18.9822Z"
          fill={fill}
        />
      </g>
    </Svg>
  )
}
