import React, { Component } from 'react'
// components
import { Button } from 'components/Button'
// redux
import { compose } from 'redux'
import { connect } from 'react-redux'
import { location } from 'domain/router'
import { Push, push } from 'connected-react-router'
// types
import { ButtonTypes, ComposeType } from 'types/common'
import { Classes } from 'jss'
// styles
import injectSheet from 'react-jss'
import { sheet } from './sheet'
// utils
import { RolesMenu } from 'lib/roles'
import cx from 'classnames'
// icons
import LogoMenu from 'components/svgComponent/LogoMenu'
import CloseMenu from 'components/svgComponent/CloseMenu'
import OpenMenu from 'components/svgComponent/OpenMenu'
import SmallLogoMenu from 'components/svgComponent/SmallLogoMenu'
import { StateInterface } from 'types/state'

interface ExternalProps {
  toggleModal: () => void
  isOpenMobileSidebar: boolean
  toggleSidebarMenu: () => void
}

interface OwnProps {
  classes: Classes
  push: Push
  location: {
    pathname: string
  }
}

type Props = ExternalProps & OwnProps

interface State {
  menuIsOpen: boolean
}

class Sidebar extends Component<Props, State> {
  state = {
    menuIsOpen: true
  }

  openCloseMenu = () => {
    this.setState({ menuIsOpen: !this.state.menuIsOpen })
  }

  checkActive = (path: string) => {
    const {
      location: { pathname }
    } = this.props
    return pathname === path
  }

  render() {
    const { classes, toggleModal, isOpenMobileSidebar, toggleSidebarMenu } = this.props
    const { menuIsOpen } = this.state
    return (
      <div
        className={cx(classes.root, {
          [classes.rootSmall]: !menuIsOpen,
          [classes.rootMobileOpen]: isOpenMobileSidebar
        })}
      >
        <div className={classes.logo}>
          {menuIsOpen ? (
            <LogoMenu onClick={() => this.props.push('/')} />
          ) : (
            <SmallLogoMenu onClick={() => this.props.push('/')} />
          )}
        </div>
        {menuIsOpen ? (
          <div className={classes.menu}>
            <div>Menu</div>
            <CloseMenu onClick={this.openCloseMenu} />
          </div>
        ) : (
          <div className={classes.menu}>
            <OpenMenu onClick={this.openCloseMenu} />
          </div>
        )}
        <div className={classes.menuItems} onClick={toggleSidebarMenu}>
          {RolesMenu.guest.map((item, i) => {
            return (
              <div
                className={cx(classes.item, {
                  [classes.active]: this.checkActive(item.to)
                })}
                key={i}
              >
                <Button
                  buttonStyle={true}
                  type={ButtonTypes.Menu}
                  Icon={item.icon}
                  iconActive={this.checkActive(item.to)}
                  label={item.name}
                  showLabel={menuIsOpen}
                  onClick={toggleModal}
                />
              </div>
            )
          })}
        </div>
      </div>
    )
  }
}

export default compose<ComposeType<Props, ExternalProps>>(
  connect(
    (state: StateInterface) => ({
      location: location(state)
    }),
    { push }
  ),
  injectSheet(sheet)
)(Sidebar)
