import React from 'react'
import Svg, { SvgType } from './Svg'

export default function(props: SvgType) {
  return (
    <Svg width="13" height="13" viewBox="0 0 13 13" fill="none" {...props}>
      <path d="M2 6.5L5.5 10L11.5 2" stroke="#286EFA" strokeWidth="2" />
    </Svg>
  )
}
