import React from 'react'
import Svg, { SvgType } from './Svg'

export default function(props: SvgType) {
  return (
    <Svg width="34" height="25" viewBox="0 0 34 25" fill="none" {...props}>
      <circle cx="11" cy="12" r="2" fill="#286EFA" />
      <circle cx="17" cy="12" r="2" fill="#286EFA" />
      <circle cx="23" cy="12" r="2" fill="#286EFA" />
      <path
        d="M12 1H2C1.44772 1 1 1.44772 1 2V23C1 23.5523 1.44771 24 2 24H12"
        stroke="#286EFA"
        strokeWidth="2"
      />
      <path
        d="M22 1H32C32.5523 1 33 1.44772 33 2V23C33 23.5523 32.5523 24 32 24H22"
        stroke="#286EFA"
        strokeWidth="2"
      />
    </Svg>
  )
}
