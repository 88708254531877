import React, { ReactNode } from 'react'

interface MyProps {
  d?: string
  width: string | number
  height: string | number
  children: ReactNode
  viewBox: string
  fill?: string
}

export interface SvgType {
  onClick?: (arg?: any) => void
  className?: string
  iconActive?: boolean
  x?: number
  y?: number
}

interface ArgType {
  width?: string | number
  height?: string | number
  viewBox: string
}

export default function Svg({
  d,
  width,
  height,
  children,
  viewBox,
  iconActive,
  ...rest
}: MyProps & SvgType) {
  const args: ArgType = { viewBox }
  if (width) {
    args.width = width
  }
  if (height) {
    args.height = height
  }

  return (
    <svg {...args} xmlns="http://www.w3.org/2000/svg" {...rest}>
      {children || <path d={d} />}
    </svg>
  )
}

Svg.defaultProps = {
  viewBox: '0 0 24 24'
}
