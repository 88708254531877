import React from 'react'
import Svg, { SvgType } from 'components/svgComponent/Svg'

export default function(props: SvgType) {
  return (
    <Svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5759 3.18336C14.5833 3.16858 14.5868 3.13508 14.5868 3.08297C14.5868 2.95644 14.5273 2.85617 14.4081 2.78139C14.3859 2.76653 14.3134 2.72373 14.1905 2.6529C14.0678 2.58234 13.9505 2.51346 13.8391 2.44642C13.7275 2.37945 13.605 2.31069 13.4708 2.24002C13.3369 2.1693 13.2216 2.11164 13.125 2.06696C13.0281 2.02236 12.9612 2 12.924 2C12.7901 2 12.6859 2.05961 12.6115 2.17861L12.009 3.26127C11.3321 3.13485 10.6623 3.07152 10.0001 3.07152C7.99861 3.07152 6.1534 3.57759 4.46433 4.58945C2.77537 5.60148 1.36177 6.98158 0.223248 8.73007C0.0743898 8.9609 0 9.21761 0 9.50036C0 9.78302 0.0743898 10.0399 0.223248 10.2704C0.877941 11.2896 1.65366 12.2013 2.55025 13.0047C3.44683 13.8085 4.42704 14.4518 5.49101 14.9357C5.16362 15.4936 4.99995 15.8174 4.99995 15.9068C4.99995 16.0406 5.05953 16.1447 5.1786 16.2191C6.08625 16.7401 6.58469 17.0003 6.67406 17.0003C6.80782 17.0003 6.91208 16.9406 6.98647 16.8215L7.53335 15.8283C8.32209 14.4224 9.49759 12.3129 11.0602 9.5004C12.6227 6.68785 13.7946 4.58218 14.5759 3.18336ZM6.19418 13.6857C4.25973 12.8151 2.67108 11.4201 1.42865 9.50047C2.55959 7.74448 3.97698 6.43114 5.68084 5.56078C5.22699 6.33447 4.99999 7.17156 4.99999 8.07186C4.99999 8.86795 5.18219 9.62298 5.54687 10.3374C5.91147 11.0517 6.41742 11.643 7.06477 12.1118L6.19418 13.6857Z"
        fill="#080910"
      />
      <path
        d="M14.1189 10.9005C14.7067 10.0485 15.0008 9.10541 15.0008 8.07134C15.0008 7.78121 14.9711 7.46868 14.9115 7.13379L11.7866 12.7363C12.7537 12.3645 13.5312 11.7522 14.1189 10.9005Z"
        fill="#080910"
      />
      <path
        d="M18.164 6.67624C18.8149 7.33844 19.3524 8.02307 19.7767 8.72983C19.9255 8.98299 19.9999 9.2397 20 9.50008C20 9.76027 19.9256 10.0171 19.7768 10.2702C19.4866 10.7465 19.0811 11.2858 18.5603 11.8886C17.4443 13.1684 16.1513 14.1617 14.6819 14.8683C13.2125 15.5752 11.6519 15.9285 10 15.9285L10.8259 14.4553C12.4034 14.3215 13.8636 13.8121 15.2067 12.9262C16.5493 12.0409 17.6712 10.8988 18.5712 9.5C17.7157 8.16822 16.6665 7.07438 15.424 6.21876L16.1271 4.96875C16.834 5.44503 17.5133 6.01408 18.164 6.67624Z"
        fill="#080910"
      />
    </Svg>
  )
}
