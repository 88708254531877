import { get } from 'lodash'
import { Action } from 'redux'
import { JobsListCandidate } from 'types/job/jobTypesCandidate'
import I, { List } from 'immutable'
import * as A from 'domain/candidate/profile'
import * as J from 'domain/candidate/jobs'
import * as R from 'domain/candidate/recommendJob'
import * as D from 'domain/candidate/dashboard'
import * as C from 'domain/candidate/candidates'
import * as MProfile from 'domain/candidate/managerProfileView'
import * as RProfile from 'domain/candidate/recruiterProfileView'
import * as CProfile from 'domain/candidate/candidateProfileView'
import * as Calendar from 'domain/candidate/calendar'
import * as Env from 'domain/env'
import * as S from 'domain/candidate/status'
import { InvitedCandidatesType } from 'types/candidateTypes'

interface ActionType extends Action {
  payload: any
}

const State = I.fromJS({
  recommendJob: I.List(),
  employeeInfo: I.Map({
    skills: []
  }),
  jobsListData: I.List(),
  dashboard: I.Map({
    assignedJobs: I.Map({ data: I.List(), meta: {} }),
    appliedJobs: I.Map({ data: I.List(), meta: {} }),
    candidates: I.Map({ data: I.List(), meta: {} }),
    pendingCandidates: I.Map({ data: I.List(), meta: {} })
  }),
  calendar: {
    eventsList: I.List(),
    event: I.Map(),
    nearestEventId: null
  },
  jobsCount: 0,
  candidateStatus: {}
})

export const reducer = {
  candidate(state = State, action: ActionType) {
    switch (action.type) {
      case J.getJobsCount.success:
        return state.set('jobsCount', action.payload)
      case R.recommendJob.SUCCESS:
        return state.set('recommendJob', action.payload)
      case A.employeeInfo.success:
        return state.set('employeeInfo', action.payload || State.get('employeeInfo'))
      case J.newJobsList.success:
        return state
          .set('jobsListData', action.payload.get('data'))
          .set('jobsListMeta', action.payload.get('meta'))
          .set('jobsCount', 0)
      case J.jobsList.success:
        return state
          .update('jobsListData', (u: JobsListCandidate) => u.concat(action.payload.get('data')))
          .set('jobsListMeta', action.payload.get('meta'))
      case J.saveJobFromList.success:
        return state.update('jobsListData', (u: JobsListCandidate) =>
          action.payload.get('isSavedTab')
            ? u.filter(item => item.get('id') !== action.payload.get('id'))
            : u.map(item => {
                if (item.get('id') === action.payload.get('id')) {
                  return item.update('isFavorite', () => action.payload.get('value'))
                }
                return item
              })
        )
      case J.jobData.success:
        return state.set('jobData', action.payload)
      case MProfile.managerProfileView.success:
        return state.set('managerProfile', action.payload)
      case RProfile.recruiterProfile.success:
        return state.set('recruiterProfile', action.payload)
      case CProfile.candidateProfileView.success:
        return state.set('candidateProfile', action.payload)
      case Calendar.candidateEvent.success:
        return state.setIn(['calendar', 'event'], action.payload)
      case Calendar.candidateEventsList.success:
        return state.setIn(['calendar', 'eventsList'], action.payload)
      case Calendar.nearestEventId.success:
        return state.setIn(['calendar', 'nearestEventId'], action.payload)
      case D.candidateAssignedJobs.success:
        return state.setIn(['dashboard', 'assignedJobs'], action.payload)
      case D.candidateAppliedJobs.success:
        return state.setIn(['dashboard', 'appliedJobs'], action.payload)
      case D.getInvitedCandidates.success:
        return state.setIn(['dashboard', 'candidates'], action.payload)
      case D.getPendingCandidates.success:
        return state.setIn(['dashboard', 'pendingCandidates'], action.payload)
      case C.candidatesList.success:
        return state.setIn(['dashboard', 'candidates'], action.payload)
      case C.declineInvitationToCandidate.SUCCESS:
        return state.updateIn(['dashboard', 'pendingCandidates', 'data'], (u: any) => {
          return u.filter((i: InvitedCandidatesType) => i.get('id') !== action.payload)
        })
      case S.candidateStatus.success:
        return state.set('candidateStatus', action.payload)
      case Env.signOut.success:
        return State

      default:
        return state
    }
  }
}
