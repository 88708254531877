import { peek, selector } from 'lib/selectors'
import { StateInterface } from 'types/state'

const superManager = (state: StateInterface) => state.superManager
export const managersSelector = selector(superManager, peek('managers'))
export const managersSearchListSelector = selector(superManager, peek('managersSearchList'))
export const managerSelector = selector(superManager, peek('manager'))
export const collaborationRequestsSelector = selector(superManager, peek('collaborationRequests'))
export const superManagerJobsSelector = selector(superManager, peek('jobsListData'))
export const superManagerJobsMetaSelector = selector(superManager, peek('jobsListMeta'))
export const superManagerClientsSelector = selector(superManager, peek('clientsData'))
export const superManagerClientsMetaSelector = selector(superManager, peek('clientsMeta'))
export const dashboardSelector = selector(superManager, peek('dashboard'))
export const jobSelector = selector(superManager, peek('jobData'))
export const candidateProfileSelector = selector(superManager, peek('candidateProfile'))
export const candidateJobNotesSelector = selector(superManager, peek('candidateNotes'))
