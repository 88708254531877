import React, { Component } from 'react'
// components
import { Button } from 'components/Button'
// types
import { Classes } from 'jss'
// styles
import injectSheet from 'react-jss'
import { sheet } from './sheet'
// icons
import MobileMenu from 'components/svgComponent/Menu'

interface Props {
  classes: Classes
  toggleLogIn: () => void
  toggleSignUp: () => void
  toggleSidebarMenu: () => void
}

class NavBar extends Component<Props> {
  render() {
    const { classes, toggleLogIn, toggleSignUp, toggleSidebarMenu } = this.props

    return (
      <div>
        <div className={classes.root}>
          <div className={classes.mobileMenu}>
            <MobileMenu onClick={toggleSidebarMenu} />
          </div>
          <Button onClick={toggleLogIn} label="log in" />
          <Button onClick={toggleSignUp} label="sign up" />
        </div>
      </div>
    )
  }
}

export default injectSheet(sheet)(NavBar)
