import React from 'react'
// components
import Verification from 'components/svgComponent/Verification'
import SmartSearch from 'components/svgComponent/SmartSearch'
import Connect from 'components/svgComponent/Connect'
import RealWork from 'components/svgComponent/RealWork'
import JobForYou from 'components/svgComponent/JobForYou'
import Contact from 'components/svgComponent/Contact'
import GetHired from 'components/svgComponent/GetHired'
import HireBest from 'components/svgComponent/HireBest'
import InstantManaging from 'components/svgComponent/InstantManaging'
import Control from 'components/svgComponent/Control'
import BigCompanies from 'components/svgComponent/BigCompanies'
// types
import { ButtonTypes } from 'types/common'

export const LayoutBlock = [
  {
    title: 'Find the best candidates as a recruiter',
    typeButton: ButtonTypes.Secondary,
    nameButton: 'Join Skilent',
    isCandidate: false,
    rightBlock: [
      [
        {
          name: 'Verification',
          icon: <Verification />,
          text:
            'SKILENT provides user verification so Recruiters trust they are working with genuine and available candidates'
        },
        {
          name: 'Smart search',
          icon: <SmartSearch />,
          text:
            'SKILENT provides a sophisticated search engine to easily find matching candidates with the right education, hourly rate or salary and location'
        }
      ],
      [
        {
          name: 'Connect',
          icon: <Connect />,
          text: 'Work with Hiring managers and search for candidates from around the world'
        },
        {
          name: 'Real Value',
          icon: <RealWork />,
          text: 'Accumulate bounty points with SKILENT that can be redeemed for cash or gift cards'
        }
      ]
    ]
  },
  {
    title: 'Get hired for the job of your dreams',
    typeButton: ButtonTypes.Default,
    nameButton: 'Get Hired now',
    isCandidate: true,
    rightBlock: [
      [
        {
          name: 'Job for you',
          icon: <JobForYou />,
          text:
            'SKILENT provides a sophisticated search engine to quickly and easily find suitable jobs that match your education, hourly rate or salary and location'
        },
        {
          name: 'Contact',
          icon: <Contact />,
          text:
            'SKILENT includes a comprehensive Instant Messaging and Calendaring feature to contact and conduct conversations with prospective Recruiters, Clients and Candidates'
        }
      ],
      [
        {
          name: 'Get hired',
          icon: <GetHired />,
          text: 'Get hired by some of the biggest companies in the world'
        },
        {}
      ]
    ]
  },
  {
    title: 'Work with companies from around the world',
    typeButton: ButtonTypes.Default,
    nameButton: 'post a job',
    isCandidate: false,
    rightBlock: [
      [
        {
          name: 'Hire the best',
          icon: <HireBest />,
          text:
            'SKILENT provides a service that connects HR Departments with Hiring Managers and their Recruiters to find and hire the best candidates for your job'
        },
        {
          name: 'Instant managing',
          icon: <InstantManaging />,
          text: 'Manage your calls, interviews and meetings natively within the service'
        }
      ],
      [
        {
          name: 'Control',
          icon: <Control />,
          text: 'Control your stats on the dashboard and improve them immediately!'
        },
        {
          name: 'Big companies',
          icon: <BigCompanies />,
          text: 'SKILENT is a way to connect real work experience with online hiring opportunities'
        }
      ]
    ]
  }
]

export const carouselSlidesData = [
  {
    content:
      'I recommend SKILENT as a top staffing and recruitment agency. They have continued to impress us over the last 5 years with their ability to exceed our expectations. Also, I have found a people I will working with for a long time now.',
    author: 'Dave Johnson',
    source: 'CEO, LLC Direct Tech Co.'
  },
  {
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    author: 'Lorem ipsum',
    source: 'Lorem ipsum dolor.'
  },
  {
    content:
      'I recommend SKILENT as a top staffing and recruitment agency. They have continued to impress us over the last 5 years with their ability to exceed our expectations. Also, I have found a people I will working with for a long time now.',
    author: 'Dave Johnson',
    source: 'CEO, LLC Direct Tech Co.'
  },
  {
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    author: 'Lorem ipsum',
    source: 'Lorem ipsum dolor.'
  },
  {
    content:
      'I recommend SKILENT as a top staffing and recruitment agency. They have continued to impress us over the last 5 years with their ability to exceed our expectations. Also, I have found a people I will working with for a long time now.',
    author: 'Dave Johnson',
    source: 'CEO, LLC Direct Tech Co.'
  },
  {
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    author: 'Lorem ipsum',
    source: 'Lorem ipsum dolor.'
  }
]
