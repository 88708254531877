import { put, call, select } from 'redux-saga/effects'
import { dashboardSelector } from 'domain/manager/selectors'
import { dateTimeRangeSelector, DateTimeRangeT } from 'domain/constants'
import { parseParamsWithRange } from 'domain/superManager/dashboard'
import { Sort } from 'types/common'
import I from 'immutable'
import Api from 'domain/api'
import * as Actions from './actions'
import * as M from 'domain/env'

interface Params {
  page?: number
  order?: Sort
  order_by?: string | null
  range?: string
}

export function* ensureJobsCounters() {
  const headers = yield select(M.userToken)
  if (!headers) return
  try {
    const {
      data: { data }
    } = yield call(Api.managerJobsCounters, {
      headers: { Authorization: `Bearer ${headers}` }
    })
    yield put({ type: Actions.managerJobsCounters.success, payload: I.fromJS(data) })
  } catch (err) {
    yield put({
      type: Actions.managerJobsCounters.failure,
      err
    })
  }
}

export function* ensureClientsCounters() {
  const headers = yield select(M.userToken)
  if (!headers) return
  try {
    const {
      data: { data }
    } = yield call(Api.managerClientsCounters, {
      headers: { Authorization: `Bearer ${headers}` }
    })
    yield put({ type: Actions.managerClientsCounters.success, payload: I.fromJS(data) })
  } catch (err) {
    yield put({
      type: Actions.managerClientsCounters.failure,
      err
    })
  }
}

export function* ensureClientClosureRate(props?: { payload?: Params; type: string }) {
  const headers = yield select(M.userToken)
  if (!headers) return
  const range: DateTimeRangeT = yield select(dateTimeRangeSelector)
  const params = parseParamsWithRange(range, props)
  try {
    const { data } = yield call(Api.managerClientClosureRate, {
      headers: { Authorization: `Bearer ${headers}` },
      params
    })
    const page = params.page
    const total = data.meta.total
    const prevStateDashboard = yield select(dashboardSelector)
    const prevState = prevStateDashboard.get('clientClosureRate')
    if (!params || !page || page === 1 || total === prevState.size) {
      yield put({ type: Actions.managerNewClientClosureRate.success, payload: I.fromJS(data) })
    } else {
      yield put({ type: Actions.managerClientClosureRate.success, payload: I.fromJS(data) })
    }
  } catch (err) {
    yield put({
      type: Actions.managerClientClosureRate.failure,
      err
    })
  }
}

export function* ensureStatistics(props?: { payload?: { range?: string }; type: string }) {
  const headers = yield select(M.userToken)
  if (!headers) return
  const range: DateTimeRangeT = yield select(dateTimeRangeSelector)
  const params = parseParamsWithRange(range, props)
  try {
    const {
      data: { data }
    } = yield call(Api.managerStatistics, {
      headers: { Authorization: `Bearer ${headers}` },
      params
    })
    yield put({ type: Actions.managerStatistics.success, payload: I.fromJS(data) })
  } catch (err) {
    yield put({
      type: Actions.managerStatistics.failure,
      err
    })
  }
}

export function* ensureTopRecruiters(props?: { payload?: Params; type: string }) {
  const headers = yield select(M.userToken)
  if (!headers) return
  const range: DateTimeRangeT = yield select(dateTimeRangeSelector)
  const params = parseParamsWithRange(range, props)
  try {
    const {
      data: { data }
    } = yield call(Api.managerTopRecruiters, {
      headers: { Authorization: `Bearer ${headers}` },
      params
    })
    yield put({ type: Actions.managerTopRecruiters.success, payload: I.fromJS(data) })
  } catch (err) {
    yield put({
      type: Actions.managerTopRecruiters.failure,
      err
    })
  }
}

export function* ensureClosedJobs(props?: { payload?: Params; type: string }) {
  const headers = yield select(M.userToken)
  if (!headers) return
  const range: DateTimeRangeT = yield select(dateTimeRangeSelector)
  const params = parseParamsWithRange(range, props)
  try {
    const { data } = yield call(Api.managerClosedJobs, {
      headers: { Authorization: `Bearer ${headers}` },
      params
    })
    const page = params.page
    const total = data.meta.total
    const prevStateDashboard = yield select(dashboardSelector)
    const prevState = prevStateDashboard.get('closedJobs')
    if (!params || !page || page === 1 || total === prevState.size) {
      yield put({ type: Actions.managerNewClosedJobs.success, payload: I.fromJS(data) })
    } else {
      yield put({ type: Actions.managerClosedJobs.success, payload: I.fromJS(data) })
    }
  } catch (err) {
    yield put({
      type: Actions.managerClosedJobs.failure,
      err
    })
  }
}
