import { all, call, fork, put, take, takeLatest } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { pageIsLoading } from 'domain/loading'
import * as J from 'domain/manager/jobs'
import * as Job from 'domain/jobs'

export function* jobsView() {
  yield all([
    fork(getJob),
    fork(holdJob),
    fork(getCandidates),
    fork(getRecruiters),
    fork(closeJob),
    fork(getJobNotes),
    fork(addNote),
    fork(deleteNote),
    fork(editNote),
    fork(initialLoad)
  ])
}

function* getJob() {
  yield takeLatest(J.jobData.type, J.ensureGetJob)
  yield take(J.jobData.success)
}

function* holdJob() {
  yield takeLatest(J.holdJob.type, J.ensureHoldJob)
  yield take(J.holdJob.success)
  yield put(push('/manager/jobs'))
}

function* getCandidates() {
  yield takeLatest(J.candidatesForCloseJob.type, J.ensureCandidatesForCloseJob)
  yield take(J.candidatesForCloseJob.success)
}

function* getRecruiters() {
  yield takeLatest(J.recruitersForCloseJob.type, J.ensureRecruitersForCloseJob)
  yield take(J.recruitersForCloseJob.success)
}

function* closeJob() {
  yield takeLatest(J.closeJob.TRIGGER, J.ensureCloseJob)
  yield take(J.closeJob.SUCCESS)
}

function* getJobNotes() {
  yield takeLatest(Job.getJobNotes.type, Job.ensureGetJobNotes)
  yield take(Job.getJobNotes.success)
}

function* addNote() {
  yield takeLatest(Job.addNoteToJob.type, Job.ensurePostJobNotes)
  yield take(Job.addNoteToJob.success)
}

function* deleteNote() {
  yield takeLatest(Job.deleteNoteInJob.type, Job.ensureDeleteJobNote)
  yield take(Job.deleteNoteInJob.success)
}

function* editNote() {
  yield takeLatest(Job.editNoteInJob.type, Job.ensureEditJobNote)
  yield take(Job.editNoteInJob.success)
}

function* initialLoad() {
  yield put({ type: pageIsLoading, payload: true })
  try {
    yield all([call(J.ensureGetJob), call(Job.ensureGetJobNotes)])
  } finally {
    yield put({ type: pageIsLoading, payload: false })
  }
}
