import React, { FC } from 'react'
// types
import { Classes } from 'jss'
// styles
import injectSheet, { Styles } from 'react-jss'
// utils
import cx from 'classnames'

const style: Styles = {
  square: {
    position: 'absolute',
    width: '33vw',
    height: '33vw',
    top: '-13vw',
    right: '-9vw',
    zIndex: -1,
    background: '#E6E6E6',
    opacity: 0.2,
    transform: 'matrix(0.71, 0.71, 0.71, -0.71, 0, 0)'
  },
  forSignUp: {
    background: '#E6E6E6',
    opacity: 0.3,
    transform: 'matrix(0.71, 0.71, 0.71, -0.71, 0, 0)',
    top: '15em',
    left: '-30em',
    width: 668,
    height: 668
  },
  '@media (max-width: 767.98px)': {
    square: {
      width: 668,
      height: 668,
      top: '-5em',
      left: '10em'
    }
  }
}

interface Props {
  classes: Classes
  className?: string
}

const SquareBackground: FC<Props> = ({ classes, className = '' }) => (
  <div className={cx(classes.square, classes[className])} />
)

export default injectSheet(style)(SquareBackground)
