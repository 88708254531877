import { all, call, fork, select, put } from 'redux-saga/effects'
import { replace } from 'connected-react-router'
import * as J from 'domain/manager/jobs'
import * as R from 'domain/router'
import * as Rec from 'domain/manager/recruiters'
import queryString from 'query-string'
import { recruitersSelector } from 'domain/manager/selectors'

export function* jobsList({ query, pathname }: { query: J.Params; pathname: string }) {
  yield all([fork(getJobsList, { query, pathname })])
}

function* getJobsList({ query, pathname }: { query: J.Params; pathname: string }) {
  const props = {
    payload: {
      ...query,
      recruiterId: query.recruiterId,
      status: query.status,
      page: query.page || 1
    },
    type: J.jobsList.request
  }
  const route = yield select(R.routing)
  const prevLocation = Object.keys(route.prevLocation || {}).length
  if (!prevLocation || (prevLocation && route.action === 'POP' && props.payload.page !== 1)) {
    const stringifyParams = queryString.stringify(props.payload, { arrayFormat: 'bracket' })
    yield put(replace(`${pathname}?${stringifyParams}`))
    return
  }
  yield call(J.ensureGetJobs, props)
  const recruiters = yield select(recruitersSelector)
  if (recruiters.isEmpty()) yield call(Rec.ensureRecruitersList)
}
