import { ClosedJobsOrderNamesT, EmploymentTypeT, DateTimeRangeT } from 'domain/constants'

export const SKILENT_VERSION = process.env.REACT_APP_SKILENT_VERSION

export const employmentType: { [x: string]: string } = {
  full_time: 'Full time',
  optional: 'Other',
  temporary: 'Temporary',
  part_time: 'Part time'
}

export const employeeType = (names: EmploymentTypeT) => ({
  [names.get('w2')]: 'W2',
  [names.get('e1099')]: '1099',
  [names.get('corpToCorp')]: 'Corp-to-corp'
})

export const closedJobsTableTitles = (title: string, names: ClosedJobsOrderNamesT) => [
  { value: names.get('jobTitle'), name: 'Job Title', long: false, canSort: true },
  { value: names.get('recruiterName'), name: title, long: false, canSort: true },
  { value: names.get('jobClosedAt'), name: 'Dates', long: false, canSort: true }
]

export const workExperienceEducationTitle = {
  education: {
    title: 'school',
    text: 'degree',
    description: 'study',
    from: 'from',
    to: 'to'
  },
  workExperience: {
    title: 'title',
    text: 'company',
    description: 'location',
    from: 'fromYear',
    to: 'toYear'
  }
}

export const renderRangeTabs = (rangeConst: DateTimeRangeT, role?: string) => {
  const tabs = [
    {
      id: 0,
      value: rangeConst.get('week'),
      name: 'week'
    },
    {
      id: 1,
      value: rangeConst.get('month'),
      name: 'month'
    },
    {
      id: 2,
      value: rangeConst.get('year'),
      name: 'year'
    }
  ]
  if (role && ['super_manager', 'manager'].includes(role)) {
    tabs.push({
      id: 3,
      value: 'all',
      name: 'all'
    })
  }
  return tabs
}

export const candidateStatuses = [
  { label: 'Contacted', value: 'Contacted' },
  { label: 'Submitted  (to Client for Job)', value: 'Submitted  (to Client for Job)' },
  { label: 'First Interview Scheduled', value: 'First Interview Scheduled' },
  { label: 'First Interview Complete', value: 'First Interview Complete' },
  { label: 'Rejected', value: 'Rejected' },
  { label: 'Second Interview Scheduled', value: 'Second Interview Scheduled' },
  { label: 'Second Interview Complete', value: 'Second Interview Complete' },
  { label: 'Candidate Selected', value: 'Candidate Selected' },
  { label: 'Hiring/OnBoarding', value: 'Hiring/OnBoarding' }
]
