import { all, fork, call } from 'redux-saga/effects'
import * as A from 'domain/superManager/managers'

export function* profilePage() {
  yield all([fork(getProfile)])
}

function* getProfile() {
  yield call(A.ensureGetManager)
}
