import React from 'react'
import Svg, { SvgType } from './Svg'

export default function(props: SvgType) {
  return (
    <Svg width="13" height="11" viewBox="0 0 13 11" fill="none" {...props}>
      <path d="M11 5.5L4.88891 10.3889L4.88891 0.611085L11 5.5Z" fill="#DEDEDE" />
    </Svg>
  )
}
