import React, { FC, LegacyRef } from 'react'
// components
import { Button } from 'components/Button'
// types
import { Classes } from 'jss'
import { ButtonTypes } from 'types/common'
import { NotificationTypeT } from 'domain/constants'
import { NotificationsListType } from 'pages/Notifications/types'
// styles
import { sheet } from './sheet'
import injectSheet from 'react-jss'
// utils
import { notificationsStructure } from 'pages/Notifications/notificationsStructure'

interface Props {
  classes: Classes
  notifications: NotificationsListType
  notificationsTypes: NotificationTypeT
  setWrapperRef: LegacyRef<HTMLDivElement>
  countUnreadNotifications: number
  toggleNotificationsMenu: () => void
  role: string
}
const Menu: FC<Props> = ({
  classes,
  setWrapperRef,
  notifications,
  notificationsTypes,
  role,
  countUnreadNotifications,
  toggleNotificationsMenu
}) => {
  const list =
    notifications && !notifications.isEmpty() && notifications.size > 7
      ? notifications.take(7)
      : notifications
  return (
    <div className={classes.root} ref={setWrapperRef} onClick={toggleNotificationsMenu}>
      <p className={classes.title}>
        Notifications<span>{countUnreadNotifications}</span>
      </p>
      {list &&
        !list.isEmpty() &&
        list.map((item, i: number) => (
          <div key={i}>{notificationsStructure(item, notificationsTypes, role, true)}</div>
        ))}
      <Button
        buttonStyle={true}
        isLink={true}
        label="View All"
        to={`/notifications`}
        type={ButtonTypes.BlueButton}
      />
    </div>
  )
}

export default injectSheet(sheet)(Menu)
