import React from 'react'
import { Styles } from 'react-select/lib/styles'
import { IndicatorProps } from 'react-select/lib/components/indicators'

export const colourStyles: Styles = {
  menuPortal: base => ({ ...base, zIndex: 9999 }),
  control: (styles, state) => {
    return {
      ...styles,
      display: 'flex',
      minWidth: 100,
      width: '100%',
      borderRadius: 2,
      color: '#111111',
      fontSize: 15,
      fontWeight: 400,
      backgroundColor: '#FFFFFF',
      boxSizing: 'border-box',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: state.theme.colors.neutral10,
      '&:hover': {
        borderColor: 'none'
      }
    }
  },
  placeholder: styles => ({
    ...styles,
    fontSize: 15,
    letterSpacing: '0.01em',
    color: '#989FA6',
    lineHeight: '20px',
    fontWeight: 'normal'
  }),
  input: styles => {
    return {
      ...styles,
      color: '#080910',
      fontWeight: 'normal',
      lineHeight: '20px',
      fontSize: '15px',
      letterSpacing: '0.01em',
      border: 'none',
      maxWidth: 100
    }
  },
  menu: styles => {
    return {
      ...styles,
      backgroundColor: '#FFFFFF',
      borderRadius: 'unset',
      boxShadow: '0px 2px 9px rgba(0, 0, 0, 0.15), 0px 1px 3px rgba(0, 0, 0, 0.08)',
      zIndex: 9999
    }
  },
  option: (styles, { isFocused }) => {
    return {
      ...styles,
      backgroundColor: isFocused ? '#EEE' : undefined,
      lineHeight: '20px',
      fontSize: 15,
      letterSpacing: '0.01em',
      color: '#080910'
    }
  },
  singleValue: styles => {
    return {
      ...styles,
      color: '#080910',
      fontWeight: 'normal',
      lineHeight: '20px',
      fontSize: '15px',
      letterSpacing: '0.01em',
      border: 'none'
    }
  },
  valueContainer: styles => {
    return { ...styles, border: 'none' }
  },
  indicatorSeparator: styles => {
    return {
      ...styles,
      display: 'none'
    }
  }
}
export const colourStylesWithoutBorder: Styles = {
  ...colourStyles,
  control: (styles, state) => {
    return {
      ...styles,
      border: 'none',
      borderBottomWidth: '1px',
      borderBottomStyle: 'solid',
      borderBottomColor: state.theme.colors.neutral10,
      borderRadius: 'none',
      boxShadow: 'none'
    }
  }
}

export const DropdownIndicator = (
  state: IndicatorProps<{ value: string | number; label: string | number }>
) => {
  return state.isFocused ? (
    <div
      style={{
        width: 0,
        height: 0,
        borderLeft: '6px solid transparent',
        borderRight: '6px solid transparent',
        borderBottom: '6px solid #080910',
        margin: '0 10px',
        transition: 'all .2s ease'
      }}
    />
  ) : (
    <div
      style={{
        width: 0,
        height: 0,
        borderLeft: '6px solid transparent',
        borderRight: '6px solid transparent',
        borderTop: '6px solid #080910',
        margin: '0 10px',
        transition: 'all .2s ease'
      }}
    />
  )
}
