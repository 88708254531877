import { put, call, select } from 'redux-saga/effects'
import { isLoadingCalendarEvent, modalIsLoading, pageIsLoading } from 'domain/loading'
import * as Actions from './actions'
import * as M from 'domain/env'
import Api from 'domain/api'
import I from 'immutable'
import { prepareDataEvent, prepareEventsList, DateType } from './helpers'

export function* ensureEventsList() {
  const headers = yield select(M.userToken)
  if (!headers) return
  yield put({ type: pageIsLoading, payload: true })
  try {
    const {
      data: { data }
    } = yield call(Api.candidate.getEventsList, {
      headers: { Authorization: `Bearer ${headers}` }
    })
    yield put({
      type: Actions.candidateEventsList.success,
      payload: I.fromJS(data)
    })
    yield put({
      type: Actions.nearestEventId.success,
      payload: prepareEventsList(data)
    })
  } catch (err) {
    yield put({
      type: Actions.candidateEventsList.failure,
      err
    })
  } finally {
    yield put({ type: pageIsLoading, payload: false })
  }
}

interface PropsAddEvent {
  payload: DateType
  type: string
}

export function* ensureAddEvent({ payload }: PropsAddEvent) {
  const headers = yield select(M.userToken)
  if (!payload || !headers) return
  try {
    yield put({ type: modalIsLoading, payload: true })
    const {
      data: { data }
    } = yield call(Api.candidate.postEvent, {
      headers: { Authorization: `Bearer ${headers}` },
      data: prepareDataEvent(payload)
    })
    yield put({ type: Actions.candidateEventAdd.SUCCESS, payload: I.fromJS(data) })
    yield put({ type: Actions.candidateEventsList.type })
  } catch (err) {
    yield put({
      type: Actions.candidateEventAdd.FAILURE,
      err
    })
  } finally {
    yield put({ type: modalIsLoading, payload: false })
  }
}

interface PropsRemoveEvent {
  payload: number
  type: string
}

export function* ensureEvent({ payload }: PropsRemoveEvent) {
  yield put({ type: isLoadingCalendarEvent, payload: true })
  yield put({ type: pageIsLoading, payload: true })
  const headers = yield select(M.userToken)
  if (!headers) return
  try {
    const {
      data: { data }
    } = yield call(Api.candidate.getEvent, {
      headers: { Authorization: `Bearer ${headers}` },
      id: payload
    })
    yield put({ type: Actions.candidateEvent.success, payload: I.fromJS(data) })
  } catch (err) {
    yield put({
      type: Actions.candidateEvent.failure,
      err
    })
  } finally {
    yield put({ type: isLoadingCalendarEvent, payload: false })
    yield put({ type: pageIsLoading, payload: false })
  }
}

export function* ensureUpdateEvent({ payload }: PropsAddEvent) {
  const headers = yield select(M.userToken)
  if (!payload || !headers) return
  try {
    yield put({ type: modalIsLoading, payload: true })
    const {
      data: { data }
    } = yield call(Api.candidate.putEvent, {
      headers: { Authorization: `Bearer ${headers}` },
      id: payload.id,
      data: prepareDataEvent(payload)
    })
    yield put({ type: Actions.candidateEventUpdate.SUCCESS, payload: I.fromJS(data) })
    yield put({ type: Actions.candidateEventsList.type })
  } catch (err) {
    yield put({
      type: Actions.candidateEventUpdate.FAILURE,
      err
    })
  } finally {
    yield put({ type: modalIsLoading, payload: false })
  }
}

export function* ensureRemoveEvent({ payload }: PropsRemoveEvent) {
  const headers = yield select(M.userToken)
  if (!headers) return
  try {
    yield put({ type: modalIsLoading, payload: true })
    const {
      data: { data }
    } = yield call(Api.candidate.deleteEvent, {
      headers: { Authorization: `Bearer ${headers}` },
      id: payload
    })
    yield put({ type: Actions.candidateEventDelete.SUCCESS, payload: I.fromJS(data) })
    yield put({ type: Actions.candidateEventsList.type })
  } catch (err) {
    yield put({
      type: Actions.candidateEventDelete.FAILURE,
      err
    })
  } finally {
    yield put({ type: modalIsLoading, payload: false })
  }
}
