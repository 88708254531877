import { Styles } from 'react-jss'

export const sheet: Styles = {
  content: {},
  number: {},
  text: {},
  button: {},
  cursor: {},
  root: {
    padding: 60,
    '& > div:first-child': {
      zIndex: 1,
      top: '-30em'
    },
    '& > h3': {
      color: '#286EFA',
      margin: 0,
      textAlign: 'center'
    },
    '& > h5': {
      textAlign: 'center'
    },
    '& > $content': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      '& > div:first-child': {
        marginRight: '3vw'
      },
      '& > div': {
        paddingLeft: '7vw',
        '& > $number': {},
        '& > $text': {
          fontStyle: 'normal',
          fontWeight: '500',
          fontSize: '17px',
          lineHeight: '21px',
          letterSpacing: '0.01em',
          color: '#080910',
          paddingLeft: 30,
          maxWidth: 234
        },
        '& > div': {
          display: 'flex',
          position: 'relative',
          marginTop: 27,
          '& > $button': {
            marginLeft: -16
          },
          '& > $cursor': {
            marginTop: 16,
            marginLeft: -60
          }
        }
      },
      '& > div:last-child > div > svg:last-child': {
        marginTop: 45,
        marginLeft: -80
      }
    }
  }
}
