import { Styles } from 'react-jss'

export const sheet: Styles = {
  largeGrow: {},
  tableRow: {
    display: 'flex',
    flexDirection: 'row',
    borderBottom: '1px solid #DEDEDE',
    background: '#FFFFFF',
    '& > div': {
      display: 'flex',
      padding: '0.5em 0.5em 0.5em 30px',
      flexDirection: 'column',
      flexGrow: '1',
      flexBasis: 0,
      wordBreak: 'break-word',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '15px',
      lineHeight: '20px',
      letterSpacing: '0.01em',
      color: '#080910'
    },
    '& > $largeGrow': {
      flexGrow: '2'
    }
  },
  type: {
    background: '#E6E6E6',
    borderRadius: '2px',
    width: '50%',
    height: '16px'
  },
  position: {
    '& > div': {
      background: '#FAFAFA',
      borderRadius: '2px',
      height: '16px',
      marginBottom: 19,
      width: '60%'
    },
    '& > div:first-child': {
      background: '#E6E6E6',
      height: '16px',
      marginBottom: 10,
      width: '100%'
    },
    '& > div:last-child': {
      marginBottom: 0,
      width: '70%',
      display: 'flex',
      flexDirection: 'row',
      background: 'none',
      '& > div:first-child': {
        width: '18px',
        height: '18px',
        background: '#FAFAFA',
        borderRadius: '50%',
        marginRight: 4
      },
      '& > div:last-child': {
        background: '#FAFAFA',
        borderRadius: '2px',
        height: '16px',
        width: '100%'
      }
    }
  },
  standard: {
    '& > div': {
      background: '#FAFAFA',
      borderRadius: '2px',
      width: '50%',
      height: '16px'
    }
  },
  points: {
    '& > div': {
      background: '#286EFA',
      borderRadius: '2px',
      width: '50%',
      height: '16px'
    }
  },
  recruiters: {
    marginBottom: 10,
    width: '70%',
    display: 'flex',
    flexDirection: 'row',
    background: 'none',
    alignItems: 'center',
    '& > div:first-child': {
      width: '26px',
      height: '26px',
      background: '#FAFAFA',
      borderRadius: '50%',
      marginRight: 4
    },
    '& > div:last-child': {
      background: '#FAFAFA',
      borderRadius: '2px',
      height: '16px',
      width: '100%'
    }
  }
}
