import { takeLatest, takeEvery, call } from 'redux-saga/effects'
import * as M from 'domain/messages'
import * as A from 'domain/env/actions'

export function* messagesWatcher() {
  // Motivation: We should connect all action watchers to main saga,
  // to make sure all of them will continue to execute after user change location
  // (otherwise they will be canceled automatically)
  yield takeLatest([A.signIn.success, A.checkAuth.success], M.ensureLoadSendBird)
  yield takeLatest(A.signOut.type, M.ensureDisconnectFromSendBird)
  yield takeLatest(M.setActiveChannelUrl.type, M.ensureSetActiveChannel)
  yield takeLatest(M.addChannels.success, M.ensureSetActiveChannel)
  yield takeLatest(M.markAsRead.type, M.ensureMarkAsRead)
  yield takeEvery(M.addChannels.type, M.ensureLoadChannels)
  yield takeEvery(M.sendTextMessage.type, M.ensureSendUserTextMessage)
  yield takeEvery(M.sendFileMessage.type, M.ensureSendUserFileMessage)
  yield takeEvery(M.addMessages.type, M.ensureLoadMessages)
}

export function* messagesPage() {
  yield call(M.ensureOpenMessages)
}
