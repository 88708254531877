import React, { Component } from 'react'
// redux
import { WrappedFieldProps } from 'redux-form'
// types
import { RadioPropsType } from 'components/Form/Fields/FieldRadio'
// styles
import { sheet } from './sheet'
import injectSheet, { StyledComponentProps } from 'react-jss'

type Props = RadioPropsType &
  React.InputHTMLAttributes<HTMLInputElement> &
  WrappedFieldProps &
  StyledComponentProps

class Radio extends Component<Props> {
  render() {
    const { input, name, label, id, classes = {}, disabled = false, ...arg } = this.props
    return (
      <div className={classes.root}>
        <input
          type="radio"
          name={name}
          id={id}
          {...input}
          className={classes.radio}
          disabled={disabled}
          {...arg}
        />
        <label htmlFor={id}>
          <div className={classes.iconSize}>
            <div className={classes.icon} />
          </div>
          {label}
        </label>
      </div>
    )
  }
}

export default injectSheet(sheet)(Radio)
