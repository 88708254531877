import React from 'react'
// types
import { Classes } from 'jss'
// components
import NotificationTitle from 'pages/Notifications/NotificationTitle'
// styles
import injectSheet from 'react-jss'
import { sheet } from './sheet'
// utils
import cx from 'classnames'

interface Props {
  classes: Classes
  title: string
  count: string
  viewedAt: boolean
  createdAt: string
}

const Index = ({ classes, title, count, viewedAt, createdAt }: Props) => {
  return (
    <div className={cx(classes.root, { [classes.unread]: viewedAt })}>
      <NotificationTitle title={`${count} ${title}`} />
      <p className="createdAt">{createdAt}</p>
    </div>
  )
}

export default injectSheet(sheet)(Index)
