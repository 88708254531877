import React, { Component } from 'react'
// components
import { Button } from 'components/Button'
import { FieldInput } from 'components/Form/Fields/FieldInput'
import Input from 'components/Form/Input'
import CircleBackground from 'components/common/CircleBackground'
// redux
import * as A from 'domain/env'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { InjectedFormProps, reduxForm } from 'redux-form'
import { push, Push } from 'connected-react-router'
// types
import { ButtonHtmlTypes, ButtonTypes, ComposeType } from 'types/common'
import { Classes } from 'jss'
// styles
import { sheet } from './sheet'
import injectSheet from 'react-jss'
// utils
import { signInValidate } from 'pages/Auth/validation'

interface OwnProps {
  classes: Classes
  signIn: (data: { email: string; password: string }) => void
  push?: Push
}

type Props = InjectedFormProps<{ email: string; password: string }> & OwnProps

class LogIn extends Component<Props> {
  submit = (data: { email: string; password: string }) => {
    this.props.signIn(data)
  }

  onClick = (path: string) => {
    if (this.props.push) this.props.push(path)
  }

  resetPassword = () => {
    this.onClick('/reset-password')
  }

  signUp = () => {
    this.onClick('/sign-up')
  }

  render() {
    const { classes, handleSubmit } = this.props
    return (
      <form onSubmit={handleSubmit(this.submit)}>
        <div className={classes.content}>
          <div className={classes.text}>
            <CircleBackground className="forLogIn" />
            <h3>
              Welcome back! <div />
            </h3>
            <h4>Skilent is growing day after day!</h4>
            {/*<div className={classes.columnBlock}>*/}
            {/*  <div>*/}
            {/*    <h4>140+</h4>*/}
            {/*    <p>Professional Hiring managers with extensive experience</p>*/}
            {/*  </div>*/}
            {/*  <div>*/}
            {/*    <h4>500+</h4>*/}
            {/*    <p>Verified Recruiters with proper education requirements</p>*/}
            {/*  </div>*/}
            {/*  <div>*/}
            {/*    <h4>23 000</h4>*/}
            {/*    <p>jobs on average are closed every month</p>*/}
            {/*  </div>*/}
            {/*</div>*/}
          </div>
          <div className={classes.form}>
            <h4>Log in to Skilent</h4>
            <FieldInput
              name="email"
              type="text"
              component={Input}
              title="Email"
              placeholder="email@example.co"
              autoComplete="on"
            />
            <FieldInput
              name="password"
              type="password"
              component={Input}
              title="Password"
              autoComplete="on"
            />
            <Button label="Forgot Password" onClick={this.resetPassword} />
            <div className={classes.buttonGroup}>
              <Button label="sign up" onClick={this.signUp} />
              <Button type={ButtonTypes.Submit} htmlType={ButtonHtmlTypes.Submit} label="Log in" />
            </div>
            {/*<LinkedinLogo name="Log in with Linked In" />*/}
          </div>
        </div>
      </form>
    )
  }
}

export default compose<ComposeType<Props>>(
  connect(
    null,
    {
      signIn: A.signIn,
      push
    }
  ),
  reduxForm({
    form: 'log-in',
    validate: signInValidate,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true
  }),
  injectSheet(sheet)
)(LogIn)
