import moment from 'moment'
import { EventI, NewEventI } from 'types/calendarTypes'

export const prepareDataEvent = (payload: NewEventI) => {
  const {
    title,
    dateStart,
    dateEnd,
    timeStart,
    timeEnd,
    candidate,
    comment,
    job,
    zoomMeetingNumber,
    zoomMeetingPassword,
    zoom
  } = payload
  const start = new Date(dateStart.setHours(timeStart.getHours(), timeStart.getMinutes(), 0, 0))
  const end = new Date(dateEnd.setHours(timeEnd.getHours(), timeEnd.getMinutes(), 0, 0))

  return {
    title,
    start,
    end,
    candidateId: candidate && candidate.value,
    jobId: job ? job.value : undefined,
    comment: comment || undefined,
    zoomMeetingNumber: zoom ? zoomMeetingNumber : null,
    zoomMeetingPassword: zoom ? zoomMeetingPassword : null
  }
}

export const prepareDataUnavailability = ({ dates }: NewEventI) => {
  return dates.map(item => {
    const { dateStart, dateEnd, timeStart, timeEnd } = item
    const start = new Date(dateStart.setHours(timeStart.getHours(), timeStart.getMinutes(), 0, 0))
    const end = new Date(dateEnd.setHours(timeEnd.getHours(), timeEnd.getMinutes(), 0, 0))

    return {
      start,
      end
    }
  })
}

export const prepareUpdateUnavailability = ({ dates }: NewEventI) => {
  if (!dates || dates.length < 1) return {}
  const date = dates[0]
  const { dateStart, dateEnd, timeStart, timeEnd } = date
  const start = new Date(dateStart.setHours(timeStart.getHours(), timeStart.getMinutes(), 0, 0))
  const end = new Date(dateEnd.setHours(timeEnd.getHours(), timeEnd.getMinutes(), 0, 0))

  return {
    start,
    end
  }
}

export const prepareEventsList = (data: EventI[]) => {
  const dateOfToday = moment()
  let nearestEvent: EventI | undefined
  const events = data.map(item => {
    if (
      !item.rendering &&
      !moment(item.start).isBefore(dateOfToday) &&
      (!nearestEvent || moment(item.start).isBefore(moment(nearestEvent.start)))
    ) {
      nearestEvent = item
    }
    const rendering = item.rendering === 'background' ? 'unavailability' : null
    return { ...item, rendering }
  })
  return {
    events,
    nearestEventId: nearestEvent && nearestEvent.id
  }
}
