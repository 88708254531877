import { eventChannel, END, Channel, Task } from 'redux-saga'
import { call, fork, put, take, cancelled, select, all, cancel } from 'redux-saga/effects'
import * as M from './actions'
import * as S from './selectors'
import * as H from './helpers'
import * as U from 'domain/env'
import { checkAuth } from 'domain/env/sagas'
import Api from 'domain/api'
import { push } from 'connected-react-router'
import * as SendBird from './sendBird'
import { GroupChannel, Member } from 'sendbird'
import { pageIsLoading } from 'domain/loading'

const CHANNELS_PAGINATION = 25 // The value of pagination limit could be set up to 100.
const MESSAGES_PAGINATION = 20

function pause(delay = 1000) {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve()
    }, delay)
  })
}

let listenerTask1: Task
let listenerTask2: Task
let listenerTask3: Task
let groupChannelLoader: any

export function* ensureConnectToSendBird() {
  const isConnected = yield select(S.isConnectedSelector)
  const user = yield select(U.userSelector)
  const uuid = user.get('uuid')
  const accessToken = user.get('sbAccessToken')
  try {
    if (!isConnected && uuid && accessToken) {
      const sbUser = yield call(SendBird.connect, uuid, accessToken)
      yield call(ensureSetUser, sbUser)
      yield fork(loadUnreadMessagesCount)

      yield put({ type: M.setIsConnected.success, payload: true })
    }
  } catch (err) {
    yield put({ type: M.setIsConnected.success, payload: false })
  }
}

export function* ensureListeningEvents() {
  const isConnected = yield select(S.isConnectedSelector)
  const isListening = yield select(S.isListeningSelector)
  try {
    if (isConnected && !isListening) {
      yield call(ensureRemoveEventListeners)
      listenerTask1 = yield fork(connectionListener)
      listenerTask2 = yield fork(channelsListener)
      listenerTask3 = yield fork(userListener)
      yield put({ type: M.setIsListening.success, payload: true })
    }
  } catch (err) {
    yield put({ type: M.setIsListening.success, payload: false })
  }
}

export function* ensureRemoveEventListeners() {
  if (listenerTask1) yield cancel(listenerTask1)
  if (listenerTask2) yield cancel(listenerTask2)
  if (listenerTask3) yield cancel(listenerTask3)
  groupChannelLoader = null
}

export function* ensureLoadSendBird() {
  yield call(ensureConnectToSendBird)
  yield call(ensureListeningEvents)
}

export function* ensureOpenMessages() {
  try {
    yield put({ type: pageIsLoading, payload: true })
    const channels = yield select(S.channelsSelector)
    yield call(ensureConnectToSendBird)
    yield call(ensureSetGroupChannelsExist)
    if (channels.isEmpty() || channels.size <= CHANNELS_PAGINATION) {
      yield call(ensureLoadChannels)
    }
    yield fork(ensureListeningEvents)
  } finally {
    yield put({ type: pageIsLoading, payload: false })
  }
}

function* ensureSetUser(sbUser: any) {
  try {
    yield put({
      type: M.setUser.success,
      payload: { user: H.sbUserRedux(sbUser) }
    })
  } catch (err) {
    yield put({
      type: M.setUser.failure,
      err
    })
  }
}

export function* ensureDisconnectFromSendBird() {
  SendBird.disconnect()
  yield call(ensureRemoveEventListeners)
  yield put({ type: M.clear.success })
}

function* loadUnreadMessagesCount() {
  try {
    const count = yield call(SendBird.getTotalUnreadMessageCount)
    yield put({ type: M.setUnreadMessagesCount.success, payload: { count } })
    if (count > 0) {
      yield fork(loadUnreadChannelsCount)
    }
  } catch (err) {
    yield put({
      type: M.setUnreadMessagesCount.failure,
      err
    })
  }
}

function* loadUnreadChannelsCount() {
  try {
    const count = yield call(SendBird.getTotalUnreadChannelCount)
    yield put({ type: M.setUnreadChannelsCount.success, payload: { count } })
  } catch (err) {
    yield put({
      type: M.setUnreadChannelsCount.failure,
      err
    })
  }
}

export function* ensureSetGroupChannelsExist() {
  try {
    if (!groupChannelLoader) {
      const sb = yield call(SendBird.getSendBirdInstance)
      groupChannelLoader = sb.GroupChannel.createMyGroupChannelListQuery()
      groupChannelLoader.includeEmpty = true
      groupChannelLoader.limit = CHANNELS_PAGINATION
    }
    const { hasNext: groupChannelsExist } = groupChannelLoader
    yield put({
      type: M.setGroupChannelsExist.success,
      payload: { groupChannelsExist }
    })
  } catch (err) {
    yield put({
      type: M.setGroupChannelsExist.failure,
      err
    })
  }
}

export function* ensureLoadChannels() {
  try {
    const user = yield select(U.userSelector)
    const userId = `${user.get('id')}`
    const userUUID = user.get('uuid')

    if (!groupChannelLoader) yield call(ensureSetGroupChannelsExist)
    const { hasNext, isLoading } = groupChannelLoader

    if (hasNext && !isLoading && userId) {
      let sbChannels = yield call(SendBird.getNextChannelList, groupChannelLoader)
      if (sbChannels.length > 0) {
        // cover case when some of channels already loaded => filter them
        const channels = yield select(S.channelsSelector)
        const urls = channels.map((c: any) => c.get('url'))
        sbChannels = sbChannels.filter((c: any) => urls.indexOf(c.url) < 0)
        // prepare new channels for redux storage
        const responses = yield all(
          sbChannels.map((chat: GroupChannel) => call(prepareChannelData, chat, userUUID))
        )
        const newChannels = yield all(responses)
        yield put({
          type: M.addChannels.success,
          payload: { channels: newChannels }
        })
      }
    }
    yield call(ensureSetGroupChannelsExist)
  } catch (err) {
    yield put({
      type: M.addChannels.failure,
      err
    })
  }
}

export function* prepareChannelData(sbChannel: GroupChannel, uuid: string) {
  const recipient = sbChannel.members.find((u: Member) => u.userId !== uuid) || sbChannel.members[0]
  const messageLoader = sbChannel.createPreviousMessageListQuery()
  messageLoader.limit = MESSAGES_PAGINATION
  return H.sbChannelRedux(sbChannel, messageLoader, recipient)
}

export function* ensureStartConversation(props?: any) {
  // currentUser
  const user = yield select(U.userSelector)
  const userId = `${user.get('id')}`
  const userUUID = user.get('uuid')
  const accessToken = user.get('sbAccessToken')
  const userRole = user.get('role')
  // recipientUser
  const { payload } = props
  const { id: recipientId, uuid: recipientUUID, role: recipientRole, isSbRegistered } = payload

  try {
    if (userUUID && recipientUUID && userUUID !== recipientUUID) {
      yield put({ type: pageIsLoading, payload: true })

      if (!accessToken && userId) {
        yield call(registerSendBirdUser, userId, userRole)
      }

      if (!isSbRegistered && recipientId) {
        yield call(registerSendBirdUser, recipientId, recipientRole)
      }

      yield call(ensureConnectToSendBird)

      const sbChannel = yield call(SendBird.createChatOneOnOne, recipientUUID, userUUID)

      if (sbChannel.memberCount === 2) {
        yield put({
          type: M.setActiveChannelUrl.success,
          payload: { url: sbChannel.url }
        })
        yield put(push(`/${userRole}/messages`))
      }

      yield put({ type: pageIsLoading, payload: false })
      // listeners should be at the end cause they will never end
      yield call(ensureListeningEvents)
    }
  } catch (err) {
    yield put({
      type: M.startConversation.failure,
      err
    })
  } finally {
    yield put({ type: pageIsLoading, payload: false })
  }
}

function* registerSendBirdUser(userId: string, role: string) {
  const headers = yield select(U.userToken)
  const id = Number(userId)
  if (id > 0 && role && H.isAcceptableRole(role)) {
    const { data: { data } = { data: { data: {} } } } = yield call(Api.registerSbUser, {
      headers: { Authorization: `Bearer ${headers}` },
      id
    })
    const { accessToken } = data
    if (accessToken) {
      const user = yield select(U.userSelector)
      const currentUserId = Number(user.get('id'))
      if (currentUserId === id) {
        yield call(ensureSetUser, data)
        // Update profile to make sure User has latest accessToken
        yield call(checkAuth)
      }
    }
  }
}

export function* updateSendBirdUser(userId: string, role: string) {
  const headers = yield select(U.userToken)
  const id = Number(userId)
  if (id > 0 && role && H.isAcceptableRole(role)) {
    const { data: { data } = { data: { data: {} } } } = yield call(Api.updateSbUser, {
      headers: { Authorization: `Bearer ${headers}` },
      id
    })
    const { accessToken } = data
    if (accessToken) {
      yield call(ensureSetUser, data)
      // Update profile to make sure User has latest accessToken
      yield call(checkAuth)
    }
  }
}

export function* ensureSetActiveChannel(props?: any) {
  try {
    let channels = yield select(S.channelsSelector)
    const activeChannelUrl = yield select(S.activeChannelUrlSelector)
    const { payload = {} } = props
    const { channelUrl = '' } = payload
    let url = channelUrl || activeChannelUrl

    if (channels.isEmpty()) {
      yield call(ensureLoadChannels)
      channels = yield select(S.channelsSelector)
    }

    if (!channels.isEmpty()) {
      let channelIndex = channels.findIndex((c: any) => c.get('url') === url)
      if (url && channelIndex < 0) {
        const channel = yield call(SendBird.getGroupChannel, url)
        if (channel) yield call(ensureAddChannel, { channel })
        channels = yield select(S.channelsSelector)
        channelIndex = channels.findIndex((c: any) => c.get('url') === url)
      }
      url = channels.getIn([channelIndex >= 0 ? channelIndex : 0, 'url'])
      if (url) {
        yield put({
          type: M.setActiveChannelUrl.success,
          payload: { url }
        })
      }
    }
  } catch (err) {
    yield put({
      type: M.setActiveChannelUrl.failure,
      err
    })
  }
}

export function* ensureAddChannel({ channel }: { channel: GroupChannel }) {
  try {
    if (channel) {
      if (!groupChannelLoader) yield call(ensureSetGroupChannelsExist)
      const { isLoading } = groupChannelLoader
      if (isLoading) yield call(pause, 2000)

      const channels = yield select(S.channelsSelector)
      const channelIndex = channels.findIndex((c: any) => c.get('url') === channel.url)

      if (channelIndex < 0) {
        const user = yield select(U.userSelector)
        const uuid = user.get('uuid').toString()
        const chat = yield call(prepareChannelData, channel, uuid)
        yield put({
          type: M.addChannel.success,
          payload: { channel: chat }
        })
        yield put({
          type: M.moveChannelUp.success,
          payload: { url: channel.url }
        })
      }
    }
  } catch (err) {
    yield put({
      type: M.addChannel.failure,
      err
    })
  }
}

export function* ensureAddMessage({ channel, message }: { channel: any; message: any }) {
  try {
    const channels = yield select(S.channelsSelector)
    const channelIndex = channels.findIndex((c: any) => c.get('url') === channel.url)

    if (channelIndex >= 0) {
      const messages = channels.getIn([channelIndex, 'messages'])
      const messageLoader = channels.getIn([channelIndex, 'messageLoader'])
      if (messages.isEmpty() && messageLoader && messageLoader.hasMore) {
        yield call(ensureLoadMessages, { payload: { channelUrl: channel.url } })
      } else {
        yield put({ type: M.addMessage.success, payload: { channelIndex, message } })
      }
    } else {
      yield call(ensureAddChannel, { channel })
    }
  } catch (err) {
    yield put({
      type: M.addMessage.failure,
      err
    })
  }
}

export function* ensureUpdateChannel({ channel }: { channel: GroupChannel }) {
  try {
    const channels = yield select(S.channelsSelector)
    const channelIndex = channels.findIndex((c: any) => c.get('url') === channel.url)

    if (channelIndex >= 0) {
      yield put({
        type: M.updateChannel.success,
        payload: {
          channelIndex,
          lastMessage: channel.lastMessage,
          unreadMessageCount: channel.unreadMessageCount
        }
      })
      yield put({
        type: M.moveChannelUp.success,
        payload: { url: channel.url }
      })
    } else {
      yield call(ensureAddChannel, { channel })
    }
    yield fork(loadUnreadMessagesCount)
  } catch (err) {
    yield put({
      type: M.updateChannel.failure,
      err
    })
  }
}

export function* ensureRemoveChannel({ url }: { url: string }) {
  try {
    yield put({
      type: M.removeChannel.success,
      payload: {
        url
      }
    })
    const activeChannelUrl = yield select(S.activeChannelUrlSelector)
    if (activeChannelUrl === url) {
      const channels = yield select(S.channelsSelector)
      yield put({
        type: M.setActiveChannelUrl.success,
        payload: {
          url: channels.getIn([0, 'url'], '')
        }
      })
    }
  } catch (err) {
    yield put({
      type: M.removeChannel.failure,
      err
    })
  }
}

export function* ensureSendUserTextMessage(props?: any) {
  try {
    const { payload } = props

    const url = yield select(S.activeChannelUrlSelector)
    const channel = yield call(SendBird.getGroupChannel, url)
    const message = yield call(SendBird.sendUserTextMessage, channel, payload)

    const channels = yield select(S.channelsSelector)
    const channelIndex = channels.findIndex((c: any) => c.get('url') === url)
    if (channelIndex >= 0) {
      yield put({ type: M.addMessage.success, payload: { channelIndex, message } })
    }
  } catch (err) {
    yield put({
      type: M.sendTextMessage.failure,
      err
    })
  }
}

export function* ensureSendUserFileMessage(props?: any) {
  try {
    const {
      payload: { file, customType, data }
    } = props

    const user = yield select(S.userSelector)
    const url = yield select(S.activeChannelUrlSelector)
    const sbChannel = yield call(SendBird.getGroupChannel, url)

    const channels = yield select(S.channelsSelector)
    const channelIndex = channels.findIndex((c: any) => c.get('url') === url)

    const createdAt = new Date().getTime()
    const tempMessage = {
      messageId: createdAt,
      data,
      customType,
      channelUrl: sbChannel.url,
      createdAt,
      name: file.name,
      progress: {
        percent: 0
      },
      sender: {
        profileUrl: user.get('profileUrl'),
        nickname: user.get('nickname')
      }
    }
    yield put({ type: M.addMessage.success, payload: { channelIndex, message: tempMessage } })
    yield call(uploadListener, sbChannel, tempMessage, file)
  } catch (err) {
    yield put({
      type: M.sendFileMessage.failure,
      err
    })
  }
}

export function* ensureMarkAsRead() {
  try {
    const url = yield select(S.activeChannelUrlSelector)
    if (url) {
      yield call(SendBird.markAsRead, url)
    }
  } catch (err) {
    yield put({
      type: M.markAsRead.failure,
      err
    })
  }
}

export function* ensureLoadMessages(props?: any) {
  try {
    const { payload: { channelUrl } = { channelUrl: '' } } = props
    const activeChannelUrl = yield select(S.activeChannelUrlSelector)
    const url = channelUrl || activeChannelUrl
    if (url) {
      const channels = yield select(S.channelsSelector)
      const channelIndex = channels.findIndex((c: any) => c.get('url') === url)

      if (channelIndex >= 0) {
        const messageLoader = channels.getIn([channelIndex, 'messageLoader'])
        if (messageLoader && messageLoader.hasMore && !messageLoader.isLoading) {
          const messages = yield call(SendBird.getPreviousMessages, messageLoader)
          yield put({ type: M.addMessages.success, payload: { channelIndex, messages } })
        }
      }
    }
  } catch (err) {
    yield put({
      type: M.addMessages.failure,
      err
    })
  }
}

function* updateMessage(props?: any) {
  try {
    const { message = {}, messageId: tempMessageId = 0 } = props
    const { channelUrl, messageId } = message
    const id = tempMessageId || messageId

    if (channelUrl && id) {
      const channels = yield select(S.channelsSelector)
      const channelIndex = channels.findIndex((c: any) => c.get('url') === channelUrl)
      const channelMessages = channels.getIn([channelIndex, 'messages'])
      const messageIndex = channelMessages.findIndex((m: any) => m.messageId === id)
      if (messageIndex >= 0) {
        yield put({
          type: M.updateMessage.success,
          payload: {
            channelIndex,
            messageIndex,
            message
          }
        })
      }
    }
  } catch (e) {
    yield put({
      type: M.updateMessage.failure
    })
  }
}

function* connectionListener() {
  let sagaChannel: Channel<any>

  function connectionEventChannel(sb: any, handlerId: string = 'connectionEventChannel') {
    return eventChannel(emit => {
      const handler = new sb.ConnectionHandler()
      handler.onReconnectStarted = () => emit({ type: M.setIsConnected.success, payload: false })
      handler.onReconnectSucceeded = () => emit({ type: M.setIsConnected.success, payload: true })
      handler.onReconnectFailed = () => emit(END)
      sb.addConnectionHandler(handlerId, handler)
      return () => {
        sb.removeConnectionHandler(handlerId)
      }
    })
  }

  try {
    const sb = yield call(SendBird.getSendBirdInstance)
    sagaChannel = yield call(connectionEventChannel, sb)
    while (true) {
      const putData = yield take(sagaChannel)
      yield put(putData)
    }
  } catch (error) {
    // console.log('Error while creating a sagaChannel', error)
  } finally {
    if (yield cancelled()) {
      yield put({ type: M.setIsListening.success, payload: false })
    }
  }
}

function* channelsListener() {
  let sagaChannel: Channel<any>

  function channelsEventHandler(sb: any) {
    const handlerId = `channelListener`
    return eventChannel(emit => {
      const ChannelHandler = new sb.ChannelHandler()
      ChannelHandler.onMessageReceived = (channel: any, message: any) =>
        emit({
          func: ensureAddMessage,
          props: { channel, message }
        })
      ChannelHandler.onChannelChanged = (channel: any) =>
        emit({ func: ensureUpdateChannel, props: { channel } })
      ChannelHandler.onChannelDeleted = (url: string) =>
        emit({ func: ensureRemoveChannel, props: { url } })
      ChannelHandler.onUserReceivedInvitation = (groupChannel: GroupChannel) =>
        emit({ func: ensureAddChannel, props: { channel: groupChannel } })
      sb.addChannelHandler(handlerId, ChannelHandler)
      return () => {
        sb.removeChannelHandler(handlerId)
        emit(END)
      }
    })
  }

  try {
    const sb = yield call(SendBird.getSendBirdInstance)
    sagaChannel = yield call(channelsEventHandler, sb)
    while (true) {
      const { func, callFunc, props } = yield take(sagaChannel)
      if (callFunc) {
        yield call(callFunc, props)
      } else if (func) {
        yield fork(func, props)
      }
    }
  } catch (error) {
    // console.log('Error while creating a sagaChannel', error)
  } finally {
    if (yield cancelled()) {
      yield put({ type: M.setIsListening.success, payload: false })
    }
  }
}

function* userListener() {
  let sagaChannel: Channel<any>

  function createUserEventChannel(sb: any) {
    const handlerId = 'sbUserHandlerId'
    return eventChannel(emit => {
      const handler = new sb.UserEventHandler()
      handler.onFriendsDiscovered = (users: any) => {
        emit(users)
      }
      sb.addUserEventHandler(handlerId, handler)
      return () => {
        sb.removeUserEventHandler(handlerId)
      }
    })
  }

  try {
    const sb = yield call(SendBird.getSendBirdInstance)
    sagaChannel = yield call(createUserEventChannel, sb)
    while (true) {
      yield take(sagaChannel)
    }
  } catch (error) {
    // console.log('Error while creating a sagaChannel', error)
  } finally {
    if (yield cancelled()) {
      yield put({ type: M.setIsListening.success, payload: false })
    }
  }
}

function* uploadListener(groupChannel: GroupChannel, tempMessage: any, file: File) {
  let sagaChannel: Channel<any>

  function createUploadEventChannel(channel: any, params: any) {
    return eventChannel(emit => {
      channel.sendFileMessage(
        params,
        ({ loaded, total }: ProgressEvent) => {
          emit({
            func: updateMessage,
            props: {
              message: {
                ...tempMessage,
                ...{
                  progress: { percent: Math.floor((loaded / total) * 100) }
                }
              }
            }
          })
        },
        (fileMessage: any, error: any) => {
          if (fileMessage) {
            emit({
              func: updateMessage,
              props: { messageId: tempMessage.messageId, message: fileMessage }
            })
          } else {
            emit({
              func: updateMessage,
              props: {
                message: {
                  ...tempMessage,
                  ...{
                    progress: { errorMessage: error.message }
                  }
                }
              }
            })
          }
          return emit(END)
        }
      )

      return () => {
        // console.log('unsubscribe uploadListener')
      }
    })
  }

  try {
    const sb = yield call(SendBird.getSendBirdInstance)
    const params = new sb.FileMessageParams()
    params.file = file // or .fileUrl (You can send a file message with a file URL.)
    params.fileName = file.name
    params.fileSize = file.size
    params.mimeType = file.type
    params.customType = tempMessage.customType
    params.data = tempMessage.data

    sagaChannel = yield call(createUploadEventChannel, groupChannel, params, tempMessage)

    while (true) {
      const { func, props } = yield take(sagaChannel)
      if (func) {
        yield fork(func, props)
      }
    }
  } catch (error) {
    // console.log('Error while creating a sagaChannel', error)
  }
}
