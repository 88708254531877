import React, { Fragment } from 'react'
// types
import { Classes } from 'jss'
// styles
import injectSheet from 'react-jss'
import { sheet } from './sheet'
// utils
import cx from 'classnames'

interface Props {
  classes: Classes
  count?: number
}

const Skeleton = ({ classes, count = 10 }: Props) => {
  const values = new Array(count).fill(0)
  return (
    <Fragment>
      {values.map((item: number, i: number) => (
        <div className={classes.tableRow} key={i}>
          <div>
            <div className={classes.type} />
          </div>
          <div className={cx(classes.largeGrow, classes.position)}>
            <div />
            <div />
            <div>
              <div />
              <div />
            </div>
          </div>
          <div />
          <div className={classes.standard}>
            <div />
          </div>
          <div className={classes.standard}>
            <div />
          </div>
          <div className={classes.points}>
            <div />
          </div>
          <div className={classes.largeGrow}>
            <div className={classes.recruiters}>
              <div />
              <div />
            </div>
            <div className={classes.recruiters}>
              <div />
              <div />
            </div>
          </div>
        </div>
      ))}
    </Fragment>
  )
}

export default injectSheet(sheet)(Skeleton)
