import React from 'react'
// components
import CircleBackground from 'components/common/CircleBackground'
// types
import { Classes } from 'jss'
// styles
import injectSheet from 'react-jss'
import { sheet } from './sheet'
// icons
import One from 'components/svgComponent/confirmEmailHelp/NumberOne'
import Two from 'components/svgComponent/confirmEmailHelp/NumberTwo'
import Button from 'components/svgComponent/confirmEmailHelp/Button'
import Cursor from 'components/svgComponent/confirmEmailHelp/Cursor'
import LetterIcon from 'components/svgComponent/confirmEmailHelp/LetterIcon'
import DropDownImage from 'components/svgComponent/confirmEmailHelp/DropDownImage'
import DropDownProfile from 'components/svgComponent/confirmEmailHelp/DropDownProfile'

interface Props {
  classes: Classes
}

function CreatePassword(props: Props) {
  const { classes } = props

  return (
    <div className={classes.root}>
      <CircleBackground className="forLogIn" />
      <h3>Thank you for joining Skilent</h3>
      <h5>Next steps for you</h5>
      <div className={classes.content}>
        <div>
          <One className={classes.number} />
          <p className={classes.text}>Click the button in the email we have sent to you</p>
          <div>
            <LetterIcon />
            <Button className={classes.button} />
            <Cursor className={classes.cursor} />
          </div>
        </div>
        <div>
          <Two className={classes.number} />
          <p className={classes.text}>Log In to become a part of Skilent!</p>
          <div>
            <DropDownProfile />
            <DropDownImage />
          </div>
        </div>
      </div>
    </div>
  )
}

export default injectSheet(sheet)(CreatePassword)
