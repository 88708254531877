// currency.ts

export interface Currency {
  code: string
  symbol: string
}

/**
 * Returns the currency symbol for a given code. If no currency with the given code exists,
 * it returns an empty string.
 *
 * @param code - The currency code
 */
export function getCurrencySymbol(code: string | null): string {
  if (!code) return ''
  return currencies[code] && currencies[code].symbol ? currencies[code].symbol : ''
}

/**
 * Returns the currency code for a given symbol. If multiple currencies share the same symbol,
 * it returns the first one found. If no currency with the given symbol exists, it returns null.
 *
 * @param symbol - The currency symbol
 */
export function getCurrencyCode(symbol: string): string | null {
  for (const key in currencies) {
    if (currencies[key].symbol === symbol) {
      return key
    }
  }
  return null
}

export const currencies: Record<string, Currency> = {
  USD: { code: 'USD', symbol: '$' },
  EUR: { code: 'EUR', symbol: '€' },
  INR: { code: 'INR', symbol: '₹' },
  CAD: { code: 'CAD', symbol: '$' },
  AED: { code: 'AED', symbol: 'د.إ' },
  AFN: { code: 'AFN', symbol: '؋' },
  ALL: { code: 'ALL', symbol: 'L' },
  AMD: { code: 'AMD', symbol: '֏' },
  ANG: { code: 'ANG', symbol: 'ƒ' },
  AOA: { code: 'AOA', symbol: 'Kz' },
  ARS: { code: 'ARS', symbol: '$' },
  AUD: { code: 'AUD', symbol: '$' },
  AWG: { code: 'AWG', symbol: 'ƒ' },
  AZN: { code: 'AZN', symbol: '₼' },
  BAM: { code: 'BAM', symbol: 'KM' },
  BBD: { code: 'BBD', symbol: '$' },
  BDT: { code: 'BDT', symbol: '৳' },
  BGN: { code: 'BGN', symbol: 'лв' },
  BHD: { code: 'BHD', symbol: '.د.ب' },
  BIF: { code: 'BIF', symbol: 'FBu' },
  BMD: { code: 'BMD', symbol: '$' },
  BND: { code: 'BND', symbol: '$' },
  BOB: { code: 'BOB', symbol: '$b' },
  BOV: { code: 'BOV', symbol: 'BOV' },
  BRL: { code: 'BRL', symbol: 'R$' },
  BSD: { code: 'BSD', symbol: '$' },
  BTC: { code: 'BTC', symbol: '₿' },
  BTN: { code: 'BTN', symbol: 'Nu.' },
  BWP: { code: 'BWP', symbol: 'P' },
  BYN: { code: 'BYN', symbol: 'Br' },
  BYR: { code: 'BYR', symbol: 'Br' },
  BZD: { code: 'BZD', symbol: 'BZ$' },
  CDF: { code: 'CDF', symbol: 'FC' },
  CHE: { code: 'CHE', symbol: 'CHE' },
  CHF: { code: 'CHF', symbol: 'CHF' },
  CHW: { code: 'CHW', symbol: 'CHW' },
  CLF: { code: 'CLF', symbol: 'CLF' },
  CLP: { code: 'CLP', symbol: '$' },
  CNH: { code: 'CNH', symbol: '¥' },
  CNY: { code: 'CNY', symbol: '¥' },
  COP: { code: 'COP', symbol: '$' },
  COU: { code: 'COU', symbol: 'COU' },
  CRC: { code: 'CRC', symbol: '₡' },
  CUC: { code: 'CUC', symbol: '$' },
  CUP: { code: 'CUP', symbol: '₱' },
  CVE: { code: 'CVE', symbol: '$' },
  CZK: { code: 'CZK', symbol: 'Kč' },
  DJF: { code: 'DJF', symbol: 'Fdj' },
  DKK: { code: 'DKK', symbol: 'kr' },
  DOP: { code: 'DOP', symbol: 'RD$' },
  DZD: { code: 'DZD', symbol: 'دج' },
  EEK: { code: 'EEK', symbol: 'kr' },
  EGP: { code: 'EGP', symbol: '£' },
  ERN: { code: 'ERN', symbol: 'Nfk' },
  ETB: { code: 'ETB', symbol: 'Br' },
  ETH: { code: 'ETH', symbol: 'Ξ' },
  FJD: { code: 'FJD', symbol: '$' },
  FKP: { code: 'FKP', symbol: '£' },
  GBP: { code: 'GBP', symbol: '£' },
  GEL: { code: 'GEL', symbol: '₾' },
  GGP: { code: 'GGP', symbol: '£' },
  GHC: { code: 'GHC', symbol: '₵' },
  GHS: { code: 'GHS', symbol: 'GH₵' },
  GIP: { code: 'GIP', symbol: '£' },
  GMD: { code: 'GMD', symbol: 'D' },
  GNF: { code: 'GNF', symbol: 'FG' },
  GTQ: { code: 'GTQ', symbol: 'Q' },
  GYD: { code: 'GYD', symbol: '$' },
  HKD: { code: 'HKD', symbol: '$' },
  HNL: { code: 'HNL', symbol: 'L' },
  HRK: { code: 'HRK', symbol: 'kn' },
  HTG: { code: 'HTG', symbol: 'G' },
  HUF: { code: 'HUF', symbol: 'Ft' },
  IDR: { code: 'IDR', symbol: 'Rp' },
  ILS: { code: 'ILS', symbol: '₪' },
  IMP: { code: 'IMP', symbol: '£' },
  IQD: { code: 'IQD', symbol: 'ع.د' },
  IRR: { code: 'IRR', symbol: '﷼' },
  ISK: { code: 'ISK', symbol: 'kr' },
  JEP: { code: 'JEP', symbol: '£' },
  JMD: { code: 'JMD', symbol: 'J$' },
  JOD: { code: 'JOD', symbol: 'JD' },
  JPY: { code: 'JPY', symbol: '¥' },
  KES: { code: 'KES', symbol: 'KSh' },
  KGS: { code: 'KGS', symbol: 'лв' },
  KHR: { code: 'KHR', symbol: '៛' },
  KMF: { code: 'KMF', symbol: 'CF' },
  KPW: { code: 'KPW', symbol: '₩' },
  KRW: { code: 'KRW', symbol: '₩' },
  KWD: { code: 'KWD', symbol: 'KD' },
  KYD: { code: 'KYD', symbol: '$' },
  KZT: { code: 'KZT', symbol: '₸' },
  LAK: { code: 'LAK', symbol: '₭' },
  LBP: { code: 'LBP', symbol: '£' },
  LKR: { code: 'LKR', symbol: '₨' },
  LRD: { code: 'LRD', symbol: '$' },
  LSL: { code: 'LSL', symbol: 'M' },
  LTC: { code: 'LTC', symbol: 'Ł' },
  LTL: { code: 'LTL', symbol: 'Lt' },
  LVL: { code: 'LVL', symbol: 'Ls' },
  LYD: { code: 'LYD', symbol: 'LD' },
  MAD: { code: 'MAD', symbol: 'MAD' },
  MDL: { code: 'MDL', symbol: 'lei' },
  MGA: { code: 'MGA', symbol: 'Ar' },
  MKD: { code: 'MKD', symbol: 'ден' },
  MMK: { code: 'MMK', symbol: 'K' },
  MNT: { code: 'MNT', symbol: '₮' },
  MOP: { code: 'MOP', symbol: 'MOP$' },
  MRO: { code: 'MRO', symbol: 'UM' },
  MRU: { code: 'MRU', symbol: 'UM' },
  MUR: { code: 'MUR', symbol: '₨' },
  MVR: { code: 'MVR', symbol: 'Rf' },
  MWK: { code: 'MWK', symbol: 'MK' },
  MXN: { code: 'MXN', symbol: '$' },
  MXV: { code: 'MXV', symbol: 'MXV' },
  MYR: { code: 'MYR', symbol: 'RM' },
  MZN: { code: 'MZN', symbol: 'MT' },
  NAD: { code: 'NAD', symbol: '$' },
  NGN: { code: 'NGN', symbol: '₦' },
  NIO: { code: 'NIO', symbol: 'C$' },
  NOK: { code: 'NOK', symbol: 'kr' },
  NPR: { code: 'NPR', symbol: '₨' },
  NZD: { code: 'NZD', symbol: '$' },
  OMR: { code: 'OMR', symbol: '﷼' },
  PAB: { code: 'PAB', symbol: 'B/.' },
  PEN: { code: 'PEN', symbol: 'S/.' },
  PGK: { code: 'PGK', symbol: 'K' },
  PHP: { code: 'PHP', symbol: '₱' },
  PKR: { code: 'PKR', symbol: '₨' },
  PLN: { code: 'PLN', symbol: 'zł' },
  PYG: { code: 'PYG', symbol: 'Gs' },
  QAR: { code: 'QAR', symbol: '﷼' },
  RMB: { code: 'RMB', symbol: '￥' },
  RON: { code: 'RON', symbol: 'lei' },
  RSD: { code: 'RSD', symbol: 'Дин.' },
  RUB: { code: 'RUB', symbol: '₽' },
  RWF: { code: 'RWF', symbol: 'R₣' },
  SAR: { code: 'SAR', symbol: '﷼' },
  SBD: { code: 'SBD', symbol: '$' },
  SCR: { code: 'SCR', symbol: '₨' },
  SDG: { code: 'SDG', symbol: 'ج.س.' },
  SEK: { code: 'SEK', symbol: 'kr' },
  SGD: { code: 'SGD', symbol: 'S$' },
  SHP: { code: 'SHP', symbol: '£' },
  SLL: { code: 'SLL', symbol: 'Le' },
  SOS: { code: 'SOS', symbol: 'S' },
  SRD: { code: 'SRD', symbol: '$' },
  SSP: { code: 'SSP', symbol: '£' },
  STD: { code: 'STD', symbol: 'Db' },
  STN: { code: 'STN', symbol: 'Db' },
  SVC: { code: 'SVC', symbol: '$' },
  SYP: { code: 'SYP', symbol: '£' },
  SZL: { code: 'SZL', symbol: 'E' },
  THB: { code: 'THB', symbol: '฿' },
  TJS: { code: 'TJS', symbol: 'SM' },
  TMT: { code: 'TMT', symbol: 'T' },
  TND: { code: 'TND', symbol: 'د.ت' },
  TOP: { code: 'TOP', symbol: 'T$' },
  TRL: { code: 'TRL', symbol: '₤' },
  TRY: { code: 'TRY', symbol: '₺' },
  TTD: { code: 'TTD', symbol: 'TT$' },
  TVD: { code: 'TVD', symbol: '$' },
  TWD: { code: 'TWD', symbol: 'NT$' },
  TZS: { code: 'TZS', symbol: 'TSh' },
  UAH: { code: 'UAH', symbol: '₴' },
  UGX: { code: 'UGX', symbol: 'USh' },
  UYI: { code: 'UYI', symbol: 'UYI' },
  UYU: { code: 'UYU', symbol: '$U' },
  UYW: { code: 'UYW', symbol: 'UYW' },
  UZS: { code: 'UZS', symbol: 'лв' },
  VEF: { code: 'VEF', symbol: 'Bs' },
  VES: { code: 'VES', symbol: 'Bs.S' },
  VND: { code: 'VND', symbol: '₫' },
  VUV: { code: 'VUV', symbol: 'VT' },
  WST: { code: 'WST', symbol: 'WS$' },
  XAF: { code: 'XAF', symbol: 'FCFA' },
  XBT: { code: 'XBT', symbol: 'Ƀ' },
  XCD: { code: 'XCD', symbol: '$' },
  XOF: { code: 'XOF', symbol: 'CFA' },
  XPF: { code: 'XPF', symbol: '₣' },
  XSU: { code: 'XSU', symbol: 'Sucre' },
  XUA: { code: 'XUA', symbol: 'XUA' },
  YER: { code: 'YER', symbol: '﷼' },
  ZAR: { code: 'ZAR', symbol: 'R' },
  ZMW: { code: 'ZMW', symbol: 'ZK' },
  ZWD: { code: 'ZWD', symbol: 'Z$' },
  ZWL: { code: 'ZWL', symbol: '$' }
}
