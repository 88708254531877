import React from 'react'
import Svg, { SvgType } from 'components/svgComponent/Svg'

export default function(props: SvgType) {
  const { iconActive } = props
  const fill = iconActive ? '#FAFAFA' : '#E6E6E6'
  const opacity = iconActive ? '1' : '0.5'
  return (
    <Svg width="22" height="22" viewBox="0 0 22 22" fill="none" {...props}>
      <g opacity={opacity}>
        <path d="M16.9609 10.3125H11.918V8.9375H16.9609V10.3125Z" fill="#E6E6E6" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.66797 9.16699C3.41406 9.16699 3.1875 9.26855 3.02344 9.43262C2.85547 9.59863 2.75 9.8291 2.75 10.084V14.667C2.75 15.1729 3.16016 15.584 3.66797 15.584H8.25C8.75781 15.584 9.16797 15.1729 9.16797 14.667V10.084C9.16797 9.57715 8.75781 9.16699 8.25 9.16699H3.66797ZM4.125 10.542V14.209H7.79297V10.542H4.125Z"
          fill={fill}
        />
        <path d="M11.918 13.0625H18.793V11.6875H11.918V13.0625Z" fill={fill} />
        <path d="M18.793 15.8125H11.918V14.4375H18.793V15.8125Z" fill={fill} />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22 18.333V5.5C22 4.99414 21.5898 4.58301 21.082 4.58301H15.582V3.66699C15.582 3.16016 15.1719 2.75 14.668 2.75H7.33203C6.82812 2.75 6.41797 3.16016 6.41797 3.66699V4.58301H0.917969C0.410156 4.58301 0 4.99414 0 5.5V18.333C0 18.8398 0.410156 19.25 0.917969 19.25H21.082C21.5898 19.25 22 18.8398 22 18.333ZM8.25 4.58301H13.75V6.41699H20.168V17.417H1.83203V6.41699H8.25V4.58301Z"
          fill={fill}
        />
      </g>
    </Svg>
  )
}
