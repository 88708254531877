import React from 'react'
import Svg, { SvgType } from 'components/svgComponent/Svg'

export default function(props: SvgType) {
  const { iconActive } = props
  const fill = iconActive ? '#FAFAFA' : '#E6E6E6'
  const opacity = iconActive ? '1' : '0.5'
  return (
    <Svg width="22" height="22" viewBox="0 0 22 22" fill="none" {...props}>
      <g opacity={opacity}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.49219 12.8333C5.26172 12.8333 5.05078 12.9182 4.89062 13.0579C4.69531 13.2258 4.57422 13.4739 4.57422 13.7502V16.5002C4.57422 17.0061 4.98438 17.4163 5.49219 17.4163H8.24219C8.74609 17.4163 9.15625 17.0061 9.15625 16.5002V13.7502C9.15625 13.6155 9.12891 13.4876 9.07422 13.3723C8.92969 13.054 8.61328 12.8333 8.24219 12.8333H5.49219ZM5.94922 14.2083V16.0413H7.78125V14.2083H5.94922Z"
          fill={fill}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.4375 0.655519C7.28125 0.531496 7.08203 0.458254 6.86719 0.458254C6.36328 0.45923 5.95312 0.870363 5.95312 1.37622L5.95703 3.66821L1.82812 3.66626C1.75781 3.66626 1.6875 3.67407 1.62109 3.6897C1.50391 3.71704 1.39453 3.76587 1.30078 3.83228C1.19922 3.90259 1.11328 3.99243 1.04688 4.09693C0.960938 4.23755 0.910156 4.40454 0.910156 4.58325V20.1663C0.910156 20.4319 1.02344 20.6711 1.20312 20.8391C1.36719 20.9905 1.58594 21.0833 1.82812 21.0833H20.1602C20.668 21.0833 21.0781 20.6721 21.0781 20.1663V4.58325C21.0781 4.07642 20.668 3.66626 20.1602 3.66626L16.0391 3.66528L16.0352 1.37329C16.0352 0.867433 15.625 0.457277 15.1172 0.458254C14.6133 0.45923 14.2031 0.870363 14.2031 1.37622L14.207 3.66821L7.78906 3.66528L7.78516 1.37329C7.78516 1.08228 7.64844 0.822511 7.4375 0.655519ZM2.74219 19.2493V5.49927H19.2422L19.2461 7.78736L2.75 7.7981V9.63111L19.2461 9.62036L19.2422 19.2493H2.74219Z"
          fill={fill}
        />
      </g>
    </Svg>
  )
}
