import { Styles } from 'react-jss'

export const sheet: Styles = {
  title: {},
  text: {},
  img: {},
  main: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: 343,
    minHeight: 110,
    background: '#FAFAFA',
    border: '1px solid #286EFA',
    boxSizing: 'border-box',
    padding: '10px 12px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 12,
    lineHeight: '16px',
    letterSpacing: '0.01em',
    color: '#565656',
    '& $text a': {
      whiteSpace: 'inherit',
      wordBreak: 'break-all'
    },
    '& > $img, & img': {
      width: 36,
      height: 36,
      boxShadow: 'inset 0px 0px 5px rgba(0, 0, 0, 0.1)',
      borderRadius: '37px',
      flex: 'none'
    }
  },
  root: {
    flex: 1,
    marginLeft: 12,
    overflow: 'hidden',
    '& > p': {
      margin: 0
    }
  },
  unread: {
    background: '#FEFAF1'
  }
}
