import { all, takeLatest, take, fork, call } from 'redux-saga/effects'
import * as A from 'domain/recruiter/points'

export function* pointsPage() {
  yield all([fork(getPoints), fork(redeemPoints)])
}

function* getPoints() {
  yield call(A.ensureGetPoints)
  yield takeLatest(A.points.type, A.ensureGetPoints)
  yield take(A.points.success)
}

function* redeemPoints() {
  yield takeLatest(A.redeem.TRIGGER, A.ensureRedeem)
  yield take(A.redeem.SUCCESS)
}

export function* pointsRewards() {
  yield all([fork(rewardsViewPoints), fork(receivedPoints)])
}

function* rewardsViewPoints() {
  yield takeLatest(A.rewardsView.TRIGGER, A.ensureRewardsView)
  yield take(A.rewardsView.SUCCESS)
}

function* receivedPoints() {
  yield call(A.ensureReceivedPoints)
  yield takeLatest(A.receivedPoints.type, A.ensureReceivedPoints)
  yield take(A.receivedPoints.success)
}
