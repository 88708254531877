import { put, call, select } from 'redux-saga/effects'
import I from 'immutable'
import Api from 'domain/api'
import { pageIsLoading, skeleton } from 'domain/loading'
import { Sort } from 'types/common'
import * as M from 'domain/env'
import * as A from './actions'

interface Params {
  page?: number
  order?: Sort
  order_by?: string | null
}

export function* ensureGetClientsList(props?: { payload?: Params; type?: string }) {
  const headers = yield select(M.userToken)
  if (!headers) return
  if (!props || !props.payload || props.payload.page === 1) {
    yield put({ type: skeleton, payload: true })
    yield put({ type: pageIsLoading, payload: true })
  }
  try {
    const { data } = yield call(Api.getSuperManagerClients, {
      headers: { Authorization: `Bearer ${headers}` },
      params: props && props.payload
    })
    yield put({ type: A.superManagerClientsList.success, payload: I.fromJS(data) })
  } catch (err) {
    yield put({ type: A.superManagerClientsList.failure, err })
  } finally {
    yield put({ type: skeleton, payload: false })
    yield put({ type: pageIsLoading, payload: false })
  }
}
