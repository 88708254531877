import {
  ManagerProfileType,
  RecruiterCandidateProfileType,
  RecruiterProfileType
} from 'types/profile'

const API_URL = process.env.REACT_APP_API_PATH_DOWNLOADS

export const transformAvatar = (data: ManagerProfileType) => {
  return data.update('avatar', (val: string) => (val ? `${API_URL}/${val}` : undefined))
}

export const transformUser = (data: RecruiterProfileType) => {
  return data.update('avatar', (val: string) => (val ? `${API_URL}/${val}` : undefined))
}

export const transformCandidate = (data: RecruiterCandidateProfileType) => {
  return data.update('avatar', (val: string) => (val ? `${API_URL}/${val}` : undefined))
}
