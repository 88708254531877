import { all, call, take, takeLatest, fork } from 'redux-saga/effects'
import * as A from 'domain/candidate/calendar'

export function* calendar() {
  yield all([
    fork(getEventsList),
    fork(addEvent),
    fork(getEvent),
    fork(deleteEvent),
    fork(updateEvent)
  ])
}

function* getEventsList() {
  yield call(A.ensureEventsList)
  yield takeLatest(A.candidateEventsList.type, A.ensureEventsList)
  yield take(A.candidateEventsList.success)
}

function* addEvent() {
  yield takeLatest(A.candidateEventAdd.TRIGGER, A.ensureAddEvent)
  yield take(A.candidateEventAdd.SUCCESS)
}

function* getEvent() {
  yield takeLatest(A.candidateEvent.type, A.ensureEvent)
  yield take(A.candidateEvent.success)
}

function* deleteEvent() {
  yield takeLatest(A.candidateEventDelete.TRIGGER, A.ensureRemoveEvent)
  yield take(A.candidateEventDelete.SUCCESS)
}

function* updateEvent() {
  yield takeLatest(A.candidateEventUpdate.TRIGGER, A.ensureUpdateEvent)
  yield take(A.candidateEventUpdate.SUCCESS)
}
