import { asyncAction } from 'lib/actionCreators'
import { createRoutine, promisifyRoutine } from 'redux-saga-routines'

export const workExperiencesList = asyncAction('WORK_EXPERIENCES_LIST')
export const workExperience = asyncAction('WORK_EXPERIENCE')
export const addWorkExperiences = createRoutine('ADD_WORK_EXPERIENCES')
export const updateWorkExperience = createRoutine('UPDATE_WORK_EXPERIENCES')
export const removeWorkExperience = createRoutine('REMOVE_WORK_EXPERIENCES')
export const addWorkExperiencesPromiseCreator = promisifyRoutine(addWorkExperiences)
export const updateWorkExperiencePromiseCreator = promisifyRoutine(updateWorkExperience)
export const removeWorkExperiencePromiseCreator = promisifyRoutine(removeWorkExperience)
