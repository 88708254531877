import { Styles } from 'react-jss'

export const sheet: Styles = {
  title: {},
  text: {},
  root: {
    width: 343,
    minHeight: 110,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    background: '#FAFAFA',
    border: '1px solid #286EFA',
    boxSizing: 'border-box',
    padding: '10px 12px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 12,
    lineHeight: '16px',
    letterSpacing: '0.01em',
    color: '#565656',
    '& $text a': {
      whiteSpace: 'inherit',
      wordBreak: 'break-all'
    },
    '&:after': {
      content: ` url("${require('components/svg/particlesNotification.svg')}")`,
      position: 'absolute',
      top: 0,
      right: 0
    },
    '& > p': {
      margin: 0
    },
    '& > $text': {
      flex: 1
    }
  },
  menu: {
    backgroundColor: '#F1F9FE'
  },
  unread: {
    background: '#FEFAF1'
  }
}
