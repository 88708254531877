import React from 'react'
import Svg, { SvgType } from 'components/svgComponent/Svg'

export default function(props: SvgType) {
  return (
    <Svg width="22" height="22" viewBox="0 0 22 22" fill="none" {...props}>
      <path d="M8.9 6.97067V8.7351H14.1V6.97067H8.9Z" fill="#080910" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 3.88268C5 3.39481 5.38778 3 5.8679 3H17.1321C17.6122 3 18 3.39481 18 3.88268V17.1183C18 17.3006 17.9446 17.4708 17.8486 17.6118C17.7526 17.7556 17.6159 17.8693 17.4571 17.9361C17.3574 17.9774 17.2466 18 17.1321 18H5.8679C5.60938 18 5.3804 17.8872 5.22159 17.7077C5.08494 17.5516 5 17.3448 5 17.1183V3.88268ZM8.4679 5.64711C7.98778 5.64711 7.6 6.04193 7.6 6.5298V9.17691C7.6 9.66385 7.98778 10.0587 8.4679 10.0587H14.5321C14.7943 10.0587 15.0307 9.93927 15.1895 9.75033C15.2486 9.68359 15.2966 9.60744 15.3298 9.52472C15.3741 9.4185 15.4 9.301 15.4 9.17691V6.5298C15.4 6.32205 15.3298 6.13123 15.2116 5.97988C15.0528 5.77684 14.8091 5.64711 14.5321 5.64711H8.4679ZM14.5321 12.9267H8.4679V11.6031H14.5321V12.9267ZM8.4679 15.1329H14.5321V13.8094H8.4679V15.1329Z"
        fill="#080910"
      />
    </Svg>
  )
}
