import { all, takeLatest, take, fork, put } from 'redux-saga/effects'
import * as A from 'domain/manager/recruiters'
import I from 'immutable'

export function* inviteRecruiters() {
  yield all([fork(invite)])
}

function* invite() {
  yield put({ type: A.inviteRecruiters.SUCCESS, payload: I.List() })
  yield takeLatest(A.inviteRecruiters.TRIGGER, A.ensureInviteRecruiters)
  while (true) {
    yield take(A.inviteRecruiters.SUCCESS)
  }
}
