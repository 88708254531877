import React from 'react'
import Svg, { SvgType } from 'components/svgComponent/Svg'

export default function(props: SvgType) {
  return (
    <Svg width="26" height="34" viewBox="0 0 26 34" fill="none" {...props}>
      <path
        d="M8.5 23.8614C8.5 25.2862 7.3664 26.4151 6 26.4151C4.6336 26.4151 3.5 25.2862 3.5 23.8614C3.5 22.4365 4.6336 21.3076 6 21.3076C7.3664 21.3076 8.5 22.4365 8.5 23.8614Z"
        stroke="#286EFA"
        strokeWidth="2"
      />
      <path
        d="M10.3297 32.9996H1.70173C1.35816 32.9996 1.11681 32.6592 1.24019 32.3385C2.25963 29.6892 3.1695 29.4453 6 29.4453C8.82213 29.4453 9.92953 29.8885 10.7944 32.3601C10.9048 32.6757 10.6641 32.9996 10.3297 32.9996Z"
        stroke="#286EFA"
        strokeWidth="2"
      />
      <path
        d="M22.5 23.8614C22.5 25.2862 21.3664 26.4151 20 26.4151C18.6336 26.4151 17.5 25.2862 17.5 23.8614C17.5 22.4365 18.6336 21.3076 20 21.3076C21.3664 21.3076 22.5 22.4365 22.5 23.8614Z"
        stroke="#286EFA"
        strokeWidth="2"
      />
      <path
        d="M24.3297 32.9991H15.7017C15.3582 32.9991 15.1168 32.6587 15.2402 32.338C16.2596 29.6888 17.1695 29.4453 20 29.4453C22.8221 29.4453 23.9295 29.8881 24.7944 32.3596C24.9048 32.6752 24.6641 32.9991 24.3297 32.9991Z"
        stroke="#286EFA"
        strokeWidth="2"
      />
      <path
        d="M10 21.3228L13 19.0578M13 19.0578L16 21.3228M13 19.0578V12.1846"
        stroke="#286EFA"
        strokeWidth="2"
      />
      <rect x="6" y="1" width="14" height="9.16896" rx="1" stroke="#286EFA" strokeWidth="2" />
      <path d="M9 4.06152V6.59992" stroke="#286EFA" strokeWidth="2" />
      <path d="M13 4.06152V6.59992" stroke="#286EFA" strokeWidth="2" />
      <path d="M17 4.06152V6.59992" stroke="#286EFA" strokeWidth="2" />
    </Svg>
  )
}
