import I from 'immutable'
import { SelectListT, CountryI, CityI } from 'types/common'

export function convertCountries(
  countries: CountryI[]
): { countries: SelectListT; phoneCodes: SelectListT } {
  const countriesList: SelectListT = []
  const phoneCodes: SelectListT = []
  countries.map(country => {
    countriesList.push({ value: country.id, label: country.name })
    if (country.phoneCode && country.phoneCode.replace(' ', '')) {
      phoneCodes.push({ value: country.id, label: country.phoneCode })
    }
  })
  return {
    countries: countriesList,
    phoneCodes
  }
}

export function convertCities(cities: CityI[]): SelectListT {
  return I.fromJS(
    cities.map((city: CityI) => ({
      value: city.id,
      label: `${city.name}, ${city.state ? `${city.state.name}, ` : ''}${city.country.name}`,
      country: {
        value: city.country.id,
        label: city.country.name
      },
      name: city.name,
      lat: city.lat,
      lng: city.lng,
      population: city.population
    }))
  )
}
