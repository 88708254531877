import { all, call, take, takeLatest, fork, select, put } from 'redux-saga/effects'
import * as A from 'domain/manager/candidates'
import * as J from 'domain/manager/jobs'

export function* candidatesPage() {
  yield all([fork(getCandidatesList), fork(saveCandidate), fork(getJob)])
}

function* getCandidatesList() {
  yield call(A.ensureGetCandidates)
  yield takeLatest(A.candidates.type, A.ensureGetCandidates)
  yield take(A.candidates.success)
}

function* saveCandidate() {
  yield takeLatest(A.candidateFavoriteFromList.type, A.ensureAddCandidateToFavoriteFromList)
  yield take(A.candidateFavoriteFromList.success)
}

function* getJob() {
  yield call(J.ensureGetJob)
  yield takeLatest(J.jobData.type, J.ensureGetJob)
  yield take(J.jobData.success)
}
