import { all, fork, call, take, put, takeLatest } from 'redux-saga/effects'
import * as A from 'domain/superManager/jobs'
import { pageIsLoading } from 'domain/loading'
import * as SMJ from 'domain/superManager/jobs'

export function* jobView() {
  yield all([
    fork(getJob),
    fork(getJobNotes),
    fork(addNote),
    fork(deleteNote),
    fork(editNote),
    ,
    fork(initialLoad)
  ])
}

function* getJob() {
  yield takeLatest(A.jobData.type, A.ensureGetJob)
  yield take(A.jobData.success)
}

function* getJobNotes() {
  yield takeLatest(SMJ.getJobNotes.type, SMJ.ensureGetJobNotes)
  yield take(SMJ.getJobNotes.success)
}

function* addNote() {
  yield takeLatest(SMJ.addNoteToJob.type, SMJ.ensurePostJobNotes)
  yield take(SMJ.addNoteToJob.success)
}

function* deleteNote() {
  yield takeLatest(SMJ.deleteNoteInJob.type, SMJ.ensureDeleteJobNote)
  yield take(SMJ.deleteNoteInJob.success)
}

function* editNote() {
  yield takeLatest(SMJ.editNoteInJob.type, SMJ.ensureEditJobNote)
  yield take(SMJ.editNoteInJob.success)
}

function* initialLoad() {
  yield put({ type: pageIsLoading, payload: true })
  try {
    yield all([call(A.ensureGetJob), call(SMJ.ensureGetJobNotes)])
  } finally {
    yield put({ type: pageIsLoading, payload: false })
  }
}
