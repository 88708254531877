import React from 'react'
import Svg, { SvgType } from 'components/svgComponent/Svg'

export default function(props: SvgType) {
  return (
    <Svg width="125" height="125" viewBox="0 0 125 125" fill="none" {...props}>
      <circle cx="62.5" cy="62.5" r="62.5" fill="#286EFA" />
      <rect x="18.749" y="32.959" width="87.5" height="59.5905" rx="4" fill="url(#paint0_linear)" />
      <mask id="mask0" mask="alpha" maskUnits="userSpaceOnUse" x="18" y="32" width="89" height="61">
        <rect
          x="18.749"
          y="32.959"
          width="87.5"
          height="59.5905"
          rx="5"
          fill="url(#paint1_linear)"
        />
      </mask>
      <g mask="url(#mask0)">
        <g filter="url(#filter0_dd)">
          <path
            d="M18.749 32.959H106.249L67.8957 64.3086C64.9695 66.7005 60.7685 66.7182 57.8222 64.3511L18.749 32.959Z"
            fill="white"
          />
        </g>
      </g>
      <path
        d="M64.0093 71.4277C63.7579 74.9478 63.4059 82.4407 64.0093 84.251M61.369 71.4277C61.369 76.7079 57.9746 81.6109 57.9746 84.251"
        stroke="black"
      />
      <g filter="url(#filter1_d)">
        <path
          d="M60.7389 57.6207C61.7894 56.859 63.2105 56.859 64.2611 57.6207L64.4126 57.7306C64.8662 58.0595 65.4028 58.2548 65.9616 58.2944L66.1484 58.3077C67.4428 58.3994 68.5314 59.3129 68.8465 60.5717L68.892 60.7533C69.0281 61.2968 69.3136 61.7913 69.7162 62.1809L69.8508 62.3111C70.7834 63.2133 71.0301 64.6129 70.4624 65.7797L70.3805 65.9481C70.1354 66.4519 70.0362 67.0142 70.0942 67.5715L70.1136 67.7577C70.2481 69.0483 69.5375 70.2791 68.3525 70.808L68.1816 70.8843C67.67 71.1127 67.2325 71.4797 66.9188 71.9439L66.814 72.099C66.0873 73.1741 64.7519 73.6602 63.5042 73.3037L63.3242 73.2522C62.7855 73.0983 62.2145 73.0983 61.6758 73.2522L61.4957 73.3037C60.2481 73.6602 58.9126 73.1741 58.186 72.099L58.0811 71.9439C57.7674 71.4797 57.33 71.1127 56.8184 70.8843L56.6474 70.808C55.4625 70.2791 54.7519 69.0483 54.8863 67.7577L54.9057 67.5715C54.9637 67.0142 54.8646 66.4519 54.6195 65.9481L54.5375 65.7797C53.9698 64.6129 54.2166 63.2133 55.1492 62.3111L55.2837 62.1809C55.6864 61.7913 55.9719 61.2968 56.1079 60.7533L56.1534 60.5717C56.4685 59.3129 57.5572 58.3994 58.8515 58.3077L59.0383 58.2944C59.5971 58.2548 60.1337 58.0595 60.5873 57.7306L60.7389 57.6207Z"
          fill="url(#paint2_radial)"
        />
        <circle
          cx="62.5005"
          cy="65.3911"
          r="4.52586"
          fill="url(#paint3_linear)"
          stroke="url(#paint4_radial)"
          strokeWidth="0.5"
        />
      </g>
      <defs>
        <filter
          id="filter0_dd"
          x="9.74902"
          y="25.959"
          width="105.5"
          height="51.1556"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="4.5" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.01 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1.5" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
          <feBlend mode="normal" in2="effect1_dropShadow" result="effect2_dropShadow" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow" result="shape" />
        </filter>
        <filter
          id="filter1_d"
          x="49.2354"
          y="53.0488"
          width="26.5298"
          height="26.3699"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="2.5" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
        <linearGradient
          id="paint0_linear"
          x1="62.499"
          y1="32.959"
          x2="62.499"
          y2="92.5495"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#F8F6F6" />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="62.499"
          y1="32.959"
          x2="62.499"
          y2="92.5495"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#F8F6F6" />
        </linearGradient>
        <radialGradient
          id="paint2_radial"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(62.5 65.7726) rotate(90) scale(6.03448)"
        >
          <stop stopColor="#4986FF" />
          <stop offset="1" stopColor="#286EFA" />
        </radialGradient>
        <linearGradient
          id="paint3_linear"
          x1="62.5005"
          y1="60.8652"
          x2="62.5005"
          y2="69.917"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0828729" stopColor="#1348B2" />
          <stop offset="0.701657" stopColor="#286EFA" />
        </linearGradient>
        <radialGradient
          id="paint4_radial"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(62.5005 65.3911) rotate(90) scale(4.90302)"
        >
          <stop offset="0.927623" stopColor="#2E72FB" />
          <stop offset="1" stopColor="white" />
        </radialGradient>
      </defs>
    </Svg>
  )
}
