import React from 'react'
import Svg, { SvgType } from './Svg'

export default function(props: SvgType) {
  return (
    <Svg width="22" height="22" viewBox="0 0 22 22" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.77734 18.6631L4.78516 3.99805H12.8633V2.16797H4.78516C3.76953 2.16797 2.94922 2.98926 2.94922 4.00293V18.6631C2.94922 19.6768 3.76953 20.498 4.78516 20.498H12.8633V18.668L4.77734 18.6631ZM4.77734 18.6631V18.665V18.6631ZM4.77734 18.6631C4.78125 18.667 4.78125 18.668 4.78125 18.668L4.77734 18.6631ZM4.78516 3.99805C4.78125 3.99805 4.78125 3.99902 4.78125 3.99902L4.78516 3.99805Z"
        fill="#888888"
      />
      <path
        d="M21.0117 10.4355L17.0117 6.43555L15.7148 7.73047L18.1523 10.168H9.36328C9.14844 10.168 8.95312 10.2412 8.79688 10.3643C8.58594 10.5322 8.44922 10.792 8.44922 11.083C8.44922 11.5879 8.85938 11.998 9.36328 11.998H18.1523L15.7148 14.4355L17.0117 15.7305L21.0117 11.7305L21.6562 11.083L21.0117 10.4355Z"
        fill="#888888"
      />
    </Svg>
  )
}
