import { asyncAction } from 'lib/actionCreators'
import { createRoutine, promisifyRoutine } from 'redux-saga-routines'

export const clientsListForJob = asyncAction('manager/CLIENTS_LIST_FOR_JOB')
export const clientsList = asyncAction('manager/CLIENTS_LIST')
export const newClientsList = asyncAction('manager/NEW_CLIENTS_LIST')
export const getClient = asyncAction('manager/GET_CLIENT')

export const postClient = createRoutine('manager/POST_CLIENT')
export const updateClient = createRoutine('manager/UPDATE_CLIENT')
export const deleteClient = createRoutine('manager/DELETE_CLIENT')
export const postClientPromiseCreator = promisifyRoutine(postClient)
export const updateClientPromiseCreator = promisifyRoutine(updateClient)
export const deleteClientPromiseCreator = promisifyRoutine(deleteClient)

export const recruiterClients = asyncAction('manager/RECRUITER_CLIENTS')
