import React from 'react'
import cx from 'classnames'
import injectSheet from 'react-jss'
import { Classes } from 'jss'
import { sheet } from 'pages/Notifications/AcceptDeclineRecruitersInvite/sheet'
import NotificationTitle from 'pages/Notifications/NotificationTitle'
import { ButtonTypes, ImmutableMap } from 'types/common'
import { Button } from '../../../components/Button'

type Payload = ImmutableMap<{
  title: string
  sender?: {
    fullName: string
  }
  recipient?: {
    fullName: string
  }
}>

interface Props {
  classes: Classes
  createdAt: string
  viewedAt: boolean
  type: 'accepted' | 'rejected' | 'received'
  payload: Payload
  path?: string
}

const TEXT = {
  accepted: (payload: Payload, path?: string) => (
    <span>
      Your invite to collaborate have been accepted by&nbsp;
      <Button
        isLink={true}
        to={`${path}/${payload.getIn(['recipient', 'id'])}`}
        label={payload.getIn(['recipient', 'fullName'])}
        type={ButtonTypes.NotificationLink}
      />
    </span>
  ),
  rejected: (payload: Payload, path?: string) => (
    <span>
      Your invite to collaborate have been rejected by&nbsp;
      <Button
        isLink={true}
        to={`${path}/${payload.getIn(['recipient', 'id'])}`}
        label={payload.getIn(['recipient', 'fullName'])}
        type={ButtonTypes.NotificationLink}
      />
    </span>
  ),
  received: (payload: Payload, path?: string) => (
    <span>
      You have been invited to collaborate with Super Hiring Manager&nbsp;
      <Button
        isLink={true}
        to={`${path}/${payload.getIn(['sender', 'id'])}`}
        label={payload.getIn(['sender', 'fullName'])}
        type={ButtonTypes.NotificationLink}
      />
      &nbsp; and join the team to share Clients, Job Requisition data and Statistics in their
      dashboard.
    </span>
  )
}

const CollaborationRequest = ({ classes, viewedAt, createdAt, payload, type, path }: Props) => (
  <div className={cx(classes.main, { [classes.unread]: viewedAt })}>
    <div className={classes.root}>
      <NotificationTitle title={payload.get('title')} />
      <p className={classes.text}>{TEXT[type](payload, path)}</p>
    </div>
    <p className="createdAt">{createdAt}</p>
  </div>
)

export default injectSheet(sheet)(CollaborationRequest)
