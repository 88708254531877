import { List } from 'immutable'

/**
 * @description Filters out items from the newItems list that already exist in the existingItems list.
 * @param existingItems - The current list of items in the state.
 * @param newItems - The new items to be added to the state.
 * @param uniqueKey - The key to use for checking uniqueness (default is 'id').
 * @return The filtered list of new items.
 */
export function filterListDuplicatesBy<ItemType extends Record<string, any>>(
  existingItems: List<ItemType>,
  newItems: List<ItemType>,
  uniqueKey: keyof ItemType = 'id'
): List<ItemType> {
  return newItems.filter(
    newItem =>
      !existingItems.find(existingItem => existingItem.get(uniqueKey) === newItem.get(uniqueKey))
  )
}
