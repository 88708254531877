import { Component } from 'react'

interface Props {
  children: (visibleData: any[], expanded: boolean, handleExpandToggle: () => void) => JSX.Element
  data: any
  limit?: number
}

interface State {
  expanded: boolean
}

export class Expanded extends Component<Props, State> {
  private static defaultProps = {
    limit: 2
  }

  state = {
    expanded: false
  }

  getVisibleData() {
    return this.state.expanded ? this.props.data : this.props.data.slice(0, this.props.limit)
  }

  render() {
    const { children: renderer } = this.props
    const { expanded } = this.state
    return renderer(this.getVisibleData(), expanded, this.handleExpandToggle)
  }

  handleExpandToggle = () =>
    this.setState(({ expanded }) => ({
      expanded: !expanded
    }))
}
