import React from 'react'
// components
import { Button } from 'components/Button'
// redux
// types
import { ButtonTypes, ReceivedPointItemType, ReceivedPointsType } from 'types/common'
import { Classes } from 'jss'
// styles
import { sheet } from './sheet'
import injectSheet from 'react-jss'
// icons
import Link from 'components/svgComponent/Link'

const API_URL = process.env.REACT_APP_API_PATH_DOWNLOADS

interface Props {
  classes: Classes
  toggleModal: (id: number) => void
  receivedPoints: ReceivedPointsType
  formError: string
}

function Profile(props: Props) {
  const { classes, receivedPoints, toggleModal, formError } = props
  const points: ReceivedPointItemType | null =
    (receivedPoints && !receivedPoints.isEmpty() && receivedPoints.first()) || null

  const data = {
    count: 0,
    job: '',
    avatarManager: null,
    nameManager: '',
    roleManager: '',
    avatarCandidate: null,
    nameCandidate: '',
    roleCandidate: ''
  }

  if (points && !points.isEmpty()) {
    data.count = points.getIn(['job', 'points'], 0)
    data.job = points.getIn(['job', 'title'], '')
    data.avatarManager = points.getIn(['manager', 'avatar'], null)
    data.nameManager = [
      points.getIn(['manager', 'firstName'], ''),
      points.getIn(['manager', 'lastName'], '')
    ].join(' ')
    data.roleManager = 'Hiring Manager'
    data.avatarCandidate = points.getIn(['candidate', 'avatar'], null)
    data.nameCandidate = [
      points.getIn(['candidate', 'firstName'], ''),
      points.getIn(['candidate', 'lastName'], '')
    ].join(' ')
    data.roleCandidate = 'Candidate'
  }

  const gotIt = () => {
    const rewardId = points && !points.isEmpty() && points.get('rewardId')
    if (rewardId) toggleModal(rewardId)
  }

  return (
    <div className={classes.root}>
      <h3>{`You received ${data.count} points!`}</h3>
      {formError && <p className={classes.error}>{formError}</p>}
      <p>for job</p>
      <p className={classes.job}>{data.job}</p>
      <div className={classes.relations}>
        <div className={classes.reverse}>
          <div className={classes.img}>
            {data.avatarManager && <img src={`${API_URL}/${data.avatarManager}`} alt="" />}
          </div>
          <div>
            <p className={classes.name}>{data.nameManager}</p>
            <p className={classes.role}>{data.roleManager}</p>
          </div>
        </div>
        <Link />
        <div>
          <div className={classes.img}>
            {data.avatarCandidate && <img src={`${API_URL}/${data.avatarCandidate}`} alt="" />}
          </div>
          <div>
            <p className={classes.name}>{data.nameCandidate}</p>
            <p className={classes.role}>{data.roleCandidate}</p>
          </div>
        </div>
      </div>
      <Button type={ButtonTypes.GreenButton} label="Got it!" onClick={gotIt} />
    </div>
  )
}

export default injectSheet(sheet)(Profile)
