import { all, takeLatest, take, fork, call } from 'redux-saga/effects'
import * as A from 'domain/recruiter/recruiterProfileView'

export function* profilePage() {
  yield all([fork(getProfile)])
}

function* getProfile() {
  yield call(A.ensureGetRecruiterProfile)
  yield takeLatest(A.recruiterProfile.type, A.ensureGetRecruiterProfile)
  yield take(A.recruiterProfile.success)
}
