import { takeLatest, take, fork, call, put } from 'redux-saga/effects'
import { pageIsLoading } from 'domain/loading'
import * as A from 'domain/candidate/candidateProfileView'

export function* profilePage() {
  yield fork(getProfile)
}

function* getProfile() {
  yield put({ type: pageIsLoading, payload: true })
  yield call(A.ensureGetCandidate)
  yield takeLatest(A.candidateProfileView.type, A.ensureGetCandidate)
  yield take(A.candidateProfileView.success)
}
