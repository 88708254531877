import React from 'react'
// components
import { Button } from 'components/Button'
import Avatar from 'components/Avatar'
import NotificationTitle from 'pages/Notifications/NotificationTitle'
// types
import { Classes } from 'jss'
import { ButtonTypes } from 'types/common'
// styles
import injectSheet from 'react-jss'
import { sheet } from './sheet'
// utils
import cx from 'classnames'

interface Props {
  classes: Classes
  avatar?: string
  title: string
  pathTitle: string
  text: string
  pathButton: string
  buttonText: string
  viewedAt: boolean
  createdAt: string
}

const Index = ({
  classes,
  avatar,
  title,
  text,
  pathButton,
  pathTitle,
  buttonText,
  viewedAt,
  createdAt
}: Props) => {
  return (
    <div className={cx(classes.main, { [classes.unread]: viewedAt })}>
      <Avatar className={classes.img} src={avatar} />
      <div className={classes.root}>
        <NotificationTitle title={title} path={pathTitle} />
        <p className={classes.text}>
          {`${text} `}
          <Button
            isLink={true}
            to={pathButton}
            label={buttonText}
            type={ButtonTypes.NotificationLink}
          />
        </p>
      </div>
      <p className="createdAt">{createdAt}</p>
    </div>
  )
}

export default injectSheet(sheet)(Index)
