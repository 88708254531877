import React, { Component } from 'react'
// redux
import { compose } from 'redux'
import { connect } from 'react-redux'
import {
  FormSection,
  getFormValues,
  InjectedFormProps,
  reduxForm,
  change,
  reset,
  getFormSyncErrors,
  FormErrors
} from 'redux-form'
import {
  clearanceTypeSelector,
  ClearanceTypeT,
  citizenshipSelector,
  CitizenshipT,
  CITIZENSHIP_KEYS,
  Citizenship
} from 'domain/constants'
// components
import { FieldInput } from 'components/Form/Fields/FieldInput'
import { FieldSelect } from 'components/Form/Fields/FieldSelect'
import { FieldTags } from 'components/Form/Fields/FieldTags'
import { FieldRadio } from 'components/Form/Fields/FieldRadio'
import { FieldCheckBox } from 'components/Form/Fields/FieldCheckBox'
import { Button } from 'components/Button'
import Input from 'components/Form/Input'
import Select from 'components/Form/Select'
import Tags from 'components/Form/Tags'
import Radio from 'components/Form/Radio'
import CheckBox from 'components/Form/CheckBox'
import Check from 'components/svgComponent/Check'
// types
import {
  ButtonTypes,
  ComposeType,
  ButtonHtmlTypes,
  SpheresExist,
  SelectListT,
  FormErrorsInterface
} from 'types/common'
import { Classes } from 'jss'
import { JobsFilterCandidate } from 'types/job/jobTypesCandidate'
import { StateInterface } from 'types/state'
// styles
import injectSheet from 'react-jss'
import { sheet } from './sheet'
// utils
import { currency } from 'lib/selectOptions'
import { onlyNumbers } from 'lib/normalize'
import { debounce } from 'lodash'
import { validation } from './validation'

interface ExternalProps {
  countriesList: SelectListT
  citiesList: SelectListT
  onSubmit: (data: JobsFilterCandidate) => void
  clearingCities: () => void
  getCities: ({ name, used1st }: { name: string; used1st?: boolean }) => void
}

interface OwnProps {
  classes: Classes
  formValues: JobsFilterCandidate
  reset: (arr: string) => void
  formErrors: FormErrors<FormErrorsInterface>
  clearanceTypeConst: ClearanceTypeT
  citizenshipConst: CitizenshipT
}

type Props = InjectedFormProps<JobsFilterCandidate> & OwnProps & ExternalProps

class Filter extends Component<Props> {
  changeSelect = debounce((val: string) => {
    this.props.clearingCities()
    this.props.getCities({
      used1st: true,
      name: val
    })
  }, 500)

  changeCountryId = () => {
    this.props.change('city', {})
    this.props.clearingCities()
  }

  resetForm = () => {
    this.props.reset('filter-jobs-list-guest')
  }

  noneDisabledEmploymentType = () => {
    this.props.change('employmentType.other', false)
  }

  noneEmploymentType = () => {
    this.props.change('employmentType.full_time', false)
    this.props.change('employmentType.part_time', false)
    this.props.change('employmentType.temporary', false)
  }

  noneDisabledClearanceType = () => {
    this.props.change('clearanceType.none', false)
  }

  noneClearanceType = () => {
    const { clearanceTypeConst } = this.props
    this.props.change(`clearanceType.${clearanceTypeConst.get('topSecret')}`, false)
    this.props.change(`clearanceType.${clearanceTypeConst.get('secret')}`, false)
    this.props.change(`clearanceType.${clearanceTypeConst.get('publicTrust')}`, false)
  }

  noneDisabledCitizenship = () => {
    this.props.change('citizenship.optional', false)
  }

  noneCitizenship = () => {
    const { citizenshipConst } = this.props
    CITIZENSHIP_KEYS.forEach(key => {
      const checkNoxName = citizenshipConst.get(key)
      if (checkNoxName !== 'optional') this.props.change(`citizenship.${checkNoxName}`, false)
    })
  }

  render() {
    const {
      classes,
      handleSubmit,
      formErrors,
      countriesList,
      citiesList,
      onSubmit,
      clearanceTypeConst,
      citizenshipConst
    } = this.props
    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={classes.root}>
          <div className={classes.header}>
            <p>Filters</p>
            <Button
              label="refine"
              type={ButtonTypes.BlueButton}
              Icon={Check}
              htmlType={ButtonHtmlTypes.Submit}
            />
            <Button label="clear" type={ButtonTypes.Cancel} onClick={this.resetForm} />
          </div>
          <div className={classes.filter}>
            <FieldSelect
              name="city"
              component={Select}
              options={citiesList}
              title="City"
              filterOption={() => true}
              onInputChange={val => this.changeSelect(val)}
              isCity={true}
            />
            <FieldTags
              name="spheres"
              title="Skills"
              component={Tags}
              allowNew={false}
              heightAuto={true}
            />
            <div className={classes.rate}>
              <p>
                Hourly Rate
                {formErrors && formErrors.hourly && (
                  <span className={classes.error}>
                    <br />
                    {formErrors.hourly}
                  </span>
                )}
              </p>
              <div className={classes.rowRate}>
                <FieldInput
                  name="rateFrom"
                  type="text"
                  component={Input}
                  normalize={onlyNumbers}
                  placeholder="00.00"
                />
                <div className={classes.separator}> - </div>
                <FieldInput
                  name="rateTo"
                  type="text"
                  component={Input}
                  normalize={onlyNumbers}
                  placeholder="00.00"
                />
                <FieldSelect
                  name="rateCurrency"
                  component={Select}
                  options={currency}
                  withoutBorder={true}
                  placeholder={' '}
                />
              </div>
            </div>
            <div className={classes.rate}>
              <p>
                Salary
                {formErrors && formErrors.salary && (
                  <span className={classes.error}>
                    <br />
                    {formErrors.salary}
                  </span>
                )}
              </p>
              <div className={classes.rowRate}>
                <FieldInput
                  name="salaryFrom"
                  type="text"
                  component={Input}
                  normalize={onlyNumbers}
                  placeholder="000.00"
                />
                <div className={classes.separator}> - </div>
                <FieldInput
                  name="salaryTo"
                  type="text"
                  component={Input}
                  normalize={onlyNumbers}
                  placeholder="000.00"
                />
                <FieldSelect
                  name="salaryCurrency"
                  component={Select}
                  options={currency}
                  withoutBorder={true}
                  placeholder={' '}
                />
              </div>
            </div>
            <div>
              <p>Relocation</p>
              <div className={classes.rowRadio}>
                <FieldRadio
                  id="3"
                  type="radio"
                  name="relocation"
                  component={Radio}
                  value="true"
                  label="Available"
                />
                <FieldRadio
                  id="4"
                  type="radio"
                  name="relocation"
                  component={Radio}
                  value="false"
                  label="Not Available"
                />
              </div>
            </div>
            <div>
              <p>Employment Type</p>
              <FormSection name="employmentType">
                <div className={classes.rowCheck}>
                  <div>
                    <FieldCheckBox
                      type="checkbox"
                      name="full_time"
                      component={CheckBox}
                      label="Full-time"
                      onChange={this.noneDisabledEmploymentType}
                    />
                    <FieldCheckBox
                      type="checkbox"
                      name="part_time"
                      component={CheckBox}
                      label="Part-time"
                      onChange={this.noneDisabledEmploymentType}
                    />
                    <FieldCheckBox
                      type="checkbox"
                      name="temporary"
                      component={CheckBox}
                      label="Temporary"
                      onChange={this.noneDisabledEmploymentType}
                    />
                  </div>
                  <FieldCheckBox
                    type="checkbox"
                    name="other"
                    component={CheckBox}
                    label="Other"
                    onChange={this.noneEmploymentType}
                  />
                </div>
              </FormSection>
            </div>
            <div>
              <p>Clearance Type</p>
              <FormSection name="clearanceType">
                <div className={classes.rowCheck}>
                  <div>
                    <FieldCheckBox
                      type="checkbox"
                      name={clearanceTypeConst.get('topSecret')}
                      component={CheckBox}
                      label="Top Secret"
                      onChange={this.noneDisabledClearanceType}
                    />
                    <FieldCheckBox
                      type="checkbox"
                      name={clearanceTypeConst.get('secret')}
                      component={CheckBox}
                      label="Secret"
                      onChange={this.noneDisabledClearanceType}
                    />
                    <FieldCheckBox
                      type="checkbox"
                      name={clearanceTypeConst.get('publicTrust')}
                      component={CheckBox}
                      label="Public trust"
                      onChange={this.noneDisabledClearanceType}
                    />
                  </div>
                  <FieldCheckBox
                    type="checkbox"
                    name="none"
                    component={CheckBox}
                    label="None of them"
                    onChange={this.noneClearanceType}
                  />
                </div>
              </FormSection>
            </div>
            <div>
              <p>Travel</p>
              <div className={classes.rowRadio}>
                <FieldRadio
                  id="5"
                  type="radio"
                  name="travel"
                  component={Radio}
                  value="yes"
                  label="Yes"
                />
                <FieldRadio
                  id="6"
                  type="radio"
                  name="travel"
                  component={Radio}
                  value="no"
                  label="No"
                />
              </div>
            </div>
            <div>
              <p>Work Authorization</p>
              <FormSection name="citizenship">
                <div className={classes.rowCheck}>
                  <div>
                    <FieldCheckBox
                      type="checkbox"
                      name={citizenshipConst.get(Citizenship.usCitizen)}
                      component={CheckBox}
                      label="US Citizen"
                      onChange={this.noneDisabledCitizenship}
                    />
                    <FieldCheckBox
                      type="checkbox"
                      name={citizenshipConst.get(Citizenship.greenCard)}
                      component={CheckBox}
                      label="Green Card"
                      onChange={this.noneDisabledCitizenship}
                    />
                    <FieldCheckBox
                      type="checkbox"
                      name={citizenshipConst.get(Citizenship.h1b)}
                      component={CheckBox}
                      label="H1-B"
                      onChange={this.noneDisabledCitizenship}
                    />
                  </div>
                  <FieldCheckBox
                    type="checkbox"
                    name={citizenshipConst.get(Citizenship.optional)}
                    component={CheckBox}
                    label="Other"
                    onChange={this.noneCitizenship}
                  />
                </div>
              </FormSection>
            </div>
          </div>
        </div>
      </form>
    )
  }
}

export default compose<ComposeType<Props, ExternalProps>>(
  connect(
    (state: StateInterface) => ({
      formValues: getFormValues('filter-jobs-list-guest')(state),
      formErrors: getFormSyncErrors('filter-jobs-list-guest')(state),
      clearanceTypeConst: clearanceTypeSelector(state),
      citizenshipConst: citizenshipSelector(state)
    }),
    { change, reset }
  ),
  reduxForm({
    form: 'filter-jobs-list-guest',
    enableReinitialize: true,
    validate: validation,
    keepDirtyOnReinitialize: true
  }),
  injectSheet(sheet)
)(Filter)
