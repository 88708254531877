import React, { FC } from 'react'
// types
import { Classes } from 'jss'
// styles
import injectSheet from 'react-jss'
import { sheet } from './sheet'
// libs
import Lottie from 'react-lottie'
import animationData from './data.json'

interface OwnProps {
  classes: Classes
}

interface ExternalProps {
  width?: string | number
  height?: string | number
  show?: boolean
  isStopped?: boolean
  isPaused?: boolean
}

type Props = OwnProps & ExternalProps

const Loader: FC<Props> = ({
  classes,
  width = '25%',
  height = '100%',
  show = false,
  isStopped = false,
  isPaused = false
}) => {
  return show ? (
    <div className={classes.root}>
      <Lottie
        options={{ loop: true, autoplay: true, animationData }}
        isStopped={isStopped}
        isPaused={isPaused}
        width={width}
        height={height}
        isClickToPauseDisabled={true}
      />
    </div>
  ) : null
}

export default injectSheet(sheet)(Loader)
