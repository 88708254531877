import moment from 'moment'
import { SelectItemValueStringI, SelectListValueStringT } from 'types/common'
import { currencies } from './currency'

export const month: Array<{ value: number; label: string }> = [
  { value: 1, label: 'January' },
  { value: 2, label: 'February' },
  { value: 3, label: 'March' },
  { value: 4, label: 'April' },
  { value: 5, label: 'May' },
  { value: 6, label: 'June' },
  { value: 7, label: 'July' },
  { value: 8, label: 'August' },
  { value: 9, label: 'September' },
  { value: 10, label: 'October' },
  { value: 11, label: 'November' },
  { value: 12, label: 'December' }
]

export type Year = (present?: boolean) => Array<{ value: string | number; label: string | number }>
export const year: Year = present => {
  const currentYear = moment().year()
  const minYear = currentYear - 80
  const maxYear = currentYear + 30
  const options = []
  if (present) options.push({ value: -1, label: 'Present' })
  for (let i = maxYear; i >= minYear; i--) {
    options.push({ value: i, label: i })
  }
  return options
}

export const currency: SelectListValueStringT = Object.keys(currencies).map(code => ({
  value: code.toLowerCase(),
  label: code
}))

export const findCurrency = (currencyLabel: string | null) => {
  return currency.find(item => item.label === currencyLabel) || currency[0]
}

export const filterLabelByInputValue = (
  option: { data: SelectItemValueStringI },
  inputValue: string
): boolean => option.data.label.toLowerCase().includes(inputValue.toLowerCase())

export const filterPhonePrefixByInputValue = (
  option: { data: SelectItemValueStringI },
  inputValue: string
): boolean => {
  const cleanInputValue = inputValue.startsWith('+') ? inputValue.slice(1) : inputValue
  const cleanLabel = option.data.label.startsWith('+')
    ? option.data.label.slice(1)
    : option.data.label

  return cleanLabel.toLowerCase().startsWith(cleanInputValue.toLowerCase())
}

export const travelPercent = [
  { value: 100, label: 'Most of the time' },
  { value: 10, label: '10% of the work time' },
  { value: 20, label: '20% of the work time' },
  { value: 30, label: '30% of the work time' },
  { value: 40, label: '40% of the work time' },
  { value: 50, label: '50% of the work time' },
  { value: 60, label: '60% of the work time' },
  { value: 70, label: '70% of the work time' },
  { value: 80, label: '80% of the work time' },
  { value: 90, label: '90% of the work time' }
]
