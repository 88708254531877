import { put, call, select } from 'redux-saga/effects'
import { isLoading, modalIsLoading, pageIsLoading } from 'domain/loading'
import { transformData } from './helpers'
import { ExperienceTypeInterface } from 'pages/Recruiter/Candidates/AddEditProfile/types'
import I from 'immutable'
import Api from 'domain/api'
import * as Actions from './actions'
import * as M from 'domain/env'

export function* ensureExperiencesList() {
  const headers = yield select(M.userToken)
  if (!headers) return
  try {
    const {
      data: { data }
    } = yield call(Api.getExperiencesList, {
      headers: { Authorization: `Bearer ${headers}` }
    })
    yield put({
      type: Actions.workExperiencesList.success,
      payload: I.fromJS(data)
    })
  } catch (err) {
    yield put({
      type: Actions.workExperiencesList.failure,
      err
    })
  }
}

export function* ensureAddExperience({
  payload
}: {
  payload: ExperienceTypeInterface
  type: string
}) {
  const headers = yield select(M.userToken)
  if (!headers) return
  try {
    yield put({ type: modalIsLoading, payload: true })
    const {
      data: { data }
    } = yield call(Api.postExperiences, {
      headers: { Authorization: `Bearer ${headers}` },
      data: transformData(payload)
    })
    yield put({ type: Actions.addWorkExperiences.SUCCESS, payload: I.fromJS(data) })
    yield put({ type: Actions.workExperiencesList.type })
  } catch (err) {
    yield put({
      type: Actions.addWorkExperiences.FAILURE,
      err
    })
  } finally {
    yield put({ type: modalIsLoading, payload: false })
  }
}

export function* ensureExperience({ payload }: { payload: number; type: string }) {
  const headers = yield select(M.userToken)
  if (!headers) return
  yield put({ type: isLoading, payload: true })
  yield put({ type: pageIsLoading, payload: true })
  try {
    const {
      data: { data }
    } = yield call(Api.getExperiences, {
      headers: { Authorization: `Bearer ${headers}` },
      id: payload
    })
    yield put({ type: Actions.workExperience.success, payload: I.fromJS(data) })
  } catch (err) {
    yield put({
      type: Actions.workExperience.failure,
      err
    })
  } finally {
    yield put({ type: isLoading, payload: false })
    yield put({ type: pageIsLoading, payload: false })
  }
}

export function* ensureUpdateExperience({
  payload
}: {
  payload: ExperienceTypeInterface
  type: string
}) {
  const headers = yield select(M.userToken)
  if (!headers) return
  try {
    yield put({ type: modalIsLoading, payload: true })
    const {
      data: { data }
    } = yield call(Api.putExperiences, {
      headers: { Authorization: `Bearer ${headers}` },
      id: payload.id,
      data: transformData(payload)
    })
    yield put({ type: Actions.updateWorkExperience.SUCCESS, payload: I.fromJS(data) })
    yield put({ type: Actions.workExperiencesList.type })
  } catch (err) {
    yield put({
      type: Actions.updateWorkExperience.FAILURE,
      err
    })
  } finally {
    yield put({ type: modalIsLoading, payload: false })
  }
}

export function* ensureRemoveExperience({ payload }: { payload: number; type: string }) {
  const headers = yield select(M.userToken)
  if (!headers) return
  try {
    yield put({ type: modalIsLoading, payload: true })
    const {
      data: { data }
    } = yield call(Api.deleteExperiences, {
      headers: { Authorization: `Bearer ${headers}` },
      id: payload
    })
    yield put({ type: Actions.removeWorkExperience.SUCCESS, payload: I.fromJS(data) })
    yield put({ type: Actions.workExperiencesList.type })
  } catch (err) {
    yield put({
      type: Actions.removeWorkExperience.FAILURE,
      err
    })
  } finally {
    yield put({ type: modalIsLoading, payload: false })
  }
}
