import { Field } from 'redux-form'

export interface RadioPropsType {
  label: string
  name: string
  value: string
  id: string
  checked?: boolean
  type: string
  disabled?: boolean
}

export const FieldRadio = Field as new () => Field<RadioPropsType>
