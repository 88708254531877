import { createSelector } from 'reselect'
import { Action } from 'redux'
import { LOCATION_CHANGE } from 'connected-react-router'
import { matchPath } from 'react-router-dom'
import queryString from 'query-string'

interface ActionType extends Action {
  payload: any
}

const ROUTES: Array<{ path: string; exact?: boolean }> = [
  { path: '/manager/jobs/:id' },
  { path: '/manager/jobs/clone/:id' },
  { path: '/manager/recruiters/:id' },
  { path: '/manager/managers/:id' },
  { path: '/manager/jobs/view/:id' },
  { path: '/manager/jobs/view/:id/close' },
  { path: '/manager/jobs/:id/candidates' },
  { path: '/manager/jobs/:id/candidates/:candidateId' },
  { path: '/manager/recruiters-jobs' },
  { path: '/manager/recruiters-clients' },
  { path: '/manager/clients-jobs' },
  { path: '/manager/candidates/:candidateId' },
  { path: '/recruiter/jobs/:jobsId/candidates/:id' },
  { path: '/recruiter/jobs/view/:id' },
  { path: '/recruiter/recruiters/:id' },
  { path: '/recruiter/managers/:id' },
  { path: '/recruiter/candidates/:id/edit' },
  { path: '/recruiter/candidates/:id' },
  { path: '/candidate/candidates/:id' },
  { path: '/candidate/jobs/view/:id' },
  { path: '/candidate/recruiters/:id' },
  { path: '/candidate/managers/:id' },
  { path: '/super_manager/managers/:id' },
  { path: '/super_manager/jobs/:id/candidates/:candidateId' },
  { path: '/super_manager/jobs/view/:id' },
  { path: '/super_manager/clients-jobs' }
]

function matchRoute(locations: any) {
  for (const item of ROUTES) {
    const match = matchPath(locations.pathname, {
      exact: 'exact' in item ? item.exact : true,
      strict: false,
      ...item
    })
    if (match !== null) {
      return match
    }
  }
  return null
}

export const routing = (r: any) => r.router
export const location = createSelector(
  routing,
  r => r.location
)
export const search = createSelector(
  location,
  r => r.search
)
export const query = createSelector(
  location,
  s => s.query
)
export const stateRouter = createSelector(
  location,
  r => r.state
)
export const matchRoutes = createSelector(
  location,
  matchRoute
)

const State = {
  location: {},
  action: ''
}

export const reducer = {
  router(states = State, action: ActionType) {
    if (action.type === LOCATION_CHANGE) {
      return {
        ...states,
        location: {
          ...action.payload.location,
          query: queryString.parse(action.payload.location.search, {
            arrayFormat: 'bracket',
            parseNumbers: true
          })
        },
        action: action.payload.action,
        prevLocation: states.location
      }
    } else {
      return states
    }
  }
}
