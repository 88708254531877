import React from 'react'
// components
import { Button } from 'components/Button'
import NotificationTitle from 'pages/Notifications/NotificationTitle'
// types
import { Classes } from 'jss'
import { ButtonTypes } from 'types/common'
// styles
import injectSheet from 'react-jss'
import { sheet } from './sheet'
// icons
import AvatarIcon from 'components/svgComponent/notification/AvatarIcon'
// utils
import cx from 'classnames'

interface Props {
  classes: Classes
  title: string
  pathTitle: string
  viewedAt: boolean
  createdAt: string
}

const Index = ({ classes, title, pathTitle, viewedAt, createdAt }: Props) => {
  return (
    <div className={cx(classes.main, { [classes.unread]: viewedAt })}>
      <AvatarIcon />
      <div className={classes.root}>
        <NotificationTitle title={title} path={pathTitle} />
        <p className={classes.text}>
          has been closed. [
          <Button
            isLink={true}
            to="/candidate/jobs"
            label="Check other opportunities"
            type={ButtonTypes.NotificationLink}
          />
          ]!
        </p>
      </div>
      <p className="createdAt">{createdAt}</p>
    </div>
  )
}

export default injectSheet(sheet)(Index)
