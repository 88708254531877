import { all, takeLatest, take, fork, call, select } from 'redux-saga/effects'
import * as A from 'domain/manager/recruiters'
import * as ASagas from 'domain/manager/recruiters/sagas'
import * as InviteRecruiter from 'domain/manager/anotherManagersRecruiter'
import { matchRoutes } from 'domain/router'

export function* profilePage() {
  yield all([fork(getProfile), fork(otherRecruiter), fork(preloadListsForRecruiter)])
}

function* preloadListsForRecruiter() {
  const route = yield select(matchRoutes)
  if (route && route.params) {
    yield all([
      call(ASagas.ensureGetRecruiterCandidates, {
        payload: { id: route.params.id },
        type: A.recruiterProfileCandidates.type
      }),
      call(ASagas.ensureGetRecruiterJobs, {
        payload: { id: route.params.id },
        type: A.recruiterProfileJobs.type
      }),
      call(ASagas.ensureGetRecruiterSkills, {
        payload: { id: route.params.id },
        type: A.recruiterProfileSkills.type
      })
    ])
  }
  yield takeLatest(A.recruiterProfileCandidates.type, ASagas.ensureGetRecruiterCandidates)
  yield takeLatest(A.recruiterProfileJobs.type, ASagas.ensureGetRecruiterJobs)
}

function* getProfile() {
  yield call(ASagas.ensureGetRecruiterProfile)
  yield takeLatest(A.recruiterProfile.type, ASagas.ensureGetRecruiterProfile)
  yield take(A.recruiterProfile.success)
}

function* otherRecruiter() {
  yield all([
    fork(inviteOtherRecruiter),
    fork(acceptOtherRecruiter),
    fork(closeOtherRecruiter),
    fork(declineOtherRecruiter)
  ])
}

function* inviteOtherRecruiter() {
  yield takeLatest(
    InviteRecruiter.inviteOtherRecruiter.type,
    InviteRecruiter.ensureInviteOtherRecruiter
  )
  yield take(InviteRecruiter.inviteOtherRecruiter.success)
}

function* acceptOtherRecruiter() {
  yield takeLatest(
    InviteRecruiter.acceptOtherRecruiter.type,
    InviteRecruiter.ensureAcceptOtherRecruiter
  )
  yield take(InviteRecruiter.acceptOtherRecruiter.success)
  yield call(ASagas.ensureGetRecruiterProfile, { payload: true })
}

function* declineOtherRecruiter() {
  yield takeLatest(
    InviteRecruiter.declineOtherRecruiter.type,
    InviteRecruiter.ensureDeclineOtherRecruiter
  )
  yield take(InviteRecruiter.declineOtherRecruiter.success)
  yield call(ASagas.ensureGetRecruiterProfile, { payload: true })
}

function* closeOtherRecruiter() {
  yield takeLatest(
    InviteRecruiter.closeOtherRecruiter.type,
    InviteRecruiter.ensureCloseOtherRecruiter
  )
  yield take(InviteRecruiter.closeOtherRecruiter.success)
  yield call(ASagas.ensureGetRecruiterProfile, { payload: true })
}
