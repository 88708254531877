import { put, call, select } from 'redux-saga/effects'
import I from 'immutable'
import Api from 'domain/api'
import * as Actions from './actions'
import * as M from 'domain/env'
import { modalIsLoading } from 'domain/loading'

export function* ensureGetPoints() {
  const headers = yield select(M.userToken)
  if (!headers) return
  try {
    const {
      data: { data }
    } = yield call(Api.getPoints, {
      headers: { Authorization: `Bearer ${headers}` }
    })
    yield put({ type: Actions.points.success, payload: I.fromJS(data) })
  } catch (err) {
    yield put({
      type: Actions.points.failure,
      err
    })
  }
}

export function* ensureReceivedPoints() {
  const headers = yield select(M.userToken)
  if (!headers) return
  try {
    const {
      data: { data }
    } = yield call(Api.receivedPoints, {
      headers: { Authorization: `Bearer ${headers}` }
    })
    yield put({ type: Actions.receivedPoints.success, payload: I.fromJS(data) })
  } catch (err) {
    yield put({
      type: Actions.receivedPoints.failure,
      err
    })
  }
}

export function* ensureRewardsView(props: { payload: number; type: string }) {
  const headers = yield select(M.userToken)
  if (!headers) return
  try {
    const { data } = yield call(Api.rewardsView, {
      headers: { Authorization: `Bearer ${headers}` },
      id: props.payload
    })
    yield put({ type: Actions.rewardsView.SUCCESS, payload: I.fromJS(data) })
  } catch (err) {
    yield put({
      type: Actions.rewardsView.FAILURE,
      err
    })
  }
}

export function* ensureRedeem(props: { payload: number; type: string }) {
  const headers = yield select(M.userToken)
  if (!headers) return
  try {
    yield put({ type: modalIsLoading, payload: true })
    const { data } = yield call(Api.redeem, {
      headers: { Authorization: `Bearer ${headers}` },
      data: { count: props.payload }
    })
    yield put({ type: Actions.redeem.SUCCESS, payload: I.fromJS(data) })
    yield call(ensureGetPoints)
  } catch (err) {
    yield put({
      type: Actions.redeem.FAILURE,
      err
    })
  } finally {
    yield put({ type: modalIsLoading, payload: false })
  }
}
