import React, { Component } from 'react'
// components
import { Button } from 'components/Button'
import { FieldInput } from 'components/Form/Fields/FieldInput'
import Input from 'components/Form/Input'
import CircleBackground from 'components/common/CircleBackground'
// redux
import { compose } from 'redux'
import { InjectedFormProps, reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import { push, Push } from 'connected-react-router'
import * as A from 'domain/env'
// types
import { ButtonHtmlTypes, ButtonTypes, ComposeType } from 'types/common'
import { Classes } from 'jss'
// styles
import { sheet } from './sheet'
import injectSheet from 'react-jss'
// utils
import { icons } from './icons'
import { resetPassword } from 'pages/Auth/validation'

interface OwnProps {
  classes: Classes
  recoveryPassword: (data: { email: string }) => void
  push?: Push
}

type Props = InjectedFormProps<{ email: string }> & OwnProps

class ResetPassword extends Component<Props> {
  submit = (data: { email: string }) => {
    this.props.recoveryPassword(data)
  }

  onClick = (path: string) => {
    if (this.props.push) this.props.push(path)
  }

  logIn = () => {
    this.onClick('/log-in')
  }

  render() {
    const { classes, handleSubmit } = this.props

    return (
      <form onSubmit={handleSubmit(this.submit)}>
        <div className={classes.root}>
          <div className={classes.content}>
            <div className={classes.textLeft}>
              <CircleBackground className="forLogIn" />
              <h3>Need a link to reset your password?</h3>
              <h4>How not to forget the password:</h4>
              <div className={classes.margin}>
                <div className={classes.iconBlock}>
                  {icons.map((item, i) => (
                    <div key={i}>
                      {item.icon}
                      <p className={classes.title}>{item.name}</p>
                      <p className={classes.text}>{item.text}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className={classes.form}>
              <h4>Reset password</h4>
              <p>
                We will send you instructions how you can reset your password and create a new one.
              </p>
              <p>
                Enter an email address and if it’s already in system please, check out your inbox
                folder!
              </p>
              <FieldInput
                name="email"
                type="text"
                component={Input}
                title="Email"
                placeholder="email@example.co"
              />
              <div className={classes.buttonGroup}>
                <Button label="Wait, I remembered!" onClick={this.logIn} />
                <Button
                  type={ButtonTypes.Submit}
                  htmlType={ButtonHtmlTypes.Submit}
                  label="reset password"
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    )
  }
}

export default compose<ComposeType<Props>>(
  connect(
    null,
    {
      recoveryPassword: A.recoveryPassword,
      push
    }
  ),
  reduxForm({
    form: 'reset-password',
    validate: resetPassword,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true
  }),
  injectSheet(sheet)
)(ResetPassword)
