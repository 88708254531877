import { asyncAction } from 'lib/actionCreators'

export const updateJobRecruiters = asyncAction('jobs/UPDATE_JOB_RECRUITERS')

// job note
export const getJobNotes = asyncAction('jobs/GET_JOB_NOTES')
export const addNoteToJob = asyncAction('jobs/ADD_NOTE_TO_JOB')
export const deleteNoteInJob = asyncAction('jobs/DELETE_NOTE_IN_JOB')
export const editNoteInJob = asyncAction('jobs/EDIT_NOTE_IN_JOB')

// candidate job note
export const getCandidateJobNotes = asyncAction('jobs/GET_CANDIDATE_JOB_NOTES')
export const addCandidateJobNote = asyncAction('jobs/ADD_CANDIDATE_JOB_NOTE')
export const deleteCandidateJobNote = asyncAction('jobs/DELETE_CANDIDATE_JOB_NOTE')
export const editCandidateJobNote = asyncAction('jobs/EDIT_CANDIDATE_JOB_NOTE')
