import React, { FC } from 'react'
// components
import Avatar from 'components/Avatar'
// types
import { Classes } from 'jss'
// styles
import injectSheet from 'react-jss'
import { style } from './sheet'
// utils
import cx from 'classnames'

interface Props {
  classes: Classes
  needEmail?: boolean
  photo: string
  role?: string
  name?: string
  isPopUp?: boolean
  email?: string
  firstName?: string
  lastName?: string
  isHideInfoOnXS?: boolean
}

const UserInfo: FC<Props> = ({
  classes,
  photo,
  role,
  name = '',
  isPopUp = false,
  isHideInfoOnXS = false,
  email,
  firstName = '',
  lastName = ''
}) => (
  <div className={cx(classes.profileInfo, { [classes.profileInfoDark]: isPopUp })}>
    <div className={cx(classes.photo, { [classes.photoDark]: isPopUp })}>
      <Avatar src={photo} />
    </div>
    <div
      className={cx(classes.infoBlock, {
        [classes.infoBlockDark]: isPopUp,
        [classes.hideOnXS]: isHideInfoOnXS
      })}
    >
      {isPopUp ? (
        <div>
          <div>{firstName}</div>
          <div>{lastName}</div>
        </div>
      ) : (
        <div>{name}</div>
      )}
      <div>{isPopUp ? email : role}</div>
    </div>
  </div>
)

export default injectSheet(style)(UserInfo)
