import React from 'react'
import Svg, { SvgType } from './Svg'

export default function(props: SvgType) {
  return (
    <Svg width="35" height="28" viewBox="0 0 35 28" fill="none" {...props}>
      <path
        d="M8.5 17.3624C8.5 18.7872 7.3664 19.9161 6 19.9161C4.6336 19.9161 3.5 18.7872 3.5 17.3624C3.5 15.9375 4.6336 14.8086 6 14.8086C7.3664 14.8086 8.5 15.9375 8.5 17.3624Z"
        stroke="#286EFA"
        strokeWidth="2"
      />
      <path
        d="M10.3297 26.5015H1.70173C1.35816 26.5015 1.11681 26.1611 1.24019 25.8405C2.25963 23.1912 3.1695 22.9473 6 22.9473C8.82213 22.9473 9.92953 23.3904 10.7944 25.8621C10.9048 26.1777 10.6641 26.5015 10.3297 26.5015Z"
        stroke="#286EFA"
        strokeWidth="2"
      />
      <path
        d="M11 16.6382L14 14.3732M14 14.3732H33C33.5523 14.3732 34 13.9255 34 13.3732V2C34 1.44772 33.5523 1 33 1H15C14.4477 1 14 1.44772 14 2V14.3732Z"
        stroke="#286EFA"
        strokeWidth="2"
      />
      <path d="M17 6H31" stroke="#286EFA" strokeWidth="2" />
      <path d="M17 10H26" stroke="#286EFA" strokeWidth="2" />
    </Svg>
  )
}
