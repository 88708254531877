import { all, takeLatest, take, fork, call } from 'redux-saga/effects'
import * as A from 'domain/candidate/managerProfileView'

export function* profilePage() {
  yield all([fork(getProfile)])
}

function* getProfile() {
  yield call(A.ensureGetManager)
  yield takeLatest(A.managerProfileView.type, A.ensureGetManager)
  yield take(A.managerProfileView.success)
}
