import React, { FC, useMemo } from 'react'
// components
import LogoFooter from 'components/svgComponent/LogoFooter'
import Location from 'components/svgComponent/Location'
import CallAnswer from 'components/svgComponent/CallAnswer'
import Mail from 'components/svgComponent/Mail'
// types
import { Classes } from 'jss'
// styles
import injectSheet from 'react-jss'
import { sheet } from './sheet'

interface Props {
  classes: Classes
  style?: React.CSSProperties
}

const Footer: FC<Props> = ({ classes }) => {
  const scrollTo = () => {
    window.scrollTo(0, 0)
  }

  const currentYear = useMemo(() => new Date().getFullYear(), [])

  return (
    <footer className={classes.footer}>
      <LogoFooter className={classes.footerLogo} onClick={scrollTo} />
      <div className={classes.bigColumn}>
        <h5>About Us</h5>
        <p>
          Our aim is to provide a new and exciting platform to efficiently source jobs and
          candidates by offering Bounty Points for true Crowd Source Recruiting!
        </p>
      </div>
      <div className={classes.bigColumn}>
        <h5>Contact Information</h5>
        <ul className={classes.linkList}>
          <li>
            <Location />
            <p>
              433 Plaza Real Suite 275, <br /> Boca Raton FL 33432 USA
            </p>
          </li>
          <li>
            <CallAnswer />
            <p>
              <a href="tel:(561) 450-9165">(561) 450-9165</a>
            </p>
          </li>
          <li>
            <Mail />
            <p>
              <a href="mailto:info@skilent.com"> info@skilent.com</a>
            </p>
          </li>
        </ul>
      </div>
      <div className={classes.helpColumn}>
        <h5>Help</h5>
        <ul>
          <li>
            <a href="javascript:void(0)">Support</a>
          </li>
          <li>
            <a href="javascript:void(0)">Pricing</a>
          </li>
          <li>
            <a href="javascript:void(0)">FAQ</a>
          </li>
        </ul>
      </div>
      <div className={classes.socialColumn}>
        <h5>Social</h5>
        <ul>
          <li>
            <a href="javascript:void(0)">Facebook</a>
          </li>
          <li>
            <a href="javascript:void(0)">Twitter</a>
          </li>
          <li>
            <a href="javascript:void(0)">LiveJournal</a>
          </li>
        </ul>
      </div>
      <div className={classes.copyright}>© Skilent {currentYear}</div>
      <div className={classes.disclaimer}>
        Skilent&trade; & its related IP are registered offerings of Comtech LLC. All Rights
        Reserved.
      </div>
    </footer>
  )
}

export default injectSheet(sheet)(Footer)
