import { asyncAction } from 'lib/actionCreators'
import { createRoutine, promisifyRoutine } from 'redux-saga-routines'

export const inviteCandidates = createRoutine('candidate/INVITE_CANDIDATES')
export const inviteCandidatesPromiseCreator = promisifyRoutine(inviteCandidates)
export const resendInvitationToCandidates = asyncAction('candidate/RESEND_INVITATION_TO_CANDIDATES')

export const candidatesList = asyncAction('candidate/CANDIDATES_LIST')
export const invitedCandidatesList = asyncAction('candidate/INVITED_CANDIDATES_LIST')
export const inviteNewCandidate = asyncAction('candidate/INVITE_NEW_CANDIDATE')

export const declineInvitationToCandidate = createRoutine(
  'candidate/DECLINE_INVITATION_TO_CANDIDATE'
)
export const declineInvitationToCandidatePromiseCreator = promisifyRoutine(
  declineInvitationToCandidate
)

export const deleteCandidate = createRoutine('caindidate/DELETE_CANDIDATE')
export const deleteCandidatePromiseCreator = promisifyRoutine(deleteCandidate)
