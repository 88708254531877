import React from 'react'
import Svg, { SvgType } from './Svg'

export default function(props: SvgType) {
  return (
    <Svg width="14" height="15" viewBox="0 0 14 15" fill="none" {...props}>
      <path d="M1 7.5L7.11109 12.3889L7.11109 2.61108L1 7.5Z" fill="#DEDEDE" stroke="#DEDEDE" />
    </Svg>
  )
}
