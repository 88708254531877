import React from 'react'
import Svg, { SvgType } from 'components/svgComponent/Svg'

export default function(props: SvgType) {
  const { iconActive } = props
  const fill = iconActive ? '#286EFA' : '#E6E6E6'
  return (
    <Svg width="24" height="25" viewBox="0 0 24 25" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 13.68V17.68L12 21.5L19 17.68V13.68L12 17.5L5 13.68ZM12 3.5L1 9.5L12 15.5L21 10.59V17.5H23V9.5L12 3.5Z"
        fill={fill}
      />
    </Svg>
  )
}
