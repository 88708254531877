import * as React from 'react'
// types
import * as PayloadType from './types'
// components
import Invitation from 'pages/Notifications/Invitation'
import Calendar from 'pages/Notifications/Calendar'
import WithAvatar from 'pages/Notifications/WithAvatar'
import ClosedJob from 'pages/Notifications/ClosedJob'
import ReceivedPoints from 'pages/Notifications/ReceivedPoints'
import RedeemPoints from 'pages/Notifications/RedeemPoints'
import NewCandidateForJobs from 'pages/Notifications/NewCandidateForJobs'
import HMAssignDecline from 'pages/Notifications/HMAssignDecline'
import UserRedeemPoints from 'pages/Notifications/UserRedeemPoints'
import AcceptDeclineRecruitersInvite from 'pages/Notifications/AcceptDeclineRecruitersInvite'
import AcceptRecommendations from 'pages/Notifications/AcceptRecommendations'
import SuperManagerRoleApproved from 'pages/Notifications/SuperManagerRoleApproved'
import CollaborationRequest from 'pages/Notifications/CollaborationRequest'
// utils
import I from 'immutable'
import moment from 'moment'
import { NotificationTypeT } from 'domain/constants'
import { ImmutableMap } from 'types/common'

type DataType = ImmutableMap<{
  payload: any
  viewedAt: null | string
  createdAt: string
  type: string
}>

export const notificationsStructure = (
  data: DataType = I.Map({}),
  notificationTypes: NotificationTypeT,
  role: string,
  isMenu: boolean | undefined = false
) => {
  if (!data || !notificationTypes || notificationTypes.isEmpty()) return null
  const payload = data.get('payload')
  const viewedAt = !data.get('viewedAt')
  const createdAt = moment(data.get('createdAt')).format('MM/DD/YYYY hh:mm A')
  switch (data.get('type')) {
    case notificationTypes.get('RECRUITER_REWARDS'):
      return recruiterRewards(payload, isMenu, viewedAt, createdAt)

    case notificationTypes.get('RECRUITER_ACCEPT_INVITE'):
      return recruiterAcceptInvite(payload, viewedAt, createdAt)

    case notificationTypes.get('RECRUITER_CLOSED_JOB'):
      return recruiterClosedJob(payload, viewedAt, createdAt)

    case notificationTypes.get('RECRUITER_REDEEM_WITHDRAW'):
      return recruiterRedeemWithdraw(payload, viewedAt, createdAt)

    case notificationTypes.get('RECRUITER_ASSIGNS_CONFIRMED'):
      return recruiterAssignsConfirmed(payload, viewedAt, createdAt)

    case notificationTypes.get('CANDIDATE_JOB_UPDATED'):
      return candidateJobUpdated(payload, 'Job has been updated', role, viewedAt, createdAt)

    case notificationTypes.get('CANDIDATE_CLOSED_JOB'):
      return candidateClosedJob(payload, viewedAt, createdAt)

    case notificationTypes.get('CANDIDATE_ASSIGNS_CONFIRMED'):
      return candidateAssignConfirmed(
        payload,
        'has accepted assign to the job.',
        viewedAt,
        createdAt
      )

    case notificationTypes.get('RECRUITER_ASSIGNS_DECLINED'):
      return candidateAssignDeclined(
        payload,
        'has declined assign to the job.',
        viewedAt,
        createdAt
      )

    case notificationTypes.get('RECRUITER_ASSIGNS_CREATED'):
      return candidateAssignDeclined(payload, 'has assigned you to the job.', viewedAt, createdAt)

    case notificationTypes.get('CANDIDATE_SELF_ASSIGNS_DECLINE'):
      return candidateAssignConfirmed(
        payload,
        'has declined his self-assign to the job.',
        viewedAt,
        createdAt
      )

    case notificationTypes.get('CANDIDATE_SELF_ASSIGNS_CREATE'):
      return candidateAssignConfirmed(
        payload,
        'has just self-assigned to the job.',
        viewedAt,
        createdAt
      )

    case notificationTypes.get('HM_JOB_CREATE'):
      return hmJobCreate(
        payload,
        'has attached you as recruiter to the job.',
        role,
        viewedAt,
        createdAt
      )

    case notificationTypes.get('HM_JOB_UPDATE'):
      return hmJobCreate(payload, 'has updated the job requirements.', role, viewedAt, createdAt)

    case notificationTypes.get('HM_JOB_CLOSED'):
      return candidateJobUpdated(payload, 'Job has been just closed!', role, viewedAt, createdAt)

    case notificationTypes.get('HM_JOB_NEW_CANDIDATES'):
      return hmJobNewCandidates(payload, role, viewedAt, createdAt)

    case notificationTypes.get('HM_ASSIGN_DECLINE'):
      return hmAssignDecline(payload, viewedAt, createdAt)

    case notificationTypes.get('HM_USER_REDEEM_WITHDRAW'):
      return hmUserRedeemWithdraw(payload, viewedAt, createdAt)

    case notificationTypes.get('CALENDAR_EVENT_SOON_STARTED'):
      return <Calendar createdAt={createdAt} data={payload} viewedAt={viewedAt} role={role} />
    case notificationTypes.get('FOREIGN_RECRUITER_INVITE_CREATED'):
      return inviteOtherRecruiter(
        payload,
        viewedAt,
        'requestManager',
        '/manager/managers',
        'recruiter',
        '/manager/recruiters',
        'Has requested to work with',
        false,
        '',
        createdAt
      )

    case notificationTypes.get('FOREIGN_RECRUITER_INVITE_DECLINED'):
      return inviteOtherRecruiter(
        payload,
        viewedAt,
        'mainManager',
        '/manager/managers',
        'recruiter',
        '/manager/recruiters',
        'Has declined a request to work with',
        false,
        '',
        createdAt
      )

    case notificationTypes.get('FOREIGN_RECRUITER_INVITE_ACCEPTED'):
      return inviteOtherRecruiter(
        payload,
        viewedAt,
        'mainManager',
        '/manager/managers',
        'recruiter',
        '/manager/recruiters',
        'Has accepted a request to work with',
        false,
        '',
        createdAt
      )

    case notificationTypes.get('FOREIGN_RECRUITER_INVITE_WORK'):
      return inviteOtherRecruiter(
        payload,
        viewedAt,
        'requestManager',
        '/recruiter/managers',
        'recruiter',
        '',
        'Now works with you!',
        true,
        '',
        createdAt
      )

    case notificationTypes.get('FOREIGN_RECRUITER_INVITE_SELF_CLOSED'):
      return inviteOtherRecruiter(
        payload,
        viewedAt,
        'recruiter',
        '/manager/recruiters',
        'requestManager',
        '/manager/managers',
        'Has finished work with',
        false,
        '',
        createdAt
      )

    case notificationTypes.get('FOREIGN_RECRUITER_INVITE_CLOSED'):
      return inviteOtherRecruiter(
        payload,
        viewedAt,
        'recruiter',
        '/manager/recruiters',
        'requestManager',
        '',
        'Has finished work with you',
        true,
        '',
        createdAt
      )

    case notificationTypes.get('FOREIGN_RECRUITER_INVITE_WORK_FINISHED'):
      return inviteOtherRecruiter(
        payload,
        viewedAt,
        'recruiter',
        '/recruiter/recruiters',
        'requestManager',
        '/recruiter/managers',
        'Your work with',
        false,
        'has been finished',
        createdAt
      )

    case notificationTypes.get('CANDIDATE_ACCEPT_RECOMMENDATION'):
      return acceptRecommendations(payload, viewedAt, createdAt)

    case notificationTypes.get('SUPER_MANAGER_ROLE_REQUEST_APPROVED'):
      return <SuperManagerRoleApproved viewedAt={viewedAt} createdAt={createdAt} />

    case notificationTypes.get('COLLABORATION_REQUEST_ACCEPTED'):
      return (
        <CollaborationRequest
          type="accepted"
          path="/super_manager/managers"
          payload={payload}
          viewedAt={viewedAt}
          createdAt={createdAt}
        />
      )

    case notificationTypes.get('COLLABORATION_REQUEST_REJECTED'):
      return (
        <CollaborationRequest
          type="rejected"
          path="/super_manager/managers"
          payload={payload}
          viewedAt={viewedAt}
          createdAt={createdAt}
        />
      )

    case notificationTypes.get('COLLABORATION_REQUEST_SENT'):
      return (
        <CollaborationRequest
          type="received"
          path="/manager/managers"
          payload={payload}
          viewedAt={viewedAt}
          createdAt={createdAt}
        />
      )
    default:
      return null
  }
}

const recruiterRewards = (
  payload: PayloadType.RecruiterRewardsPayload = I.Map({}),
  isMenu: boolean,
  viewedAt: boolean,
  createdAt: string
) => {
  const title = `You received ${payload.get('points')} points!`
  const job = payload.get('job')
  const jobId = job && !job.isEmpty() ? job.get('id') : ''
  const path = jobId ? `/recruiter/jobs/view/${jobId}` : ''
  const name = job && !job.isEmpty() ? job.get('title') : ''
  return (
    <ReceivedPoints
      createdAt={createdAt}
      title={title}
      jobName={name}
      path={path}
      isMenu={isMenu}
      viewedAt={viewedAt}
    />
  )
}

const recruiterAcceptInvite = (
  payload: PayloadType.RecruiterAcceptInvitePayload = I.Map({}),
  viewedAt: boolean,
  createdAt: string
) => {
  const text = 'accepted invitation via email as recruiter'
  const recruiter = payload.get('recruiter')
  const title = recruiter && !recruiter.isEmpty() ? recruiter.get('email') : ''
  return <Invitation createdAt={createdAt} text={text} title={title} viewedAt={viewedAt} />
}

const recruiterClosedJob = (
  payload: PayloadType.RecruiterClosedJobPayload = I.Map({}),
  viewedAt: boolean,
  createdAt: string
) => {
  const text = 'has been just closed with your candidate'
  const job = payload.get('job')
  const jobId = job && !job.isEmpty() ? job.get('id') : ''
  const title = job && !job.isEmpty() ? job.get('title') : ''
  const path = jobId ? `/recruiter/jobs/view/${jobId}` : ''
  return (
    <ClosedJob createdAt={createdAt} title={title} text={text} path={path} viewedAt={viewedAt} />
  )
}

const recruiterRedeemWithdraw = (
  payload: PayloadType.RecruiterRedeemWithdrawPayload = I.Map({}),
  viewedAt: boolean,
  createdAt: string
) => {
  const title = `points were successfully redeemed`
  const count = payload.get('amount')
  return <RedeemPoints createdAt={createdAt} title={title} count={count} viewedAt={viewedAt} />
}

const recruiterAssignsConfirmed = (
  payload: PayloadType.RecruiterAssignsConfirmedPayload = I.Map({}),
  viewedAt: boolean,
  createdAt: string
) => {
  const job = payload.get('job')
  const title = job && !job.isEmpty() ? job.get('title') : ''
  const jobId = job && !job.isEmpty() ? job.get('id') : ''
  const path = jobId ? `/candidate/jobs/view/${jobId}` : ''
  const recruiter = payload.get('recruiter')
  const name =
    recruiter && !recruiter.isEmpty()
      ? [recruiter.get('firstName'), recruiter.get('lastName')].join(' ')
      : ''
  const avatar = recruiter && !recruiter.isEmpty() ? recruiter.get('avatar') : ''
  const recruiterId = recruiter && !recruiter.isEmpty() ? recruiter.get('id') : ''
  const text = 'has assigned you to the job.'
  return (
    <WithAvatar
      createdAt={createdAt}
      text={text}
      buttonText={name}
      title={title}
      avatar={avatar}
      pathTitle={path}
      pathButton={`/candidate/recruiters/${recruiterId}`}
      viewedAt={viewedAt}
    />
  )
}

const candidateJobUpdated = (
  payload: PayloadType.CandidateJobUpdatedPayload = I.Map({}),
  text: string,
  role: string,
  viewedAt: boolean,
  createdAt: string
) => {
  const job = payload.get('job')
  const title = job && !job.isEmpty() ? job.get('title') : ''
  const jobId = job && !job.isEmpty() ? job.get('id') : ''
  const path = jobId ? `/${role}/jobs/view/${jobId}` : ''
  return (
    <ClosedJob createdAt={createdAt} title={title} text={text} path={path} viewedAt={viewedAt} />
  )
}

const candidateClosedJob = (
  payload: PayloadType.CandidateClosedJobPayload = I.Map({}),
  viewedAt: boolean,
  createdAt: string
) => {
  const job = payload.get('job')
  const title = job && !job.isEmpty() ? job.get('title') : ''
  const jobId = job && !job.isEmpty() ? job.get('id') : ''
  const path = jobId ? `/candidate/jobs/view/${jobId}` : ''
  const manager = payload.get('manager')
  const name =
    manager && !manager.isEmpty()
      ? [manager.get('firstName'), manager.get('lastName')].join(' ')
      : ''
  const avatar = manager && !manager.isEmpty() ? manager.get('avatar') : ''
  const managerId = manager && !manager.isEmpty() ? manager.get('id') : ''
  const text = 'has applied you to the job.'
  return (
    <WithAvatar
      createdAt={createdAt}
      text={text}
      buttonText={name}
      title={title}
      avatar={avatar}
      pathTitle={path}
      pathButton={`/candidate/managers/${managerId}`}
      viewedAt={viewedAt}
    />
  )
}

const candidateAssignConfirmed = (
  payload: PayloadType.CandidateAssignConfirmedPayload = I.Map({}),
  text: string,
  viewedAt: boolean,
  createdAt: string
) => {
  const job = payload.get('job')
  const title = job && !job.isEmpty() ? job.get('title') : ''
  const jobId = job && !job.isEmpty() ? job.get('id') : ''
  const path = jobId ? `/recruiter/jobs/view/${jobId}` : ''
  const candidate = payload.get('candidate')
  const name =
    candidate && !candidate.isEmpty()
      ? [candidate.get('firstName'), candidate.get('lastName')].join(' ')
      : ''
  const avatar = candidate && !candidate.isEmpty() ? candidate.get('avatar') : ''
  const candidateId = candidate && !candidate.isEmpty() ? candidate.get('id') : ''
  return (
    <WithAvatar
      createdAt={createdAt}
      text={text}
      buttonText={name}
      title={title}
      avatar={avatar}
      pathTitle={path}
      pathButton={`/recruiter/candidates/${candidateId}`}
      viewedAt={viewedAt}
    />
  )
}

const candidateAssignDeclined = (
  payload: PayloadType.CandidateAssignDeclinePayload = I.Map({}),
  text: string,
  viewedAt: boolean,
  createdAt: string
) => {
  const job = payload.get('job')
  const title = job && !job.isEmpty() ? job.get('title') : ''
  const jobId = job && !job.isEmpty() ? job.get('id') : ''
  const path = jobId ? `/candidate/jobs/view/${jobId}` : ''
  const recruiter = payload.get('recruiter')
  const name =
    recruiter && !recruiter.isEmpty()
      ? [recruiter.get('firstName'), recruiter.get('lastName')].join(' ')
      : ''
  const avatar = recruiter && !recruiter.isEmpty() ? recruiter.get('avatar') : ''
  const recruiterId = recruiter && !recruiter.isEmpty() ? recruiter.get('id') : ''
  return (
    <WithAvatar
      createdAt={createdAt}
      text={text}
      buttonText={name}
      title={title}
      avatar={avatar}
      pathTitle={path}
      pathButton={`/candidate/recruiters/${recruiterId}`}
      viewedAt={viewedAt}
    />
  )
}

const hmJobCreate = (
  payload: PayloadType.HMJobCreatedPayload = I.Map({}),
  text: string,
  role: string,
  viewedAt: boolean,
  createdAt: string
) => {
  const job = payload.get('job')
  const title = job && !job.isEmpty() ? job.get('title') : ''
  const jobId = job && !job.isEmpty() ? job.get('id') : ''
  const path = jobId ? `/${role}/jobs/view/${jobId}` : ''
  const manager = payload.get('manager')
  const name =
    manager && !manager.isEmpty()
      ? [manager.get('firstName'), manager.get('lastName')].join(' ')
      : ''
  const avatar = manager && !manager.isEmpty() ? manager.get('avatar') : ''
  const managerId = manager && !manager.isEmpty() ? manager.get('id') : ''
  return (
    <WithAvatar
      createdAt={createdAt}
      text={text}
      buttonText={name}
      title={title}
      avatar={avatar}
      pathTitle={path}
      pathButton={`/${role}/managers/${managerId}`}
      viewedAt={viewedAt}
    />
  )
}

const hmJobNewCandidates = (
  payload: PayloadType.HMJobNewCandidatesPayload = I.Map({}),
  role: string,
  viewedAt: boolean,
  createdAt: string
) => {
  const job = payload.get('job')
  const title = job && !job.isEmpty() ? job.get('title') : ''
  const jobId = job && !job.isEmpty() ? job.get('id') : ''
  const path = jobId ? `/${role}/jobs/view/${jobId}` : ''
  const candidate = payload.get('candidate')
  const candidateName =
    candidate && !candidate.isEmpty()
      ? [candidate.get('firstName'), candidate.get('lastName')].join(' ')
      : ''
  const candidateId = candidate && !candidate.isEmpty() ? candidate.get('id') : ''
  const recruiter = payload.get('recruiter')
  const recruiterName =
    recruiter && !recruiter.isEmpty()
      ? [recruiter.get('firstName'), recruiter.get('lastName')].join(' ')
      : ''
  const recruiterId = recruiter && !recruiter.isEmpty() ? recruiter.get('id') : ''
  return (
    <NewCandidateForJobs
      createdAt={createdAt}
      title={title}
      pathTitle={path}
      candidate={candidateName}
      candidatePath={`/${role}/candidates/${candidateId}`}
      recruiter={recruiterName}
      recruiterPath={`/${role}/recruiters/${recruiterId}`}
      viewedAt={viewedAt}
    />
  )
}

const hmAssignDecline = (
  payload: PayloadType.HMAssignDeclinePayload = I.Map({}),
  viewedAt: boolean,
  createdAt: string
) => {
  const job = payload.get('job')
  const title = job && !job.isEmpty() ? job.get('title') : ''
  const jobId = job && !job.isEmpty() ? job.get('id') : ''
  const path = jobId ? `/candidate/jobs/view/${jobId}` : ''
  return (
    <HMAssignDecline createdAt={createdAt} title={title} pathTitle={path} viewedAt={viewedAt} />
  )
}

const hmUserRedeemWithdraw = (
  payload: PayloadType.HMUseRedeemWithDrawPayload = I.Map({}),
  viewedAt: boolean,
  createdAt: string
) => {
  const recruiter = payload.get('user')
  const name =
    recruiter && !recruiter.isEmpty()
      ? [recruiter.get('firstName'), recruiter.get('lastName')].join(' ')
      : ''
  const avatar = recruiter && !recruiter.isEmpty() ? recruiter.get('avatar') : ''
  const recruiterId = recruiter && !recruiter.isEmpty() ? recruiter.get('id') : ''
  const count = payload.get('amount') || '0'
  return (
    <UserRedeemPoints
      createdAt={createdAt}
      title={name}
      path={`/manager/recruiters/${recruiterId}`}
      avatar={avatar}
      count={count}
      viewedAt={viewedAt}
    />
  )
}

const inviteOtherRecruiter = (
  payload: PayloadType.InviteOtherRecruiterPayload = I.Map({}),
  viewedAt: boolean,
  managerName: 'requestManager' | 'mainManager' | 'recruiter',
  managerPath: string,
  recruiterName: 'requestManager' | 'mainManager' | 'recruiter',
  path: string,
  text: string,
  onlyText: boolean = false,
  endText: string = '',
  createdAt: string
) => {
  const requestManager = payload.get(managerName)
  const title =
    requestManager && !requestManager.isEmpty()
      ? [requestManager.get('firstName'), requestManager.get('lastName')].join(' ')
      : ''
  const requestManagerId =
    requestManager && !requestManager.isEmpty() ? requestManager.get('id') : ''
  const requestManagerPath = requestManagerId ? `${managerPath}/${requestManagerId}` : ''
  const avatar = requestManager && !requestManager.isEmpty() ? requestManager.get('avatar') : ''
  const recruiter = payload.get(recruiterName)
  const name =
    recruiter && !recruiter.isEmpty()
      ? [recruiter.get('firstName'), recruiter.get('lastName')].join(' ')
      : ''
  const recruiterId = recruiter && !recruiter.isEmpty() ? recruiter.get('id') : ''
  const recruiterPath = recruiterId ? `${path}/${recruiterId}` : ''
  return (
    <AcceptDeclineRecruitersInvite
      createdAt={createdAt}
      text={text}
      buttonText={name}
      title={title}
      onlyText={onlyText}
      avatar={avatar}
      pathTitle={requestManagerPath}
      pathButton={recruiterPath}
      viewedAt={viewedAt}
      endText={endText}
    />
  )
}

const acceptRecommendations = (
  payload: PayloadType.CandidateAssignConfirmedPayload = I.Map({}),
  viewedAt: boolean,
  createdAt: string
) => {
  const job = payload.get('job')
  const title = job && !job.isEmpty() ? job.get('title') : ''
  const jobId = job && !job.isEmpty() ? job.get('id') : ''
  const path = jobId ? `/candidate/jobs/view/${jobId}` : ''
  const candidate = payload.get('candidate')
  const name =
    candidate && !candidate.isEmpty()
      ? [candidate.get('firstName'), candidate.get('lastName')].join(' ')
      : ''
  const avatar = candidate && !candidate.isEmpty() ? candidate.get('avatar') : ''
  const candidateId = candidate && !candidate.isEmpty() ? candidate.get('id') : ''
  const text = 'Has accepted your recommendation for'
  return (
    <AcceptRecommendations
      createdAt={createdAt}
      text={text}
      buttonText={title}
      title={name}
      avatar={avatar}
      pathTitle={`/candidate/candidates/${candidateId}`}
      pathButton={path}
      viewedAt={viewedAt}
    />
  )
}
