import React from 'react'
import Svg, { SvgType } from 'components/svgComponent/Svg'

const fillColorBlue = '#45B1E9' // blue
const fillColorWhite = '#FAFAFA'

export default function(props: SvgType) {
  return (
    <Svg width="62" height="34" viewBox="0 0 512 512" fill={fillColorWhite} {...props}>
      <path
        stroke="none"
        d="
M206.895416,426.048279
	C205.411804,425.317017 203.871719,424.321167 202.611588,423.048187
	C186.102646,406.370941 169.633301,389.654449 152.997162,372.676910
	C150.729874,366.334900 153.851791,364.509644 159.221313,362.560730
	C167.726486,359.473724 175.776184,355.075165 183.847397,350.900513
	C185.335114,350.131012 186.011459,347.792908 187.136139,345.765503
	C190.818008,348.679138 194.541153,351.890625 198.013977,355.353149
	C212.435913,369.732361 226.782333,384.187317 241.250458,398.889160
	C243.131439,409.169891 233.912811,409.390808 228.733643,412.763062
	C222.693207,416.696075 215.889099,419.446045 209.492538,422.852417
	C208.449646,423.407745 207.800308,424.702087 206.895416,426.048279
z"
      />
      <path
        stroke="none"
        d="
M234.106339,199.776367
	C234.065567,199.371796 234.024796,198.967224 233.975708,197.947632
	C233.893311,196.904648 233.819275,196.476685 233.745239,196.048737
	C233.358917,187.993103 237.037277,181.832230 242.940277,176.335999
	C253.315384,169.431763 260.962891,169.445450 270.568359,176.213226
	C274.142090,179.705139 277.383087,183.064728 280.357117,186.719864
	C278.819702,186.718201 277.364197,186.719254 276.313843,186.067429
	C273.242889,184.161682 271.354126,185.689835 269.640686,188.001190
	C266.035431,192.864456 261.825134,197.417786 259.050415,202.719681
	C256.199371,208.167511 248.877594,211.530746 250.533401,219.213043
	C243.539642,213.741409 237.819534,207.595398 234.106339,199.776367
z"
      />
      <path
        stroke="none"
        d="
M322.738647,228.741440
	C320.169159,230.624908 316.829773,231.643860 314.911194,233.881195
	C308.193787,241.714600 301.996552,249.992523 295.511169,258.028473
	C294.775970,258.939423 293.466370,259.386810 291.764221,259.931824
	C277.688965,246.320099 264.272583,232.825684 250.856216,219.331268
	C248.877594,211.530746 256.199371,208.167511 259.050415,202.719681
	C261.825134,197.417786 266.035431,192.864456 269.640686,188.001190
	C271.354126,185.689835 273.242889,184.161682 276.313843,186.067429
	C277.364197,186.719254 278.819702,186.718201 280.474426,187.025391
	C294.779266,200.835449 308.699738,214.635544 322.738647,228.741440
z"
      />
      <path
        stroke="none"
        d="
M266.603821,48.057610
	C270.557617,50.954468 274.171600,53.698997 277.980835,57.056175
	C282.462280,62.081306 286.748444,66.493782 291.184937,71.205780
	C300.627197,80.964043 309.919220,90.422768 318.903687,100.112869
	C318.623596,103.057991 319.304901,105.971695 318.548340,108.445694
	C316.727844,114.399017 314.120392,120.106285 312.040405,125.987091
	C309.543121,133.047943 307.263672,140.185822 304.560852,147.186371
	C303.098969,146.117996 301.886444,145.232254 300.845459,144.177277
	C287.546265,130.699188 274.205414,117.260925 261.041016,103.652092
	C258.045074,100.555016 255.889496,100.332329 252.898712,103.592415
	C249.203751,107.620049 245.077332,111.251854 241.137344,115.054688
	C240.732971,113.982742 239.721008,112.686539 240.020737,111.874428
	C242.343887,105.579742 245.140610,99.455627 247.355499,93.126404
	C249.510193,86.969254 251.258972,80.661690 252.983215,74.364647
	C254.669952,68.204460 256.178375,61.992039 257.609253,55.766720
	C258.266113,52.908737 258.550140,49.965054 259.004852,46.633377
	C261.425659,46.772778 263.844788,47.339031 266.603821,48.057610
z"
      />
      <path
        stroke="none"
        d="
M135.935303,230.131210
	C136.290787,224.551315 141.510468,223.491623 144.860168,220.869919
	C151.844620,215.403366 159.155426,210.351868 166.373322,205.187363
	C167.566391,204.333710 168.968414,203.772049 170.926483,203.175262
	C172.967712,204.706802 174.356323,206.138535 175.867737,207.844208
	C178.000137,209.996323 180.009735,211.874481 182.291931,213.958679
	C196.044434,227.776031 209.524338,241.387375 222.638306,255.004211
	C221.491409,255.236603 220.542725,255.253342 219.954987,255.722366
	C212.715591,261.499573 205.687622,267.556152 198.262344,273.079895
	C194.487549,275.888062 190.021957,277.767609 185.571136,279.922333
	C168.827515,263.228882 152.381409,246.680054 135.935303,230.131210
z"
      />
      <path
        stroke="none"
        d="
M222.638306,255.004211
	C240.355560,272.253357 257.725037,289.489868 275.020447,306.800415
	C276.272461,308.053558 276.985168,309.845581 278.140259,311.687256
	C278.871094,315.366913 279.410278,318.746307 279.967834,322.551208
	C277.972534,326.725891 276.353455,330.762268 273.872375,334.171326
	C265.882660,345.149353 249.284988,344.392365 241.393967,336.219940
	C223.260193,317.439484 204.619247,299.148773 186.026398,280.363098
	C190.021957,277.767609 194.487549,275.888062 198.262344,273.079895
	C205.687622,267.556152 212.715591,261.499573 219.954987,255.722366
	C220.542725,255.253342 221.491409,255.236603 222.638306,255.004211
z"
      />
      <path
        stroke="none"
        d="
M345.938477,188.615997
	C348.323425,182.710510 350.766113,177.103134 353.272522,171.524368
	C356.189087,165.032654 359.265167,158.610107 362.048950,152.062698
	C362.931488,149.986954 363.098785,147.607117 363.911926,145.114746
	C366.657257,147.135452 369.084015,149.407852 371.846039,152.162018
	C374.802307,155.050339 377.423340,157.456894 380.044373,159.863434
	C385.224976,165.196564 390.456970,170.481186 395.559723,175.887787
	C397.477448,177.919739 399.095276,180.234741 401.056061,182.701248
	C401.700684,184.801620 402.139069,186.621429 402.724091,189.140945
	C402.917053,191.223358 402.963348,192.606094 403.009674,193.988815
	C402.935852,194.398026 402.862030,194.807236 402.573730,195.788605
	C402.225128,197.239883 402.091034,198.118973 401.956940,198.998077
	C401.956940,198.998077 401.992889,198.994156 401.982758,198.976715
	C397.871216,205.768448 393.145386,211.757446 384.931885,214.049759
	C377.564453,216.105927 371.233582,213.856155 366.029053,209.125870
	C359.026154,202.761063 352.662415,195.693008 345.938477,188.615997
z"
      />
      <path
        stroke="none"
        d="
M383.743317,289.740753
	C381.824493,289.473877 379.785156,288.901825 377.435547,288.242706
	C370.139008,297.132111 362.809235,306.194611 355.283478,315.091370
	C354.118317,316.468811 352.055237,317.086731 349.745728,317.938599
	C329.941620,298.586975 310.799133,279.352661 292.039917,260.083740
	C293.466370,259.386810 294.775970,258.939423 295.511169,258.028473
	C301.996552,249.992523 308.193787,241.714600 314.911194,233.881195
	C316.829773,231.643860 320.169159,230.624908 322.857025,229.047256
	C331.369080,237.420837 339.919189,245.756165 348.383942,254.177292
	C360.163727,265.896362 371.879578,277.679718 383.743317,289.740753
z"
      />
      <path
        stroke="none"
        d="
M241.344452,399.163879
	C246.395950,403.898132 251.447449,408.632385 256.872711,413.716919
	C269.288544,401.406433 281.423462,389.374481 294.210938,377.174530
	C295.594574,376.062103 296.325684,375.117645 297.056763,374.173218
	C299.226562,371.897400 301.396362,369.621582 304.223938,367.178589
	C306.606567,365.064301 308.331329,363.117188 310.056091,361.170105
	C312.561310,358.564514 315.066559,355.958923 318.232666,353.182129
	C319.949860,351.738281 321.006195,350.465637 322.062500,349.192993
	C324.692474,346.482666 327.322449,343.772339 330.636230,340.978088
	C337.484863,334.960510 343.658478,329.035797 349.783447,323.061249
	C350.037537,322.813385 349.836365,322.098785 349.959839,321.348846
	C349.925812,320.105896 349.779297,319.115753 350.020081,318.090759
	C352.055237,317.086731 354.118317,316.468811 355.283478,315.091370
	C362.809235,306.194611 370.139008,297.132111 377.435547,288.242706
	C379.785156,288.901825 381.824493,289.473877 383.863861,290.045959
	C389.414398,295.496521 395.392334,300.591766 400.340363,306.543243
	C402.924133,309.650970 403.695923,314.265228 405.308655,318.852295
	C405.394592,319.668732 405.458008,319.831940 405.521423,319.995178
	C405.466705,320.433533 405.412018,320.871857 405.260773,322.013062
	C402.709320,327.133911 401.096436,332.386200 397.671417,335.843048
	C355.829681,378.073517 313.737061,420.055603 271.670624,462.063141
	C263.229309,470.492676 250.654541,470.851074 242.108688,462.448273
	C230.127975,450.668213 218.563980,438.464325 206.895416,426.048279
	C207.800308,424.702087 208.449646,423.407745 209.492538,422.852417
	C215.889099,419.446045 222.693207,416.696075 228.733643,412.763062
	C233.912811,409.390808 243.131439,409.169891 241.344452,399.163879
z"
      />
      <path
        stroke="none"
        d="
M318.903687,100.112869
	C320.647827,101.149055 322.084473,102.416618 323.836853,104.168472
	C327.114838,107.395523 330.077087,110.138283 333.039307,112.881035
	C343.331665,123.342018 353.623993,133.803009 363.754883,144.815216
	C363.098785,147.607117 362.931488,149.986954 362.048950,152.062698
	C359.265167,158.610107 356.189087,165.032654 353.272522,171.524368
	C350.766113,177.103134 348.323425,182.710510 345.852570,188.305222
	C343.016754,185.771164 340.038757,183.377655 337.370056,180.678391
	C326.598083,169.783157 315.921509,158.793610 305.049805,147.564621
	C307.263672,140.185822 309.543121,133.047943 312.040405,125.987091
	C314.120392,120.106285 316.727844,114.399017 318.548340,108.445694
	C319.304901,105.971695 318.623596,103.057991 318.903687,100.112869
z"
      />
      <path
        stroke="none"
        d="
M108.046753,322.903473
	C107.296867,313.356323 111.955910,306.470947 119.156174,300.460602
	C127.466270,294.998474 135.417679,296.119843 143.770355,301.196350
	C157.105240,314.438202 169.965378,327.250397 182.776733,340.111237
	C184.386230,341.726959 185.738525,343.598938 187.136154,345.765503
	C186.011459,347.792908 185.335114,350.131012 183.847397,350.900513
	C175.776184,355.075165 167.726486,359.473724 159.221313,362.560730
	C153.851791,364.509644 150.729874,366.334900 152.510010,372.296326
	C141.175064,361.328186 130.258026,350.378754 119.125946,339.652466
	C114.139336,334.847626 109.940598,329.678925 108.046753,322.903473
z"
      />
      <path
        stroke="none"
        d="
M246.114090,48.235191
	C250.116730,47.505154 254.119370,46.775108 258.562622,46.552650
	C258.550140,49.965054 258.266113,52.908737 257.609253,55.766720
	C256.178375,61.992039 254.669952,68.204460 252.983215,74.364647
	C251.258972,80.661690 249.510193,86.969254 247.355499,93.126404
	C245.140610,99.455627 242.343887,105.579742 240.020737,111.874428
	C239.721008,112.686539 240.732971,113.982742 240.972412,115.352661
	C233.321335,123.185944 225.851913,130.737961 218.345703,138.253265
	C200.851944,155.768173 183.321899,173.246887 165.872864,190.806168
	C164.528809,192.158691 161.148987,193.575958 165.056061,196.338593
	C166.451508,197.800385 167.691986,198.980728 169.038818,200.418991
	C169.707977,201.144714 170.270737,201.612534 170.875732,202.310867
	C170.917984,202.541367 170.977112,203.006287 170.625488,203.040863
	C168.968414,203.772049 167.566391,204.333710 166.373322,205.187363
	C159.155426,210.351868 151.844620,215.403366 144.860168,220.869919
	C141.510468,223.491623 136.290787,224.551315 135.637970,229.974701
	C131.005127,225.528076 126.668449,221.239166 122.334427,216.947556
	C116.617050,211.286133 110.422340,205.978394 108.111496,197.390305
	C108.027161,195.660858 107.945076,194.327942 107.954857,192.618454
	C109.064728,189.173523 110.082733,186.105133 111.364319,182.838409
	C112.787125,181.227692 113.835587,179.704453 115.121948,178.419373
	C140.670349,152.896545 166.216400,127.371239 191.826721,101.910591
	C209.873383,83.969383 228.014908,66.123589 246.114090,48.235191
z"
      />
    </Svg>
  )
}
