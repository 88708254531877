import { put, call, select } from 'redux-saga/effects'
import { error403, pageIsLoading } from 'domain/loading'
import { transformCandidate } from 'domain/recruiter/helpers'
import { candidateJobNotesSelector } from 'domain/superManager/selectors'
import { NoteType } from 'types/common'
import Api from 'domain/api'
import I from 'immutable'
import * as Actions from './actions'
import * as M from 'domain/env'
import * as Router from 'domain/router'

export function* ensureGetCandidateProfile(props?: { payload?: boolean; type?: string } | null) {
  yield put({ type: error403, payload: false })
  const headers = yield select(M.userToken)
  const {
    params: { candidateId: id }
  } = yield select(Router.matchRoutes)

  if (!headers || !id) return

  try {
    if (!props || !props.payload) yield put({ type: pageIsLoading, payload: true })
    const {
      data: { data }
    } = yield call(Api.getCandidateProfileSM, {
      headers: { Authorization: `Bearer ${headers}` },
      id
    })
    yield put({
      type: Actions.candidateProfile.success,
      payload: transformCandidate(I.fromJS(data))
    })
  } catch (err) {
    yield put({
      type: Actions.candidateProfile.success,
      payload: I.fromJS({})
    })
    const { statusCode } = err.response.data
    if (statusCode === 403 || statusCode === 404) {
      yield put({ type: error403, payload: true })
    }
    yield put({
      type: Actions.candidateProfile.failure,
      err
    })
  } finally {
    yield put({ type: pageIsLoading, payload: false })
  }
}

export function* ensureGetCandidateJobNotes() {
  yield put({ type: pageIsLoading, payload: true })
  const headers = yield select(M.userToken)

  if (!headers) return

  const user = yield select(M.userSelector)
  const userRole = user.get('role')

  const {
    params: { candidateId, id, jobsId }
  } = yield select(Router.matchRoutes)

  const rightCandidateId = userRole === 'recruiter' ? id : candidateId
  const rightJobId = userRole === 'recruiter' ? jobsId : id

  try {
    const { data } = yield call(Api.getSMCandidateJobNotes, {
      headers: { Authorization: `Bearer ${headers}` },
      candidateId: rightCandidateId,
      jobId: rightJobId
    })

    yield put({ type: Actions.getCandidateJobNotes.success, payload: I.fromJS(data.data.notes) })
  } catch (err) {
    yield put({
      type: Actions.getCandidateJobNotes.failure,
      err
    })
  } finally {
    yield put({ type: pageIsLoading, payload: false })
  }
}

export function* ensurePostCandidateJobNotes(props: { payload: { note: string }; type: string }) {
  yield put({ type: pageIsLoading, payload: true })
  const headers = yield select(M.userToken)

  if (!headers) return

  const user = yield select(M.userSelector)
  const candidateJobNotes = yield select(candidateJobNotesSelector)
  const userRole = user.get('role')

  const {
    params: { id, candidateId, jobsId }
  } = yield select(Router.matchRoutes)

  const rightCandidateId = userRole === 'recruiter' ? id : candidateId
  const rightJobId = userRole === 'recruiter' ? jobsId : id

  try {
    const { data } = yield call(Api.postSMCandidateJobNotes, {
      headers: { Authorization: `Bearer ${headers}` },
      candidateId: rightCandidateId,
      jobId: rightJobId,
      data: props.payload
    })
    const newNotes = [{ ...data.data, createdBy: user.toJS() }, ...candidateJobNotes.toJS()]
    yield put({ type: Actions.getCandidateJobNotes.success, payload: I.fromJS(newNotes) })
  } catch (err) {
    yield put({
      type: Actions.addCandidateJobNote.failure,
      err
    })
  } finally {
    yield put({ type: pageIsLoading, payload: false })
  }
}

export function* ensureDeleteCandidateJobNote(props: {
  payload: { noteId: number }
  type: string
}) {
  yield put({ type: pageIsLoading, payload: true })
  const headers = yield select(M.userToken)

  if (!headers) return

  const user = yield select(M.userSelector)
  const userRole = user.get('role')

  const {
    params: { id, candidateId, jobsId }
  } = yield select(Router.matchRoutes)

  const rightCandidateId = userRole === 'recruiter' ? id : candidateId
  const rightJobId = userRole === 'recruiter' ? jobsId : id

  try {
    yield call(Api.deleteSMCandidateJobNote, {
      headers: { Authorization: `Bearer ${headers}` },
      candidateId: rightCandidateId,
      jobId: rightJobId,
      noteId: props.payload.noteId
    })

    const candidateJobNotes = yield select(candidateJobNotesSelector)
    const newCandidateJobNotes = candidateJobNotes
      .toJS()
      .filter((note: NoteType) => note.id !== props.payload.noteId)

    yield put({
      type: Actions.getCandidateJobNotes.success,
      payload: I.fromJS(newCandidateJobNotes)
    })
  } catch (err) {
    yield put({
      type: Actions.deleteCandidateJobNote.failure,
      err
    })
  } finally {
    yield put({ type: pageIsLoading, payload: false })
  }
}

export function* ensureEditCandidateJobNote(props: {
  payload: { noteId: number; noteText: string }
  type: string
}) {
  yield put({ type: pageIsLoading, payload: true })
  const headers = yield select(M.userToken)

  if (!headers) return
  const user = yield select(M.userSelector)
  const userRole = user.get('role')

  const {
    params: { id, candidateId, jobsId }
  } = yield select(Router.matchRoutes)

  const rightCandidateId = userRole === 'recruiter' ? id : candidateId
  const rightJobId = userRole === 'recruiter' ? jobsId : id

  try {
    const { data } = yield call(Api.editSMCandidateJobNote, {
      headers: { Authorization: `Bearer ${headers}` },
      candidateId: rightCandidateId,
      jobId: rightJobId,
      noteId: props.payload.noteId,
      data: { note: props.payload.noteText }
    })

    const candidateJobNotes = yield select(candidateJobNotesSelector)
    const newCandidateJobNotes = candidateJobNotes
      .toJS()
      .map((item: NoteType) => (item.id === props.payload.noteId ? data.data : item))

    yield put({
      type: Actions.getCandidateJobNotes.success,
      payload: I.fromJS(newCandidateJobNotes)
    })
  } catch (err) {
    yield put({
      type: Actions.editCandidateJobNote.failure,
      err
    })
  } finally {
    yield put({ type: pageIsLoading, payload: false })
  }
}
