import { all, call, take, takeLatest, fork, select } from 'redux-saga/effects'
import * as RR from 'domain/recruiter/parsedResumes'
import { query } from 'domain/router'

export function* resumesPage() {
  yield all([fork(getParsedResumesList)])
}

function* getParsedResumesList() {
  const { status } = yield select(query)
  const props = status
    ? { payload: { status, page: 1 }, type: RR.parsedResumesList.request }
    : undefined
  yield call(RR.ensureGetParsedResumes, props)
  yield takeLatest(RR.parsedResumesList.type, RR.ensureGetParsedResumes)
  yield take(RR.parsedResumesList.success)
}
