import I from 'immutable'
import * as A from './actions'
import { Action } from 'redux'

interface ActionType extends Action {
  payload: any
}

const State = I.fromJS({
  countries: I.List(),
  cities: I.List(),
  phoneCodes: I.List()
})

export const reducer = {
  location(state = State, action: ActionType) {
    switch (action.type) {
      case A.countries.success: {
        return state
          .set('countries', action.payload.countries)
          .set('phoneCodes', action.payload.phoneCodes)
      }
      case A.cities.success: {
        return state.set('cities', action.payload)
      }
      case 'profile/CLEAR_CITIES': {
        return state.set('cities', I.List())
      }

      default:
        return state
    }
  }
}
