import { put, call, select } from 'redux-saga/effects'
import { SocialLinksType } from 'types/common'
import { transformUser } from 'domain/env/helpers'
import I from 'immutable'
import Api from 'domain/api'
import * as Actions from './actions'
import * as M from 'domain/env'

export interface PropsSocialLinks {
  payload: SocialLinksType
  type: string
}

export function* ensureAddSocialLinks({ payload }: PropsSocialLinks) {
  const headers = yield select(M.userToken)
  if (!headers) return
  try {
    const {
      data: { data }
    } = yield call(Api.socialLinks, {
      headers: { Authorization: `Bearer ${headers}` },
      data: payload
    })
    yield put({ type: Actions.addSocialLinks.success, payload: transformUser(I.fromJS(data)) })
  } catch (err) {
    yield put({
      type: Actions.addSocialLinks.failure,
      err
    })
  }
}
