import { all, takeLatest, take, fork, put } from 'redux-saga/effects'
import * as A from 'domain/candidate/candidates'
import I from 'immutable'

export function* inviteCandidates() {
  yield all([fork(invite)])
}

function* invite() {
  yield put({ type: A.inviteCandidates.SUCCESS, payload: I.List() })
  yield takeLatest(A.inviteCandidates.TRIGGER, A.ensureInviteCandidates)
  while (true) {
    yield take(A.inviteCandidates.SUCCESS)
  }
}
