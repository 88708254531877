import { Styles } from 'react-jss'

export const sheet: Styles = {
  radio: {},
  icon: {},
  iconSize: {},
  root: {
    width: 'auto',
    minWidth: '30%',
    '& > $radio': {
      display: 'none',
      '& + label': {
        position: 'relative',
        cursor: 'pointer',
        width: 'fit-content',
        height: 'fit-content',
        padding: 0,
        '& > $iconSize': {
          width: 18,
          height: 18,
          marginRight: 10,
          '& > $icon': {
            transform: 'scale(0.1)',
            transformOrigin: 'top left',
            display: 'block',
            width: 160,
            height: 160,
            background: 'inherit',
            border: '20px solid #E6E6E6',
            borderRadius: '50%',
            position: 'relative'
          }
        }
      },
      '&:checked, &:not(:checked)': {
        position: 'absolute',
        left: '-9999px'
      },
      '&:checked + label, &:not(:checked) + label': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        position: 'relative',
        cursor: 'pointer',
        fontWeight: 'normal',
        lineHeight: '20px',
        fontSize: 15,
        letterSpacing: '0.01em',
        color: '#080910',
        whiteSpace: 'nowrap'
      },
      '&:checked + label > $iconSize > $icon:before, &:not(:checked) + label > $iconSize > $icon:before': {
        content: '""',
        transition: 'all 0.2s ease',
        display: 'block',
        position: 'absolute',
        borderRadius: '50%',
        backgroundColor: '#286EFA',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        transform: 'scale(0.8)'
      },
      '&:not(:checked) + label > $iconSize > $icon:before': {
        opacity: 0
      },
      '&:checked + label > $iconSize > $icon:before': {
        opacity: 1
      },
      '&:checked + label > $iconSize > $icon': {
        border: '20px solid #286EFA'
      }
    }
  }
}
