import { put, call, select } from 'redux-saga/effects'
import { transformUser } from 'domain/env/helpers'
import I from 'immutable'
import Api from 'domain/api'
import * as Actions from './actions'
import * as M from 'domain/env'
import * as AM from 'domain/messages'
import { pageIsLoading } from '../loading'

interface PropsProfilePayload {
  firstName: string
  lastName: string
  email: string
  country?: {
    value: string
    label: string
  }
  city?: {
    value: string
    label: string
    country?: {
      value: string
      label: string
    }
  }
  phonePrefix: {
    value: string
    label: string
  }
  phone: string
  birthday?: string
  notificationSettings: {
    expiresIn?: {
      value: number | string
      unit?: string
    }
  }
}

export interface PropsProfile {
  payload: PropsProfilePayload
  type: string
}

interface DataForSend {
  firstName: string
  lastName: string
  countryId?: string
  cityId?: string
  phonePrefix?: string
  phone: string
  birthday?: string
  notificationSettings: {
    expiresIn?: {
      value: number | string
      unit?: string
    }
  }
}
export function* ensureUpdateProfile({ payload }: PropsProfile) {
  const headers = yield select(M.userToken)
  if (!headers) return

  const countryId = payload.city
    ? payload.city.country
      ? payload.city.country.value
      : undefined
    : undefined

  const dataForSend: DataForSend = {
    firstName: payload.firstName,
    lastName: payload.lastName,
    countryId,
    cityId: payload.city ? payload.city.value : undefined,
    phonePrefix: payload.phonePrefix ? payload.phonePrefix.label : undefined,
    phone: payload.phone.split(' ').join(''),
    notificationSettings: {
      expiresIn: payload.notificationSettings.expiresIn
        ? { value: payload.notificationSettings.expiresIn.value }
        : undefined
    }
  }
  if (payload.birthday) {
    dataForSend.birthday = payload.birthday
  }
  try {
    yield put({ type: pageIsLoading, payload: true })
    const {
      data: { data }
    } = yield call(Api.profile, {
      headers: { Authorization: `Bearer ${headers}` },
      data: dataForSend
    })
    yield put({ type: Actions.updateProfile.SUCCESS, payload: transformUser(I.fromJS(data)) })

    // Update SendBird User if current user has accessToken
    const { id, role, sbAccessToken } = data
    if (sbAccessToken) {
      yield call(AM.updateSendBirdUser, id, role)
    }
  } catch (err) {
    yield put({
      type: Actions.updateProfile.FAILURE,
      err
    })
  } finally {
    yield put({ type: pageIsLoading, payload: false })
  }
}

export function* ensureChangePassword({ payload }: any) {
  const headers = yield select(M.userToken)
  if (!headers) return
  try {
    yield put({ type: pageIsLoading, payload: true })
    const {
      data: { data }
    } = yield call(Api.changePasswordProfile, {
      headers: { Authorization: `Bearer ${headers}` },
      data: payload
    })
    yield put({ type: Actions.changePassword.SUCCESS })
  } catch (err) {
    yield put({
      type: Actions.changePassword.FAILURE,
      err
    })
  } finally {
    yield put({ type: pageIsLoading, payload: false })
  }
}
