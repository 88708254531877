export const SESSION_STORAGE = 'sessionStorage'
export const LOCAL_STORAGE = 'localStorage'

export function getStorageType() {
  const storage = window.localStorage && window.localStorage.getItem('storage')
  if (storage === LOCAL_STORAGE) return storage
  return SESSION_STORAGE
}

export function setStorageType(type: string) {
  if (type !== LOCAL_STORAGE && type !== SESSION_STORAGE) return false
  return window.localStorage.setItem('storage', type)
}

export function getStorage() {
  return window[getStorageType()]
}

export function setStorageItem(key: string, value: string) {
  if (arguments.length !== 2) return false
  return getStorage().setItem(key, value)
}

export function getStorageItem(key: string, def = '') {
  const storage = getStorage()
  return (storage && storage.getItem(key)) || def
}

export function clearStorage() {
  window.localStorage.clear()
  window.sessionStorage.clear()
}
