import { all, takeLatest, take, fork, call, put } from 'redux-saga/effects'
import I from 'immutable'
import * as A from 'domain/superManager/candidates'
import * as J from 'domain/jobs'
import * as S from 'domain/candidate/status'

export function* profilePage() {
  yield all([
    fork(getProfile),
    fork(getNote),
    fork(addNote),
    fork(deleteNote),
    fork(editNote),
    fork(getStatus)
  ])
}

function* getProfile() {
  yield put({
    type: A.candidateProfile.success,
    payload: I.Map()
  })
  yield call(A.ensureGetCandidateProfile)
  yield takeLatest(A.candidateProfile.type, A.ensureGetCandidateProfile)
  yield take(A.candidateProfile.success)
}

function* getNote() {
  yield takeLatest(A.getCandidateJobNotes.type, A.ensureGetCandidateJobNotes)
  yield take(A.getCandidateJobNotes.success)
}

function* addNote() {
  yield takeLatest(A.addCandidateJobNote.type, A.ensurePostCandidateJobNotes)
  yield take(A.addCandidateJobNote.success)
}

function* deleteNote() {
  yield takeLatest(A.deleteCandidateJobNote.type, A.ensureDeleteCandidateJobNote)
  yield take(A.deleteCandidateJobNote.success)
}

function* editNote() {
  yield takeLatest(A.editCandidateJobNote.type, A.ensureEditCandidateJobNote)
  yield take(A.editCandidateJobNote.success)
}

function* getStatus() {
  yield takeLatest(S.candidateStatus.type, S.ensureGetCandidateStatus)
  yield take(S.candidateStatus.success)
}
