import { asyncAction } from 'lib/actionCreators'
import { createRoutine, promisifyRoutine } from 'redux-saga-routines'

export const managerCollaborationRequests = asyncAction('manager/MANAGER_COLLABORATION_REQUESTS')
export const rejectManagerCollaborationRequest = createRoutine(
  'manager/REJECT_MANAGER_COLLABORATION_REQUEST'
)
export const rejectManagerCollaborationRequestPromiseCreator = promisifyRoutine(
  rejectManagerCollaborationRequest
)
export const acceptManagerCollaborationRequest = createRoutine(
  'manager/ACCEPT_MANAGER_COLLABORATION_REQUEST'
)
export const acceptManagerCollaborationRequestPromiseCreator = promisifyRoutine(
  acceptManagerCollaborationRequest
)
