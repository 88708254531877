import { Styles } from 'react-jss'

export const sheet: Styles = {
  root: {
    height: '100%',
    maxHeight: 'calc(100vh - 130px)'
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& > p': {
      marginTop: 0,
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: 17,
      lineHeight: '21px',
      letterSpacing: '0.01em',
      color: '#080910'
    },
    '& > button': {
      textTransform: 'uppercase',
      marginBottom: '1em'
    }
  },
  filter: {
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid #E6E6E6',
    boxSizing: 'border-box',
    borderRadius: '2px',
    overflow: 'auto',
    height: 'calc(100vh - 130px)',
    padding: '5% 3%',
    '& > div': {
      marginTop: 0
    },
    '& p': {
      display: 'inline-block',
      fontWeight: 700,
      letterSpacing: '0.01em',
      fontSize: 15,
      color: '#080910',
      margin: 0
    }
  },
  rowRate: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    margin: '0.5em 0 1em 0',
    '& > div': {
      margin: '0 5px 0 0',
      width: 'auto',
      '& input': {
        width: 80
      }
    },
    '& > div:last-child': {
      width: 80,
      margin: 0
    }
  },
  rate: {
    '& > p': {
      margin: 0
    }
  },
  rowRadio: {
    display: 'flex',
    flexDirection: 'row',
    margin: '10px 0 1em 0',
    '& > div:first-child': {
      marginRight: 10
    }
  },
  rowCheck: {
    display: 'flex',
    flexDirection: 'column',
    margin: '10px 0 1em 0',
    '& > div:first-child': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      '& > div': {
        marginRight: 10,
        marginBottom: '1em'
      },
      '& > div:last-child': {
        marginRight: 0
      }
    }
  },
  separator: {
    margin: 0,
    lineHeight: '40px'
  },
  error: {
    fontWeight: 400,
    letterSpacing: '0.01em',
    fontSize: 12,
    color: '#dc233b',
    margin: '0 0 0 2px'
  }
}
