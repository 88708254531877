import { put, call, select } from 'redux-saga/effects'
import I from 'immutable'
import Api from 'domain/api'
import * as Actions from './actions'
import * as M from 'domain/env'
import * as Router from 'domain/router'
import { error403, pageIsLoading, skeleton } from 'domain/loading'
import { jobsSelector } from 'domain/recruiter/selectors'
import { Sort } from 'types/common'

interface Params {
  page?: number
  order?: Sort
  order_by?: string | null
  status?: string
  search?: string
}

export function* ensureGetJobs(props?: { payload?: Params; type: string }) {
  const headers = yield select(M.userToken)
  if (!headers) return
  if (!props || !props.payload || props.payload.page === 1) {
    yield put({ type: skeleton, payload: true })
    yield put({ type: pageIsLoading, payload: true })
  }
  const params = (props && props.payload) || { status: 'new' }
  try {
    const { data } = yield call(Api.getJobsRecruiter, {
      headers: { Authorization: `Bearer ${headers}` },
      params
    })
    const page = params.page
    const total = data.meta.total
    const prevState = yield select(jobsSelector)
    if (!params || !page || page === 1 || total === prevState.size) {
      yield put({ type: Actions.newJobsList.success, payload: I.fromJS(data) })
    } else {
      yield put({ type: Actions.jobsList.success, payload: I.fromJS(data) })
    }
  } catch (err) {
    yield put({
      type: Actions.jobsList.failure,
      err
    })
  } finally {
    yield put({ type: skeleton, payload: false })
    yield put({ type: pageIsLoading, payload: false })
  }
}

export function* ensureGetJob() {
  yield put({ type: Actions.jobData.success, payload: I.fromJS({}) })
  yield put({ type: error403, payload: false })
  const headers = yield select(M.userToken)
  if (!headers) return
  const {
    params: { id }
  } = yield select(Router.matchRoutes)
  try {
    const {
      data: { data }
    } = yield call(Api.getJobRecruiter, {
      headers: { Authorization: `Bearer ${headers}` },
      id
    })
    yield put({ type: Actions.jobData.success, payload: I.fromJS(data) })
  } catch (err) {
    const { statusCode } = err.response.data
    if (statusCode === 403 || statusCode === 404) {
      yield put({ type: error403, payload: true })
    }
    yield put({
      type: Actions.jobData.failure,
      err
    })
  }
}

export function* ensureGetJobsCount() {
  const headers = yield select(M.userToken)
  if (!headers) return
  try {
    const {
      data: { data }
    } = yield call(Api.getJobsRecruiterCount, {
      headers: { Authorization: `Bearer ${headers}` }
    })
    yield put({ type: Actions.getJobsCount.success, payload: data })
  } catch (err) {
    yield put({
      type: Actions.getJobsCount.failure,
      err
    })
  }
}

export function* ensureGetJobById({ jobId }: { jobId: any }) {
  if (!jobId || jobId <= 0) {
    yield put({ type: Actions.jobData.success, payload: I.fromJS({}) })
    yield put({ type: error403, payload: false })
    return
  }
  const headers = yield select(M.userToken)
  if (!headers) return
  try {
    const {
      data: { data }
    } = yield call(Api.getJobRecruiter, {
      headers: { Authorization: `Bearer ${headers}` },
      id: jobId
    })
    yield put({ type: Actions.jobData.success, payload: I.fromJS(data) })
  } catch (err) {
    const { statusCode } = err.response.data
    if (statusCode === 403 || statusCode === 404) {
      yield put({ type: error403, payload: true })
    }
    yield put({
      type: Actions.jobData.failure,
      err
    })
  }
}
