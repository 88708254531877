import { Styles } from 'react-jss'

export const sheet: Styles = {
  title: {},
  root: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    height: 'auto',
    right: 0,
    top: 52,
    backgroundColor: '#FCFCFC',
    overflow: 'scroll',
    boxShadow: '0px 2px 9px rgba(0, 0, 0, 0.15), 0px 1px 3px rgba(0, 0, 0, 0.08)',
    padding: '12px 0 0',
    zIndex: 999,
    '& > $title': {
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '12px',
      lineHeight: '14px',
      letterSpacing: '0.03em',
      textTransform: 'uppercase',
      color: '#989FA6',
      margin: '0 12px 12px',
      '& > span': {
        fontSize: 13,
        lineHeight: '18px',
        textTransform: 'capitalize',
        color: '#286EFA',
        marginLeft: 8
      }
    },
    '& > div': {
      borderTop: '1px solid #E6E6E6',
      '& > div': {
        margin: 0,
        border: 'none'
      }
    },
    '& > a': {
      borderTop: '1px solid #E6E6E6',
      padding: '10px 0',
      background: '#FAFAFA'
    }
  }
}
