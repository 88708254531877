import { all, takeLatest, take, fork, call, put } from 'redux-saga/effects'
import { pageIsLoading } from 'domain/loading'
import * as D from 'domain/manager/dashboard'

export function* dashboard() {
  yield all([
    fork(jobsCounters),
    fork(clientsCounters),
    fork(clientClosureRate),
    fork(statistics),
    fork(topRecruiters),
    fork(closedJobs),
    fork(initialLoad)
  ])
}

function* jobsCounters() {
  yield takeLatest(D.managerJobsCounters.type, D.ensureJobsCounters)
  yield take(D.managerJobsCounters.success)
}

function* clientsCounters() {
  yield takeLatest(D.managerClientsCounters.type, D.ensureClientsCounters)
  yield take(D.managerClientsCounters.success)
}

function* clientClosureRate() {
  yield takeLatest(D.managerClientClosureRate.type, D.ensureClientClosureRate)
  yield take(D.managerClientClosureRate.success)
}

function* statistics() {
  yield takeLatest(D.managerStatistics.type, D.ensureStatistics)
  yield take(D.managerStatistics.success)
}

function* topRecruiters() {
  yield takeLatest(D.managerTopRecruiters.type, D.ensureTopRecruiters)
  yield take(D.managerTopRecruiters.success)
}

function* closedJobs() {
  yield takeLatest(D.managerClosedJobs.type, D.ensureClosedJobs)
  yield take(D.managerClosedJobs.success)
}

function* initialLoad() {
  yield put({ type: pageIsLoading, payload: true })
  try {
    yield all([
      call(D.ensureClosedJobs),
      call(D.ensureTopRecruiters),
      call(D.ensureStatistics),
      call(D.ensureClientClosureRate),
      call(D.ensureClientsCounters),
      call(D.ensureJobsCounters)
    ])
  } finally {
    yield put({ type: pageIsLoading, payload: false })
  }
}
