import { MetaT } from '../types/common'

const DEFAULT_PER_PAGE = 25

/**
 * @description Calculates if there are some additional pages from meta data
 */
export const hasMorePages = (meta?: MetaT, nextPage?: number): boolean => {
  if (!meta) {
    return false
  }
  const total = meta.get('total')
  if (!meta.get('total') || meta.get('total') <= 0) {
    return false
  }

  let perPage = meta.get('perPage')
  if (!perPage || perPage <= 0) {
    perPage = DEFAULT_PER_PAGE
  }

  const metaPage = meta.get('page') || 1
  const currentPage = nextPage ? nextPage - 1 : metaPage
  const totalPages = Math.ceil(total / perPage)

  return currentPage < totalPages
}

export const getNextPage = (meta?: MetaT): number => {
  if (!meta) {
    return 1
  }
  const currentPage = meta.get('page') || 0

  return currentPage + 1
}
