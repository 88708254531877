import moment from 'moment'

export interface DateType {
  id: number
  dateEnd: Date
  dateStart: Date
  dates: Array<{
    dateEnd: Date
    dateStart: Date
    timeEnd: Date
    timeStart: Date
  }>
  timeEnd: Date
  timeStart: Date
  title: string
  comment?: string
}

export const prepareDataEvent = (payload: DateType) => {
  const { title, dateStart, dateEnd, timeStart, timeEnd, comment } = payload
  const start = new Date(dateStart.setHours(timeStart.getHours(), timeStart.getMinutes(), 0, 0))
  const end = new Date(dateEnd.setHours(timeEnd.getHours(), timeEnd.getMinutes(), 0, 0))

  return {
    title,
    start,
    end,
    comment: comment || undefined
  }
}

interface DataForEventList {
  start: string
  rendering?: boolean
  id: number
}

export const prepareEventsList = (data: DataForEventList[]) => {
  const dateOfToday = moment()
  let nearestEvent: DataForEventList | undefined
  data.forEach(item => {
    if (
      !item.rendering &&
      !moment(item.start).isBefore(dateOfToday) &&
      (!nearestEvent || moment(item.start).isBefore(moment(nearestEvent.start)))
    ) {
      nearestEvent = item
    }
  })
  return nearestEvent && nearestEvent.id
}
