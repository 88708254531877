import React from 'react'
import Svg, { SvgType } from 'components/svgComponent/Svg'

export default function(props: SvgType) {
  return (
    <Svg width="22" height="22" viewBox="0 0 22 22" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.1479 6.47328C16.1479 6.94606 16.0728 7.40168 15.9303 7.82966C15.3327 9.63689 13.5756 10.9475 11.502 10.9475C8.9376 10.9475 6.85997 8.94393 6.85997 6.47328C6.85997 4.00264 8.9376 2 11.502 2C14.0664 2 16.1479 4.00264 16.1479 6.47328ZM14.288 6.47328C14.288 7.95643 13.0414 9.15744 11.502 9.15744C10.5878 9.15744 9.77656 8.73137 9.26606 8.07368C9.12359 7.88876 9.00487 7.68573 8.91385 7.46936C8.78721 7.16148 8.71598 6.82501 8.71598 6.47328C8.71598 5.74219 9.0207 5.07973 9.51142 4.59551C10.018 4.09795 10.7224 3.78912 11.502 3.78912C13.0414 3.78912 14.288 4.99108 14.288 6.47328Z"
        fill="#080910"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.288 11.8426H8.71598C6.6621 11.8426 5 13.4449 5 15.4218C5 17.3977 6.6621 19 8.71598 19H14.288C16.3379 19 18 17.3977 18 15.4218C18 13.4449 16.3379 11.8426 14.288 11.8426ZM6.85601 15.4218C6.85601 14.4333 7.68706 13.6317 8.71598 13.6317H14.288C15.3129 13.6317 16.144 14.4333 16.144 15.4218C16.144 16.4093 15.3129 17.2109 14.288 17.2109H8.71598C7.68706 17.2109 6.85601 16.4093 6.85601 15.4218Z"
        fill="#080910"
      />
    </Svg>
  )
}
