import { selector } from 'lib/selectors'
import { MessagesType, StateInterface } from 'types/state'

const messages = (state: StateInterface) => state.messages

export const userSelector = selector(messages, (e: MessagesType) => e.get('user'))
export const isConnectedSelector = selector(messages, (e: MessagesType) => e.get('isConnected'))
export const isListeningSelector = selector(messages, (e: MessagesType) => e.get('isListening'))
export const groupChannelsExistSelector = selector(messages, (e: MessagesType) =>
  e.get('groupChannelsExist')
)

export const channelsSelector = selector(messages, (e: MessagesType) => e.get('channels'))
export const unreadChannelsCountSelector = selector(messages, (e: MessagesType) =>
  e.get('unreadChannelsCount')
)

export const activeChannelUrlSelector = selector(messages, (e: MessagesType) =>
  e.get('activeChannelUrl')
)
export const unreadMessagesCountSelector = selector(messages, (e: MessagesType) =>
  e.get('unreadMessagesCount')
)
