import { Action } from 'redux'
import { JobsListCandidate } from 'types/job/jobTypesCandidate'
import I from 'immutable'
import * as Guest from 'domain/guestJobs'

interface ActionType extends Action {
  payload: any
}

const State = I.fromJS({
  jobsList: I.List()
})

export const reducer = {
  guest(state = State, action: ActionType) {
    switch (action.type) {
      case Guest.guestNewJobsList.success:
        return state
          .set('jobsList', action.payload.get('data'))
          .set('jobsListMeta', action.payload.get('meta'))
      case Guest.guestJobsList.success:
        return state
          .update('jobsList', (u: JobsListCandidate) => u.concat(action.payload.get('data')))
          .set('jobsListMeta', action.payload.get('meta'))

      default:
        return state
    }
  }
}
