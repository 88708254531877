import { eventChannel, Channel } from 'redux-saga'
import { call, take, fork, select, put, cancelled } from 'redux-saga/effects'
import { notificationTypeSelector, NotificationTypeT } from 'domain/constants'
import * as M from 'domain/env'
import * as Actions from 'domain/notifications'
import io from 'socket.io-client'
import I from 'immutable'

const API_URL = process.env.REACT_APP_API_SOCKET || ''
const SOCKET_PATH = process.env.REACT_APP_API_SOCKET_PATH || ''

function createWebSocketConnection(token: string) {
  return new Promise(resolve => {
    const socket = io(API_URL, {
      transports: ['websocket'],
      path: SOCKET_PATH,
      query: { token },
      reconnection: true
    })

    socket.on('connect', () => {
      resolve(socket)
    })

    socket.on('connect_error', (e: any) => {
      console.log('connect_error', e)
    })

    socket.on('connect_timeout', (e: any) => {
      console.log('connect_timeout', e)
    })

    socket.on('reconnect', (attemptNumber: number) => {
      console.log('reconnect', attemptNumber)
    })

    socket.on('reconnecting', (attemptNumber: number) => {
      console.log('reconnecting', attemptNumber)
    })

    socket.on('disconnect', (reason: any) => {
      console.log('disconnect', reason)
    })

    socket.on('error', (error: any) => {
      console.log('error', error)
    })
  })
}

function createSocketChannel(socket: any, notificationType: NotificationTypeT) {
  return eventChannel(emit => {
    function unsubscribe() {
      socket.close()
    }

    if (notificationType && !notificationType.isEmpty()) {
      notificationType.forEach(item => {
        socket.on(item, (data: any) => {
          emit(data)
        })
      })
    }

    return unsubscribe
  })
}

function* listenForSocketMessages(token: string) {
  let socket: Promise<any>
  let socketChannel: Channel<any> | undefined

  try {
    const notificationType = yield select(notificationTypeSelector)
    socket = yield call(createWebSocketConnection, token)
    socketChannel = yield call(createSocketChannel, socket, notificationType)
    // tell the application that we have a connection
    while (true) {
      // wait for a message from the channel
      const payload = socketChannel ? yield take(socketChannel) : null
      if (payload.type === notificationType.get('SUPER_MANAGER_ROLE_REQUEST_APPROVED') && window) {
        window.location.reload()
      }
      if (
        payload &&
        (!payload.type || payload.type === notificationType.get('GROUP_UNREAD_NOTIFICATIONS'))
      ) {
        yield put({ type: Actions.unreadNotifications.success, payload: payload.count })
      } else {
        yield put({ type: Actions.socketNotification.success, payload: I.fromJS(payload) })
      }
    }
  } catch (error) {
    console.log('Error while connecting to the WebSocket', error)
  } finally {
    if (yield cancelled()) console.log('listenForSocketMessages saga cancelled')
    console.log('WebSocket disconnected')
    if (socketChannel) socketChannel.close()
  }
}

export function* connect() {
  const token = yield select(M.userToken)
  if (token) {
    yield fork(listenForSocketMessages, token)
  }
}
