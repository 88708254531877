import { Styles } from 'react-jss'

export const sheet: Styles = {
  title: {},
  text: {},
  root: {},
  main: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: 343,
    minHeight: 110,
    background: '#FAFAFA',
    border: '1px solid #286EFA',
    boxSizing: 'border-box',
    padding: '10px 12px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 12,
    lineHeight: '16px',
    letterSpacing: '0.01em',
    color: '#565656',
    '& $text a': {
      whiteSpace: 'inherit',
      wordBreak: 'break-all'
    },
    '& > svg': {
      flex: 'none'
    },
    '& > $root': {
      marginLeft: 12,
      flex: 1,
      overflow: 'hidden'
    },
    '& p': {
      margin: 0
    }
  },
  unread: {
    background: '#FEFAF1'
  }
}
