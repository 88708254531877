import { all, takeLatest, take, fork, call, put } from 'redux-saga/effects'
import * as A from 'domain/profile'
import * as L from 'domain/locations'
import * as SocialLinks from 'domain/socialLinks/actions'
import * as Avatar from 'domain/avatar/actions'
import * as M from 'domain/superManager/managers'
import { ensureAddSocialLinks } from 'domain/socialLinks/sagas'
import { ensureAddAvatar } from 'domain/avatar/sagas'
import { pageIsLoading } from 'domain/loading'

export function* profilePage() {
  yield all([
    fork(addAvatar),
    fork(addSocialLinks),
    fork(updateProfile),
    fork(initialLoad),
    fork(changePassword),
    fork(reloadCollaborationRequests),
    fork(declineCollaborationRequest),
    fork(sendCollaborationRequest),
    fork(searchManagers),
    fork(resendCollaborationRequest)
  ])
}

function* resendCollaborationRequest() {
  yield takeLatest(M.collaborationRequestResend.type, M.ensureResendCollaborationRequest)
}

function* searchManagers() {
  yield takeLatest(M.managersSearch.type, M.ensureSearchManagers)
}

function* sendCollaborationRequest() {
  yield takeLatest(M.sendCollaborationRequest.TRIGGER, M.ensureSendCollaborationRequest)
}

function* declineCollaborationRequest() {
  yield takeLatest(M.declineCollaborationRequest.TRIGGER, M.ensureDeclineCollaborationRequest)
}

function* reloadCollaborationRequests() {
  yield takeLatest(M.sendCollaborationRequest.SUCCESS, M.ensureGetCollaborationRequests)
}

function* changePassword() {
  yield takeLatest(A.changePassword.TRIGGER, A.ensureChangePassword)
}

function* addAvatar() {
  yield takeLatest(Avatar.addAvatar.type, ensureAddAvatar)
  yield take(Avatar.addAvatar.success)
}

function* updateProfile() {
  yield takeLatest(A.updateProfile.TRIGGER, A.ensureUpdateProfile)
  yield take(A.updateProfile.SUCCESS)
}

function* addSocialLinks() {
  yield takeLatest(SocialLinks.addSocialLinks.type, ensureAddSocialLinks)
  yield take(SocialLinks.addSocialLinks.success)
}

function* initialLoad() {
  yield put({ type: pageIsLoading, payload: true })
  try {
    yield all([
      call(M.ensureGetManagers),
      call(M.ensureGetCollaborationRequests),
      call(L.ensureGetCounties)
    ])
    yield put({ type: 'profile/CLEAR_CITIES' })
  } finally {
    yield put({ type: pageIsLoading, payload: false })
  }
}
