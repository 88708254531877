import SendBird, { GroupChannel, User } from 'sendbird'

const APP_ID = process.env.REACT_APP_SEND_BIRD_APP_ID || ''

export const connect = (userId: string, accessToken: string) => {
  return new Promise((resolve, reject) => {
    if (!userId) {
      reject('UserID is required.')
    }

    const sb = new SendBird({ appId: APP_ID })

    sb.connect(userId, accessToken, (user, error) => {
      error ? reject('SendBird Login Failed') : resolve(user)
    })
  })
}
export const disconnect = () => {
  const sb = SendBird.getInstance()
  if (sb) {
    sb.disconnect()
  }
}

export const updateUser = (nickname: string = '', profileUrl: string = '') => {
  return new Promise((resolve, reject) => {
    if (!nickname) {
      reject('Nickname is required.')
      return
    }

    let sb = SendBird.getInstance()
    if (!sb) sb = new SendBird({ appId: APP_ID })

    sb.updateCurrentUserInfo(nickname, profileUrl, (user: User, error: any) => {
      error ? reject(error) : resolve(user)
    })
  })
}

export const updateUserMetaData = (metadata: {}) => {
  return new Promise((resolve, reject) => {
    let sb = SendBird.getInstance()
    if (!sb) sb = new SendBird({ appId: APP_ID })

    const data = {
      ...sb.currentUser.metaData,
      ...metadata
    }

    sb.currentUser.createMetaData(data, (updatedMetaData: {}, error: any) => {
      error ? reject(error) : resolve(sb.currentUser)
    })
  })
}

export const getNextChannelList = (channelListQuery: any) => {
  return new Promise((resolve, reject) => {
    if (channelListQuery.hasNext && channelListQuery.next) {
      channelListQuery.next((channels: any, error: any) => {
        return error ? reject(error) : resolve(channels)
      })
    } else {
      reject('channelListQuery does not has hasNext ')
    }
  })
}

export const createChatOneOnOne = (
  recipientId: string,
  userId: string,
  isDistinct: boolean = true
) => {
  return new Promise((resolve, reject) => {
    if (!recipientId && !userId) {
      reject('OneOnOne expect userId and recipientId')
    }
    const sb = SendBird.getInstance()
    sb.GroupChannel.createChannelWithUserIds(
      [recipientId, userId],
      isDistinct,
      'oneOnOne',
      (channel, error) => {
        error ? reject(error) : resolve(channel)
      }
    )
  })
}

export const getGroupChannel = (channelUrl: string) => {
  return new Promise((resolve, reject) => {
    const sb = SendBird.getInstance()

    sb.GroupChannel.getChannel(channelUrl, (channel, error) => {
      error ? reject(error) : resolve(channel)
    })
  })
}

export const getTotalUnreadChannelCount = () => {
  return new Promise((resolve, reject) => {
    const sb = SendBird.getInstance()

    sb.getTotalUnreadChannelCount((count: number, error: any) => {
      error ? reject(error) : resolve(count)
    })
  })
}

export const getTotalUnreadMessageCount = () => {
  return new Promise((resolve, reject) => {
    const sb = SendBird.getInstance()

    sb.getTotalUnreadMessageCount((count: number, error: any) => {
      error ? reject(error) : resolve(count)
    })
  })
}

export const createMetaData = (channel: GroupChannel, data: {}) => {
  return new Promise((resolve, reject) => {
    channel.createMetaData(data, (response: {}, error: any) => {
      error ? reject(error) : resolve(response)
    })
  })
}

export const updateMetaData = (
  channel: GroupChannel,
  data: {},
  upsertIfNotExist: boolean = false
) => {
  return new Promise((resolve, reject) => {
    channel.updateMetaData(data, upsertIfNotExist, (response: {}, error: any) => {
      error ? reject(error) : resolve(response)
    })
  })
}

export const getMetaData = (channel: GroupChannel, keys: [string]) => {
  return new Promise((resolve, reject) => {
    channel.getMetaData(keys, (response: {}, error: any) => {
      error ? reject(error) : resolve(response)
    })
  })
}

export const deleteMetaData = (channel: GroupChannel, key: string) => {
  return new Promise((resolve, reject) => {
    channel.deleteMetaData(key, (response: any, error: any) => {
      error ? reject(error) : resolve(response)
    })
  })
}

export const getAllMetaData = (channel: GroupChannel) => {
  return new Promise((resolve, reject) => {
    channel.getAllMetaData((response: {}, error: any) => {
      error ? reject(error) : resolve(response)
    })
  })
}

export const deleteAllMetaData = (channel: GroupChannel) => {
  return new Promise((resolve, reject) => {
    channel.deleteAllMetaData((response: {}, error: any) => {
      error ? reject(error) : resolve(response)
    })
  })
}

export const sendUserTextMessage = (channel: GroupChannel, textMessage: string) => {
  return new Promise((resolve, reject) => {
    const sb = SendBird.getInstance()
    const params = new sb.UserMessageParams()

    params.message = textMessage
    params.customType = 'text'

    if (channel.isGroupChannel()) {
      channel.endTyping()
    }

    channel.sendUserMessage(params, (message: any, error: any) => {
      error ? reject(error) : resolve(message)
    })
  })
}

export const sendUserFileMessage = (
  channel: GroupChannel,
  file: any,
  customType: string = 'general'
) => {
  return new Promise((resolve, reject) => {
    const sb = SendBird.getInstance()
    const params = new sb.FileMessageParams()

    if (channel.isGroupChannel()) {
      channel.endTyping()
    }

    params.file = file // or .fileUrl (You can send a file message with a file URL.)
    params.fileName = file.name
    params.fileSize = file.size
    params.mimeType = file.type
    params.customType = customType

    channel.sendFileMessage(params, (fileMessage: any, error: any) => {
      error ? reject(error) : resolve(fileMessage)
    })
  })
}

export const markAsRead = (channelUrl: string, groupChannel?: GroupChannel) => {
  if (groupChannel) {
    groupChannel.markAsRead()
  } else {
    getGroupChannel(channelUrl).then((channel: any) => channel.markAsRead())
  }
}

export const getSendBirdInstance = () => {
  return new Promise((resolve, reject) => {
    let sb = SendBird.getInstance()
    if (!sb) sb = new SendBird({ appId: APP_ID })
    return !sb ? reject('Cannot get SendBird Instance') : resolve(sb)
  })
}

export const getPreviousMessages = (messageLoader: any) => {
  return new Promise((resolve, reject) => {
    messageLoader.load((messages: any, error: any) => {
      error ? reject(error) : resolve(messages)
    })
  })
}
