import { Styles } from 'react-jss'

export const sheet: Styles = {
  leftBlock: {},
  mainBlock: {},
  blockTitle: {},
  title: {},
  topSecret: {},
  rateInfo: {},
  rateBlock: {},
  workType: {},
  location: {},
  info: {},
  row: {},
  spheres: {},
  saveJob: {
    cursor: 'pointer',
    color: '#989FA6'
  },
  travel: {},
  relocation: {},
  tooltipText: {
    visibility: 'hidden',
    width: '110px',
    background: '#080910',
    boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.1)',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 13,
    lineHeight: '17px',
    letterSpacing: '0.01em',
    textTransform: 'capitalize',
    textAlign: 'center',
    color: '#FAFAFA',
    padding: '5px 0',
    position: 'absolute',
    top: '100%',
    left: '50%',
    marginLeft: -55,
    zIndex: '1',
    '&:after': {
      content: '""',
      position: 'absolute',
      bottom: '100%',
      left: '50%',
      marginLeft: '-5px',
      borderWidth: '5px',
      borderStyle: 'solid',
      borderColor: 'transparent transparent black transparent'
    }
  },
  tableRow: {
    display: 'flex',
    flexDirection: 'row',
    background: '#FFFFFF',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: 15,
    lineHeight: '21px',
    letterSpacing: '0.01em',
    padding: '1%',
    color: '#080910',
    overflow: 'hidden',
    justifyContent: 'space-between',
    boxSizing: 'border-box',
    border: '1px solid transparent',
    borderBottom: '1px solid #DEDEDE',
    transition: 'border-color 0.25s linear',
    '&:hover': {
      borderColor: '#286EFA',
      '& $title': {
        color: '#286EFA'
      },
      '& $saveJob > svg': {
        color: '#080910'
      }
    },
    '& > $leftBlock': {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      cursor: 'default',
      '& > $mainBlock': {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        '& > $blockTitle': {
          marginRight: '2%',
          width: '100%',
          '& > $title': {
            fontSize: 17,
            marginTop: 0,
            wordBreak: 'break-all'
          },
          '& > $topSecret': {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            fontWeight: 'bold',
            lineHeight: '19px',
            wordBreak: 'break-all',
            '& > span:last-child:not(:first-child)': {
              marginLeft: 32,
              background: '#FEF5F5',
              border: '1px dashed #BC2121',
              boxSizing: 'border-box',
              fontStyle: 'normal',
              fontWeight: '500',
              fontSize: 13,
              lineHeight: '18px',
              letterSpacing: '0.03em',
              textTransform: 'capitalize',
              color: '#E14A4A',
              padding: '5px 8px',
              whiteSpace: 'nowrap'
            }
          }
        },
        '& > $rateInfo': {
          border: '1px dashed #989FA6',
          fontStyle: 'italic',
          fontWeight: 'normal',
          fontSize: 15,
          lineHeight: '19px',
          letterSpacing: '0.01em',
          color: '#989FA6',
          padding: '10px',
          height: 'fit-content',
          '& > $rateBlock': {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            '& > p': {
              whiteSpace: 'nowrap'
            },
            '& > p:first-child': {
              marginRight: 16
            }
          },
          '& > $workType': {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-end',
            '& > svg': {
              marginRight: 12
            }
          }
        }
      },
      '& > $location': {
        color: '#989FA6',
        display: 'flex',
        flexDirection: 'row',
        position: 'relative',
        paddingLeft: 20,
        margin: 0,
        alignItems: 'center',
        height: 29,
        '&:before': {
          content: ` url("${require('components/svg/location.svg')}")`,
          position: 'absolute',
          top: 0,
          left: 0
        }
      },
      '& > $info': {
        fontWeight: 'normal',
        lineHeight: '20px',
        wordBreak: 'break-all',
        '& > span': {
          fontWeight: 'bold'
        }
      },
      '& > $row': {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        textTransform: 'uppercase',
        '& > $spheres': {
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          '& > span': {
            textTransform: 'capitalize',
            background: '#F0F0F0',
            fontStyle: 'normal',
            fontWeight: '500',
            fontSize: 13,
            lineHeight: '18px',
            letterSpacing: '0.03em',
            color: '#080910',
            margin: '0 10px 10px 0',
            padding: '4px 10px',
            wordBreak: 'break-all'
          }
        },
        '& > div:last-child': {
          margin: 0,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          whiteSpace: 'nowrap',
          '& > svg:first-child': {
            marginRight: 16
          },
          '& > $travel, & > $relocation': {
            position: 'relative',
            marginRight: 45,
            marginBottom: 25,
            '&:hover $tooltipText': {
              visibility: 'visible'
            }
          },
          '& > $travel': {
            marginRight: 16
          }
        }
      }
    }
  }
}
