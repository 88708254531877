import { Styles } from 'react-jss'

export const sheet: Styles = {
  title: {},
  field: {},
  button: {},
  error: {},
  root: {
    padding: 30,
    '& > h4': {
      margin: 0
    },
    '& > $error': {
      fontWeight: 400,
      letterSpacing: '0.01em',
      fontSize: 12,
      color: '#dc233b',
      margin: '0 0 0 2px',
      wordBreak: 'break-all'
    },
    '& > p': {
      margin: '24px 0',
      color: '#989FA6',
      fontWeight: 'normal',
      lineHeight: '20px',
      fontSize: 15,
      letterSpacing: '0.01em'
    },
    '& > $button': {
      marginTop: 30,
      display: 'flex',
      justifyContent: 'flex-end'
    }
  },
  arrayFields: {
    '& > $title': {
      fontWeight: 'bold',
      lineHeight: '19px',
      fontSize: 15,
      letterSpacing: '0.01em',
      color: '#080910',
      '& > span': {
        color: '#989FA6'
      }
    },
    '& > $field': {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      marginBottom: 30,
      '& > div': {
        margin: 0,
        width: '100%'
      },
      '& > svg': {
        marginLeft: 30,
        marginTop: 10
      }
    },
    '& > button': {
      textTransform: 'uppercase'
    }
  }
}
