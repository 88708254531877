import { all, call, fork, select, put, takeLatest, take } from 'redux-saga/effects'
import { replace } from 'connected-react-router'
import * as J from 'domain/manager/jobs'
import * as R from 'domain/router'
import { ownershipSelector, jobStatusSelector } from 'domain/constants'
import queryString from 'query-string'

export function* jobsList({ query, pathname }: { query: J.Params; pathname: string }) {
  yield all([fork(getJobsList, { query, pathname }), fork(getManagers)])
}

function* getJobsList({ query, pathname }: { query: J.Params; pathname: string }) {
  const ownership = yield select(ownershipSelector)
  const jobStatuses = yield select(jobStatusSelector)

  const props = {
    payload: {
      ...query,
      ownership: query.ownership || ownership.get('own'),
      status: query.status || jobStatuses.get('signed'),
      page: query.page || 1
    },
    type: J.jobsList.request
  }
  const route = yield select(R.routing)
  const prevLocation = Object.keys(route.prevLocation || {}).length
  if (!prevLocation || (prevLocation && route.action === 'POP' && props.payload.page !== 1)) {
    const stringifyParams = queryString.stringify(props.payload, { arrayFormat: 'bracket' })
    yield put(replace(`${pathname}?${stringifyParams}`))
    return
  }
  yield call(J.ensureGetJobs, props)
}

function* getManagers() {
  yield takeLatest(J.getManagersList.type, J.ensureGetManagersList)
  yield take(J.getManagersList.success)
}
