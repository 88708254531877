import React from 'react'
import injectSheet from 'react-jss'
import { Classes } from 'jss'

const sheet = {
  title: {
    fontWeight: '500',
    fontSize: 14,
    lineHeight: '18px',
    color: '#080910',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    flex: '1 1 100%',
    textDecoration: 'none',
    display: 'block'
  }
}

const NotificationTitle = ({
  title,
  classes,
  path
}: {
  title: string
  classes: Classes
  path?: string
}) => (
  <>
    {path ? (
      <a href={path} className={classes.title} data-tip={title}>
        {title}
      </a>
    ) : (
      <p className={classes.title} data-tip={title}>
        {title}
      </p>
    )}
  </>
)

export default injectSheet(sheet)(NotificationTitle)
