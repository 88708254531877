import { ActionCreator } from 'redux'

function makeActionDefault(payload: object): { payload: object } {
  return { payload }
}

export function actionCreators(type: string, makeAction: any = makeActionDefault) {
  const actionCreator: any = (...args: []) => {
    const action: any = makeAction(...args)
    if (typeof action === 'object') {
      action.type = type
    }
    return action
  }
  actionCreator.type = type
  return actionCreator
}

interface ActionCreatorInterface extends ActionCreator<any> {
  type: string
  request: string
  success: string
  failure: string
}

export function asyncAction(base: string, makeAction: any = makeActionDefault) {
  const actionCreator: ActionCreatorInterface = (...args: []) => {
    const action = makeAction(...args)
    if (typeof action === 'object') {
      action.type = base
    }
    return action
  }

  actionCreator.type = base
  actionCreator.request = `${base}/REQUEST`
  actionCreator.success = `${base}/SUCCESS`
  actionCreator.failure = `${base}/FAILURE`
  return actionCreator
}
