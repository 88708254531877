import React from 'react'
// components
import App from './pages'
// redux
import ReactDOM from 'react-dom'
import { ConnectedRouter } from 'connected-react-router'
import { Provider } from 'react-redux'
// styles
import './index.css'
import './tagStyles.css'
import 'react-datepicker/dist/react-datepicker.css'
import theme from './theme'
import { ThemeProvider } from 'theming'
// utils
import * as serviceWorker from './serviceWorker'
import { history } from './lib/history'
import configureStore from './domain/configureStore'
import rootSaga from './sagas'

import * as Sentry from '@sentry/browser'

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://f482f71940604662952e6f957ee69191@o410879.ingest.sentry.io/5285535',
    release: process.env.REACT_APP_SENTRY_RELEASE
  })
}

const store = configureStore(history)
store.runSaga(rootSaga)

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
)

serviceWorker.unregister()
