import { put, call, select } from 'redux-saga/effects'
import { dashboardSelector } from 'domain/recruiter/selectors'
import { dateTimeRangeSelector, DateTimeRangeT } from 'domain/constants'
import { Sort } from 'types/common'
import I from 'immutable'
import Api from 'domain/api'
import * as Actions from './actions'
import * as M from 'domain/env'

interface Params {
  page?: number
  order?: Sort
  order_by?: string | null
  range?: string
}

export function* ensureJobsInProgress() {
  const headers = yield select(M.userToken)
  if (!headers) return
  try {
    const {
      data: { data }
    } = yield call(Api.recruiterJobsInProgress, {
      headers: { Authorization: `Bearer ${headers}` }
    })
    yield put({ type: Actions.recruiterJobsInProgress.success, payload: I.fromJS(data) })
  } catch (err) {
    yield put({
      type: Actions.recruiterJobsInProgress.failure,
      err
    })
  }
}

export function* ensureJobsCounters() {
  const headers = yield select(M.userToken)
  if (!headers) return
  try {
    const {
      data: { data }
    } = yield call(Api.recruiterJobsCounters, {
      headers: { Authorization: `Bearer ${headers}` }
    })
    yield put({ type: Actions.recruiterJobsCounters.success, payload: I.fromJS(data) })
  } catch (err) {
    yield put({
      type: Actions.recruiterJobsCounters.failure,
      err
    })
  }
}

export function* ensureClosedJobs(props?: { payload?: Params; type: string }) {
  const headers = yield select(M.userToken)
  if (!headers) return
  const range: DateTimeRangeT = yield select(dateTimeRangeSelector)
  const params = (props && props.payload) || { range: range.get('week') }
  try {
    const { data } = yield call(Api.recruiterClosedJobs, {
      headers: { Authorization: `Bearer ${headers}` },
      params
    })
    const page = params.page
    const total = data.meta.total
    const prevStateDashboard = yield select(dashboardSelector)
    const prevState = prevStateDashboard.get('closedJobs')
    if (!params || !page || page === 1 || total === prevState.size) {
      yield put({ type: Actions.recruiterNewClosedJobs.success, payload: I.fromJS(data) })
    } else {
      yield put({ type: Actions.recruiterClosedJobs.success, payload: I.fromJS(data) })
    }
  } catch (err) {
    yield put({
      type: Actions.recruiterClosedJobs.failure,
      err
    })
  }
}

export function* ensureRecruiterSkills() {
  const headers = yield select(M.userToken)
  if (!headers) return
  try {
    const {
      data: { data }
    } = yield call(Api.recruiterRecruiterSkills, {
      headers: { Authorization: `Bearer ${headers}` }
    })
    yield put({ type: Actions.recruiterRecruiterSkills.success, payload: I.fromJS(data) })
  } catch (err) {
    yield put({
      type: Actions.recruiterRecruiterSkills.failure,
      err
    })
  }
}

export function* ensureClients() {
  const headers = yield select(M.userToken)
  if (!headers) return
  try {
    const {
      data: { data }
    } = yield call(Api.recruiterClients, {
      headers: { Authorization: `Bearer ${headers}` }
    })
    yield put({ type: Actions.recruiterClients.success, payload: I.fromJS(data) })
  } catch (err) {
    yield put({
      type: Actions.recruiterClients.failure,
      err
    })
  }
}

export function* ensureStatistics(props?: { payload?: { range?: string }; type: string }) {
  const headers = yield select(M.userToken)
  if (!headers) return
  const range: DateTimeRangeT = yield select(dateTimeRangeSelector)
  const params = (props && props.payload) || { range: range.get('week') }
  try {
    const {
      data: { data }
    } = yield call(Api.recruiterStatistics, {
      headers: { Authorization: `Bearer ${headers}` },
      params
    })
    yield put({ type: Actions.recruiterStatistics.success, payload: I.fromJS(data) })
  } catch (err) {
    yield put({
      type: Actions.recruiterStatistics.failure,
      err
    })
  }
}

export function* ensureTopRecruiters(props?: { payload?: Params; type: string }) {
  const headers = yield select(M.userToken)
  if (!headers) return
  const range: DateTimeRangeT = yield select(dateTimeRangeSelector)
  const params = (props && props.payload) || { range: range.get('week') }
  try {
    const {
      data: { data }
    } = yield call(Api.recruiterTopRecruiters, {
      headers: { Authorization: `Bearer ${headers}` },
      params
    })
    yield put({ type: Actions.recruiterTopRecruiters.success, payload: I.fromJS(data) })
  } catch (err) {
    yield put({
      type: Actions.recruiterTopRecruiters.failure,
      err
    })
  }
}
