import { all, call, take, takeLatest, fork, put } from 'redux-saga/effects'
import { isLoading } from 'domain/loading'
import * as A from 'domain/manager/recruiters'
import * as L from 'domain/locations'

export function* recruitersList() {
  yield all([fork(getRecruitersList), fork(initialLoad)])
}

function* getRecruitersList() {
  yield takeLatest(A.allRecruiters.type, A.ensureGetAllRecruiters)
  yield take(A.allRecruiters.success)
}

function* initialLoad() {
  yield put({ type: isLoading, payload: true })
  try {
    yield all([call(L.ensureGetCounties), call(A.ensureGetAllRecruiters)])
    yield put({ type: 'profile/CLEAR_CITIES' })
  } finally {
    yield put({ type: isLoading, payload: false })
  }
}
