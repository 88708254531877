import React from 'react'
import Svg, { SvgType } from './Svg'

export default function(props: SvgType) {
  return (
    <Svg width="16" height="24" viewBox="0 0 16 24" fill="none" {...props}>
      <path
        d="M14 10.9091C14 12.692 13.0037 14.8549 11.6629 16.912C10.3728 18.8912 8.86982 20.6149 8 21.5544C7.13018 20.6149 5.6272 18.8912 4.33711 16.912C2.99626 14.8549 2 12.692 2 10.9091C2 7.65787 4.67393 5 8 5C11.3261 5 14 7.65787 14 10.9091Z"
        fill="#E6E6E6"
        stroke="#E6E6E6"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="8" cy="11" r="2.25" fill="#202332" stroke="#202332" strokeWidth="1.5" />
    </Svg>
  )
}
