import React from 'react'
// redux
import { Push } from 'connected-react-router'
// components
import { Expanded } from 'components/common/Expand'
import SalaryRate from 'components/SalaryRate'
// types
import { Classes } from 'jss'
import { ClearanceTypeT } from 'domain/constants'
import { ImmutableMap, SpheresImmutableTItem } from 'types/common'
import { JobCandidate } from 'types/job/jobTypesCandidate'
// utils
import { employmentType } from 'lib/variables'
import { getLocationString } from 'lib/helpers'
import { getCurrencySymbol } from 'lib/currency'
// styles
import injectSheet from 'react-jss'
import { sheet } from './sheet'
// icons
import Relocation from 'components/svgComponent/job/Relocation'
import Schedule from 'components/svgComponent/job/Schedule'
import Travel from 'components/svgComponent/job/Travel'
import I, { List } from 'immutable'

interface RateI {
  from: number | null
  to: number | null
  currency: string
}
interface JobDataI {
  title: string
  topSecret: string | boolean
  clientName: string
  hourlyRate: RateI
  salaryRate: RateI
  textArray: string[] | string
  spheres: List<SpheresImmutableTItem>
  location: string
  employmentType: List<string>
  travel: boolean
  relocation: boolean
  favorite: boolean
}

interface Props {
  classes: Classes
  push: Push
  job: ImmutableMap<JobCandidate>
  toggleLogIn: () => void
  clearanceTypeConst: ClearanceTypeT
}

const TableRow = ({ classes, toggleLogIn, job, clearanceTypeConst }: Props) => {
  let jobData: JobDataI = {
    title: '',
    topSecret: '',
    clientName: '',
    hourlyRate: {} as RateI,
    salaryRate: {} as RateI,
    textArray: [],
    spheres: I.List([]),
    location: '',
    employmentType: I.List([]),
    travel: false,
    relocation: false,
    favorite: false
  }

  if (job && !job.isEmpty()) {
    const client = job.get('client')
    const clientName = client && !client.isEmpty() ? client.get('companyName') : 'Comtech LLC'
    const city = job.getIn(['city', 'name'])
    const country = job.getIn(['country', 'name'])
    const state = job.getIn(['state', 'name'])
    const rateCurrency = getCurrencySymbol(job.get('rateCurrency'))
    const salaryCurrency = getCurrencySymbol(job.get('salaryCurrency'))
    const hourlyRate = {
      from: job.get('rateFrom'),
      to: job.get('rateTo'),
      currency: rateCurrency
    }
    const salaryRate = {
      from: job.get('salaryFrom'),
      to: job.get('salaryTo'),
      currency: salaryCurrency
    }
    jobData = {
      title: job.get('title'),
      topSecret:
        job.get('clearanceType') &&
        job.get('clearanceType').includes(clearanceTypeConst.get('topSecret')),
      clientName,
      hourlyRate,
      salaryRate,
      textArray: job.get('desc').split(' '),
      spheres: job.get('spheres'),
      location: getLocationString(city, country, state),
      employmentType: job.get('employmentType'),
      travel: job.get('travel'),
      relocation: job.get('relocation'),
      favorite: job.get('isFavorite')
    }
  }

  return (
    <div className={classes.tableRow}>
      <div className={classes.leftBlock} onClick={toggleLogIn}>
        <div className={classes.mainBlock}>
          <div className={classes.blockTitle}>
            <p className={classes.title}>{jobData.title}</p>
            <p className={classes.topSecret}>
              <span>{jobData.clientName}</span>
              {jobData.topSecret && <span>TOP SECRET</span>}
            </p>
          </div>
          <div className={classes.rateInfo}>
            <div className={classes.rateBlock}>
              <SalaryRate
                currency={jobData.hourlyRate.currency}
                from={jobData.hourlyRate.from}
                to={jobData.hourlyRate.to}
                period="hourly"
              />
              <SalaryRate
                currency={jobData.salaryRate.currency}
                from={jobData.salaryRate.from}
                to={jobData.salaryRate.to}
                period="annual"
              />
            </div>
            <div className={classes.workType}>
              <Schedule />
              {jobData.employmentType.map((item: string) => employmentType[item]).join(', ')}
            </div>
          </div>
        </div>
        {jobData.location && <p className={classes.location}>{jobData.location}</p>}
        <Expanded data={jobData.textArray} limit={60}>
          {(data: string[], expanded: boolean, toggleExpand: () => void) => {
            return (
              <p className={classes.info}>
                {data.join(' ')}
                {jobData.textArray.length > 60 && (
                  <span onClick={toggleExpand}>{expanded ? ' less' : ` ...more`}</span>
                )}
              </p>
            )
          }}
        </Expanded>
        <div className={classes.row}>
          <div className={classes.spheres}>
            {jobData.spheres.map((item: SpheresImmutableTItem, i: number) => {
              return <span key={i}>{item.get('name')}</span>
            })}
          </div>
          <div>
            <p className={classes.travel}>
              <Travel iconActive={jobData.travel} />
              {jobData.travel && <span className={classes.tooltipText}>Requires Travel</span>}
            </p>
            <p className={classes.relocation}>
              <Relocation iconActive={jobData.relocation} />
              {jobData.relocation && <span className={classes.tooltipText}>Need Relocation</span>}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default injectSheet(sheet)(TableRow)
