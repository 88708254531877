import { put, call, select } from 'redux-saga/effects'
import { error403, pageIsLoading } from 'domain/loading'
import { transformAvatar } from 'domain/candidate/helpers'
import * as Actions from './actions'
import * as M from 'domain/env'
import * as Router from 'domain/router'
import Api from 'domain/api'
import I from 'immutable'

export function* ensureGetManager() {
  yield put({
    type: Actions.managerProfileView.success,
    payload: I.fromJS({})
  })
  yield put({ type: error403, payload: false })
  const headers = yield select(M.userToken)
  if (!headers) return
  yield put({ type: pageIsLoading, payload: true })
  const {
    params: { id }
  } = yield select(Router.matchRoutes)
  try {
    const {
      data: { data }
    } = yield call(Api.getManagerProfileC, {
      headers: { Authorization: `Bearer ${headers}` },
      id
    })
    yield put({
      type: Actions.managerProfileView.success,
      payload: transformAvatar(I.fromJS(data))
    })
  } catch (err) {
    const { statusCode } = err.response.data
    if (statusCode === 403 || statusCode === 404) {
      yield put({ type: error403, payload: true })
    }
    yield put({
      type: Actions.managerProfileView.failure,
      err
    })
  } finally {
    yield put({ type: pageIsLoading, payload: false })
  }
}
