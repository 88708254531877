import { Styles } from 'react-jss'

export const sheet: Styles = {
  inputRow: {
    margin: '1em 0',
    position: 'relative'
  },
  columnBlock: {},
  activeRadio: {},
  title: {},
  text: {},
  input: {},
  radio: {},
  radioInput: {},
  name: {},
  backgroundIcon: {},
  content: {
    display: 'flex',
    flexDirection: 'row',
    '& h4': {
      color: '#080910',
      fontWeight: 500,
      margin: 0
    },
    '@media (max-width: 600px)': {
      flexDirection: 'column'
    }
  },
  textLeft: {
    position: 'relative',
    overflow: 'hidden',
    padding: '3em',
    flex: 1,
    zIndex: 1,
    backgroundColor: '#FCFCFC',
    border: '1px solid #DEDEDE',
    '& > $columnBlock': {
      display: 'flex',
      flexDirection: 'row',
      '& > div': {
        borderRight: '1px solid #E6E6E6',
        marginRight: '1.5em'
      },
      '& > div:last-child': {
        marginRight: 0,
        border: 'none'
      },
      '& p': {
        fontSize: 15,
        letterSpacing: '0.01em',
        color: '#888888',
        opacity: 0.8
      }
    },
    '& > $radio': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      '& > $radioInput': {
        position: 'absolute',
        opacity: 0,
        '& + label': {
          position: 'relative',
          cursor: 'pointer',
          padding: 0
        },
        '& + label:before': {
          content: '""',
          marginRight: 10,
          display: 'inline-block',
          verticalAlign: 'text-top',
          width: 20,
          height: 20,
          background: '#FFFFFF',
          border: '2px solid #000000',
          boxSizing: 'border-box',
          borderRadius: 1
        },
        '&:checked, &:not(:checked)': {
          position: 'absolute',
          left: '-9999px'
        },
        '&:checked + label, &:not(:checked) + label': {
          display: 'inline-block',
          position: 'relative',
          paddingLeft: '8%',
          cursor: 'pointer'
        },
        '&:checked + label:before, &:not(:checked) + label::before': {
          content: '""',
          position: 'absolute',
          left: 0,
          top: 0,
          bottom: 0,
          margin: 'auto',
          width: 24,
          height: 24,
          border: '2px solid #CCCCCC',
          boxSizing: 'border-box',
          borderRadius: '100%'
        },
        '&:checked + label:after, &:not(:checked) + label:after': {
          content: '""',
          position: 'absolute',
          transition: 'all 0.2s ease',
          left: 5,
          top: 0,
          bottom: 0,
          margin: 'auto',
          width: 14,
          height: 14,
          borderRadius: '100%',
          backgroundColor: '#286EFA'
        },
        '&:not(:checked) + label:after': {
          opacity: 0
        },
        '&:checked + label:after': {
          opacity: 1
        },
        '&:checked + label:before': {
          border: '2px solid #286EFA'
        }
      }
    },
    '& h3': {
      color: '#E6E6E6',
      whiteSpace: 'nowrap',
      lineHeight: '26px',
      margin: '0.5em',
      '&$activeRadio': {
        color: '#286EFA'
      },
      '@media (max-width: 600px)': {
        fontSize: '24px'
      }
    },
    '@media (max-width: 600px)': {
      padding: '20px'
    }
  },
  parent: {
    padding: '3em',
    position: 'relative',
    flex: 0.6,
    backgroundColor: '#FFFFFF',
    '@media (max-width: 600px)': {
      padding: '1.8em'
    }
  },
  form: {
    backgroundColor: '#FFFFFF',
    '& > button': {
      fontWeight: 400,
      fontSize: 15,
      letterSpacing: '0.01em',
      color: '#888888',
      padding: 0,
      textTransform: 'none'
    },
    '& > $name': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      '& > div': {
        marginBottom: 0
      },
      '& > div:first-child': {
        marginRight: '5%'
      }
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      width: 40,
      height: 40,
      backgroundColor: '#f7f7f7',
      borderRight: '1px solid #DEDEDE',
      borderTop: '1px solid #DEDEDE',
      transform: 'rotate(45deg)',
      top: 40,
      right: '95.5%',
      zIndex: 999
    }
  },
  buttonGroup: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: '3em 0',
    '& > button:first-child': {
      fontWeight: 500,
      fontSize: 14,
      letterSpacing: '0.03em',
      color: '#286EFA',
      padding: 0
    }
  },
  iconBlock: {
    display: 'flex',
    '& > div': {
      position: 'relative',
      padding: '20px 0 20px 30px',
      flex: 1,
      '& > $title': {
        fontWeight: 500,
        fontSize: 17,
        color: '#080910'
      },
      '& > $text': {
        fontWeight: 400,
        fontSize: 15,
        color: '#989FA6'
      },
      '& > svg': {
        position: 'absolute',
        top: 0,
        left: 0
      }
    }
  },
  margin: {
    marginTop: '10%'
  },
  linkText: {
    fontWeight: 'normal',
    lineHeight: '20px',
    fontSize: 15,
    letterSpacing: '0.01em',
    color: '#080910',
    '& > a': {
      color: '#286EFA',
      textDecoration: 'none'
    }
  },
  '@media (min-width: 992px) and (max-width: 1199.98px)': {
    textLeft: {
      padding: '3em 3em 3em 1.5em',
      '& h3': {
        fontSize: 35
      }
    },
    parent: {
      padding: '4em 3em 3em 1.5em',
      '& h4': {
        whiteSpace: 'nowrap'
      }
    },
    form: {
      '&:after': {
        width: 25,
        height: 25,
        top: 65,
        right: '96.5%'
      }
    }
  },
  '@media (min-width: 768px) and (max-width: 991.98px)': {
    textLeft: {
      padding: '3em 1em 3em 1em',
      '& h3': {
        fontSize: 25
      }
    },
    parent: {
      padding: '3em 1em 3em 1em',
      '& h4': {
        whiteSpace: 'nowrap'
      }
    },
    form: {
      '&:after': {
        width: 20,
        height: 20,
        top: 50,
        right: '97%'
      }
    },
    inputRow: {
      margin: '1.5em 0'
    },
    buttonGroup: {
      margin: '1.5em 0'
    }
  },
  '@media (max-width: 767.98px)': {
    form: {
      '&:after': {
        display: 'none'
      }
    }
  }
}
