import { Styles } from 'react-jss'

export const sheet: Styles = {
  root: {},
  rootFocused: {},
  selected: {},
  selectedTag: {},
  longTag: {},
  search: {},
  searchInput: {},
  suggestions: {},
  heightAuto: {},
  error: {},
  main: {
    '& > $root': {
      border: '1px solid #E6E6E6',
      boxSizing: 'border-box',
      padding: '0 0 0 10px',
      minHeight: 131,
      fontFamily: 'Roboto, sans-serif',
      position: 'relative',
      '& > $selected': {
        display: 'inline-block',
        border: 'none',
        boxShadow: 'none',
        fontFamily: 'Roboto, sans-serif',
        '& > $selectedTag': {
          fontFamily: 'Roboto, sans-serif',
          border: 'none',
          boxShadow: 'none',
          background: '#D8ECFF',
          fontWeight: '500',
          lineHeight: '18px',
          fontSize: 13,
          letterSpacing: '0.03em',
          textTransform: 'capitalize',
          color: '#080910',
          wordBreak: 'break-all',
          padding: '4px 10px',
          display: 'inline-block',
          boxSizing: 'border-box',
          margin: '6px 10px 6px 0',
          '&:after': {
            content: '"\\2715"',
            color: '#989FA6',
            marginLeft: 10,
            fontWeight: '900'
          }
        },
        '& > $longTag': {
          border: '1px solid red'
        }
      },
      '& > $search': {
        '& > $searchInput': {
          '& > input': {
            fontFamily: 'Roboto, sans-serif',
            fontWeight: 'normal',
            lineHeight: '20px',
            fontSize: 15,
            letterSpacing: '0.01em',
            color: '#080910'
          }
        },
        '& > $suggestions': {
          left: 'auto',
          fontFamily: 'Roboto, sans-serif',
          fontWeight: 'normal',
          lineHeight: '20px',
          fontSize: 15,
          letterSpacing: '0.01em',
          color: '#080910'
        }
      }
    },
    '& > $rootFocused': {
      border: '1px solid #85BFFF'
    },
    '& > $heightAuto': {
      minHeight: 'auto'
    },
    '& > $error': {
      border: '1px solid #dc233b',
      outlineColor: '#dc233b',
      '&:focus, &:active': {
        outlineColor: '#dc233b',
        border: '1px solid #dc233b'
      }
    }
  }
}
