import { asyncAction } from 'lib/actionCreators'

export const superManagerClientsCounters = asyncAction('super-manager/DASHBOARD_CLIENTS_COUNTERS')
export const superManagerJobsCounters = asyncAction('super-manager/DASHBOARD_JOBS_COUNTERS')
export const superManagerStatistics = asyncAction('super-manager/DASHBOARD_STATISTICS')
export const superManagerClosedJobs = asyncAction('super-manager/DASHBOARD_CLOSED_JOBS')
export const superManagerClientClosureRate = asyncAction(
  'super-manager/DASHBOARD_CLIENTS_CLOSURE_RATE'
)
export const superManagerTopRecruiters = asyncAction('super-manager/DASHBOARD_TOP_RECRUITERS')
export const superManagerTopManagers = asyncAction('super-manager/DASHBOARD_TOP_MANAGERS')
