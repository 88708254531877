import { all, call, fork, put, select, take, takeLatest } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { pageIsLoading } from 'domain/loading'
import { jobSelector } from 'domain/manager/selectors'
import { jobStatusSelector } from 'domain/constants'
import * as L from 'domain/locations'
import * as R from 'domain/manager/recruiters'
import * as C from 'domain/manager/clients'
import * as J from 'domain/manager/jobs'
import * as Jobs from 'domain/jobs'
import * as Router from 'domain/router'

export function* jobsEdit() {
  yield all([
    fork(getRecruiters),
    fork(postJobs),
    fork(getJob),
    fork(putJob),
    fork(deleteJob),
    fork(getClientsForFob),
    fork(initialLoad),
    fork(updateRecruiters)
  ])
}

export function* jobsClone() {
  yield all([
    fork(getRecruiters),
    fork(postJobs),
    fork(getJobForClone),
    fork(getClientsForFob),
    fork(initialLoad),
    fork(updateRecruiters)
  ])
}

function* updateRecruiters() {
  yield takeLatest(Jobs.updateJobRecruiters.type, Jobs.ensureUpdateJobRecruiters)
  yield take(Jobs.updateJobRecruiters.success)
  yield put(push('/manager/jobs?ownership=all&page=1'))
}

function* getRecruiters() {
  yield takeLatest(R.recruitersList.type, R.ensureRecruitersList)
  yield take(R.recruitersList.success)
}

function* postJobs() {
  yield takeLatest(J.postJob.TRIGGER, J.ensurePostJob)
  yield take(J.postJob.SUCCESS)
  yield put(push('/manager/jobs'))
}

function* getJob() {
  yield takeLatest(J.jobData.type, J.ensureGetJob)
  yield take(J.jobData.success)
  const {
    params: { id }
  } = yield select(Router.matchRoutes)
  const job = yield select(jobSelector)
  const jobStatus = yield select(jobStatusSelector)
  if (job && !job.isEmpty() && job.get('status') === jobStatus.get('closed')) {
    yield put(push(`/manager/jobs/view/${id}`))
  }
}
function* getJobForClone() {
  yield takeLatest(J.jobData.type, J.ensureGetJob)
  yield take(J.jobData.success)
}

function* putJob() {
  yield takeLatest(J.putJob.TRIGGER, J.ensurePutJob)
  yield take(J.putJob.SUCCESS)
  yield put(push('/manager/jobs'))
}

function* deleteJob() {
  yield takeLatest(J.deleteJob.TRIGGER, J.ensureDeleteJob)
  yield take(J.deleteJob.SUCCESS)
  yield put(push('/manager/jobs'))
}

function* getClientsForFob() {
  yield takeLatest(C.clientsListForJob.type, C.ensureGetClients)
}

function* initialLoad() {
  yield put({ type: pageIsLoading, payload: true })
  try {
    yield all([
      call(L.ensureGetCounties),
      call(R.ensureRecruitersList),
      call(J.ensureGetJob),
      call(C.ensureGetClients)
    ])
    yield put({ type: 'profile/CLEAR_CITIES' })
  } finally {
    yield put({ type: pageIsLoading, payload: false })
  }
}
