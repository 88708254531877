import { all, takeLatest, take, fork, call } from 'redux-saga/effects'
import { calendar } from 'pages/Recruiter/Calendar/sagas'
import * as A from 'domain/recruiter/managerProfileView'
import { ensureCandidateJobsList } from 'domain/recruiter/calendar/sagas'

export function* profilePage() {
  yield all([fork(getProfile), fork(calendar), fork(getCandidateJob)])
}

function* getProfile() {
  yield call(A.ensureGetManager)
  yield takeLatest(A.managerProfileView.type, A.ensureGetManager)
  yield take(A.managerProfileView.success)
}

function* getCandidateJob() {
  yield takeLatest((action: any) => {
    if (
      action.type === '@@redux-form/CHANGE' &&
      action.meta.form === 'add-event' &&
      action.meta.field === 'candidate'
    ) {
      return action
    }
  }, ensureCandidateJobsList)
}
