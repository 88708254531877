import React, { ChangeEvent, Component } from 'react'
// redux
import { EventWithDataHandler, InjectedFormProps, reduxForm } from 'redux-form'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { cities, clearCities } from 'domain/locations'
// components
import { Button } from 'components/Button'
import { FieldInput } from 'components/Form/Fields/FieldInput'
import { FieldSelect } from 'components/Form/Fields/FieldSelect'
import Input from 'components/Form/Input'
import Select from 'components/Form/Select'
// types
import { ButtonHtmlTypes, ButtonTypes, ComposeType, SelectListT } from 'types/common'
import { Classes } from 'jss'
import { ClientFormValues, ClientListItemT } from 'types/clientTypes'
import { StateInterface } from 'types/state'
// styles
import { sheet } from './sheet'
import injectSheet from 'react-jss'
// utils
import { validateClient } from './validation'
import { normalizePhone, upper } from 'lib/normalize'
import { debounce, get } from 'lodash'
import { collectiveInitialValues } from './helpers'
import { removeDuplicatesByLabel, sortPhoneCodes } from 'lib/helpers'
import { filterPhonePrefixByInputValue } from 'lib/selectOptions'

interface SelectOptionType {
  value: number
  label: string
}

export interface ExternalProps {
  openCloseModal: () => void
  onSubmit: (data: ClientFormValues) => void
  isNewClient: boolean
  deleteClient?: (id: number) => void
  formError: string
}

interface OwnProps {
  classes: Classes
  isLoading: boolean
  formValues: ClientFormValues
  clearCities: () => void
  getCities: ({ name }: { name: string }) => void
  countriesList: SelectListT & Array<{ phoneCode: string }>
  citiesList: SelectListT
  client: ClientListItemT
  phoneCodes: SelectListT
  phonePrefix: SelectOptionType
}

type Props = InjectedFormProps<ClientFormValues> & OwnProps & ExternalProps

interface State {
  countryId: number | null
}

class Index extends Component<Props, State> {
  state = {
    countryId: null
  }

  changeSelect = debounce((val: any) => {
    if (this.props.formValues && this.props.formValues.city && !val) return
    this.props.clearCities()
    this.props.getCities({ name: val })
  }, 500)

  changeCountryId = (country: any) => {
    this.setState({ countryId: country.value }, () => {
      const newPrefix = this.props.phoneCodes.find(item => item.value === this.state.countryId)
      this.props.change('phonePrefix', newPrefix || null)
      this.onChangePrefixState(newPrefix)
      this.props.change('city', {})
      this.props.clearCities()
    })
  }

  clickDeleteClient = () => {
    if (this.props.deleteClient) this.props.deleteClient(this.props.client.get('id'))
  }

  onChangePrefix: EventWithDataHandler<ChangeEvent<SelectOptionType>> = data => {
    this.onChangePrefixState(data)
  }

  onChangePrefixState = (value: any) => {
    if (value) {
      this.props.change('phone', normalizePhone(value.label)(get(this.props, 'formValues.phone')))
    }
  }

  render() {
    if (this.props.isLoading) return null
    const {
      classes,
      handleSubmit,
      countriesList,
      citiesList,
      onSubmit,
      isNewClient,
      deleteClient,
      client,
      formError,
      phoneCodes,
      formValues
    } = this.props
    const noDelete =
      client && !client.isEmpty() && client.get('jobs') && client.get('jobs').size > 0
    const valuePrefix = formValues && formValues.phonePrefix ? formValues.phonePrefix.label : ''
    const normalizeFunc = normalizePhone(valuePrefix)
    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={classes.root}>
          <h4>{isNewClient ? 'Add new client' : 'Edit client'}</h4>
          {formError && <p className={classes.error}>{formError}</p>}
          <FieldInput
            name="companyName"
            type="text"
            component={Input}
            title="Company name"
            normalize={upper}
          />
          <div className={classes.location}>
            <FieldSelect
              name="city"
              component={Select}
              options={citiesList}
              filterOption={() => true}
              onInputChange={val => this.changeSelect(val)}
              isCity={true}
              title="Location"
            />
          </div>
          <FieldInput
            name="contactPerson"
            type="text"
            component={Input}
            title="Contact person"
            normalize={upper}
          />
          <FieldInput
            name="contactPersonsPosition"
            type="text"
            component={Input}
            title="Contact person`s Position"
            normalize={upper}
            placeholder="e.g. Project Manager"
          />

          <div>
            <label className={classes.phoneLabel}>Phone</label>
            <div className={classes.phone}>
              <FieldSelect
                menuPortalTarget={document.body}
                customLayoutClasses={classes.phoneSelect}
                name="phonePrefix"
                component={Select}
                options={sortPhoneCodes(removeDuplicatesByLabel(phoneCodes))}
                filterOption={filterPhonePrefixByInputValue}
                onChange={this.onChangePrefix}
                withoutBorder={true}
              />
              <FieldInput
                customLayoutClasses={classes.phoneSelect}
                name="phone"
                type="text"
                component={Input}
                normalize={normalizeFunc}
              />
            </div>
          </div>
          <FieldInput
            name="email"
            type="text"
            component={Input}
            title="Email"
            placeholder="example@email.co"
          />
          <div className={classes.button}>
            {!isNewClient && deleteClient && !noDelete && (
              <Button
                type={ButtonTypes.RedButton}
                label="delete"
                onClick={this.clickDeleteClient}
              />
            )}
            <Button
              type={ButtonTypes.Submit}
              htmlType={ButtonHtmlTypes.Submit}
              label={isNewClient ? 'add client' : 'update'}
            />
          </div>
        </div>
      </form>
    )
  }
}

export default compose<ComposeType<Props, ExternalProps>>(
  connect(
    (state: StateInterface, ownProps: ExternalProps) => collectiveInitialValues(state, ownProps),
    {
      getCities: cities,
      clearCities
    }
  ),
  reduxForm({
    form: 'client',
    validate: validateClient,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true
  }),
  injectSheet(sheet)
)(Index)
