import React from 'react'
import Svg, { SvgType } from 'components/svgComponent/Svg'

export default function(props: SvgType) {
  return (
    <Svg width="29" height="32" viewBox="0 0 29 32" fill="none" {...props}>
      <path
        d="M5.16106 3.87187C4.33748 3.67286 4.37269 2.49107 5.20546 2.33951L14.3583 0.674739C14.4517 0.6579 14.5466 0.6579 14.6399 0.674739L23.7928 2.33951C24.1862 2.41146 24.402 2.71304 24.4327 3.03221C24.451 3.21897 24.4066 3.41186 24.2964 3.568V8.48732C24.2964 8.92054 23.9459 9.2711 23.5126 9.2711C23.0794 9.2711 22.7288 8.92054 22.7288 8.48732V4.14053L19.5937 4.71077V7.70353C15.9213 5.0296 13.592 4.79829 9.40452 7.70353V4.71077L5.16106 3.87187Z"
        fill="#286EFA"
      />
      <path
        d="M4.91895 28.9354C4.91895 24.6871 8.3629 21.2432 12.6112 21.2432H16.3888C20.6372 21.2432 24.0811 24.6871 24.0811 28.9354C24.0811 29.6428 23.5077 30.2161 22.8004 30.2161H6.19964C5.49233 30.2161 4.91895 29.6428 4.91895 28.9354Z"
        stroke="#286EFA"
        strokeWidth="2"
      />
      <circle cx="14.5001" cy="11.2295" r="5.66216" stroke="#286EFA" strokeWidth="2" />
    </Svg>
  )
}
