import { put, call, select } from 'redux-saga/effects'
import { modalIsLoading, pageIsLoading } from 'domain/loading'
import * as Actions from './actions'
import * as M from 'domain/env'
import Api from 'domain/api'
import I from 'immutable'
import { InviteCandidate } from 'types/candidateTypes'
import { ensurePendingCandidatesList } from 'domain/candidate/dashboard'
interface Props {
  payload?: Array<{ email: string }>
  type: string
}

export function* ensureInviteCandidates({ payload }: Props) {
  const headers = yield select(M.userToken)
  if (!headers) return
  try {
    yield put({ type: modalIsLoading, payload: true })
    if (!payload) {
      return
    }
    const emails = payload.map(item => item.email)

    const {
      data: { data }
    } = yield call(Api.postCandidateInvites, {
      headers: { Authorization: `Bearer ${headers}` },
      data: { emails }
    })

    const filterData = data.filter((item: InviteCandidate) => !item.available)

    yield call(ensurePendingCandidatesList)
    yield put({ type: Actions.inviteCandidates.SUCCESS, payload: I.fromJS(filterData) })
  } catch (err) {
    yield put({
      type: Actions.inviteCandidates.FAILURE,
      err
    })
  } finally {
    yield put({ type: modalIsLoading, payload: false })
  }
}

export function* ensureCandidatesList() {
  const headers = yield select(M.userToken)
  if (!headers) return
  try {
    const {
      data: { data }
    } = yield call(Api.getCandidatesAcceptedInvites, {
      headers: { Authorization: `Bearer ${headers}` }
    })
    yield put({ type: Actions.candidatesList.success, payload: I.fromJS(data) })
  } catch (err) {
    yield put({
      type: Actions.candidatesList.failure,
      err
    })
  }
}

export function* ensureInvitedCandidatesList() {
  const headers = yield select(M.userToken)
  if (!headers) return
  try {
    const {
      data: { data }
    } = yield call(Api.getPendingCandidates, {
      headers: { Authorization: `Bearer ${headers}` }
    })
    yield put({ type: Actions.invitedCandidatesList.success, payload: I.fromJS(data) })
  } catch (err) {
    yield put({
      type: Actions.invitedCandidatesList.failure,
      err
    })
  }
}

export function* ensureDeleteCandidate(props: { payload: { id: number }; type: string }) {
  yield put({ type: pageIsLoading, payload: true })
  const headers = yield select(M.userToken)
  if (!headers) return
  try {
    console.log('ensureDeleteCandidate')
    // yield call(Api.deleteRecruiter, {
    //   headers: { Authorization: `Bearer ${headers}` },
    //   id: props.payload.id
    // })
    // yield put({ type: Actions.deleteCandidate.SUCCESS, payload: props.payload.id })
  } catch (err) {
    yield put({
      type: Actions.deleteCandidate.FAILURE,
      err
    })
  } finally {
    yield put({ type: pageIsLoading, payload: false })
  }
}

export function* ensureDeclineInvitationToCandidate(props: {
  payload: { id: number }
  type: string
}) {
  yield put({ type: pageIsLoading, payload: true })
  const headers = yield select(M.userToken)
  if (!headers) return
  try {
    yield call(Api.cancelCandidateInvite, {
      headers: { Authorization: `Bearer ${headers}` },
      inviteId: props.payload.id
    })
    yield put({ type: Actions.declineInvitationToCandidate.SUCCESS, payload: props.payload.id })
  } catch (err) {
    yield put({
      type: Actions.declineInvitationToCandidate.FAILURE,
      err
    })
  } finally {
    yield put({ type: pageIsLoading, payload: false })
  }
}

export function* ensureResendInvitationToCandidate(props: {
  payload: { id: number }
  type: string
}) {
  yield put({ type: pageIsLoading, payload: true })
  const headers = yield select(M.userToken)
  if (!headers) return
  try {
    const {
      data: { data }
    } = yield call(Api.resendCandidateInvite, {
      headers: { Authorization: `Bearer ${headers}` },
      inviteId: props.payload.id
    })
    yield put({ type: Actions.resendInvitationToCandidates.success, payload: I.fromJS(data) })
  } catch (err) {
    yield put({
      type: Actions.resendInvitationToCandidates.failure,
      err
    })
  } finally {
    yield put({ type: pageIsLoading, payload: false })
  }
}
