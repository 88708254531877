import { Styles } from 'react-jss'

export const sheet: Styles = {
  tab: {},
  info: {},
  main: {
    height: '100%',
    margin: '0 auto',
    minWidth: 360,
    display: 'flex',
    position: 'relative',
    transition: 'margin .6s ease',
    overflow: 'hidden'
  },
  rootContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%'
  },
  root: {
    padding: 30,
    maxHeight: 'calc(100vh - 52px)',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'row',
    overflow: 'scroll',
    '& > div': {
      display: 'flex',
      flexDirection: 'column'
    },
    '& > div:first-child': {
      flex: 3,
      marginRight: '5%',
      minWidth: 333
    },
    '& > div:last-child': {
      flex: 1.2
    },
    '@media (max-width: 767px)': { padding: '15px 15px 15px 25px' }
  },
  header: {
    marginTop: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: 0,
    marginBottom: 16,
    minHeight: 'fit-content',
    borderBottom: '1px solid #E6E6E6',
    '& > $tab': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      width: '100%',
      '& > h4': {
        margin: '0 5% 0 0',
        fontWeight: '500',
        whiteSpace: 'nowrap',
        position: 'relative',
        cursor: 'default',
        '&:before': {
          position: 'absolute',
          content: '""',
          top: '50%',
          width: 0,
          height: 0,
          borderTop: '6px solid transparent',
          borderBottom: '6px solid transparent',
          borderRight: '6px solid #080910',
          marginLeft: -15,
          transform: 'translateY(-50%)'
        }
      }
    },
    '& > $info': {
      display: 'flex',
      paddingTop: 40,
      paddingBottom: 8,
      justifyContent: 'space-between',
      '& > p': {
        margin: 0,
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: 15,
        lineHeight: '20px',
        letterSpacing: '0.01em',
        color: '#989FA6'
      },
      '& > p:last-child': {
        fontWeight: 'bold',
        lineHeight: '19px',
        color: '#080910',
        display: 'flex',
        flexDirection: 'row',
        whiteSpace: 'nowrap',
        alignItems: 'center',
        cursor: 'default',
        '& > span:first-child': {
          paddingRight: 25
        },
        '& > span:last-child': {
          width: 12,
          height: 12,
          margin: '0 5px 0 -15px',
          flex: 'none',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }
      }
    }
  },
  tableBody: {
    overflow: 'auto',
    height: '100%'
  },
  NONE: {
    width: 0,
    height: 0,
    borderTop: '6px solid transparent',
    borderBottom: '6px solid transparent',
    borderRight: '6px solid #080910',
    transition: 'all .2s ease'
  },
  DESC: {
    width: 0,
    height: 0,
    borderLeft: '6px solid transparent',
    borderRight: '6px solid transparent',
    borderBottom: '6px solid #080910',
    transition: 'all .2s ease'
  },
  ASC: {
    width: 0,
    height: 0,
    borderLeft: '6px solid transparent',
    borderRight: '6px solid transparent',
    borderTop: '6px solid #080910',
    transition: 'all .2s ease'
  },
  '@media (max-width: 767px)': {
    openSidebarOverlay: {
      position: 'absolute',
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
      background: 'rgba(0, 0, 0, 0.4)',
      zIndex: 1000
    }
  }
}
