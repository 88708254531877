import React from 'react'
import Svg, { SvgType } from 'components/svgComponent/Svg'

export default function(props: SvgType) {
  const { iconActive } = props
  const fill = iconActive ? '#FAFAFA' : '#E6E6E6'
  const opacity = iconActive ? '1' : '0.5'
  return (
    <Svg width="22" height="22" viewBox="0 0 22 22" fill="none" {...props}>
      <g opacity={opacity}>
        <path
          d="M11 0C7.45312 0 4.58203 2.87305 4.58203 6.41699V14.208H2.75C2.24219 14.208 1.83203 14.6191 1.83203 15.125C1.83203 15.6309 2.24219 16.042 2.75 16.042L4.58203 16.0635H17.4141L19.25 16.042C19.7578 16.042 20.168 15.6309 20.168 15.125C20.168 14.6191 19.7578 14.208 19.25 14.208H17.4141V6.41699C17.4141 2.87305 14.543 0 11 0Z"
          fill={fill}
        />
        <path
          d="M12.8346 18.333C12.8346 19.3455 12.0138 20.1663 11.0013 20.1663C9.98878 20.1663 9.16797 19.3455 9.16797 18.333"
          stroke={fill}
          strokeWidth="1.83333"
        />
      </g>
    </Svg>
  )
}
