import React from 'react'
import Svg, { SvgType } from 'components/svgComponent/Svg'

export default function(props: SvgType) {
  return (
    <Svg width="37" height="28" viewBox="0 0 37 28" fill="none" {...props}>
      <rect x="1" y="1" width="30" height="21" rx="1" stroke="#286EFA" strokeWidth="2" />
      <circle cx="15.5" cy="11.5" r="5.5" stroke="#286EFA" strokeWidth="2" />
      <circle cx="6.5" cy="11.5" r="1.5" fill="#286EFA" />
      <circle cx="25.5" cy="11.5" r="1.5" fill="#286EFA" />
      <path d="M3.5 24V26.5H35.5V6H33.5" stroke="#286EFA" strokeWidth="2" strokeLinejoin="round" />
    </Svg>
  )
}
