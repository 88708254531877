import React from 'react'
import Svg, { SvgType } from './Svg'

export default function(props: SvgType) {
  return (
    <Svg width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
      <path
        d="M17.5965 14.2371L14.8167 11.4507C14.263 10.898 13.346 10.9148 12.7731 11.4893L11.3726 12.8926C11.2841 12.8437 11.1925 12.7927 11.0962 12.7385C10.2118 12.2474 9.0014 11.5742 7.72766 10.2967C6.45015 9.01641 5.77788 7.80136 5.28635 6.91439C5.23449 6.82043 5.18479 6.72983 5.13572 6.64379L6.07565 5.70313L6.53775 5.23941C7.11161 4.66409 7.12743 3.74536 6.57504 3.19103L3.79522 0.404295C3.24282 -0.149249 2.32549 -0.132456 1.75163 0.442856L0.968184 1.23259L0.989593 1.25389C0.726892 1.58985 0.507372 1.97733 0.344011 2.39519C0.193424 2.79293 0.0996685 3.17248 0.0567986 3.55281C-0.310259 6.60269 1.0803 9.39005 4.85409 13.1724C10.0706 18.4004 14.2744 18.0055 14.4558 17.9862C14.8508 17.9389 15.2293 17.8443 15.614 17.6946C16.0273 17.5327 16.4136 17.313 16.7486 17.0503L16.7658 17.0655L17.5594 16.2866C18.1321 15.7114 18.1486 14.7923 17.5965 14.2371Z"
        fill="#E6E6E6"
      />
    </Svg>
  )
}
