import React from 'react'
import Svg, { SvgType } from './Svg'

export default function(props: SvgType) {
  return (
    <Svg width="33" height="33" viewBox="0 0 33 33" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.17819 5.41055C7.03421 5.41055 7.80285 4.68878 7.80285 3.70528C7.80285 2.72177 7.03421 2 6.17819 2C5.32217 2 4.55354 2.72177 4.55354 3.70528C4.55354 4.68878 5.32217 5.41055 6.17819 5.41055ZM6.17819 7.41055C8.18003 7.41055 9.80285 5.75164 9.80285 3.70528C9.80285 1.65891 8.18003 0 6.17819 0C4.17635 0 2.55354 1.65891 2.55354 3.70528C2.55354 5.75164 4.17635 7.41055 6.17819 7.41055Z"
        fill="#286EFA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.51276 9.13215C3.51187 8.57101 4.75114 8.52729 6.17819 8.52729C7.64694 8.52729 8.91133 8.63162 9.9291 9.21914C11.0012 9.83801 11.6187 10.8771 12.0984 12.2684C12.4418 13.2641 11.6755 14.2331 10.6873 14.2331H1.7003C0.680502 14.2331 -0.0759482 13.2147 0.30295 12.2178C0.849993 10.7785 1.45502 9.72622 2.51276 9.13215ZM2.4606 12.2331H9.93927C9.61405 11.5077 9.28839 11.1586 8.92921 10.9513C8.41713 10.6557 7.64264 10.5273 6.17819 10.5273C4.66361 10.5273 3.96279 10.6116 3.49214 10.8759C3.16058 11.0622 2.84044 11.4033 2.4606 12.2331Z"
        fill="#286EFA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.17819 24.178C7.03421 24.178 7.80285 23.4562 7.80285 22.4727C7.80285 21.4892 7.03421 20.7674 6.17819 20.7674C5.32217 20.7674 4.55354 21.4892 4.55354 22.4727C4.55354 23.4562 5.32217 24.178 6.17819 24.178ZM6.17819 26.178C8.18003 26.178 9.80285 24.5191 9.80285 22.4727C9.80285 20.4263 8.18003 18.7674 6.17819 18.7674C4.17635 18.7674 2.55354 20.4263 2.55354 22.4727C2.55354 24.5191 4.17635 26.178 6.17819 26.178Z"
        fill="#286EFA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.51287 27.8993C3.51197 27.3383 4.75123 27.2947 6.17819 27.2947C7.64686 27.2947 8.91124 27.3989 9.92899 27.9863C11.0011 28.605 11.6186 29.644 12.0984 31.0353C12.4418 32.031 11.6755 33 10.6873 33H1.7003C0.680504 33 -0.0759538 31.9817 0.302955 30.9847C0.850006 29.5454 1.45506 28.4932 2.51287 27.8993ZM2.46061 31H9.93925C9.61406 30.2747 9.28843 29.9258 8.92931 29.7185C8.41722 29.423 7.64272 29.2947 6.17819 29.2947C4.66353 29.2947 3.9627 29.3789 3.49203 29.6432C3.16054 29.8293 2.84043 30.1703 2.46061 31Z"
        fill="#286EFA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.7124 8.81977H14.9809V6.81977H21.7124V8.81977Z"
        fill="#286EFA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.7124 27.0659H14.9809V25.0659H21.7124V27.0659Z"
        fill="#286EFA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.4083 9.46899C28.2929 9.46899 29.033 8.74337 29.033 7.81977C29.033 6.89617 28.2929 6.17054 27.4083 6.17054C26.5238 6.17054 25.7837 6.89617 25.7837 7.81977C25.7837 8.74337 26.5238 9.46899 27.4083 9.46899ZM27.4083 11.469C29.4102 11.469 31.033 9.83518 31.033 7.81977C31.033 5.80436 29.4102 4.17054 27.4083 4.17054C25.4065 4.17054 23.7837 5.80436 23.7837 7.81977C23.7837 9.83518 25.4065 11.469 27.4083 11.469Z"
        fill="#286EFA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.5669 26.0129L27.8741 28.3358L30.1813 26.0129L27.8741 23.6901L25.5669 26.0129ZM23.4528 25.3034C23.0636 25.6953 23.0636 26.3306 23.4528 26.7224L27.1694 30.4642C27.5586 30.8561 28.1896 30.8561 28.5788 30.4642L32.2954 26.7224C32.6846 26.3306 32.6846 25.6953 32.2954 25.3035L28.5788 21.5616C28.1896 21.1698 27.5586 21.1698 27.1694 21.5616L23.4528 25.3034Z"
        fill="#286EFA"
      />
    </Svg>
  )
}
