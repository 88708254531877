import { all, takeLatest, take, fork, call, put } from 'redux-saga/effects'
import * as A from 'domain/profile'
import * as L from 'domain/locations'
import * as E from 'domain/env'
import * as SocialLinks from 'domain/socialLinks/actions'
import * as Avatar from 'domain/avatar/actions'
import * as R from 'domain/manager/recruiters'
import * as CR from 'domain/manager/collaborationRequests'
import { ensureAddSocialLinks } from 'domain/socialLinks/sagas'
import { ensureAddAvatar } from 'domain/avatar/sagas'
import { pageIsLoading } from 'domain/loading'
import { ensureSuperManagerRequest } from 'domain/env/sagas'

export function* profilePage() {
  yield all([
    fork(deleteRecruiter),
    fork(declineInvitation),
    fork(resendInvitation),
    fork(addAvatar),
    fork(addSocialLinks),
    fork(updateProfile),
    fork(getRecruiters),
    fork(initialLoad),
    fork(changePassword),
    fork(makeSuperManagerRequest),
    fork(manageManagerCollaborationRequest)
  ])
}

function* manageManagerCollaborationRequest() {
  yield takeLatest(
    CR.rejectManagerCollaborationRequest.TRIGGER,
    CR.ensureRejectCollaborationRequest
  )
  yield takeLatest(
    CR.acceptManagerCollaborationRequest.TRIGGER,
    CR.ensureAcceptCollaborationRequest
  )
}

function* makeSuperManagerRequest() {
  yield takeLatest(E.superManagerRequest.type, ensureSuperManagerRequest)
}

function* changePassword() {
  yield takeLatest(A.changePassword.TRIGGER, A.ensureChangePassword)
}

function* deleteRecruiter() {
  yield takeLatest(R.deleteRecruiter.TRIGGER, R.ensureDeleteRecruiter)
}

function* declineInvitation() {
  yield takeLatest(R.declineInvitationToRecruiter.TRIGGER, R.ensureDeclineInvitationToRecruiter)
}

function* resendInvitation() {
  yield takeLatest(R.resendInvitationToRecruiter.type, R.ensureResendInvitationToRecruiter)
}

function* addAvatar() {
  yield takeLatest(Avatar.addAvatar.type, ensureAddAvatar)
  yield take(Avatar.addAvatar.success)
}

function* updateProfile() {
  yield takeLatest(A.updateProfile.TRIGGER, A.ensureUpdateProfile)
  yield take(A.updateProfile.SUCCESS)
}

function* getRecruiters() {
  yield takeLatest(R.recruitersList.type, R.ensureRecruitersList)
  yield take(R.recruitersList.success)
}

function* addSocialLinks() {
  yield takeLatest(SocialLinks.addSocialLinks.type, ensureAddSocialLinks)
  yield take(SocialLinks.addSocialLinks.success)
}

function* initialLoad() {
  yield put({ type: pageIsLoading, payload: true })
  try {
    yield all([
      call(R.ensureRecruitersList),
      call(L.ensureGetCounties),
      call(R.ensureInvitedRecruitersList),
      call(CR.ensureCollaborationRequests)
    ])
    yield put({ type: 'profile/CLEAR_CITIES' })
  } finally {
    yield put({ type: pageIsLoading, payload: false })
  }
}
