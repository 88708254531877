import * as A from './actions'
import * as Env from 'domain/env'
import { Action } from 'redux'
import { StateInterface } from 'types/state'

interface ActionType extends Action {
  payload: boolean
}

export const loading = (state: StateInterface) => state.loadingBlock.loading
export const loadingCalendarEvent = (state: StateInterface) =>
  state.loadingBlock.loadingCalendarEvent
export const error403Selector = (state: StateInterface) => state.loadingBlock.error403
export const pageIsLoadingSelector = (state: StateInterface) => state.loadingBlock.pageIsLoading
export const modalIsLoadingSelector = (state: StateInterface) => state.loadingBlock.modalIsLoading
export const skeletonSelector = (state: StateInterface) => state.loadingBlock.skeleton

const State = {
  loading: false,
  loadingCalendarEvent: false,
  pageIsLoading: false,
  modalIsLoading: false,
  error403: false,
  skeleton: false
}

export const reducer = {
  loadingBlock(state = State, action: ActionType) {
    switch (action.type) {
      case A.isLoading:
        return { ...state, loading: action.payload }
      case A.isLoadingCalendarEvent:
        return { ...state, loadingCalendarEvent: action.payload }
      case A.error403:
        return { ...state, error403: action.payload }
      case A.pageIsLoading:
        return { ...state, pageIsLoading: action.payload }
      case A.modalIsLoading:
        return { ...state, modalIsLoading: action.payload }
      case A.skeleton:
        return { ...state, skeleton: action.payload }
      case Env.signOut.success:
        return State
      default:
        return state
    }
  }
}
