import { Field } from 'redux-form'

export interface CheckBoxPropsType {
  label?: JSX.Element | string
  name: string
  type?: string
  value?: string
  disabled?: boolean
}

export const FieldCheckBox = Field as new () => Field<CheckBoxPropsType>
