import { peek, selector } from 'lib/selectors'
import { StateInterface } from 'types/state'

const candidate = (state: StateInterface) => state.candidate
export const recommendJobSelector = selector(candidate, peek('recommendJob'))
export const employeeInfoSelector = selector(candidate, peek('employeeInfo'))
export const jobsSelector = selector(candidate, peek('jobsListData'))
export const jobsMetaSelector = selector(candidate, peek('jobsListMeta'))
export const jobSelector = selector(candidate, peek('jobData'))
export const managerProfileSelector = selector(candidate, peek('managerProfile'))
export const recruiterProfileSelector = selector(candidate, peek('recruiterProfile'))
export const candidateProfileSelector = selector(candidate, peek('candidateProfile'))
export const eventsListSelector = selector(candidate, peek('calendar', 'eventsList'))
export const eventSelector = selector(candidate, peek('calendar', 'event'))
export const nearestEventIdSelector = selector(candidate, peek('calendar', 'nearestEventId'))
export const candidateJobsCountSelector = selector(candidate, peek('jobsCount'))
export const dashboardSelector = selector(candidate, peek('dashboard'))
export const candidatesEmails = selector(candidate, peek('dashboard', 'pendingCandidates'))
export const candidateStatusSelector = selector(candidate, peek('candidateStatus'))
