import { compose } from 'redux'
import { rules, addError } from 'lib/validationRules'

const MIN_8 = 'Password must contain 8 char'
const MAX_40 = '40 chars max'
const MIN_2 = 'at least 2 chars'

type AuthValidator = (values: { [x: string]: string }) => (errors: {}, fieldName: string) => {}
const authValidator: AuthValidator = values => (errors, fieldName): {} => {
  const val = values[fieldName]
  switch (fieldName) {
    case 'lastName':
      return compose(
        addError(fieldName, rules.strMinLength(val, 2, MIN_2)),
        addError(fieldName, rules.strMaxLength(val, 40, MAX_40)),
        addError(fieldName, rules.required(val))
      )(errors)

    case 'firstName':
      return compose(
        addError(fieldName, rules.strMinLength(val, 2, MIN_2)),
        addError(fieldName, rules.strMaxLength(val, 40, MAX_40)),
        addError(fieldName, rules.required(val))
      )(errors)

    case 'email':
      return compose(
        addError(fieldName, rules.email(val)),
        addError(fieldName, rules.required(val))
      )(errors)

    case 'password':
    case 'newPassword':
      return compose(
        addError(fieldName, rules.strMinLength(val, 8, MIN_8)),
        addError(fieldName, rules.strMaxLength(val, 40, MAX_40)),
        addError(fieldName, rules.required(val))
      )(errors)

    case 'confirmationPassword':
    case 'confirmPassword':
      const compareValue = values.newPassword || values.password
      return compose(
        addError(fieldName, rules.same(val, compareValue)),
        addError(fieldName, rules.required(val))
      )(errors)

    case 'accept':
      return compose(addError(fieldName, rules.required(val)))(errors)

    default:
      return errors
  }
}

export const signInValidate = (values: { [x: string]: string }) =>
  ['email', 'password'].reduce(authValidator(values), {})

export const signUpValidate = (values: { [x: string]: string }) =>
  ['firstName', 'lastName', 'email', 'password', 'confirmationPassword', 'accept'].reduce(
    authValidator(values),
    {}
  )

export const resetPassword = (values: { [x: string]: string }) =>
  ['email'].reduce(authValidator(values), {})

export const createPassword = (values: { [x: string]: string }) =>
  ['password', 'confirmationPassword'].reduce(authValidator(values), {})

export const createPasswordRecruiter = (values: { [x: string]: string }) =>
  ['firstName', 'lastName', 'password', 'confirmationPassword'].reduce(authValidator(values), {})

export const changePasswordValidation = (values: { [x: string]: string }) =>
  ['oldPassword', 'newPassword', 'confirmPassword'].reduce(authValidator(values), {})
