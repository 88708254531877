import { asyncAction } from 'lib/actionCreators'

export const managerJobsCounters = asyncAction('manager/DASHBOARD_JOBS_COUNTERS')
export const managerClientsCounters = asyncAction('manager/DASHBOARD_CLIENTS_COUNTERS')
export const managerClientClosureRate = asyncAction('manager/DASHBOARD_CLIENTS_CLOSURE_RATE')
export const managerNewClientClosureRate = asyncAction('manager/DASHBOARD_NEW_CLIENTS_CLOSURE_RATE')
export const managerStatistics = asyncAction('manager/DASHBOARD_STATISTICS')
export const managerTopRecruiters = asyncAction('manager/DASHBOARD_TOP_RECRUITERS')
export const managerClosedJobs = asyncAction('manager/DASHBOARD_CLOSED_JOBS')
export const managerNewClosedJobs = asyncAction('manager/DASHBOARD_NEW_CLOSED_JOBS')
