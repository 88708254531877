import { Styles } from 'react-jss'

export const sheet: Styles = {
  root: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#202332',
    height: 52,
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '8px 11px',
    position: 'relative',
    width: '100%',
    maxWidth: 'inherit',
    boxSizing: 'border-box',
    zIndex: 1,
    '& > a': {
      textTransform: 'uppercase',
      fontSize: 15,
      margin: 0
    }
  },
  mobileMenu: {
    display: 'none'
  },
  '@media (max-width: 767px)': {
    mobileMenu: {
      display: 'block',
      marginRight: 'auto'
    }
  }
}
