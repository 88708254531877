import { Styles } from 'react-jss'

export const sheet: Styles = {
  header: {
    position: 'relative',
    display: 'grid',
    gridGap: '10px',
    gridTemplateColumns: '0.5fr 1.5fr 8fr 1.5fr 0.5fr',
    gridTemplateRows: '90px 1fr',
    gridTemplateAreas: '". header-logo header-nav auth-block ." ". . header-title . ."',
    height: '93vh'
  },
  menuIcons: {
    display: 'none',
    zIndex: 1,
    gridArea: '2 / 2 / 2 / 2'
  },
  headerLogo: {
    gridArea: 'header-logo',
    justifySelf: 'center',
    alignSelf: 'end',
    zIndex: 1
  },
  headerNav: {
    gridArea: 'header-nav',
    alignSelf: 'end',
    justifySelf: 'start',
    zIndex: 1,
    '& > a': {
      padding: '12px 0 11px'
    }
  },
  headerAuth: {
    gridArea: 'auth-block',
    alignSelf: 'end',
    zIndex: 1,
    display: 'flex',
    justifySelf: 'center'
  },
  headerTitle: {
    gridArea: 'header-title',
    alignSelf: 'center',
    zIndex: 1
  },
  gradient: {
    backgroundImage: 'linear-gradient(180deg, rgba(98, 149, 255, 0.25) 0%, #000000 100%)',
    opacity: '0.8',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    position: 'absolute'
  },
  videoClass: {
    position: 'absolute',
    objectFit: 'cover',
    width: '100%',
    height: '100%',
    zIndex: -1
  },
  '@media (max-width: 992px)': {
    header: {
      gridTemplateColumns: '1fr 10fr 1fr',
      gridTemplateRows: '90px auto auto 1fr',
      gridGap: 0,
      '& .showMenu': {
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%'
      }
    },
    headerLogo: {
      gridArea: '1 / 2 / 1 / 2',
      justifySelf: 'start',
      width: '95px'
    },
    headerAuth: {
      justifyContent: 'flex-end',
      justifySelf: 'end',
      gridArea: '1 / 2 / 1 / 2'
    },
    headerTitle: {
      gridArea: '4 / 2 / 4 / 2',
      alignSelf: 'start',
      '& h1': {
        fontSize: '40px',
        lineHeight: '55px',
        margin: '16px 0'
      }
    },
    headerNav: {
      display: 'none',
      gridArea: '3 / 2 / 3 / 2'
    },
    menuIcons: {
      display: 'flex',
      alignItems: 'center',
      alignSelf: 'start'
    }
  }
}
