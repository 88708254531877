import { all, takeLatest, take, fork, call } from 'redux-saga/effects'
import * as A from 'domain/workExperiences'

export function* experiencesPage() {
  yield all([
    fork(getExperiences),
    fork(addExperience),
    fork(getExperience),
    fork(deleteExperience),
    fork(updateExperience)
  ])
}

function* getExperiences() {
  yield call(A.ensureExperiencesList)
  yield takeLatest(A.workExperiencesList.type, A.ensureExperiencesList)
  yield take(A.workExperiencesList.success)
}

function* addExperience() {
  yield takeLatest(A.addWorkExperiences.TRIGGER, A.ensureAddExperience)
  yield take(A.addWorkExperiences.SUCCESS)
}

function* getExperience() {
  yield takeLatest(A.workExperience.type, A.ensureExperience)
  yield take(A.workExperience.success)
}

function* deleteExperience() {
  yield takeLatest(A.removeWorkExperience.TRIGGER, A.ensureRemoveExperience)
  yield take(A.removeWorkExperience.SUCCESS)
}

function* updateExperience() {
  yield takeLatest(A.updateWorkExperience.TRIGGER, A.ensureUpdateExperience)
  yield take(A.updateWorkExperience.SUCCESS)
}
