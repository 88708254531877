import { Styles } from 'react-jss'

export const sheet: Styles = {
  modalCover: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 10,
    transform: 'translateZ(0)',
    backgroundColor: 'rgba(8, 9, 16, 0.6)'
  },
  modal: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    overflow: 'auto'
  },
  '@media screen and (min-width: 500px) ': {
    modal: {
      left: '50%',
      top: '50%',
      height: 'auto',
      transform: 'translate(-50%, -50%)',
      maxWidth: '70em',
      maxHeight: 'calc(100% - 1em)'
    }
  },
  modalClose: {
    position: 'absolute',
    top: 0,
    right: 0,
    padding: '.5em',
    lineHeight: 1,
    background: 'inherit',
    border: 'none',
    boxShadow: 'none',
    cursor: 'pointer',
    margin: '1.5em',
    zIndex: 10
  },
  modalBody: {
    backgroundColor: '#FCFCFC',
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.2)'
  },
  uLockScroll: {
    overflow: 'hidden !important'
  },
  '@media (min-width: 992px) and (max-width: 1199.98px)': {
    modal: {
      width: '90%'
    }
  },
  '@media (min-width: 768px) and (max-width: 991.98px)': {
    modal: {
      width: '90%'
    }
  },
  '@media (max-width: 767.98px)': {}
}
