import React from 'react'
import Svg, { SvgType } from 'components/svgComponent/Svg'

export default function(props: SvgType) {
  return (
    <Svg width="58" height="60" viewBox="0 0 58 60" fill="none" {...props}>
      <rect x="19" y="23" width="20" height="2" fill="#FAFAFA" />
      <rect x="19" y="29" width="20" height="2" fill="#FAFAFA" />
      <rect x="19" y="35" width="20" height="2" fill="#FAFAFA" />
    </Svg>
  )
}
