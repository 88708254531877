import React, { Component } from 'react'
// redux
import { WrappedFieldProps } from 'redux-form'
// types
import { CheckBoxPropsType } from 'components/Form/Fields/FieldCheckBox'
// styles
import { sheet } from './sheet'
import injectSheet, { StyledComponentProps } from 'react-jss'

type Props = CheckBoxPropsType &
  React.InputHTMLAttributes<HTMLInputElement> &
  WrappedFieldProps &
  StyledComponentProps

class CheckBox extends Component<Props> {
  render() {
    const { input, label, meta, classes = {}, disabled = false, ...rest } = this.props
    return (
      <div className={classes.formGroup}>
        <input
          {...rest}
          {...input}
          type="checkbox"
          id={`${meta.form}_${input.name}`}
          className={classes.formCheckInput}
          disabled={disabled}
        />
        {label && (
          <label className={classes.formCheckLabel} htmlFor={`${meta.form}_${input.name}`}>
            {label}
          </label>
        )}
        {this.validationState && <p className={classes.formText}>{meta.error || meta.warning}</p>}
      </div>
    )
  }

  get validationState() {
    const { touched, error, warning } = this.props.meta

    if (!touched) return
    if (error) return 'error'
    if (warning) return 'warning'
  }
}

export default injectSheet(sheet)(CheckBox)
