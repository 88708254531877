import { put, call, select } from 'redux-saga/effects'
import I from 'immutable'
import Api from 'domain/api'
import * as Actions from './actions'
import * as M from 'domain/env'
import { clientsSelector } from 'domain/manager/selectors'
import { isLoading, skeleton, modalIsLoading, pageIsLoading } from 'domain/loading'
import { location } from 'domain/router'
import { push } from 'connected-react-router'
import { convertClients, parseClientBeforeSendData } from './helpers'
import { ClientFormValues } from 'types/clientTypes'
import { Sort } from 'types/common'

interface Params {
  page?: number
  order?: Sort
  order_by?: string | null
}

export function* ensureGetClients() {
  const headers = yield select(M.userToken)
  if (!headers) return
  try {
    const {
      data: { data }
    } = yield call(Api.getClientsListForJob, {
      headers: { Authorization: `Bearer ${headers}` }
    })
    yield put({ type: Actions.clientsListForJob.success, payload: convertClients(data) })
  } catch (err) {
    yield put({
      type: Actions.clientsListForJob.failure,
      err
    })
  }
}

export function* ensurePostClient({
  payload
}: {
  payload: { data: ClientFormValues }
  type: string
}) {
  const headers = yield select(M.userToken)
  if (!headers) return
  yield put({ type: modalIsLoading, payload: true })
  const clientData = parseClientBeforeSendData(payload.data)
  try {
    const {
      data: { data }
    } = yield call(Api.postClient, {
      headers: { Authorization: `Bearer ${headers}` },
      data: clientData
    })
    yield put({ type: Actions.postClient.SUCCESS, payload: I.fromJS(data) })
    const locationPath = yield select(location)
    if (locationPath && locationPath.pathname && locationPath.pathname === '/manager/clients') {
      yield put(push(`/manager/clients`))
    }
  } catch (err) {
    yield put({
      type: Actions.postClient.FAILURE,
      err
    })
  } finally {
    yield put({ type: modalIsLoading, payload: false })
  }
}

export function* ensureGetClientsList(props?: { payload?: Params; type?: string }) {
  const headers = yield select(M.userToken)
  if (!headers) return
  if (!props || !props.payload || props.payload.page === 1) {
    yield put({ type: skeleton, payload: true })
    yield put({ type: pageIsLoading, payload: true })
  }
  const params = props && props.payload
  try {
    const { data } = yield call(Api.getClientsList, {
      headers: { Authorization: `Bearer ${headers}` },
      params
    })
    const page = params && params.page
    const total = data.meta.total
    const prevState = yield select(clientsSelector)
    if (!params || !page || page === 1 || total === prevState.size) {
      yield put({ type: Actions.newClientsList.success, payload: I.fromJS(data) })
    } else {
      yield put({ type: Actions.clientsList.success, payload: I.fromJS(data) })
    }
  } catch (err) {
    yield put({
      type: Actions.clientsList.failure,
      err
    })
  } finally {
    yield put({ type: skeleton, payload: false })
    yield put({ type: pageIsLoading, payload: false })
  }
}

export function* ensureGetRecruteirClientsList(props?: { payload?: Params; type?: string }) {
  const headers = yield select(M.userToken)
  if (!headers) return
  if (!props || !props.payload || props.payload.page === 1) {
    yield put({ type: skeleton, payload: true })
    yield put({ type: pageIsLoading, payload: true })
  }
  const params = props && props.payload
  try {
    const { data } = yield call(Api.getClientsList, {
      headers: { Authorization: `Bearer ${headers}` },
      params
    })

    yield put({ type: Actions.recruiterClients.success, payload: I.fromJS(data) })
  } catch (err) {
    yield put({
      type: Actions.recruiterClients.failure,
      err
    })
  } finally {
    yield put({ type: skeleton, payload: false })
    yield put({ type: pageIsLoading, payload: false })
  }
}

export function* ensureGetClient({ payload }: { payload: number; type: string }) {
  const headers = yield select(M.userToken)
  if (!headers) return
  yield put({ type: isLoading, payload: true })
  yield put({ type: pageIsLoading, payload: true })
  if (!payload) {
    yield put({ type: Actions.getClient.success, payload: I.fromJS({}) })
    yield put({ type: isLoading, payload: false })
    return
  }
  try {
    const {
      data: { data }
    } = yield call(Api.getClient, {
      headers: { Authorization: `Bearer ${headers}` },
      id: payload
    })
    yield put({ type: Actions.getClient.success, payload: I.fromJS(data) })
  } catch (err) {
    yield put({
      type: Actions.getClient.failure,
      err
    })
  } finally {
    yield put({ type: isLoading, payload: false })
    yield put({ type: pageIsLoading, payload: false })
  }
}

export function* ensurePutClient({
  payload
}: {
  payload: { data: ClientFormValues; params: Params; id: number }
  type: string
}) {
  const headers = yield select(M.userToken)
  if (!headers) return
  yield put({ type: modalIsLoading, payload: true })
  const { params, id } = payload
  const clientData = parseClientBeforeSendData(payload.data)
  try {
    const {
      data: { data }
    } = yield call(Api.putClient, {
      headers: { Authorization: `Bearer ${headers}` },
      id,
      data: clientData
    })
    yield put({ type: Actions.updateClient.SUCCESS, payload: I.fromJS(data) })
    yield call(ensureGetClientsList, { payload: params })
  } catch (err) {
    yield put({
      type: Actions.updateClient.FAILURE,
      err
    })
  } finally {
    yield put({ type: modalIsLoading, payload: false })
  }
}

export function* ensureDeleteClient({
  payload
}: {
  payload: { id: number; params: Params }
  type: string
}) {
  const headers = yield select(M.userToken)
  if (!headers) return
  yield put({ type: modalIsLoading, payload: true })
  const { id, params } = payload
  try {
    const {
      data: { data }
    } = yield call(Api.deleteClient, {
      headers: { Authorization: `Bearer ${headers}` },
      id
    })
    yield put({ type: Actions.deleteClient.SUCCESS, payload: I.fromJS(data) })
    yield call(ensureGetClientsList, { payload: params })
  } catch (err) {
    yield put({
      type: Actions.deleteClient.FAILURE,
      err
    })
  } finally {
    yield put({ type: modalIsLoading, payload: false })
  }
}
