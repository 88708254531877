import { asyncAction } from 'lib/actionCreators'
import { createRoutine, promisifyRoutine } from 'redux-saga-routines'

export const candidateEventsList = asyncAction('CANDIDATE_EVENTS_LIST')
export const candidateEvent = asyncAction('CANDIDATE_EVENT')
export const candidateEventUpdate = createRoutine('CANDIDATE_EVENT_UPDATE')
export const candidateEventDelete = createRoutine('CANDIDATE_EVENT_DELETE')
export const candidateEventAdd = createRoutine('CANDIDATE_EVENT_ADD')
export const candidateEventUpdatePromisify = promisifyRoutine(candidateEventUpdate)
export const candidateEventDeletePromisify = promisifyRoutine(candidateEventDelete)
export const candidateEventAddPromisify = promisifyRoutine(candidateEventAdd)
export const nearestEventId = asyncAction('NEAREST_EVENT_ID')
