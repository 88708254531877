import I from 'immutable'
import { SelectListT } from 'types/common'
import { ClientsForJobI, ClientFormValues } from 'types/clientTypes'

export function convertClients(clients: ClientsForJobI[]): SelectListT {
  return I.fromJS(clients.map(client => ({ value: client.id, label: client.companyName })))
}

export function parseClientBeforeSendData({
  country,
  city,
  phonePrefix,
  phone,
  ...rest
}: ClientFormValues) {
  const newCountryId = country ? country.value : city ? city.country.value : undefined
  return {
    ...rest,
    countryId: newCountryId,
    cityId: city.value,
    phonePrefix: phonePrefix ? phonePrefix.label : undefined,
    phone: phone ? phone.split(' ').join('') : undefined
  }
}
