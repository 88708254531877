import { Styles } from 'react-jss'

export const sheet: Styles = {
  logo: {},
  menu: {},
  menuItems: {},
  item: {},
  notification: {},
  circle: {},
  count: {},
  active: {},
  openMenuButton: {},
  versionText: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    fontSize: '10px',
    color: '#989FA6',
    alignSelf: 'end',
    padding: 5
  },
  root: {
    minWidth: 200,
    maxWidth: 200,
    overflowY: 'auto',
    minHeight: '100vh',
    backgroundColor: '#202332',
    position: 'relative',
    gridArea: 'sidebar',
    '& > $logo': {
      height: 52,
      background: 'rgba(240, 240, 240, 0.1)',
      display: 'flex',
      position: 'relative',
      justifyContent: 'center',
      alignItems: 'center'
    },
    '& > $menu': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      padding: 15,
      margin: '15px 0',
      alignItems: 'center',
      fontWeight: 'normal',
      lineHeight: '20px',
      fontSize: 15,
      letterSpacing: '0.01em',
      color: '#989FA6'
    },
    '& > $menuItems': {
      '& > $item': {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        '& > svg': {
          marginRight: 14
        },
        '&$active': {
          background: ' rgba(230, 230, 230, 0.2)',
          borderLeft: ' 2px solid #E6E6E6',
          boxSizing: 'border-box',
          '& > a': {
            background: 'transparent',
            fontWeight: 'bold',
            lineHeight: '19px',
            color: '#F0F0F0',
            pointerEvents: 'none'
          }
        },
        '& > $notification': {
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          marginRight: '6px',
          '& > $circle': {
            width: 8,
            height: 8,
            backgroundColor: '#286EFA',
            borderRadius: '50%',
            marginRight: 4
          },
          '& > $count': {
            fontWeight: '500',
            lineHeight: '18px',
            fontSize: 13,
            letterSpacing: '0.03em',
            color: '#E6E6E6',
            textAlign: 'center',
            textTransform: 'capitalize'
          }
        },
        '&:hover': {
          background: ' rgba(230, 230, 230, 0.2)',
          transition: 'all .2s ease'
        }
      }
    }
  },
  rootSmall: {
    minWidth: 62,
    maxWidth: 62,
    '& a': {
      margin: 0
    },
    '& > $menu': {
      justifyContent: 'center',
      padding: '15px 20px 15px 15px'
    }
  },
  '@media (max-width: 767px)': {
    rootMobileOpen: {},
    menu: {},
    root: {
      '& $menu': {
        display: 'none'
      },
      '&$rootMobileOpen': {
        transform: 'none'
      },
      position: 'fixed',
      top: '0',
      height: '100vh',
      transform: 'translateX(-100%)',
      transition: 'transform 300ms linear',
      willChange: 'transform',
      zIndex: 1001
    }
  }
}
