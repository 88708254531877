import React from 'react'
// components
import { Button } from 'components/Button'
import NotificationTitle from 'pages/Notifications/NotificationTitle'
// types
import { Classes } from 'jss'
import { ButtonTypes } from 'types/common'
import { CalendarType } from '../types'
// styles
import injectSheet from 'react-jss'
import { sheet } from './sheet'
// icons
import Calendar from 'components/svgComponent/notification/Calendar'
// utils
import cx from 'classnames'
import moment from 'moment'
import { ServerRoles } from 'lib/roles'

interface Props {
  classes: Classes
  data: CalendarType
  viewedAt: boolean
  role: string
  createdAt: string
}

const Index = ({ classes, data, viewedAt, role, createdAt }: Props) => {
  if (!data || data.isEmpty()) return null
  let eventTitle = data.getIn(['event', 'title'], '')
  let pathTitle = ''
  let name = ''
  let text = ''
  const startEvent = data.getIn(['event', 'start'], '')
  const startAt = startEvent ? moment(startEvent).format('MMM D') : ''
  const candidate = data.get('candidate')
  const candidateName =
    candidate && !candidate.isEmpty()
      ? [candidate.get('firstName'), candidate.get('lastName')].join(' ')
      : ''
  const candidateId = candidate && !candidate.isEmpty() ? candidate.get('id') : ''
  const manager = data.get('manager')
  const managerName =
    manager && !manager.isEmpty()
      ? [manager.get('firstName'), manager.get('lastName')].join(' ')
      : ''
  const managerId = manager && !manager.isEmpty() ? manager.get('id') : ''

  if (role === ServerRoles.candidate) {
    eventTitle = managerName ? `${eventTitle} with ` : eventTitle
    name = managerName
    pathTitle = `/candidate/managers/${managerId}`
    text = `· ${candidateName} · ${startAt}`
  }

  if (role === ServerRoles.manager) {
    eventTitle = candidateName ? `${eventTitle} with ` : eventTitle
    name = candidateName
    pathTitle = `/manager/candidates/${candidateId}`
    text = `· ${managerName} · ${startAt}`
  }

  return (
    <div className={cx(classes.main, { [classes.unread]: viewedAt })}>
      <Calendar />
      <div className={classes.root}>
        <div className={classes.row}>
          <div className={classes.title}>
            <NotificationTitle title={eventTitle} />
            <Button
              isLink={true}
              to={pathTitle}
              label={name}
              type={ButtonTypes.NotificationLinkTitle}
            />
          </div>
          <div className={classes.time}>30 min</div>
        </div>
        <p className={classes.text}>{text}</p>
      </div>
      <p className="createdAt">{createdAt}</p>
    </div>
  )
}

export default injectSheet(sheet)(Index)
