import { call, put } from 'redux-saga/effects'
import * as T from 'domain/tags'
import I from 'immutable'
import Api from 'domain/api'

export function* ensureTags() {
  try {
    const {
      data: { data }
    } = yield call(Api.getTags)
    yield put({ type: T.tagsList.success, payload: I.fromJS(data) })
  } catch (err) {
    yield put({
      type: T.tagsList.failure,
      err
    })
  }
}
