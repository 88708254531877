import React, { Component, RefObject } from 'react'
// components
import TableRow from './TableRow'
import Skeleton from './Skeleton'
import Filter from './Filter'
import Sidebar from 'pages/Guest/Sidebar'
import NavBar from 'pages/Guest/NavBar'
import SignUp from 'pages/Auth/SignUp'
import ModalLogin from 'components/ModalAuth'
import LogIn from 'pages/Auth/LogIn'
// redux
import { compose } from 'redux'
import { connect } from 'react-redux'
import { push, Push, goBack, GoBack } from 'connected-react-router'
import { jobsMetaGuestSelector, jobsGuestSelector, guestJobsList } from 'domain/guestJobs'
import { loading, skeletonSelector } from 'domain/loading'
import { Waypoint } from 'react-waypoint'
import { cities, citiesSelector, clearCities, countriesSelector } from 'domain/locations'
import { FormErrors, getFormSyncErrors } from 'redux-form'
import { clearanceTypeSelector, ClearanceTypeT } from 'domain/constants'
// types
import {
  ComposeType,
  Sort,
  SpheresExist,
  SelectListT,
  SpheresItemExist,
  MetaT,
  FormErrorsInterface
} from 'types/common'
import { Classes } from 'jss'
import { JobsFilterCandidate, JobsListCandidate } from 'types/job/jobTypesCandidate'
import { StateInterface } from 'types/state'
// styles
import injectSheet from 'react-jss'
import { sheet } from './sheet'
import { hasMorePages } from 'lib/pagination'
// utils

interface Props {
  push: Push
  goBack: GoBack
  list: JobsListCandidate
  classes: Classes
  isLoading: boolean
  isSkeleton: boolean
  getJobsList: (params: Params) => void
  meta: MetaT
  citiesList: SelectListT
  countriesList: SelectListT
  clearingCities: () => void
  getCities: ({ name }: { name: string }) => void
  formErrors: FormErrors<FormErrorsInterface>
  clearanceTypeConst: ClearanceTypeT
}

interface State {
  filter: FilterType
  page: number
  order?: Sort
  isOpenLogIn: boolean
  isOpenSignUp: boolean
  isOpenMobileSidebar: boolean
}

interface Params {
  order?: string
  filter?: FilterType
  page?: number
}

interface FilterType {
  countryId?: number
  cityId?: number
  tagIds?: string[]
  rateFrom?: string
  rateTo?: string
  rateCurrency?: string
  salaryFrom?: string
  salaryTo?: string
  salaryCurrency?: string
  relocation?: boolean
  employmentType?: string[]
  clearanceType?: string[]
  travel?: boolean
  citizenship?: string[]
}

class JobsList extends Component<Props, State> {
  myRef: RefObject<HTMLDivElement> = React.createRef()
  state = {
    filter: {},
    page: 1,
    order: Sort.None,
    isOpenLogIn: false,
    isOpenSignUp: false,
    isOpenMobileSidebar: false
  }

  toggleLogIn = () => {
    this.setState({ isOpenLogIn: !this.state.isOpenLogIn })
  }

  toggleSignUp = () => {
    this.setState({ isOpenSignUp: !this.state.isOpenSignUp })
  }

  changeSort = () => {
    const nextSortType = {
      [Sort.None]: Sort.Asc,
      [Sort.Asc]: Sort.Desc,
      [Sort.Desc]: Sort.None
    }
    this.setState(
      {
        order: nextSortType[this.state.order],
        page: 1
      },
      this.request
    )
    if (this.myRef && this.myRef.current) {
      this.myRef.current.scrollTo(0, 0)
    }
  }

  loadItems = () => {
    const page = this.state.page + 1
    this.setState({ page }, () => {
      const { meta } = this.props
      if (hasMorePages(meta)) this.request()
    })
  }

  onSubmit = (data: JobsFilterCandidate) => {
    const formErrors = this.props.formErrors
    if (formErrors && Object.keys(formErrors).length > 0) return

    const filter: FilterType = {}

    if (data.country && data.country.value) filter.countryId = data.country.value
    if (data.city && data.city.value) filter.cityId = data.city.value
    const spheres = data.spheres && data.spheres.map((item: SpheresItemExist) => `${item.id}`)
    if (spheres) filter.tagIds = spheres
    if (data.rateFrom) filter.rateFrom = data.rateFrom
    if (data.rateTo) filter.rateTo = data.rateTo
    if (data.rateCurrency && data.rateCurrency.label) filter.rateCurrency = data.rateCurrency.label
    if (data.salaryFrom) filter.salaryFrom = data.salaryFrom
    if (data.salaryTo) filter.salaryTo = data.salaryTo
    if (data.salaryCurrency && data.salaryCurrency.label) {
      filter.salaryCurrency = data.salaryCurrency.label
    }
    if (data.relocation !== undefined) {
      filter.relocation = data.relocation === 'true'
    }
    const employmentType =
      data.employmentType &&
      Object.keys(data.employmentType).filter(item => data.employmentType[item] && item !== 'other')
    if (employmentType && employmentType.length) filter.employmentType = employmentType
    const clearanceType =
      data.clearanceType &&
      Object.keys(data.clearanceType).filter(item => data.clearanceType[item] && item !== 'none')
    if (clearanceType && clearanceType.length) filter.clearanceType = clearanceType
    const citizenship =
      data.citizenship &&
      Object.keys(data.citizenship).filter(item => data.citizenship[item] && item !== 'Other')
    if (citizenship && citizenship.length) filter.citizenship = citizenship

    if (data.travel !== undefined) {
      filter.travel = data.travel === 'yes'
    }

    this.setState({ filter, page: 1 }, this.request)
    if (this.myRef && this.myRef.current) {
      this.myRef.current.scrollTo(0, 0)
    }
  }

  getParamsForRequest = () => {
    const params: Params = {}
    const { filter, page, order } = this.state
    if (filter) {
      params.filter = filter
    }
    params.page = page || 1
    if (order && order !== Sort.None) {
      params.order = order
    }
    return params
  }

  request = () => {
    this.props.getJobsList(this.getParamsForRequest())
  }

  toggleSidebarMenu = () => {
    this.setState(prevState => ({ isOpenMobileSidebar: !prevState.isOpenMobileSidebar }))
  }

  render() {
    const { page, order, isOpenSignUp, isOpenLogIn, isOpenMobileSidebar } = this.state
    const {
      classes,
      list,
      isLoading,
      isSkeleton,
      meta,
      citiesList,
      countriesList,
      clearingCities,
      getCities,
      clearanceTypeConst
    } = this.props

    const total = (meta && !meta.isEmpty() && meta.get('total')) || 0
    const hasMoreItems = hasMorePages(meta, page)

    return (
      <div className={classes.main}>
        {isOpenLogIn && (
          <ModalLogin modalComponent={{ component: LogIn }} toggleModal={this.toggleLogIn} />
        )}
        {isOpenSignUp && (
          <ModalLogin modalComponent={{ component: SignUp }} toggleModal={this.toggleSignUp} />
        )}
        <Sidebar
          toggleModal={this.toggleLogIn}
          isOpenMobileSidebar={isOpenMobileSidebar}
          toggleSidebarMenu={this.toggleSidebarMenu}
        />
        <div className={classes.rootContainer}>
          <NavBar
            toggleLogIn={this.toggleLogIn}
            toggleSignUp={this.toggleSignUp}
            toggleSidebarMenu={this.toggleSidebarMenu}
          />
          {isOpenMobileSidebar && (
            <div className={classes.openSidebarOverlay} onClick={this.toggleSidebarMenu} />
          )}
          <div className={classes.root}>
            <div>
              <div className={classes.header}>
                <div className={classes.tab}>
                  <h4 onClick={this.props.goBack}>Search jobs</h4>
                </div>
                <div className={classes.info}>
                  <p>{`${total} results`}</p>
                  <p onClick={this.changeSort}>
                    <span>Most relevant</span>
                    <span>
                      <span className={classes[order]} />
                    </span>
                  </p>
                </div>
              </div>
              {isSkeleton ? (
                <div className={classes.tableBody}>
                  <Skeleton />
                </div>
              ) : (
                list &&
                !list.isEmpty() && (
                  <div className={classes.tableBody} ref={this.myRef}>
                    {list.map((job, i: number) => (
                      <TableRow
                        push={this.props.push}
                        job={job}
                        key={i}
                        toggleLogIn={this.toggleLogIn}
                        clearanceTypeConst={clearanceTypeConst}
                      />
                    ))}
                    {hasMoreItems && (
                      <Waypoint onEnter={this.loadItems}>
                        <div>
                          <Skeleton count={1} />
                        </div>
                      </Waypoint>
                    )}
                  </div>
                )
              )}
            </div>
            <div>
              {!isLoading && (
                <Filter
                  clearingCities={clearingCities}
                  getCities={getCities}
                  countriesList={countriesList}
                  citiesList={citiesList}
                  onSubmit={this.onSubmit}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default compose<ComposeType<Props>>(
  connect(
    (state: StateInterface) => ({
      list: jobsGuestSelector(state),
      meta: jobsMetaGuestSelector(state),
      isLoading: loading(state),
      isSkeleton: skeletonSelector(state),
      countriesList: countriesSelector(state).toJS(),
      citiesList: citiesSelector(state).toJS(),
      formErrors: getFormSyncErrors('filter-jobs-list-guest')(state),
      clearanceTypeConst: clearanceTypeSelector(state)
    }),
    {
      push,
      goBack,
      getJobsList: guestJobsList,
      getCities: cities,
      clearingCities: clearCities
    }
  ),
  injectSheet(sheet)
)(JobsList)
