import React from 'react'
import Svg, { SvgType } from 'components/svgComponent/Svg'

export default function(props: SvgType) {
  return (
    <Svg width="133" height="20" viewBox="0 0 133 20" fill="none" {...props}>
      <path
        d="M38.556 11.2033L36.3988 13.3302V18.8479H33.0911V1.15225H36.3988V9.45318L38.2292 7.3506L43.7987 1.15225H47.7993L40.7263 8.99134L48.2046 18.8479H44.2824L38.556 11.2033Z"
        fill="#FAFAFA"
      />
      <path d="M50.1788 18.8479H53.4735V1.15225H50.1788V18.8479Z" fill="#FAFAFA" />
      <path
        d="M100.481 18.8479H97.1729L88.6879 6.29323V18.8479H85.3802V1.15225H88.6879L97.1991 13.7556V1.15225H100.481V18.8479Z"
        fill="#FAFAFA"
      />
      <path
        d="M111.816 3.63159H117.751V1.15225H102.651V3.63159H108.534V18.8479H111.816V3.63159Z"
        fill="#FAFAFA"
      />
      <path
        d="M25.9919 12.4916C26.5846 12.9129 26.8809 13.5125 26.8809 14.2903C26.8809 15.0033 26.5889 15.5786 26.005 16.0161C25.7367 16.2163 25.4062 16.3698 25.0129 16.4768C24.786 16.5383 24.5383 16.5845 24.2696 16.6153C24.1476 16.6291 24.0214 16.6396 23.8909 16.6469L23.692 16.6562C23.602 16.6591 23.51 16.6603 23.4163 16.6603C22.0828 16.6603 21.0586 16.3848 20.3439 15.8338C19.6292 15.2829 19.1542 14.505 19.1542 13.5003L16.0352 14.3781C16.1614 15.1306 16.4558 15.8103 16.9185 16.4172C17.5635 17.2517 18.4613 17.908 19.6118 18.3861C20.771 18.856 22.0392 19.091 23.4163 19.091C25.4994 19.091 27.1468 18.6575 28.3583 17.7906C29.5785 16.9155 30.1886 15.7406 30.1886 14.266C30.1886 13.3585 29.9795 12.5686 29.5611 11.8961C29.1427 11.2155 28.4847 10.6159 27.5869 10.0973C26.6892 9.57066 25.4994 9.10477 24.0177 8.69965C23.147 8.45698 22.4207 8.21148 21.8389 7.96314C21.4308 7.78853 21.0937 7.61271 20.8277 7.43567C20.1827 6.99814 19.8602 6.45528 19.8602 5.80709C19.8602 5.03736 20.1565 4.43778 20.7492 4.00835C21.3506 3.57893 22.1917 3.36421 23.2725 3.36421C24.4317 3.36421 25.3208 3.62754 25.9396 4.1542C26.5671 4.67275 26.8809 5.39387 26.8809 6.31754L30.0235 5.09875C29.8798 4.54524 29.6341 4.02378 29.2865 3.53436C28.6939 2.69171 27.8789 2.04352 26.8417 1.58978C25.8045 1.13605 24.6235 0.90918 23.2986 0.90918C21.3027 0.90918 19.6771 1.37102 18.422 2.29469C17.1756 3.21837 16.5524 4.39322 16.5524 5.81924C16.5524 7.44783 17.4197 8.76852 19.1542 9.78132C20.0606 10.308 21.2809 10.786 22.8149 11.2155C24.3489 11.6449 25.4079 12.0703 25.9919 12.4916Z"
        fill="#FAFAFA"
      />
      <path
        d="M56.647 18.8479H68.9982L68.0463 16.3929H59.9547V1.15225H56.647V18.8479Z"
        fill="#FAFAFA"
      />
      <path
        d="M73.5482 8.51735H81.3664L80.7536 10.9481H73.5482V16.3929H82.3162L83.1606 18.8479H70.2405V1.15225H82.5954L81.6048 3.63159H73.5482V8.51735Z"
        fill="#FAFAFA"
      />
    </Svg>
  )
}
