import React, { Component } from 'react'
// components
import { icons } from './icons'
import { Button } from 'components/Button'
import { FieldInput } from 'components/Form/Fields/FieldInput'
import CircleBackground from 'components/common/CircleBackground'
import Input from 'components/Form/Input'
// redux
import { compose } from 'redux'
import { InjectedFormProps, reduxForm } from 'redux-form'
// types
import { ButtonHtmlTypes, ButtonTypes, ComposeType } from 'types/common'
import { Classes } from 'jss'
// styles
import injectSheet from 'react-jss'
import { sheet } from './sheet'
// utils
import { createPasswordRecruiter } from 'pages/Auth/validation'
import { upper } from 'lib/normalize'

interface FormValuesInterface {
  firstName: string
  lastName: string
  password: string
  confirmationPassword: string
}

interface OwnProps {
  classes: Classes
  acceptInvitation: (data: FormValuesInterface) => void
}

type Props = InjectedFormProps<FormValuesInterface> & OwnProps

class CreatePassword extends Component<Props> {
  submit = (data: FormValuesInterface) => {
    this.props.acceptInvitation(data)
  }

  render() {
    const { classes, handleSubmit } = this.props

    return (
      <form onSubmit={handleSubmit(this.submit)}>
        <div className={classes.root}>
          <div className={classes.content}>
            <div className={classes.textLeft}>
              <CircleBackground className="forLogIn" />
              <h3>Create a new password for your Skilent Account!</h3>
              <h4>Tips for strong password:</h4>
              <div className={classes.margin}>
                <div className={classes.iconBlock}>
                  {icons.map((item, i) => (
                    <div key={i}>
                      {item.icon}
                      <p className={classes.title}>{item.name}</p>
                      <p className={classes.text}>{item.text}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className={classes.form}>
              <h4>Create a new password</h4>
              <div className={classes.name}>
                <FieldInput
                  name="firstName"
                  type="text"
                  component={Input}
                  title="First name"
                  normalize={upper}
                />
                <FieldInput
                  name="lastName"
                  type="text"
                  component={Input}
                  title="Last name"
                  normalize={upper}
                />
              </div>
              <FieldInput
                name="password"
                type="password"
                component={Input}
                title="Password"
                isPassword={true}
                placeholder="must include at least 8 symbols"
              />
              <FieldInput
                name="confirmationPassword"
                type="password"
                component={Input}
                isPassword={true}
                title="Repeat password"
              />
              <div className={classes.buttonGroup}>
                <Button
                  type={ButtonTypes.Submit}
                  htmlType={ButtonHtmlTypes.Submit}
                  label="confirm"
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    )
  }
}

export default compose<ComposeType<Props>>(
  reduxForm({
    form: 'create-password-recruiter',
    validate: createPasswordRecruiter,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true
  }),
  injectSheet(sheet)
)(CreatePassword)
