import { all, call, fork, select, put } from 'redux-saga/effects'
import { replace } from 'connected-react-router'
import * as C from 'domain/superManager/clients'
import * as M from 'domain/superManager/managers'
import { managersSelector } from 'domain/superManager/selectors'
import * as R from 'domain/router'
import queryString from 'query-string'

export function* clientsList({ query, pathname }: { query: any; pathname: string }) {
  yield all([fork(getClientsList, { query, pathname }), fork(getManagers)])
}

function* getClientsList({ query, pathname }: { query: any; pathname: string }) {
  const route = yield select(R.routing)
  const props = {
    payload: {
      ...query,
      page: query.page || 1,
      perPage: query.perPage || 15
    },
    type: C.superManagerClientsList.request
  }

  // load list from the first page and change params in url after reload
  const prevLocation = Object.keys(route.prevLocation || {}).length
  if (!prevLocation || (prevLocation && route.action === 'POP' && props.payload.page !== 1)) {
    const stringifyParams = queryString.stringify(
      { ...props.payload, page: 1 },
      { arrayFormat: 'bracket' }
    )
    yield put(replace(`${pathname}?${stringifyParams}`))
    return
  }
  yield call(C.ensureGetClientsList, props)
}

function* getManagers() {
  const managers = yield select(managersSelector)
  if (managers.isEmpty()) yield call(M.ensureGetManagers)
}
