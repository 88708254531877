import I from 'immutable'
import { getStorageItem } from 'lib/storage'
import { Action } from 'redux'
import * as A from './actions'
import * as P from 'domain/profile'
import * as SocialLinks from 'domain/socialLinks/actions'
import * as Avatar from 'domain/avatar/actions'

interface ActionType extends Action {
  payload: any
  tokens: string
  isLoading: boolean
  addAvatarProgress: null | number
}

const State = I.fromJS({
  isAuthorized: null,
  user: I.Map(),
  avatarProgress: null,
  isLoading: false
})

const LogOutState = I.fromJS({
  isAuthorized: false,
  avatarProgress: null,
  user: I.Map()
})

export function initialState() {
  const state = State
  try {
    const tokens = JSON.parse(getStorageItem('tokens', ''))
    return state.set('tokens', I.fromJS(tokens))
  } catch (err) {
    return state
  }
}

export const reducer = {
  env(state = State, action: ActionType) {
    switch (action.type) {
      case A.superManagerRequest.success:
        return state.setIn(['user', 'superManagerRequestPending'], true)
      case Avatar.addAvatarProgress.type:
        return state.set('avatarProgress', action.payload)
      case A.checkUserEmail.TRIGGER:
        return state.set('isLoading', true)
      case A.checkUserEmail.SUCCESS:
      case A.checkUserEmail.FAILURE:
        return state.set('isLoading', false)
      case A.signUpManager.success:
      case A.signUpCandidate.success: {
        return state
          .set('user', action.payload)
          .set('isAuthorized', false)
          .set('tokens', action.tokens)
      }
      case A.signIn.success:
      case A.checkAuth.success: {
        return state
          .set('user', action.payload)
          .set('isAuthorized', true)
          .set('tokens', action.tokens)
      }
      case A.checkAuth.failure:
      case A.signOut.success:
        return LogOutState
      case Avatar.addAvatar.success:
      case SocialLinks.addSocialLinks.success:
      case P.updateProfile.SUCCESS: {
        return state.set('user', action.payload)
      }

      default:
        return state
    }
  }
}
