import React from 'react'
import Svg, { SvgType } from 'components/svgComponent/Svg'

export default function(props: SvgType) {
  return (
    <Svg width="24" height="25" viewBox="0 0 24 25" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 8.9707C4.93913 8.9707 3.92172 9.39213 3.17157 10.1423C2.42143 10.8924 2 11.9098 2 12.9707C2 14.0316 2.42143 15.049 3.17157 15.7991C3.54301 16.1706 3.98396 16.4652 4.46927 16.6662C4.95457 16.8672 5.47471 16.9707 6 16.9707H9C9.55229 16.9707 10 17.4184 10 17.9707C10 18.523 9.55229 18.9707 9 18.9707H6C5.21207 18.9707 4.43185 18.8155 3.7039 18.514C2.97595 18.2125 2.31451 17.7705 1.75736 17.2133C0.632141 16.0881 0 14.562 0 12.9707C0 11.3794 0.632141 9.85328 1.75736 8.72806C2.88258 7.60284 4.4087 6.9707 6 6.9707L9 6.9707C9.55229 6.9707 10 7.41842 10 7.9707C10 8.52299 9.55229 8.9707 9 8.9707H6ZM14 7.9707C14 7.41842 14.4477 6.9707 15 6.9707H18C18.7879 6.9707 19.5681 7.1259 20.2961 7.42743C21.0241 7.72895 21.6855 8.17091 22.2426 8.72806C22.7998 9.28521 23.2417 9.94665 23.5433 10.6746C23.8448 11.4026 24 12.1828 24 12.9707C24 13.7586 23.8448 14.5388 23.5433 15.2668C23.2417 15.9948 22.7998 16.6562 22.2426 17.2133C21.6855 17.7705 21.0241 18.2125 20.2961 18.514C19.5681 18.8155 18.7879 18.9707 18 18.9707H15C14.4477 18.9707 14 18.523 14 17.9707C14 17.4184 14.4477 16.9707 15 16.9707H18C18.5253 16.9707 19.0454 16.8672 19.5307 16.6662C20.016 16.4652 20.457 16.1706 20.8284 15.7991C21.1999 15.4277 21.4945 14.9867 21.6955 14.5014C21.8965 14.0161 22 13.496 22 12.9707C22 12.4454 21.8965 11.9253 21.6955 11.44C21.4945 10.9547 21.1999 10.5137 20.8284 10.1423C20.457 9.77084 20.016 9.4762 19.5307 9.27519C19.0454 9.07417 18.5253 8.9707 18 8.9707H15C14.4477 8.9707 14 8.52299 14 7.9707Z"
        fill="#286EFA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 12.9707C7 12.4184 7.44772 11.9707 8 11.9707H16C16.5523 11.9707 17 12.4184 17 12.9707C17 13.523 16.5523 13.9707 16 13.9707H8C7.44772 13.9707 7 13.523 7 12.9707Z"
        fill="#286EFA"
      />
    </Svg>
  )
}
