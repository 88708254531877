import { Styles } from 'react-jss'

export const sheet: Styles = {
  columnBlock: {},
  content: {
    display: 'flex',
    flexDirection: 'row',
    '& h4': {
      color: '#080910',
      fontWeight: 500
    },
    '@media (max-width: 600px)': {
      flexDirection: 'column'
    }
  },
  text: {
    position: 'relative',
    overflow: 'hidden',
    padding: '3em',
    zIndex: 1,
    flex: 1,
    backgroundColor: '#FCFCFC',
    border: '1px solid #DEDEDE',
    '& > h3': {
      color: '#286EFA',
      marginBottom: '3em',
      '& > div': {
        border: '1px solid #286EFA',
        width: '10%'
      },
      '@media (max-width: 600px)': {
        marginBottom: '2em'
      }
    },
    '& > $columnBlock': {
      display: 'flex',
      flexDirection: 'row',
      '& > div': {
        borderRight: '1px solid #E6E6E6',
        marginRight: '1.5em'
      },
      '& > div:last-child': {
        marginRight: 0,
        border: 'none'
      },
      '& p': {
        fontSize: 15,
        letterSpacing: '0.01em',
        color: '#888888',
        opacity: 0.8
      }
    },
    '@media (max-width: 600px)': {
      padding: '20px'
    }
  },
  form: {
    padding: '3em',
    position: 'relative',
    flex: 0.6,
    backgroundColor: '#FFFFFF',
    '& > button': {
      fontWeight: 400,
      fontSize: 15,
      letterSpacing: '0.01em',
      color: '#888888',
      padding: 0,
      textTransform: 'none'
    },
    '@media (max-width: 600px)': {
      padding: '20px',
      flex: 1
    }
  },
  buttonGroup: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: '3em 0',
    '& > button:first-child': {
      fontWeight: 500,
      fontSize: 14,
      letterSpacing: '0.03em',
      color: '#286EFA',
      padding: 0
    }
  },
  '@media (min-width: 992px) and (max-width: 1199.98px)': {
    text: {
      padding: '3em 3em 3em 1.5em',
      '& h3': {
        fontSize: 35
      }
    },
    form: {
      padding: '3em 3em 3em 1.5em',
      '& > h4': {
        whiteSpace: 'nowrap'
      }
    }
  },
  '@media (min-width: 768px) and (max-width: 991.98px)': {
    text: {
      padding: '1em 1em 3em',
      '& > h3': {
        marginBottom: '1.5em'
      }
    },
    form: {
      padding: '1em 1em 3em'
    },
    inputRow: {
      margin: '1.5em 0'
    },
    buttonGroup: {
      margin: '1.5em 0'
    }
  }
}
