import { Action } from 'redux'
import I from 'immutable'
import * as Notifications from 'domain/notifications'
import * as Env from 'domain/env'
import { NotificationsListType } from 'pages/Notifications/types'

interface ActionType extends Action {
  payload: any
}

const State = I.fromJS({
  list: I.List(),
  unreadNotifications: 0
})

export const reducer = {
  notifications(state = State, action: ActionType) {
    switch (action.type) {
      case Notifications.newNotificationsList.success:
        return state.set('list', action.payload.get('data')).set('meta', action.payload.get('meta'))
      case Notifications.socketNotification.success:
        return state.set('list', state.get('list').insert(0, action.payload))
      case Notifications.notificationsList.success:
        return state
          .update('list', (u: NotificationsListType) => u.concat(action.payload.get('data')))
          .set('meta', action.payload.get('meta'))
      case Notifications.unreadNotificationsSocket.success:
        return state.set('unreadNotifications', action.payload)
      case Notifications.unreadNotifications.success:
        return state.set('unreadNotifications', action.payload)
      case Env.signOut.success:
        return State

      default:
        return state
    }
  }
}
