import * as storage from 'lib/storage'
import { FormErrors } from 'redux-form'
import { normalizePhone } from 'lib/normalize'
import { ImmutableMap } from 'types/common'
import { InfoType } from 'pages/Recruiter/Candidates/AddEditProfile/types'

const API_URL = process.env.REACT_APP_API_PATH_DOWNLOADS

export function persistToken(tokens: string) {
  storage.setStorageType(storage.LOCAL_STORAGE)
  storage.setStorageItem('tokens', JSON.stringify(tokens))
}

type CheckSignUpType = (message?: string) => FormErrors
export const checkSignUp: CheckSignUpType = message => {
  return { email: message }
}

export const transformUser = (data: ImmutableMap<InfoType>) => {
  return data
    .update('avatar', (val: string) => (val ? `${API_URL}/${val}` : undefined))
    .update('phone', normalizePhone(data.get('phonePrefix')))
}

export const checkInviteRecruiter = (data: {
  errors: Array<{ context: { key: number }; message: string }>
}) => {
  const errors: { [x: string]: string } = {}
  if (data && data.errors) {
    data.errors.forEach(({ context: { key }, message }) => {
      errors[key] = message
    })
  }
  return errors
}
