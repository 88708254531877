import { peek, selector } from 'lib/selectors'
import { StateInterface } from 'types/state'

const recruiter = (state: StateInterface) => state.recruiter
export const managersSelector = selector(recruiter, peek('managers'))
export const jobsSelector = selector(recruiter, peek('jobsListData'))
export const jobsMetaSelector = selector(recruiter, peek('jobsListMeta'))
export const jobSelector = selector(recruiter, peek('jobData'))
export const pointsSelector = selector(recruiter, peek('points'))
export const receivedPointsSelector = selector(recruiter, peek('receivedPoints'))
export const managerProfileSelector = selector(recruiter, peek('managerProfile'))
export const recruiterProfileSelector = selector(recruiter, peek('recruiterProfile'))
export const candidatesSelector = selector(recruiter, peek('candidatesListData'))
export const candidatesMetaSelector = selector(recruiter, peek('candidatesListMeta'))
export const candidateProfileSelector = selector(recruiter, peek('candidateProfile'))
export const importedCandidateProfileSelector = selector(
  recruiter,
  peek('importedCandidateProfile')
)
export const importedResumeSelector = selector(recruiter, peek('resume'))
export const parsedResumesSelector = selector(recruiter, peek('parsedResumes'))
export const parsedResumesMetaSelector = selector(recruiter, peek('parsedResumesMeta'))
export const jobsToAssigned = selector(recruiter, peek('jobsToAssigned'))
export const jobsSelfAssigned = selector(recruiter, peek('jobsSelfAssigned'))
export const jobsForFilter = selector(recruiter, peek('jobsListForFilter'))
export const dashboardSelector = selector(recruiter, peek('dashboard'))
export const eventsListSelector = selector(recruiter, peek('calendar', 'eventsList'))
export const eventSelector = selector(recruiter, peek('calendar', 'event'))
export const candidatesListSelector = selector(recruiter, peek('calendar', 'candidatesList'))
export const nearestEventIdSelector = selector(recruiter, peek('calendar', 'nearestEventId'))
export const candidateJobsListSelector = selector(recruiter, peek('calendar', 'candidateJobs'))
export const recruiterJobsCountSelector = selector(recruiter, peek('jobsCount'))
export const recruiterCandidateJobsSelector = selector(recruiter, peek('candidateJobs', 'data'))
export const recruiterCandidateJobsMetaSelector = selector(recruiter, peek('candidateJobs', 'meta'))
