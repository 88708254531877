import React, { Component } from 'react'
// components
import { Button } from 'components/Button'
import FindPostJob from './FindPostJob'
import Recommendations from './Recommendations'
import SquareBackground from 'components/common/SquareBackground'
import CircleBackground from 'components/common/CircleBackground'
// redux
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Push, push } from 'connected-react-router'
// types
import { Classes } from 'jss'
import { ComposeType } from 'types/common'
// styles
import injectSheet from 'react-jss'
import { sheet } from './sheet'
// utils
import { LayoutBlock } from 'lib/layoutVariables'
import cx from 'classnames'

interface Props {
  classes: Classes
  style?: React.CSSProperties
  push?: Push
}

class Body extends Component<Props> {
  onClick = (path: string, isCandidate: boolean = false) => {
    if (this.props.push) {
      this.props.push({
        pathname: path,
        state: {
          isCandidate
        }
      })
    }
  }

  render() {
    const { classes } = this.props
    return (
      <>
        <section className={classes.root}>
          <SquareBackground />
          <CircleBackground />
          {LayoutBlock.map((item, i) => {
            const signUp = () => {
              this.onClick('/sign-up', item.isCandidate)
            }
            return (
              <div className={classes.component} key={i}>
                <div className={classes.leftBlock}>
                  <h3>{item.title}</h3>
                  <Button type={item.typeButton} label={item.nameButton} onClick={signUp} />
                </div>
                <div className={classes.rightBlock}>
                  {item.rightBlock.map((obj, j) => (
                    <div className={classes.iconBlock} key={j}>
                      {obj.map((el, k) => (
                        <div key={k} className={cx({ [classes.hideBlock]: !el.name })}>
                          {el.icon}
                          <p className={classes.title}>{el.name}</p>
                          <p className={classes.text}>{el.text}</p>
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              </div>
            )
          })}
          {/*<div className={classes.candidates}>*/}
          {/*  <div className={classes.number}>15 000</div>*/}
          {/*  <h3>*/}
          {/*    candidates <br /> found their jobs <br /> with us*/}
          {/*  </h3>*/}
          {/*</div>*/}
        </section>
        {/*<FindPostJob />*/}
        {/*<Recommendations />*/}
      </>
    )
  }
}

export default compose<ComposeType<Props>>(
  connect(
    null,
    {
      push
    }
  ),
  injectSheet(sheet)
)(Body)
