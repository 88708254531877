import React from 'react'
import Svg, { SvgType } from 'components/svgComponent/Svg'

export default function(props: SvgType) {
  const { iconActive = true } = props
  const fill = iconActive ? '#286EFA' : '#C1C9D2'
  return (
    <Svg width="18" height="15" viewBox="0 0 18 15" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.2708 15H15.8316V2.43796H17.2708C17.6738 2.43796 18 2.75434 18 3.14516V14.3114C18 14.6836 17.6738 15 17.2708 15ZM3.87847 15V2.43796H5.02772L6.46695 0H11.4947L12.9339 2.43796H14.0832V15H3.87847ZM10.8038 1.04218H7.19616L6.46695 2.43796H11.4947L10.8038 1.04218ZM0 14.3114V3.14516C0 2.75434 0.326224 2.43796 0.729209 2.43796H2.16844V15H0.729209C0.326224 15 0 14.6836 0 14.3114Z"
        fill={fill}
      />
    </Svg>
  )
}
