import { put, call, select } from 'redux-saga/effects'
import { FormAction } from 'redux-form'
import { isLoadingCalendarEvent, modalIsLoading, pageIsLoading } from 'domain/loading'
import { EventI, NewEventI } from 'types/calendarTypes'
import { prepareDataEvent, prepareEventsList } from './helpers'
import * as Actions from './actions'
import * as M from 'domain/env'
import * as Router from 'domain/router'
import Api from 'domain/api'
import I from 'immutable'

const API_URL = process.env.REACT_APP_API_PATH_DOWNLOADS

export function* ensureEventsList() {
  const headers = yield select(M.userToken)
  if (!headers) return
  yield put({ type: pageIsLoading, payload: true })
  const {
    params: { id }
  } = yield select(Router.matchRoutes)
  try {
    const {
      data: { data }
    } = yield call(Api.recruiter.getEventsList, {
      headers: { Authorization: `Bearer ${headers}` },
      id
    })
    const eventsList = prepareEventsList(data)
    yield put({
      type: Actions.recruiterEventsList.success,
      payload: I.fromJS(eventsList.events)
    })
    yield put({
      type: Actions.nearestEventId.success,
      payload: eventsList.nearestEventId
    })
  } catch (err) {
    yield put({
      type: Actions.recruiterEventsList.failure,
      err
    })
  } finally {
    yield put({ type: pageIsLoading, payload: false })
  }
}

export function* ensureCandidatesList() {
  const headers = yield select(M.userToken)
  if (!headers) return
  try {
    const {
      data: { data }
    } = yield call(Api.recruiter.getCandidatesList, {
      headers: { Authorization: `Bearer ${headers}` }
    })
    const candidatesList =
      data &&
      data.map((item: { [x: string]: number | string }) => ({
        value: item.id,
        label: [item.firstName, item.lastName].join(', '),
        avatar: item.avatar ? `${API_URL}/${item.avatar}` : undefined
      }))
    yield put({
      type: Actions.recruiterCandidatesList.success,
      payload: I.fromJS(candidatesList || [])
    })
  } catch (err) {
    yield put({
      type: Actions.recruiterCandidatesList.failure,
      err
    })
  }
}

export function* ensureAddEvent({ payload }: { payload: NewEventI; type: string }) {
  const headers = yield select(M.userToken)
  if (!headers) return
  const {
    params: { id }
  } = yield select(Router.matchRoutes)

  if (!payload) return
  yield put({ type: modalIsLoading, payload: true })
  try {
    const {
      data: { data }
    } = yield call(Api.recruiter.postEvent, {
      headers: { Authorization: `Bearer ${headers}` },
      data: prepareDataEvent(payload, id)
    })
    yield put({ type: Actions.recruiterEventAdd.SUCCESS, payload: I.fromJS(data) })
    yield put({ type: Actions.recruiterEventsList.type })
  } catch (err) {
    yield put({
      type: Actions.recruiterEventAdd.FAILURE,
      err
    })
  } finally {
    yield put({ type: modalIsLoading, payload: false })
  }
}

export function* ensureEvent({ payload }: { payload: EventI; type: string }) {
  const headers = yield select(M.userToken)
  if (!headers) return
  yield put({ type: pageIsLoading, payload: true })
  yield put({ type: isLoadingCalendarEvent, payload: true })
  try {
    const {
      data: { data }
    } = yield call(Api.recruiter.getEvent, {
      headers: { Authorization: `Bearer ${headers}` },
      id: payload
    })
    yield put({ type: Actions.recruiterEvent.success, payload: I.fromJS(data) })
  } catch (err) {
    yield put({
      type: Actions.recruiterEvent.failure,
      err
    })
  } finally {
    yield put({ type: pageIsLoading, payload: false })
    yield put({ type: isLoadingCalendarEvent, payload: false })
  }
}

export function* ensureUpdateEvent({ payload }: { payload: NewEventI; type: string }) {
  const headers = yield select(M.userToken)
  if (!headers) return
  const {
    params: { id }
  } = yield select(Router.matchRoutes)
  if (!payload) return
  yield put({ type: modalIsLoading, payload: true })
  try {
    const {
      data: { data }
    } = yield call(Api.recruiter.putEvent, {
      headers: { Authorization: `Bearer ${headers}` },
      id: payload.id,
      data: prepareDataEvent(payload, id)
    })
    yield put({ type: Actions.recruiterEventUpdate.SUCCESS, payload: I.fromJS(data) })
    yield put({ type: Actions.recruiterEventsList.type })
  } catch (err) {
    yield put({
      type: Actions.recruiterEventUpdate.FAILURE,
      err
    })
  } finally {
    yield put({ type: modalIsLoading, payload: false })
  }
}

export function* ensureRemoveEvent({ payload }: { payload: number; type: string }) {
  const headers = yield select(M.userToken)
  if (!headers) return
  yield put({ type: modalIsLoading, payload: true })
  try {
    const {
      data: { data }
    } = yield call(Api.recruiter.deleteEvent, {
      headers: { Authorization: `Bearer ${headers}` },
      id: payload
    })
    yield put({ type: Actions.recruiterEventDelete.SUCCESS, payload: I.fromJS(data) })
    yield put({ type: Actions.recruiterEventsList.type })
  } catch (err) {
    yield put({
      type: Actions.recruiterEventDelete.FAILURE,
      err
    })
  } finally {
    yield put({ type: modalIsLoading, payload: false })
  }
}

export function* ensureCandidateJobsList(props: FormAction | { payload: { value: number } }) {
  const headers = yield select(M.userToken)
  if (!headers) return
  try {
    const {
      data: { data }
    } = yield call(Api.getAppliedJobsCandidate, {
      headers: { Authorization: `Bearer ${headers}` },
      id: props.payload.value
    })
    yield put({ type: Actions.candidateJobsList.success, payload: I.fromJS(data) })
  } catch (err) {
    yield put({
      type: Actions.candidateJobsList.failure,
      err
    })
  }
}
