import { all, takeLatest, take, fork, call, put } from 'redux-saga/effects'
import { pageIsLoading } from 'domain/loading'
import * as D from 'domain/recruiter/dashboard'

export function* dashboard() {
  yield all([
    fork(jobsInProgress),
    fork(jobsCounters),
    fork(closedJobs),
    fork(recruiterSkills),
    fork(clients),
    fork(statistics),
    fork(topRecruiters),
    fork(initialLoad)
  ])
}

function* jobsInProgress() {
  yield takeLatest(D.recruiterJobsInProgress.type, D.ensureJobsInProgress)
  yield take(D.recruiterJobsInProgress.success)
}

function* jobsCounters() {
  yield takeLatest(D.recruiterJobsCounters.type, D.ensureJobsCounters)
  yield take(D.recruiterJobsCounters.success)
}

function* closedJobs() {
  yield takeLatest(D.recruiterClosedJobs.type, D.ensureClosedJobs)
  yield take(D.recruiterClosedJobs.success)
}

function* recruiterSkills() {
  yield takeLatest(D.recruiterRecruiterSkills.type, D.ensureRecruiterSkills)
  yield take(D.recruiterRecruiterSkills.success)
}

function* clients() {
  yield takeLatest(D.recruiterClients.type, D.ensureClients)
  yield take(D.recruiterClients.success)
}

function* statistics() {
  yield takeLatest(D.recruiterStatistics.type, D.ensureStatistics)
  yield take(D.recruiterStatistics.success)
}

function* topRecruiters() {
  yield takeLatest(D.recruiterTopRecruiters.type, D.ensureTopRecruiters)
  yield take(D.recruiterTopRecruiters.success)
}

function* initialLoad() {
  yield put({ type: pageIsLoading, payload: true })
  try {
    yield all([
      call(D.ensureJobsInProgress),
      call(D.ensureJobsCounters),
      call(D.ensureClosedJobs),
      call(D.ensureRecruiterSkills),
      call(D.ensureClients),
      call(D.ensureStatistics),
      call(D.ensureTopRecruiters)
    ])
  } finally {
    yield put({ type: pageIsLoading, payload: false })
  }
}
