import React from 'react'
import Svg, { SvgType } from 'components/svgComponent/Svg'

export default function(props: SvgType) {
  const { iconActive } = props
  const fill = iconActive ? '#FAFAFA' : '#E6E6E6'
  const opacity = iconActive ? '1' : '0.5'
  return (
    <Svg width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
      <path
        opacity={opacity}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.8753 13.1726C10.7098 14.0651 9.25781 14.6018 7.67513 14.6018C3.85091 14.6018 0.75 11.501 0.75 7.67591C0.75 3.8508 3.85091 0.75 7.67513 0.75C11.5011 0.75 14.6021 3.8508 14.6021 7.67591C14.6021 9.25826 14.0649 10.7099 13.1715 11.8761L16.9814 15.6854C17.3395 16.0434 17.3395 16.6238 16.9814 16.9818C16.6234 17.3397 16.0433 17.3397 15.6852 16.9818L11.8753 13.1726ZM12.7687 7.67591C12.7687 10.4885 10.4878 12.7685 7.67513 12.7685C4.86426 12.7685 2.58333 10.4885 2.58333 7.67591C2.58333 4.86336 4.86426 2.58333 7.67513 2.58333C10.4878 2.58333 12.7687 4.86336 12.7687 7.67591Z"
        fill={fill}
      />
    </Svg>
  )
}
