import React from 'react'
import Svg, { SvgType } from 'components/svgComponent/Svg'

export default function(props: SvgType) {
  return (
    <Svg width="32" height="31" viewBox="0 0 32 31" fill="none" {...props}>
      <path
        d="M1 29.9997H0C0 30.3724 0.207247 30.7142 0.53772 30.8865C0.868193 31.0588 1.26705 31.033 1.57259 30.8196L1 29.9997ZM6.20545 26.3641L6.77804 27.184L6.20545 26.3641ZM0 11.9209V29.9997H2V11.9209H0ZM1.57259 30.8196L6.77804 27.184L5.63285 25.5443L0.427405 29.1799L1.57259 30.8196ZM6.77804 27.184H21.0731V25.184H6.77804V27.184ZM23.0731 25.184V11.9209H21.0731V25.184H23.0731ZM21.0731 9.9209H2V11.9209H21.0731V9.9209ZM23.0731 11.9209C23.0731 10.8163 22.1777 9.9209 21.0731 9.9209V11.9209V11.9209H23.0731ZM21.0731 27.184C22.1777 27.184 23.0731 26.2885 23.0731 25.184H21.0731H21.0731V27.184ZM6.77804 27.184H6.77804V25.184C6.36842 25.184 5.96868 25.3097 5.63285 25.5443L6.77804 27.184ZM2 11.9209H2V9.9209C0.895431 9.9209 0 10.8163 0 11.9209H2Z"
        fill="#286EFA"
      />
      <path d="M5.68311 17.0264H16.6099" stroke="#286EFA" strokeWidth="2" />
      <path d="M5.68311 21.6055H16.6099" stroke="#286EFA" strokeWidth="2" />
      <path
        d="M10 1V10.8621H22.5V18.4483H25.2069L31 23V1H10Z"
        stroke="#286EFA"
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </Svg>
  )
}
