import { all, takeLatest, take, fork, call, put } from 'redux-saga/effects'
import { pageIsLoading } from 'domain/loading'
import * as A from 'domain/manager/candidates'
import * as J from 'domain/jobs'
import * as S from 'domain/candidate/status'

export function* profilePage() {
  yield all([
    fork(getProfile),
    fork(savedCandidate),
    fork(getJobs),
    fork(getRecruiters),
    fork(closeJob),
    fork(declineCandidate),
    fork(changeStatus),
    fork(getStatus),
    fork(getNote),
    fork(addNote),
    fork(deleteNote),
    fork(editNote)
  ])
}

function* getProfile() {
  yield put({ type: pageIsLoading, payload: true })
  yield call(A.ensureGetCandidateProfile)
  yield takeLatest(A.candidateProfile.type, A.ensureGetCandidateProfile)
  yield take(A.candidateProfile.success)
}

function* savedCandidate() {
  yield takeLatest(A.candidateFavorite.type, A.ensureAddCandidateToFavorite)
  yield take(A.candidateFavorite.success)
}

function* changeStatus() {
  yield takeLatest(S.changeCandidateStatus.type, S.ensureChangeCandidateStatus)
  yield take(S.changeCandidateStatus.success)
}

function* getStatus() {
  yield takeLatest(S.candidateStatus.type, S.ensureGetCandidateStatus)
  yield take(S.candidateStatus.success)
}

function* getJobs() {
  yield takeLatest(A.jobsForCloseJobCandidateProfile.type, A.ensureJobsForCloseJobCandidateProfile)
  yield take(A.jobsForCloseJobCandidateProfile.success)
}

function* getRecruiters() {
  yield takeLatest(
    A.recruitersForCloseJobCandidateProfile.type,
    A.ensureRecruitersForCloseJobCandidateProfile
  )
  yield take(A.recruitersForCloseJobCandidateProfile.success)
}

function* closeJob() {
  yield takeLatest(A.closeJobCandidateProfile.TRIGGER, A.ensureCloseJobCandidateProfile)
  yield take(A.closeJobCandidateProfile.SUCCESS)
}

function* declineCandidate() {
  yield takeLatest(A.candidateDecline.TRIGGER, A.ensureDeclineCandidate)
  yield take(A.candidateDecline.SUCCESS)
}

function* getNote() {
  yield takeLatest(J.getCandidateJobNotes.type, J.ensureGetCandidateJobNotes)
  yield take(J.getCandidateJobNotes.success)
}

function* addNote() {
  yield takeLatest(J.addCandidateJobNote.type, J.ensurePostCandidateJobNotes)
  yield take(J.addCandidateJobNote.success)
}

function* deleteNote() {
  yield takeLatest(J.deleteCandidateJobNote.type, J.ensureDeleteCandidateJobNote)
  yield take(J.deleteCandidateJobNote.success)
}

function* editNote() {
  yield takeLatest(J.editCandidateJobNote.type, J.ensureEditCandidateJobNote)
  yield take(J.editCandidateJobNote.success)
}
