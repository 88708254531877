import { asyncAction } from 'lib/actionCreators'
import { createRoutine, promisifyRoutine } from 'redux-saga-routines'

export const managerEventsList = asyncAction('MANAGER_EVENTS_LIST')
export const managerCandidatesList = asyncAction('MANAGER_CANDIDATES_LIST')
export const managerEvent = asyncAction('MANAGER_EVENT')
export const managerEventUpdate = createRoutine('MANAGER_EVENT_UPDATE')
export const managerEventDelete = createRoutine('MANAGER_EVENT_DELETE')
export const managerEventAdd = createRoutine('MANAGER_EVENT_ADD')
export const managerEventUpdatePromisify = promisifyRoutine(managerEventUpdate)
export const managerEventDeletePromisify = promisifyRoutine(managerEventDelete)
export const managerEventAddPromisify = promisifyRoutine(managerEventAdd)

export const nearestEventId = asyncAction('NEAREST_EVENT_ID')
export const unavailability = asyncAction('UNAVAILABILITY')
export const unavailabilityUpdate = createRoutine('UNAVAILABILITY_UPDATE')
export const unavailabilityDelete = createRoutine('UNAVAILABILITY_DELETE')
export const unavailabilityAdd = createRoutine('UNAVAILABILITY_ADD')
export const unavailabilityUpdatePromisify = promisifyRoutine(unavailabilityUpdate)
export const unavailabilityDeletePromisify = promisifyRoutine(unavailabilityDelete)
export const unavailabilityAddPromisify = promisifyRoutine(unavailabilityAdd)

export const managerCandidateJobsList = asyncAction('MANAGER_CANDIDATE_JOBS_LIST')
