import { asyncAction } from 'lib/actionCreators'
import { createRoutine, promisifyRoutine } from 'redux-saga-routines'

export const candidates = asyncAction('manager/CANDIDATES')
export const candidatesNewList = asyncAction('manager/CANDIDATES_NEW_LIST')
export const candidateProfile = asyncAction('manager/CANDIDATE_PROFILE')
export const candidateFavorite = asyncAction('manager/CANDIDATE_FAVORITE')
export const candidateFavoriteFromList = asyncAction('manager/CANDIDATE_FAVORITE_FROM_LIST')
export const closeJobCandidateProfile = createRoutine('manager/CLOSE_JOB_CANDIDATE_PROFILE')
export const closeJobCandidateProfilePromiseCreator = promisifyRoutine(closeJobCandidateProfile)
export const jobsForCloseJobCandidateProfile = asyncAction('manager/JOBS_FOR_CLOSE_JOB')
export const recruitersForCloseJobCandidateProfile = asyncAction(
  'manager/RECRUITERS_FOR_CLOSE_JOB_CANDIDATE_PROFILE'
)
export const candidateAsViewed = asyncAction('manager/CANDIDATE_AS_VIEWED')
export const candidateDecline = createRoutine('manager/CANDIDATE_DECLINE')
export const candidateDeclinePromiseCreator = promisifyRoutine(candidateDecline)
