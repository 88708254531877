import React from 'react'
import Svg, { SvgType } from 'components/svgComponent/Svg'

export default function(props: SvgType) {
  const { iconActive } = props
  const fill = iconActive ? '#FAFAFA' : '#989FA6'
  const opacity = iconActive ? '1' : '0.5'
  const fillRule = iconActive ? 'nonzero' : 'evenodd'
  return (
    <Svg width="22" height="22" viewBox="0 0 22 22" fill="none" {...props}>
      <g clipPath="url(#clip0)">
        <g opacity={opacity}>
          <path
            fillRule={fillRule}
            clipRule="evenodd"
            d="M16.9375 1.05005C17.0781 0.965088 17.2422 0.91626 17.418 0.91626H21.082C21.5898 0.91626 22 1.32642 22 1.83325V21.0833C22 21.5891 21.5898 21.9993 21.082 21.9993H17.418C16.9102 21.9993 16.5 21.5891 16.5 21.0833V1.83325C16.5 1.50122 16.6758 1.21118 16.9375 1.05005ZM18.332 20.1663V2.74927H20.168V20.1663H18.332Z"
            fill={fill}
          />
          <path
            fillRule={fillRule}
            clipRule="evenodd"
            d="M9.16797 10.0833C8.66016 10.0833 8.25 10.4934 8.25 11.0002V21.0833C8.25 21.5891 8.66016 22.0002 9.16797 22.0002H12.832C13.3398 22.0002 13.75 21.5891 13.75 21.0833V11.0002C13.75 10.6936 13.5977 10.4221 13.3672 10.2551C13.2148 10.1467 13.0312 10.0833 12.832 10.0833H9.16797ZM10.082 11.9163V20.1663H11.918V11.9163H10.082Z"
            fill={fill}
          />
          <path
            fillRule={fillRule}
            clipRule="evenodd"
            d="M0.917969 15.5833C0.410156 15.5833 0 15.9934 0 16.5002V21.0833C0 21.5891 0.410156 22.0002 0.917969 22.0002H4.58203C5.08984 22.0002 5.5 21.5891 5.5 21.0833V16.5002C5.5 15.9934 5.08984 15.5833 4.58203 15.5833H0.917969ZM1.83203 17.4163V20.1663H3.66797V17.4163H1.83203Z"
            fill={fill}
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="22" height="22" fill="white" />
        </clipPath>
      </defs>
    </Svg>
  )
}
