import React, { Component } from 'react'
// libs
import ReactTooltip from 'react-tooltip'
// components
import { Button } from 'components/Button'
import Menu from 'pages/NavBar/Menu'
import UserInfo from 'pages/NavBar/UserInfo'
import Modal from 'components/Modal'
import InviteRecruiters from 'pages/Manager/InviteRecruiters'
import AddClient from 'pages/Manager/Clients/List/AddEdit'
import ReceivePoints from 'pages/Recruiter/RecievedPoints'
import NotificationsMenu from 'pages/Notifications/Menu'
// redux
import { compose, Dispatch } from 'redux'
import { connect } from 'react-redux'
import { userSelector, signOut } from 'domain/env'
import { postClientPromiseCreator } from 'domain/manager/clients/actions'
import { receivedPointsSelector } from 'domain/recruiter/selectors'
import { rewardsViewPromiseCreator, receivedPoints } from 'domain/recruiter/points'
import { notificationTypeSelector, NotificationTypeT } from 'domain/constants'
import { ActionCreatorFunction, bindPromiseCreators, PromiseCreator } from 'redux-saga-routines'
import {
  notificationsSelector,
  unreadNotificationsCount,
  notificationsAsViewed
} from 'domain/notifications'
// types
import { ComposeType, ReceivedPointsType, UserType } from 'types/common'
import { Classes } from 'jss'
import { NotificationsListType } from 'pages/Notifications/types'
import { ClientFormValues } from 'types/clientTypes'
import { StateInterface } from 'types/state'
// styles
import injectSheet from 'react-jss'
import { sheet } from './sheet'
// utils
import { Roles } from 'lib/roles'
// icons
import Notifications from 'components/svgComponent/Notifications'
import ArrowUp from 'components/svgComponent/ArrowUp'
import ArrowDown from 'components/svgComponent/ArrowDown'
import MobileMenu from 'components/svgComponent/Menu'

interface Props {
  classes: Classes
  user: UserType
  signOut: () => void
  postClientPromiseCreator: PromiseCreator
  dispatch: Dispatch
  rewardsView: PromiseCreator
  points: ReceivedPointsType
  getReceivedPoints: ActionCreatorFunction
  countUnreadNotifications: number
  notifications: NotificationsListType
  notificationsTypes: NotificationTypeT
  notificationsAsViewed: (val: number[]) => void
  toggleSidebarMenu: () => void
}

interface State {
  openMenu: boolean
  isOpenModalInvite: boolean
  isOpenModalAddClient: boolean
  isOpenNotificationsMenu: boolean
  formError: string
}

class NavBar extends Component<Props, State> {
  wrapperRef: HTMLDivElement | null | undefined
  wrapperRefMenu: HTMLDivElement | null | undefined
  wrapperRefNotifications: HTMLDivElement | null | undefined
  wrapperRefNotificationsMenu: HTMLDivElement | null | undefined

  state = {
    openMenu: false,
    isOpenModalInvite: false,
    isOpenModalAddClient: false,
    isOpenNotificationsMenu: false,
    formError: ''
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  handleClickOutside = (event: any) => {
    if (
      this.wrapperRef &&
      this.wrapperRefMenu &&
      !this.wrapperRef.contains(event.target) &&
      !this.wrapperRefMenu.contains(event.target) &&
      this.state.openMenu
    ) {
      this.openCloseMenu()
    }

    if (
      this.wrapperRefNotifications &&
      this.wrapperRefNotificationsMenu &&
      !this.wrapperRefNotifications.contains(event.target) &&
      !this.wrapperRefNotificationsMenu.contains(event.target) &&
      this.state.isOpenNotificationsMenu
    ) {
      this.toggleNotificationsMenu()
    }
  }

  setWrapperRef = (node: HTMLDivElement) => {
    this.wrapperRef = node
  }

  setWrapperRefMenu = (node: HTMLDivElement) => {
    this.wrapperRefMenu = node
  }

  setWrapperRefNotifications = (node: HTMLDivElement) => {
    this.wrapperRefNotifications = node
  }

  setWrapperRefNotificationsMenu = (node: HTMLDivElement) => {
    this.wrapperRefNotificationsMenu = node
  }

  openCloseMenu = () => {
    this.setState({ openMenu: !this.state.openMenu })
  }

  toggleNotificationsMenu = () => {
    if (!this.props.notifications || this.props.notifications.isEmpty()) return
    this.setState({ isOpenNotificationsMenu: !this.state.isOpenNotificationsMenu }, () => {
      ReactTooltip.rebuild()
      if (!this.state.isOpenNotificationsMenu && this.props.countUnreadNotifications > 0) {
        const { notifications } = this.props
        const asViewedArray =
          notifications && !notifications.isEmpty() && notifications.size > 7
            ? notifications.take(7)
            : notifications
        const indexArray = asViewedArray
          .filter(item => !item.get('viewedAt'))
          .map(item => item.get('id'))
          .toJS()
        if (indexArray && indexArray.length) {
          this.props.notificationsAsViewed(indexArray)
        }
      }
    })
  }

  toggleModalInvite = () => {
    this.setState({ isOpenModalInvite: !this.state.isOpenModalInvite })
  }

  rewardsViewPoints = (id: number) => {
    const promise = this.props.rewardsView(id, this.props.dispatch)
    promise.then(
      () => {
        this.setState({ formError: '' })
        this.props.getReceivedPoints()
      },
      failurePayload => {
        const errorText =
          failurePayload &&
          failurePayload.err &&
          failurePayload.err.response &&
          failurePayload.err.response.data &&
          ((failurePayload.err.response.data.errors &&
            failurePayload.err.response.data.errors.length > 0 &&
            failurePayload.err.response.data.errors[0].message) ||
            failurePayload.err.response.data.message)
        this.setState({
          formError: errorText || 'Something went wrong!'
        })
      }
    )
  }

  toggleModalAddClient = () => {
    this.setState({ isOpenModalAddClient: !this.state.isOpenModalAddClient })
  }

  addClient = (data: ClientFormValues) => {
    const promise = this.props.postClientPromiseCreator({ data }, this.props.dispatch)
    promise.then(
      () => {
        this.setState({ formError: '' })
        this.toggleModalAddClient()
      },
      failurePayload => {
        const errorText =
          failurePayload &&
          failurePayload.err &&
          failurePayload.err.response &&
          failurePayload.err.response.data &&
          ((failurePayload.err.response.data.errors &&
            failurePayload.err.response.data.errors.length > 0 &&
            failurePayload.err.response.data.errors[0].message) ||
            failurePayload.err.response.data.message)
        this.setState({
          formError: errorText || 'Something went wrong!'
        })
      }
    )
  }

  render() {
    const {
      classes,
      user,
      points,
      countUnreadNotifications,
      notifications,
      notificationsTypes,
      toggleSidebarMenu
    } = this.props
    const {
      openMenu,
      isOpenModalInvite,
      isOpenModalAddClient,
      formError,
      isOpenNotificationsMenu
    } = this.state
    const photo = user.get('avatar')
    const name = [user.get('firstName'), user.get('lastName')].join(' ')
    const role = Roles[user.get('role')]

    return (
      <nav className={classes.root}>
        <MobileMenu className={classes.mobileMenu} onClick={toggleSidebarMenu} />

        {points && !points.isEmpty() && role === Roles.recruiter && (
          <Modal withIcon={true} hideClose={true}>
            <ReceivePoints
              toggleModal={this.rewardsViewPoints}
              receivedPoints={points}
              formError={formError}
            />
          </Modal>
        )}
        {isOpenModalInvite && (
          <Modal openCloseModal={this.toggleModalInvite}>
            <InviteRecruiters toggleModal={this.toggleModalInvite} />
          </Modal>
        )}
        {isOpenModalAddClient && (
          <Modal openCloseModal={this.toggleModalAddClient} isScroll={true}>
            <AddClient
              openCloseModal={this.toggleModalAddClient}
              isNewClient={true}
              onSubmit={this.addClient}
              formError={formError}
            />
          </Modal>
        )}
        <div className={classes.leftHeader}>
          <Button isLink={true} to="/support" label="Support" authLink={true} />
        </div>
        <div className={classes.rightHeader}>
          {openMenu && (
            <Menu
              setWrapperRef={this.setWrapperRef}
              user={user}
              signOut={this.props.signOut}
              openCloseMenu={this.openCloseMenu}
              toggleModalInvite={this.toggleModalInvite}
              toggleModalAddClient={this.toggleModalAddClient}
            />
          )}
          {isOpenNotificationsMenu && (
            <NotificationsMenu
              setWrapperRef={this.setWrapperRefNotifications}
              notifications={notifications}
              notificationsTypes={notificationsTypes}
              countUnreadNotifications={countUnreadNotifications}
              toggleNotificationsMenu={this.toggleNotificationsMenu}
              role={user.get('role')}
            />
          )}
          <div
            className={classes.notifications}
            onClick={this.toggleNotificationsMenu}
            ref={this.setWrapperRefNotificationsMenu}
          >
            <Notifications />
            <div className={classes.circle}>
              {countUnreadNotifications > 99 ? '99+' : countUnreadNotifications}
            </div>
          </div>
          <div
            onClick={this.openCloseMenu}
            ref={this.setWrapperRefMenu}
            className={classes.clickArea}
          >
            <UserInfo role={role} photo={photo} name={name} isHideInfoOnXS={true} />
            <div className={classes.arrow}>{openMenu ? <ArrowUp /> : <ArrowDown />}</div>
          </div>
        </div>
      </nav>
    )
  }
}

export default compose<ComposeType<Props, { toggleSidebarMenu: () => void }>>(
  connect(
    (state: StateInterface) => ({
      user: userSelector(state),
      points: receivedPointsSelector(state),
      countUnreadNotifications: unreadNotificationsCount(state),
      notifications: notificationsSelector(state),
      notificationsTypes: notificationTypeSelector(state)
    }),
    dispatch => ({
      ...bindPromiseCreators(
        {
          postClientPromiseCreator,
          rewardsView: rewardsViewPromiseCreator
        },
        dispatch
      ),
      signOut: () => dispatch(signOut()),
      notificationsAsViewed: (data: number[]) => dispatch(notificationsAsViewed(data)),
      getReceivedPoints: () => dispatch(receivedPoints()),
      dispatch
    })
  ),
  injectSheet(sheet)
)(NavBar)
