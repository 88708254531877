import React from 'react'
// components
import { Button } from 'components/Button'
import NotificationTitle from 'pages/Notifications/NotificationTitle'
// types
import { Classes } from 'jss'
import { ButtonTypes } from 'types/common'
// styles
import injectSheet from 'react-jss'
import { sheet } from './sheet'
// utils
import cx from 'classnames'

interface Props {
  classes: Classes
  title: string
  recruiter: string
  candidate: string
  recruiterPath: string
  candidatePath: string
  pathTitle: string
  viewedAt: boolean
  createdAt: string
}

const Index = ({
  classes,
  title,
  pathTitle,
  candidate,
  recruiter,
  recruiterPath,
  candidatePath,
  viewedAt,
  createdAt
}: Props) => {
  return (
    <div className={cx(classes.main, { [classes.unread]: viewedAt })}>
      <div className={classes.img}>+1</div>
      <div className={classes.root}>
        <NotificationTitle title={title} path={pathTitle} />
        <p className={classes.text}>
          <span>
            +1 New Candidate{' '}
            <Button
              isLink={true}
              to={candidatePath}
              label={candidate}
              type={ButtonTypes.NotificationLink}
            />
          </span>{' '}
          from{' '}
          <Button
            isLink={true}
            to={recruiterPath}
            label={recruiter}
            type={ButtonTypes.NotificationLink}
          />
        </p>
      </div>
      <p className="createdAt">{createdAt}</p>
    </div>
  )
}

export default injectSheet(sheet)(Index)
