import React from 'react'
// components
import SpecificInformation from 'components/svgComponent/SpecificInformation'
import Variations from 'components/svgComponent/Variations'

interface Icon {
  name: string
  icon: JSX.Element
  text: string
}

export const icons: Icon[] = [
  {
    name: 'Specific information',
    icon: <SpecificInformation />,
    text: 'Use information that only you know and don’t tell it to others'
  },
  {
    name: 'Variations',
    icon: <Variations />,
    text: 'Do not use same password on every site. Change it'
  }
]
