import I from 'immutable'
import { createSelectorCreator, defaultMemoize } from 'reselect'

export const peek = (...path: any[]) => (obj: any) => obj.getIn(path)

function sThen(selectors: any) {
  // @ts-ignore
  return (state: any) => selectors(this(state))(state)
}

function sMap(f: any) {
  // @ts-ignore
  return selector(this, f)
}

const Selector = createSelectorCreator(defaultMemoize, I.is)

export function selector(...args: any) {
  // @ts-ignore
  const res = Selector(...args)
  res.then = sThen
  res.map = sMap
  return res
}

export const select = (f: () => void) => selector((x: any) => x, f)
