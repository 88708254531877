import { all, call, take, select, fork, put } from 'redux-saga/effects'
import { isLoading } from 'domain/loading'
import { replace } from 'connected-react-router'
import * as A from 'domain/manager/clients'
import * as R from 'domain/router'
import * as Rec from 'domain/manager/recruiters'
import queryString from 'query-string'
import { recruitersSelector } from 'domain/manager/selectors'

export function* clients({ query, pathname }: { query: any; pathname: string }) {
  yield all([fork(getClientsList, { query, pathname })])
}

function* getClientsList({ query, pathname }: { query: any; pathname: string }) {
  const route = yield select(R.routing)
  const props = {
    payload: {
      ...query,
      page: query.page || 1,
      perPage: query.perPage || 15
    },
    type: A.clientsList.request
  }

  // load list from the first page and change params in url after reload
  const prevLocation = Object.keys(route.prevLocation || {}).length
  if (!prevLocation || (prevLocation && route.action === 'POP' && props.payload.page !== 1)) {
    const stringifyParams = queryString.stringify(
      { ...props.payload, page: 1 },
      { arrayFormat: 'bracket' }
    )
    yield put(replace(`${pathname}?${stringifyParams}`))
    return
  }
  // parameters that need for query
  const payload = {
    payload: {
      byJobsParams: {
        status: query.status,
        range: query.range,
        recruiterId: query.recruiterId
      },
      page: query.page || 1,
      perPage: query.perPage || 15
    },
    type: A.clientsList.request
  }
  yield call(A.ensureGetRecruteirClientsList, payload)

  const recruiters = yield select(recruitersSelector)
  if (recruiters.isEmpty()) yield call(Rec.ensureRecruitersList)
}
