import { asyncAction } from 'lib/actionCreators'

export const superManagerJobsList = asyncAction('super_manager/JOBS_LIST')
export const jobData = asyncAction('super_manager/JOB')

// job note
export const getJobNotes = asyncAction('super_manager/GET_JOB_NOTES')
export const addNoteToJob = asyncAction('super_manager/ADD_NOTE_TO_JOB')
export const deleteNoteInJob = asyncAction('super_manager/DELETE_NOTE_IN_JOB')
export const editNoteInJob = asyncAction('super_manager/EDIT_NOTE_IN_JOB')
