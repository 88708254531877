import { Styles } from 'react-jss'

export const sheet: Styles = {
  footer: {
    display: 'grid',
    padding: '6% 0',
    gridTemplateColumns: '2fr repeat(8, 1fr) 2fr',
    gridRowGap: '2vw',
    gridColumnGap: '1vw',
    background: 'linear-gradient(180deg, #081331 0%, #131722 100%)',
    '& h5': {
      color: '#FAFAFA',
      whiteSpace: 'nowrap'
    },
    '& > div': {
      fontWeight: 'normal',
      fontSize: 15,
      letterSpacing: '0.01em',
      color: '#989FA6'
    },
    '& ul': {
      listStyleType: 'none',
      padding: 0,
      '& > li': {
        marginBottom: '1em'
      }
    },
    '& a': {
      textDecoration: 'none',
      color: '#989FA6'
    },
    '& p': {
      margin: 0
    }
  },
  footerLogo: {
    marginTop: '0.7em',
    gridColumn: '2 / span 2'
  },
  bigColumn: {
    gridColumn: 'span 2'
  },
  linkList: {
    '& > li': {
      display: 'flex',
      flexDirection: 'row',
      '& > p': {
        flex: 3,
        marginLeft: '1vw'
      }
    }
  },
  copyright: {
    gridColumn: '9',
    gridRow: '2'
  },
  disclaimer: {
    fontSize: '12px',
    color: '#989FA6',
    textAlign: 'center',
    gridColumn: '1 / -1',
    padding: '1em 0'
  },
  '@media (max-width: 768px)': {
    footer: {
      gridTemplateColumns: '1fr 5fr 5fr 1fr'
    },
    footerLogo: {
      marginTop: '0.7em',
      gridColumn: '2 / span 2'
    },
    bigColumn: {
      gridColumn: '2 / span 2'
    },
    helpColumn: {
      gridColumn: '2'
    },
    socialColumn: {
      gridColumn: '3'
    },
    copyright: {
      gridRow: 'auto',
      gridColumn: '2'
    },
    disclaimer: {
      gridColumn: '1 / -1'
    }
  }
}
