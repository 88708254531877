import { put, call } from 'redux-saga/effects'
import I from 'immutable'
import Api from 'domain/api'
import * as Actions from './actions'
import { convertCities, convertCountries } from './helpers'

export function* ensureGetCounties() {
  try {
    const {
      data: { data }
    } = yield call(Api.getCountries)
    const { countries, phoneCodes } = convertCountries(data)
    yield put({
      type: Actions.countries.success,
      payload: {
        countries: I.fromJS(countries),
        phoneCodes: I.fromJS(phoneCodes)
      }
    })
    yield put({ type: 'profile/CLEAR_CITIES' })
  } catch (err) {
    yield put({
      type: Actions.countries.failure,
      err
    })
  }
}

export function* ensureGetCities({
  payload
}: {
  payload: { name: string; used1st?: boolean }
  type: string
}) {
  if (!payload.name || payload.name.length < 2) {
    yield put({ type: Actions.cities.success, payload: I.fromJS([]) })
    return
  }
  try {
    const {
      data: { data }
    } = yield call(Api.getCities, {
      params: {
        take: 25,
        ...payload
      }
    })
    yield put({ type: Actions.cities.success, payload: I.fromJS(convertCities(data)) })
  } catch (err) {
    yield put({
      type: Actions.cities.failure,
      err
    })
  }
}

export function* ensureGetCity({ payload }: { payload: { cityId: number } }) {
  if (!payload.cityId || payload.cityId <= 0) {
    yield put({ type: Actions.cities.success, payload: I.fromJS([]) })
    return
  }
  try {
    const {
      data: { data }
    } = yield call(Api.getCity, { cityId: payload.cityId })
    yield put({ type: Actions.cities.success, payload: I.fromJS(convertCities([data])) })
  } catch (err) {
    yield put({
      type: Actions.cities.failure,
      err
    })
  }
}
