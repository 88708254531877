import { asyncAction } from 'lib/actionCreators'
import { createRoutine, promisifyRoutine } from 'redux-saga-routines'

export const educationsList = asyncAction('EDUCATIONS_LIST')
export const education = asyncAction('EDUCATION')

export const updateEducation = createRoutine('UPDATE_EDUCATION')
export const removeEducation = createRoutine('REMOVE_EDUCATION')
export const addEducation = createRoutine('ADD_EDUCATION')
export const updateEducationPromiseCreator = promisifyRoutine(updateEducation)
export const removeEducationPromiseCreator = promisifyRoutine(removeEducation)
export const addEducationPromiseCreator = promisifyRoutine(addEducation)
