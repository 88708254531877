import { put, call, select, take } from 'redux-saga/effects'
import { eventChannel, END } from 'redux-saga'
import { transformUser } from 'domain/env/helpers'
import I from 'immutable'
import Api from 'domain/api'
import * as Actions from './actions'
import * as M from 'domain/env'
import { AxiosPromise, AxiosRequestConfig } from 'axios'

interface Props {
  payload: FormData
  type: string
}

export const createUploadEventChanel = (
  { headers, payload }: { headers: string; payload: FormData },
  apiMethod: (config: AxiosRequestConfig) => AxiosPromise<any>
) =>
  eventChannel((emit: any) => {
    apiMethod({
      headers: { Authorization: `Bearer ${headers}` },
      data: payload,
      onUploadProgress: (progress: any) => {
        const percentCompleted = Math.round((progress.loaded * 100) / progress.total)
        emit({ progress: percentCompleted })
      }
    })
      .then(({ data }) => {
        emit({ success: data })
        emit(END)
      })
      .catch(err => {
        emit({ failure: err })
        emit(END)
      })
    return () => null
  })

export function* ensureAddAvatar({ payload }: Props) {
  const headers = yield select(M.userToken)
  if (!headers) return
  const channel = yield call(createUploadEventChanel, { headers, payload }, Api.addAvatar)
  while (true) {
    const { progress = 0, success, failure } = yield take(channel)
    if (success) {
      yield put({ type: Actions.addAvatar.success, payload: transformUser(I.fromJS(success.data)) })
      yield put(Actions.addAvatarProgress(null))
      return
    }
    if (failure) {
      yield put({
        type: Actions.addAvatar.failure,
        err: failure.err
      })
      yield put(Actions.addAvatarProgress(null))
      return
    }
    yield put(Actions.addAvatarProgress(progress))
  }
}
