import I, { List } from 'immutable'
import { Action } from 'redux'
import * as M from 'domain/superManager/managers'
import * as J from 'domain/superManager/jobs'
import * as C from 'domain/superManager/clients'
import * as D from 'domain/superManager/dashboard'
import * as Candidate from 'domain/superManager/candidates'
import * as Env from 'domain/env'
// types
import { CollaboratorRequest, ManagersOfSuperManager } from 'types/superManagerTypes'
import { JobManagerImmutable } from 'types/job/jobTypesManager'
import { ImmutableMap } from 'types/common'
import { IClosedJobs, IClientClosureRate } from 'pages/Dashboard/types'

interface ActionType extends Action {
  payload: any
}

const State = I.fromJS({
  manager: {},
  managers: [],
  managersSearchList: [],
  collaborationRequests: [],
  jobsListData: [],
  jobsListMeta: {},
  clientsData: [],
  clientsMeta: {},
  dashboard: {
    clientsCounters: {},
    jobsCounters: {},
    statistics: {},
    topRecruiters: {},
    topManagers: {},
    closedJobs: {
      data: [],
      meta: {}
    },
    clientClosureRate: {
      data: [],
      meta: {}
    }
  },
  jobData: I.Map(),
  candidateNotes: I.List()
})

export const reducer = {
  superManager(state = State, action: ActionType) {
    switch (action.type) {
      case M.managersList.success:
        return state.set('managers', action.payload)
      case M.managersSearch.success:
        return state.set('managersSearchList', action.payload)
      case M.collaborationRequests.success:
        return state.set('collaborationRequests', action.payload)
      case M.declineCollaborationRequest.SUCCESS:
        if (action.payload.isDeleteManager) {
          return state.update('managers', (u: List<ManagersOfSuperManager>) =>
            u.filter((i: ManagersOfSuperManager) => i.get('id') !== action.payload.id)
          )
        } else {
          return state.update('collaborationRequests', (u: List<CollaboratorRequest>) =>
            u.filter((i: CollaboratorRequest) => i.getIn(['recipient', 'id']) !== action.payload.id)
          )
        }
      case J.superManagerJobsList.success:
        return state
          .update('jobsListData', (u: JobManagerImmutable) =>
            action.payload.getIn(['meta', 'page']) === 1
              ? action.payload.get('data')
              : u.concat(action.payload.get('data'))
          )
          .set('jobsListMeta', action.payload.get('meta'))
      case J.jobData.success:
        return state.set('jobData', action.payload)
      case M.managerProfileView.success:
        return state.set('manager', action.payload)
      case C.superManagerClientsList.success:
        return state
          .update('clientsData', (u: JobManagerImmutable) =>
            action.payload.getIn(['meta', 'page']) === 1
              ? action.payload.get('data')
              : u.concat(action.payload.get('data'))
          )
          .set('clientsMeta', action.payload.get('meta'))
      case Candidate.candidateProfile.success:
        return state.set('candidateProfile', action.payload)
      case D.superManagerClientsCounters.success:
        return state.setIn(['dashboard', 'clientsCounters'], action.payload)
      case D.superManagerJobsCounters.success:
        return state.setIn(['dashboard', 'jobsCounters'], action.payload)
      case D.superManagerStatistics.success:
        return state.setIn(['dashboard', 'statistics'], action.payload)
      case D.superManagerClosedJobs.success:
        return state
          .updateIn(['dashboard', 'closedJobs', 'data'], (u: List<ImmutableMap<IClosedJobs>>) =>
            action.payload.getIn(['meta', 'page']) === 1
              ? action.payload.get('data')
              : u.concat(action.payload.get('data'))
          )
          .setIn(['dashboard', 'closedJobs', 'meta'], action.payload.get('meta'))
      case D.superManagerClientClosureRate.success:
        return state
          .updateIn(
            ['dashboard', 'clientClosureRate', 'data'],
            (u: List<ImmutableMap<IClientClosureRate>>) =>
              action.payload.getIn(['meta', 'page']) === 1
                ? action.payload.get('data')
                : u.concat(action.payload.get('data'))
          )
          .setIn(['dashboard', 'clientClosureRate', 'meta'], action.payload.get('meta'))
      case D.superManagerTopRecruiters.success:
        return state.setIn(['dashboard', 'topRecruiters'], action.payload)
      case D.superManagerTopManagers.success:
        return state.setIn(['dashboard', 'topManagers'], action.payload)
      case M.sendCollaborationRequest.SUCCESS:
        return state.set('managersSearchList', List([]))
      case Candidate.getCandidateJobNotes.success:
        return state.set('candidateNotes', action.payload)
      case Env.signOut.success:
        return State
      default:
        return state
    }
  }
}
