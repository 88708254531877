import { put, call, select } from 'redux-saga/effects'
import { isLoading } from 'domain/loading'
import { notificationsSelector } from './selectors'
import * as Actions from './actions'
import * as M from 'domain/env'
import Api from 'domain/api'
import I from 'immutable'

export function* ensureGetNotifications(props?: { payload?: { page: number }; type: string }) {
  const params = (props && props.payload) || { page: 1 }
  const headers = yield select(M.userToken)
  if (!headers) return
  try {
    const { data } = yield call(Api.getNotifications, {
      headers: { Authorization: `Bearer ${headers}` },
      params: { ...params, perPage: 50 }
    })
    const page = params.page
    const total = data.meta.total
    const prevState = yield select(notificationsSelector)
    if (!params || !page || page === 1 || total === prevState.size) {
      yield put({ type: Actions.newNotificationsList.success, payload: I.fromJS(data) })
    } else {
      yield put({ type: Actions.notificationsList.success, payload: I.fromJS(data) })
    }
  } catch (err) {
    yield put({
      type: Actions.notificationsList.failure,
      err
    })
  } finally {
    yield put({ type: isLoading, payload: false })
  }
}

export function* ensureGetUnreadNotifications() {
  const headers = yield select(M.userToken)
  if (!headers) return
  try {
    const {
      data: { data }
    } = yield call(Api.getUnreadNotifications, {
      headers: { Authorization: `Bearer ${headers}` }
    })
    const count = data ? data.length : 0
    yield put({ type: Actions.unreadNotifications.success, payload: count })
  } catch (err) {
    yield put({
      type: Actions.unreadNotifications.failure,
      err
    })
  }
}

export function* ensurePostNotificationsAsViewed(props?: { payload?: number[]; type?: string }) {
  const headers = yield select(M.userToken)
  if (!props || !props.payload || !headers) return
  try {
    const {
      data: { data }
    } = yield call(Api.postNotificationsAsViewed, {
      headers: { Authorization: `Bearer ${headers}` },
      data: props.payload
    })
    yield put({ type: Actions.notificationsAsViewed.success, payload: I.fromJS(data) })
    yield call(ensureGetUnreadNotifications)
    yield call(ensureGetNotifications)
  } catch (err) {
    yield put({
      type: Actions.notificationsAsViewed.failure,
      err
    })
  }
}
