import * as A from './actions'
import { Action } from 'redux'
import I from 'immutable'
import { StateInterface } from 'types/state'

interface ActionType extends Action {
  payload: any
}

const State = I.fromJS({
  isConnected: false,
  isListening: false,
  groupChannelsExist: false,
  user: I.Map(),
  channels: I.List(),
  unreadChannelsCount: 0,
  activeChannelUrl: '',
  unreadMessagesCount: 0
})

export const messages = (state: StateInterface) => state.messages

export const reducer = {
  messages(state = State, action: ActionType) {
    switch (action.type) {
      case A.setIsConnected.success:
        return state.set('isConnected', action.payload)
      case A.setIsListening.success:
        return state.set('isListening', action.payload)
      case A.setGroupChannelsExist.success:
        return state.set('groupChannelsExist', action.payload.groupChannelsExist)
      case A.setUser.success:
        return state.set('user', action.payload.user)
      case A.setActiveChannelUrl.success:
        return state.set('activeChannelUrl', action.payload.url)
      case A.addChannel.success:
        return state.update('channels', (chList: any) =>
          chList.concat(I.fromJS([action.payload.channel]))
        )
      case A.addChannels.success:
        return state.update('channels', (chList: any) =>
          chList.concat(I.fromJS(action.payload.channels))
        )
      case A.updateChannel.success:
        return state.updateIn(['channels', action.payload.channelIndex], (item: any) =>
          item
            .set('lastMessage', I.Map({ ...action.payload.lastMessage }))
            .set('unreadMessageCount', action.payload.unreadMessageCount)
        )
      case A.removeChannel.success:
        return state.update('channels', (list: any) =>
          list.filter((c: any) => c.get('url') !== action.payload.url)
        )
      case A.moveChannelUp.success:
        return state.update('channels', (chList: any) =>
          chList.sortBy((c: any) => c.get('url') !== action.payload.url)
        )
      case A.setUnreadChannelsCount.success:
        return state.set('unreadChannelsCount', action.payload.count)
      case A.addMessage.success:
        return state.updateIn(['channels', action.payload.channelIndex, 'messages'], (l: any) =>
          l.push(action.payload.message)
        )
      case A.addMessages.success:
        return state.updateIn(['channels', action.payload.channelIndex, 'messages'], (l: any) =>
          I.List(action.payload.messages).merge(l)
        )
      case A.updateMessage.success:
        return state.updateIn(
          ['channels', action.payload.channelIndex, 'messages', action.payload.messageIndex],
          () => action.payload.message
        )
      case A.setUnreadMessagesCount.success:
        return state.set('unreadMessagesCount', action.payload.count)
      case A.clear.success:
        return State
      default:
        return state
    }
  }
}
