import { FieldArray } from 'redux-form'

export interface Props {
  name: string
  component: any
  recruiters?: any
  formValues?: any
  canEdit?: boolean
}

export const FieldCustomArray = FieldArray as new () => FieldArray<Props>
