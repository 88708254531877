import React from 'react'
import Svg, { SvgType } from './Svg'

export default function(props: SvgType) {
  return (
    <Svg width="13" height="13" viewBox="0 0 13 13" fill="none" {...props}>
      <path
        d="M2 2L6.5 6.5M6.5 6.5L11 2M6.5 6.5L2 11M6.5 6.5L11 11"
        stroke="#080910"
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </Svg>
  )
}
