import { peek, selector } from 'lib/selectors'
import { StateInterface } from 'types/state'

const manager = (state: StateInterface) => state.manager
export const recruitersEmails = selector(manager, peek('invitedRecruiters'))
export const recruitersSelector = selector(manager, peek('recruiters'))
export const jobsSelector = selector(manager, peek('jobsListData'))
export const jobsMetaSelector = selector(manager, peek('jobsListMeta'))
export const jobSelector = selector(manager, peek('jobData'))
export const candidatesForCloseJobSelector = selector(manager, peek('jobCandidates'))
export const recruitersForCloseJobSelector = selector(manager, peek('jobRecruiters'))
export const clientsForJobSelector = selector(manager, peek('clientsListDataForJob'))
export const recruiterProfile = selector(manager, peek('recruiterProfile'))
export const allRecruitersSelector = selector(manager, peek('allRecruiters'))
export const allRecruitersMetaSelector = selector(manager, peek('allRecruitersMeta'))
export const managerProfileSelector = selector(manager, peek('managerView'))
export const candidatesSelector = selector(manager, peek('candidatesListData'))
export const candidatesMetaSelector = selector(manager, peek('candidatesListMeta'))
export const candidateProfileSelector = selector(manager, peek('candidateProfile'))
export const clientsSelector = selector(manager, peek('clientsListData'))
export const clientsMetaSelector = selector(manager, peek('clientsListMeta'))
export const clientSelector = selector(manager, peek('client'))
export const dashboardSelector = selector(manager, peek('dashboard'))
export const jobsCloseJobSelector = selector(manager, peek('jobsCloseJob'))
export const recruitersCloseJobSelector = selector(manager, peek('recruitersCloseJob'))
export const eventsListSelector = selector(manager, peek('calendar', 'eventsList'))
export const candidatesListSelector = selector(manager, peek('calendar', 'candidatesList'))
export const eventSelector = selector(manager, peek('calendar', 'event'))
export const nearestEventIdSelector = selector(manager, peek('calendar', 'nearestEventId'))
export const managerCandidateJobsListSelector = selector(
  manager,
  peek('calendar', 'managerCandidateJobsList')
)
export const invitedRecruitersSelector = selector(manager, peek('invitedRecruitersOnProfile'))
export const collaborationRequestsSelector = selector(manager, peek('collaborationRequests'))
export const recruiterProfileSkillsSelector = selector(manager, peek('recruiterProfileSkills'))
export const recruiterProfileJobsSelector = selector(manager, peek('recruiterProfileJobs', 'data'))
export const recruiterProfileJobsMetaSelector = selector(
  manager,
  peek('recruiterProfileJobs', 'meta')
)
export const recruiterProfileCandidatesSelector = selector(
  manager,
  peek('recruiterProfileCandidates', 'data')
)
export const recruiterProfileCandidatesMetaSelector = selector(
  manager,
  peek('recruiterProfileCandidates', 'meta')
)
export const managersListSelector = selector(manager, peek('managersList'))

export const recruiterClientsListSelector = selector(manager, peek('recruiterClients', 'data'))
export const recruiterClientsMetaSelector = selector(manager, peek('recruiterClients', 'meta'))
