import React from 'react'
import { List, Map } from 'immutable'

export enum ButtonTypes {
  Default = 'default',
  Primary = 'primary',
  Auth = 'auth',
  Secondary = 'secondary',
  Submit = 'submit',
  SubmitDisabled = 'submitDisabled',
  SubmitUnclick = 'submitUnclick',
  BlueButton = 'blueButton',
  RedButton = 'redButton',
  Menu = 'menu',
  Unsaved = 'unsaved',
  Saved = 'saved',
  Cancel = 'cancel',
  Delete = 'delete',
  Tab = 'tab',
  ActiveTab = 'activeTab',
  Add = 'add',
  Reopen = 'reopen',
  HoldJob = 'holdJob',
  CloseJob = 'closeJob',
  Back = 'back',
  RedButtonDelete = 'redButtonDelete',
  Accept = 'accept',
  Decline = 'decline',
  Dashboard = 'dashboard',
  TabPeriod = 'tabPeriod',
  ActiveTabPeriod = 'activeTabPeriod',
  DisabledTabPeriod = 'disabledTabPeriod',
  GreenButton = 'greenButton',
  DateTimeEvent = 'dateTimeEvent',
  NotificationLink = 'notificationLink',
  NotificationLinkTitle = 'notificationLinkTitle',
  Dangerous = 'dangerous'
}

export enum ButtonHtmlTypes {
  Submit = 'submit',
  Button = 'button'
}

export enum Sort {
  Desc = 'DESC',
  Asc = 'ASC',
  None = 'NONE'
}

export interface ComposeType<T, P = {}> extends React.ComponentClass<P> {
  new (props: T): React.Component<T>
}

export type SocialLinksType =
  | ImmutableMap<{
      linkedin?: string
      facebook?: string
    }>
  | ImmutableMap<{}>

export type UserType = ImmutableMap<{
  id: number
  uuid: string
  avatar: string
  birthday: string | null
  cityId: string | null
  confirmedAt: string
  countryId: string | null
  createdAt: string
  email: string
  firstName: string
  lastName: string
  phone: string | null
  phonePrefix: string | null
  role: string
  socialLinks: SocialLinksType
  superManagerRequestPending?: boolean
  canRequestSuperManagerRole?: boolean
}>

export interface ImmutableMap<T> extends Map<string, any> {
  get<K extends keyof T>(name: K, notSetValue?: T[K]): T[K]
}

export type Header = HeaderItem[]

export interface HeaderItem {
  value: any
  name: string
  long?: boolean
  canSort?: boolean
}

export type SpheresImmutableT = List<SpheresImmutableTItem>

export type SpheresImmutableTItem =
  | ImmutableMap<{
      id: number
      name: string
    }>
  | ImmutableMap<{ name: string }>

export type SpheresListT = Array<
  | {
      id: number
      name: string
    }
  | { name: string }
>

export type SpheresExist = SpheresItemExist[]

export interface SpheresItemExist {
  id: number
  name: string
}

export type SelectListValueStringT = SelectItemValueStringI[]

export interface SelectItemValueStringI {
  value: string
  label: string
}

export interface CityI {
  countryId: number
  id: number
  name: string
  state: { name: string } | null
  country: CountryI
  lat?: string | number
  lng?: string | number
  zip?: string
  population?: string | number
}

export interface StateI {
  id: number
  name: string
}
export type SelectListT = SelectItemI[]

export interface SelectItemI {
  value: number
  label: string
}

export interface CountryI {
  name: string
  id: number
  phoneCode: string
  isActive: boolean
  iso: string
}

export interface RateI {
  rateFrom: string
  rateTo: string
  currency: SelectItemValueStringI
}

export type MetaT = ImmutableMap<{
  total: number
  perPage?: number
  page?: number
}>

export interface FormErrorsInterface {
  [x: string]: any & { [x: string]: any }
}

export type ChangeFormFieldsType = (field: string, value: any) => void

export interface Education {
  id: number
  school: string
  degree: string
  study: string
  from: number
  to: number | null
  userId: number
  createdAt: string
}

export interface Experience {
  toMonth: number
  isWorking: boolean
  fromYear: number
  location: string
  fromMonth: number
  toYear: number
  title: string
  id: number
  createdAt: string
  company: string
  candidateId: number
}

export type ReceivedPointsType = List<ReceivedPointItemType>

export type ReceivedPointItemType = ImmutableMap<{
  rewardId: number
  job: ImmutableMap<{
    id: number
    title: string
    points: number
  }>
  manager: ImmutableMap<{
    id: number
    firstName: string
    lastName: string
    role: string
    avatar: string | null
  }>
  candidate: ImmutableMap<{
    id: number
    firstName: string
    lastName: string
    role: string
    avatar: string | null
  }>
}>

export type PointsType = ImmutableMap<{
  latestReceived: ImmutableMap<{
    createdAt: string
    points: number
  }>
  availablePoints: number
}>

// candidate status
export enum CandidateJobStatusList {
  CONTACTED = 'Contacted',
  SUBMITTED = 'Submitted  (to Client for Job)',
  FIRST_INTERVIEW_SCHEDULED = 'First Interview Scheduled',
  FIRST_INTERVIEW_COMPLETED = 'First Interview Complete',
  REJECTED = 'Rejected',
  SECOND_INTERVIEW_SCHEDULED = 'Second Interview Scheduled',
  SECOND_INTERVIEW_COMPLETED = 'Second Interview Complete',
  CANDIDATE_SELECTED = 'Candidate Selected',
  HIRING = 'Hiring/OnBoarding'
}

export type CandidateStatusType = ImmutableMap<{
  id: number
  jobId: number
  candidateId: number
  managerId: number
  status: CandidateJobStatusList | null
  createdAt: Date
  updatedAt: Date
  createdBy: UserType
}>
export enum NoteTypes {
  STATUS_CHANGE = 'status_change',
  USER_NOTE = 'user_note'
}

export interface NoteType {
  id: number
  note: string
  jobId: number
  candidateId: number
  createdById: number
  createdAt: Date
  updatedAt: Date
  createdBy: UserType
  noteType: NoteTypes
}

export type CandidateNotesItemType = ImmutableMap<NoteType>

export type NotesType = List<CandidateNotesItemType>

interface MList {
  id: number
  firstName: string
  lastName: string
}

export type ManagerListType = List<ImmutableMap<MList>>

export interface ResolveT {
  resolve?: (value: undefined) => void
}

export interface TabItem {
  id: number
  value: string
}

export type Tab = TabItem[]

export interface Params {
  page?: number
  order?: Sort
  order_by?: string | null
  range: string
}

export interface ResumeParsedData {
  candidate_id: number | null
  firstName: string
  lastName: string
  email: string
  phone: string
  phonePrefix: string
  skills: List<ImmutableMap<INamed>>
  country: INamed
  city: INamed
  state: string | null
  workExperiences: List<Experience>
  educations: List<Education>
  employeeInfo: ImmutableMap<EmployeeInfo>
  // avatar: string | null
  // role: string
  // resume: Resume
  // uuid: string | null
  // sbAccessToken: string | null
  // isGenerated: boolean
  // clearanceType: ImmutableList<any> // Define a type for clearance type if available
  // cityNew: string | null
  // createdById: number | null
  // canAssign: boolean
  // canDecline: boolean
  // jobAssignsCount: number
  // selfAssignedJobsCount: number
  // isFavorite: boolean
  // isSbRegistered: boolean
}

export interface EmployeeInfo {
  relocation: boolean
  softSkills: string
}

export interface INamed {
  name: string
}
