import React from 'react'
import Svg, { SvgType } from 'components/svgComponent/Svg'

export default function(props: SvgType) {
  return (
    <Svg width="34" height="31" viewBox="0 0 34 31" fill="none" {...props}>
      <rect
        x="27.8262"
        y="3.90625"
        width="5.17393"
        height="26.0936"
        rx="1"
        stroke="#286EFA"
        strokeWidth="2"
      />
      <rect
        x="14.9131"
        y="16.5488"
        width="5.17393"
        height="13.4515"
        rx="1"
        stroke="#286EFA"
        strokeWidth="2"
      />
      <rect
        x="2"
        y="23.5723"
        width="5.17393"
        height="6.42807"
        rx="1"
        stroke="#286EFA"
        strokeWidth="2"
      />
      <path
        d="M1 15.5937L6.50001 10.1562H17.0417L21.1667 2M21.1667 2L22.5417 7.43747M21.1667 2L16.125 4.26561"
        stroke="#286EFA"
        strokeWidth="2"
      />
    </Svg>
  )
}
