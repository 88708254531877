import React from 'react'
// components
import JobForYou from 'components/svgComponent/JobForYou'
import Contact from 'components/svgComponent/Contact'
import GetHired from 'components/svgComponent/GetHired'

interface Icon {
  data: Array<
    Array<{
      name?: string
      icon?: JSX.Element
      text?: string
    }>
  >
}

export const icons: Icon = {
  data: [
    [
      {
        name: 'Job for you',
        icon: <JobForYou />,
        text:
          'Search for jobs selected specifically for you relaying on your skills, hourly rate and salary and location'
      },
      {
        name: 'Contact',
        icon: <Contact />,
        text:
          'SKILENT includes a comprehensive Instant Messaging and Calendaring feature to contact and conduct conversations with prospective Recruiters, Clients and Candidates'
      }
    ],
    [
      {
        name: 'Hire the Best',
        icon: <GetHired />,
        text: 'Get hired by some of the biggest companies in the world'
      },
      {}
    ]
  ]
}
