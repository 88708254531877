import React, { Component } from 'react'
// components
import Password from 'components/svgComponent/Password'
import HidePassword from 'components/svgComponent/HidePassword'
import Spinner from 'components/Spinner'
import Layout from './Layout'
// redux
import { WrappedFieldProps } from 'redux-form'
// types
import { BorderColor, InputPropsType } from 'components/Form/Fields/FieldInput'
import { Classes } from 'jss'
// utils
import cx from 'classnames'
// styles
import injectSheet from 'react-jss/lib/injectSheet'
import { fieldsClasses } from './sheet'
// icons
import AddEducation from 'components/svgComponent/AddEducation'
import AddWorkExperience from 'components/svgComponent/AddWorkExperience'
import CheckIcon from 'components/svgComponent/Check'

interface State {
  showPassword: boolean
}

interface OwnProps {
  classes: Classes
  isLoading?: boolean
  withCheckIcon?: boolean
}

type Props = OwnProps &
  InputPropsType &
  React.InputHTMLAttributes<HTMLInputElement> &
  WrappedFieldProps

class Input extends Component<Props, State> {
  state = {
    showPassword: false
  }

  showHidePassword = () => {
    this.setState({ showPassword: !this.state.showPassword })
  }

  renderCheckIcon = (isError?: boolean, isLoading?: boolean) => {
    const { classes, meta } = this.props
    const isShowCheckIcon = isError === undefined && !meta.active

    return isLoading ? (
      <Spinner overrideClass={classes.rightIcon} />
    ) : (
      isShowCheckIcon && <CheckIcon className={classes.rightIcon} />
    )
  }

  render() {
    const {
      input,
      classes,
      type,
      title,
      meta,
      isPassword = false,
      borderColor,
      education = false,
      experience = false,
      disabled = false,
      autoComplete = 'new-password',
      withCheckIcon = false,
      isLoading = false,
      customLayoutClasses,
      ...args
    } = this.props
    const { showPassword } = this.state
    const id = input.name
    const isError = meta.touched && meta.error

    return (
      <Layout customClasses={customLayoutClasses} title={title} meta={meta} id={id}>
        <input
          id={id}
          type={isPassword && showPassword ? 'text' : type}
          disabled={disabled}
          className={cx(
            classes.inputControl,
            {
              [classes.disabled]: disabled
            },
            {
              [classes.paddingInput]: education || experience
            },
            {
              [classes.inputControlError]: isError || borderColor === BorderColor.ErrorEmail
            },
            {
              [classes.inputBorder]: borderColor === BorderColor.Success
            }
          )}
          {...input}
          {...args}
          autoComplete={autoComplete}
        />
        {withCheckIcon && this.renderCheckIcon(isError, isLoading)}
        {education && <AddEducation className={classes.iconInput} iconActive={!!input.value} />}
        {experience && (
          <AddWorkExperience className={classes.iconInput} iconActive={!!input.value} />
        )}
        {isPassword && showPassword && (
          <HidePassword onClick={this.showHidePassword} className={classes.passwordIcon} />
        )}
        {isPassword && !showPassword && (
          <Password onClick={this.showHidePassword} className={classes.passwordIcon} />
        )}
      </Layout>
    )
  }
}

export default injectSheet(fieldsClasses)(Input)
