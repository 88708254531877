import React from 'react'
import Svg, { SvgType } from 'components/svgComponent/Svg'

export default function(props: SvgType) {
  return (
    <Svg width="37" height="35" viewBox="0 0 37 35" fill="none" {...props}>
      <path
        d="M11 9C7.45312 9 4.58203 11.873 4.58203 15.417V23.208H2.75C2.24219 23.208 1.83203 23.6191 1.83203 24.125C1.83203 24.6309 2.24219 25.042 2.75 25.042L4.58203 25.0635H17.4141L19.25 25.042C19.7578 25.042 20.168 24.6309 20.168 24.125C20.168 23.6191 19.7578 23.208 19.25 23.208H17.4141V15.417C17.4141 11.873 14.543 9 11 9Z"
        fill="#FAFAFA"
      />
      <path
        d="M12.8346 27.333C12.8346 28.3455 12.0138 29.1663 11.0013 29.1663C9.98878 29.1663 9.16797 28.3455 9.16797 27.333"
        stroke="#FAFAFA"
        strokeWidth="1.83333"
      />
    </Svg>
  )
}
