import React from 'react'
import Svg, { SvgType } from 'components/svgComponent/Svg'

export default function(props: SvgType) {
  const { iconActive = true } = props
  const fill = iconActive ? '#286EFA' : '#C1C9D2'
  return (
    <Svg width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
      <circle cx="9" cy="9" r="9" fill={fill} />
      <path d="M4.84766 9.00004L8.07843 12.2308L13.6169 4.84619" stroke="#FAFAFA" strokeWidth="2" />
    </Svg>
  )
}
