import { Styles } from 'react-jss'

export const sheet: Styles = {
  main: {
    height: '100%',
    margin: '0 auto',
    minWidth: 360,
    position: 'relative',
    overflow: 'hidden'
  },
  root: {
    overflow: 'scroll',
    padding: 30,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    maxHeight: 'calc(100vh - 52px)',
    boxSizing: 'border-box'
  },
  navBar: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#202332',
    height: 52,
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '8px 11px',
    position: 'relative',
    width: '100%',
    maxWidth: 'inherit',
    boxSizing: 'border-box',
    zIndex: 1
  }
}
