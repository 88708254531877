import { put, call, select } from 'redux-saga/effects'
import { transformData } from './helpers'
import { EmployeeInfoType } from 'pages/Profile/EmployeeInfo/types'
import * as Actions from './actions'
import * as M from 'domain/env'
import Api from 'domain/api'
import I from 'immutable'

export function* ensureEmployeeInfo() {
  const headers = yield select(M.userToken)
  if (!headers) return
  try {
    const {
      data: { data }
    } = yield call(Api.getEmployeeInfo, {
      headers: { Authorization: `Bearer ${headers}` }
    })
    yield put({ type: Actions.employeeInfo.success, payload: I.fromJS(data) })
  } catch (err) {
    yield put({
      type: Actions.employeeInfo.failure,
      err
    })
  }
}

export function* ensureAddEmployeeInfo({ payload }: { payload: EmployeeInfoType; type: string }) {
  const headers = yield select(M.userToken)
  if (!headers) return
  try {
    const {
      data: { data }
    } = yield call(Api.postEmployeeInfo, {
      headers: { Authorization: `Bearer ${headers}` },
      data: transformData(payload)
    })
    yield put({ type: Actions.addEmployeeInfo.SUCCESS, payload: I.fromJS(data) })
    yield put({ type: Actions.employeeInfo.type })
  } catch (err) {
    yield put({
      type: Actions.addEmployeeInfo.FAILURE,
      err
    })
  }
}
