import { all, takeLatest, take, fork, put } from 'redux-saga/effects'
import * as Actions from 'domain/recruiter/importedCandidate/actions'
import * as Sagas from 'domain/recruiter/importedCandidate/sagas'
import { ensureCheckUserEmail } from 'domain/env/sagas'
import { checkUserEmail } from 'domain/env/actions'
import I from 'immutable'

export function* profilePage() {
  yield all([
    fork(getProfile),
    fork(postProfile),
    fork(checkCandidateEmail),
    fork(postResume),
    fork(postResumeAndCreate)
  ])
}

function* getProfile() {
  yield put({
    type: Actions.importedCandidateProfile.success,
    payload: I.fromJS({})
  })
  yield takeLatest(Actions.importedCandidateProfile.type, Sagas.ensureGetImportedCandidate)
  yield take(Actions.importedCandidateProfile.success)
}

function* postProfile() {
  yield takeLatest(Actions.candidatesAdd.TRIGGER, Sagas.ensureAddProfileIC)
  yield take(Actions.candidatesAdd.SUCCESS)
}

function* postResume() {
  yield put({
    type: Actions.resumeAdd.SUCCESS,
    payload: I.fromJS({})
  })
  yield takeLatest(Actions.resumeAdd.TRIGGER, Sagas.ensureParseResume)
  yield take(Actions.resumeAdd.SUCCESS)
}

function* postResumeAndCreate() {
  yield takeLatest(Actions.resumeAddCreate.TRIGGER, Sagas.ensureParseResumeAndCreate)
  yield take(Actions.resumeAddCreate.SUCCESS)
}

function* checkCandidateEmail() {
  yield takeLatest(checkUserEmail.TRIGGER, ensureCheckUserEmail)
}
