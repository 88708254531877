import { Styles } from 'react-jss'

export const sheet: Styles = {
  main: {
    height: '100%',
    margin: '0 auto',
    minWidth: 360,
    overflow: 'auto'
  },
  root: {
    height: '100%',
    margin: '0 auto',
    minWidth: 360,
    display: 'flex',
    position: 'relative',
    transition: 'margin .6s ease',
    overflow: 'hidden'
  },
  // // rootContainer: {
  // //   display: 'flex',
  // //   flexDirection: 'column',
  // //   height: '100%',
  // //   width: '100%'
  // // },
  rootContainer: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    gridTemplateRows: '52px 1fr',
    gridTemplateAreas: '"sidebar nav" "sidebar content"',
    width: '100%'
  },
  '@media (max-width: 767px)': {
    rootContainer: {
      gridTemplateAreas: '"nav nav" "content content"'
    },
    openSidebarOverlay: {
      position: 'absolute',
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
      background: 'rgba(0, 0, 0, 0.4)',
      zIndex: 1000
    }
  }
}
