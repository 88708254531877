import React from 'react'
import Svg, { SvgType } from 'components/svgComponent/Svg'

export default function(props: SvgType) {
  return (
    <Svg width="20" height="13" viewBox="0 0 20 13" fill="none" {...props}>
      <path
        d="M19.7769 5.65851C18.7351 3.95465 17.3343 2.5856 15.5747 1.55141C13.815 0.517175 11.9569 0 10 0C8.04314 0 6.18491 0.516979 4.42522 1.55141C2.6655 2.5856 1.26479 3.95465 0.223132 5.65851C0.0743903 5.91889 0 6.1756 0 6.42864C0 6.68168 0.0743903 6.93839 0.223132 7.1987C1.26479 8.90264 2.6655 10.2718 4.42522 11.3058C6.18491 12.3401 8.04314 12.8569 10 12.8569C11.9569 12.8569 13.8151 12.3418 15.5747 11.3114C17.3343 10.2809 18.7353 8.90995 19.7769 7.1987C19.9256 6.93839 20 6.6818 20 6.42864C20 6.1756 19.9256 5.91889 19.7769 5.65851ZM14.8494 10.0728C13.3575 10.9766 11.7411 11.4287 10 11.4287C8.25892 11.4287 6.64251 10.9768 5.15072 10.0728C3.65892 9.16873 2.41821 7.95405 1.42866 6.42864C2.5596 4.67263 3.97701 3.35929 5.68087 2.48893C5.22702 3.26262 5.00002 4.09975 5.00002 4.99998C5.00002 6.37661 5.48932 7.55376 6.46765 8.53233C7.44599 9.51089 8.62356 9.99996 10 9.99996C11.3766 9.99996 12.5538 9.51062 13.5323 8.53233C14.5109 7.55403 15 6.37661 15 4.99998C15 4.09975 14.773 3.2625 14.3191 2.48893C16.0229 3.35929 17.4404 4.67275 18.5713 6.42864C17.5819 7.95405 16.3413 9.16873 14.8494 10.0728Z"
        fill="#E6E6E6"
      />
    </Svg>
  )
}
