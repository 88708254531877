import React, { FC } from 'react'
// types
import { Classes } from 'jss'
// styles
import injectSheet, { Styles } from 'react-jss'
// utils
import cx from 'classnames'

const style: Styles = {
  circle: {
    position: 'absolute',
    width: '33vw',
    height: '33vw',
    left: '-10vw',
    top: '35vw',
    zIndex: -1,
    background: '#FAFAFA',
    borderRadius: '50%',
    transform: 'matrix(1, 0, 0, -1, 0, 0)'
  },
  forLogIn: {
    background: '#E6E6E6',
    opacity: 0.2,
    borderRadius: '668px',
    transform: 'matrix(1, 0, 0, -1, 0, 0)',
    top: '-25em',
    left: '20em',
    width: 668,
    height: 668
  },
  '@media (max-width: 767.98px)': {
    circle: {
      width: 668,
      height: 668,
      top: '21%',
      left: '-32em'
    }
  }
}

interface Props {
  classes: Classes
  className?: string
}

const CircleBackground: FC<Props> = ({ classes, className = '' }) => (
  <div className={cx(classes.circle, classes[className])} />
)

export default injectSheet(style)(CircleBackground)
