import { put, call, select } from 'redux-saga/effects'
import { error403, pageIsLoading, skeleton } from 'domain/loading'
import { jobsSelector } from 'domain/candidate/selectors'
import * as Actions from './actions'
import * as M from 'domain/env'
import * as Router from 'domain/router'
import Api from 'domain/api'
import I from 'immutable'

type WithLoadingT = boolean | undefined

interface Params {
  order?: string
  scope?: string
  filter?: FilterType
  page?: number
}

interface FilterType {
  countryId?: number
  cityId?: number
  tagIds?: string[]
  rateFrom?: string
  rateTo?: string
  rateCurrency?: string
  salaryFrom?: string
  salaryTo?: string
  salaryCurrency?: string
  relocation?: boolean
  employmentType?: string[]
  clearanceType?: string[]
  travel?: boolean
  citizenship?: string[]
}

export function* ensureGetJobs(
  props?: { payload?: Params; type?: string },
  withLoading: WithLoadingT = true
) {
  const headers = yield select(M.userToken)
  if (!headers) return
  if (withLoading && (!props || !props.payload || props.payload.page === 1)) {
    yield put({ type: skeleton, payload: true })
    yield put({ type: pageIsLoading, payload: true })
  }
  const params = (props && props.payload) || {}
  try {
    const { data } = yield call(Api.getJobsCandidate, {
      headers: { Authorization: `Bearer ${headers}` },
      params
    })
    const page = params.page
    const total = data.meta.total
    const prevState = yield select(jobsSelector)
    if (!params || !page || page === 1 || total === prevState.size) {
      yield put({ type: Actions.newJobsList.success, payload: I.fromJS(data) })
    } else {
      yield put({ type: Actions.jobsList.success, payload: I.fromJS(data) })
    }
  } catch (err) {
    yield put({
      type: Actions.jobsList.failure,
      err
    })
  } finally {
    yield put({ type: skeleton, payload: false })
    yield put({ type: pageIsLoading, payload: false })
  }
}

export function* ensureAddJobToFavorite(props: {
  payload: { id: number; params: Params }
  type: string
}) {
  const headers = yield select(M.userToken)
  if (!headers) return
  const id = props && props.payload && props.payload.id
  try {
    yield put({ type: pageIsLoading, payload: true })
    const {
      data: { data }
    } = yield call(Api.addJobToFavoriteCandidate, {
      headers: { Authorization: `Bearer ${headers}` },
      id,
      type: 'job'
    })
    yield put({ type: Actions.saveJob.success, payload: I.fromJS(data) })
    yield call(ensureGetJob)
  } catch (err) {
    yield put({
      type: Actions.saveJob.failure,
      err
    })
  } finally {
    yield put({ type: pageIsLoading, payload: false })
  }
}

export function* ensureAddJobToFavoriteFromList(props: {
  payload: { id: number; isSavedTab: boolean }
  type: string
}) {
  const headers = yield select(M.userToken)
  if (!headers) return
  const id = props && props.payload && props.payload.id
  try {
    yield put({ type: pageIsLoading, payload: true })
    const {
      data: { data }
    } = yield call(Api.addJobToFavoriteCandidate, {
      headers: { Authorization: `Bearer ${headers}` },
      id,
      type: 'job'
    })
    yield put({
      type: Actions.saveJobFromList.success,
      payload: I.fromJS({ id, value: data, isSavedTab: props.payload.isSavedTab })
    })
  } catch (err) {
    yield put({
      type: Actions.saveJobFromList.failure,
      err
    })
  } finally {
    yield put({ type: pageIsLoading, payload: false })
  }
}

export function* ensureGetJob() {
  yield put({ type: error403, payload: false })
  const headers = yield select(M.userToken)
  if (!headers) return
  const {
    params: { id }
  } = yield select(Router.matchRoutes)
  try {
    const {
      data: { data }
    } = yield call(Api.getJobCandidate, {
      headers: { Authorization: `Bearer ${headers}` },
      id
    })
    yield put({ type: Actions.jobData.success, payload: I.fromJS(data) })
  } catch (err) {
    yield put({ type: Actions.jobData.success, payload: I.fromJS({}) })
    const { statusCode } = err.response.data
    if (statusCode === 403 || statusCode === 404) {
      yield put({ type: error403, payload: true })
    }
    yield put({
      type: Actions.jobData.failure,
      err
    })
  }
}

export function* ensureAssignJob() {
  const headers = yield select(M.userToken)
  if (!headers) return
  const {
    params: { id }
  } = yield select(Router.matchRoutes)
  try {
    const {
      data: { data }
    } = yield call(Api.assignJobCandidate, {
      headers: { Authorization: `Bearer ${headers}` },
      id
    })
    yield put({ type: Actions.assignJob.success, payload: I.fromJS(data) })
    yield call(ensureGetJob)
  } catch (err) {
    yield put({
      type: Actions.assignJob.failure,
      err
    })
  }
}

export function* ensureAcceptJob() {
  const headers = yield select(M.userToken)
  if (!headers) return
  const {
    params: { id }
  } = yield select(Router.matchRoutes)
  try {
    const {
      data: { data }
    } = yield call(Api.acceptJobCandidate, {
      headers: { Authorization: `Bearer ${headers}` },
      id
    })
    yield put({ type: Actions.acceptJob.success, payload: I.fromJS(data) })
    yield call(ensureGetJob)
  } catch (err) {
    yield put({
      type: Actions.acceptJob.failure,
      err
    })
  }
}

export function* ensureCancelJob() {
  const headers = yield select(M.userToken)
  if (!headers) return
  const {
    params: { id }
  } = yield select(Router.matchRoutes)
  try {
    const {
      data: { data }
    } = yield call(Api.cancelJobCandidate, {
      headers: { Authorization: `Bearer ${headers}` },
      id
    })
    yield put({ type: Actions.cancelJob.SUCCESS, payload: I.fromJS(data) })
    yield call(ensureGetJob)
  } catch (err) {
    yield put({
      type: Actions.cancelJob.FAILURE,
      err
    })
  }
}

export function* ensureGetJobsCount() {
  const headers = yield select(M.userToken)
  if (!headers) return
  try {
    const {
      data: { data }
    } = yield call(Api.getJobsCandidateCount, {
      headers: { Authorization: `Bearer ${headers}` }
    })
    yield put({ type: Actions.getJobsCount.success, payload: data })
  } catch (err) {
    yield put({
      type: Actions.getJobsCount.failure,
      err
    })
  }
}
