import React from 'react'
import Svg, { SvgType } from 'components/svgComponent/Svg'

export default function(props: SvgType) {
  return (
    <Svg width="15" height="15" viewBox="0 0 15 15" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.50068 8.5L1.13672 8.5V6.5L6.50068 6.5V1.13604L8.50068 1.13604L8.50068 6.5H13.8646V8.5H8.50068L8.50068 13.864L6.50068 13.864V8.5Z"
        fill="#286EFA"
      />
    </Svg>
  )
}
