import { fork, takeEvery, cancel, select, call } from 'redux-saga/effects'
import { matchPath } from 'react-router-dom'
import { query as querySelector } from 'domain/router'
import { LOCATION_CHANGE, LocationChangeAction } from 'connected-react-router'
import { Task } from 'redux-saga'
import { constantsSelector, State } from 'domain/constants'
import { ensureGetConstants } from 'domain/constants/sagas'
import * as MProfile from 'pages/Manager/Profile/sagas'
import * as MJobsEdit from 'pages/Manager/Jobs/Edit/sagas'
import * as MJobsView from 'pages/Manager/Jobs/View/sagas'
import * as MJobsList from 'pages/Manager/Jobs/List/sagas'
import * as MRecruiters from 'pages/Manager/Recruiters/List/sagas'
import * as MRecruiterView from 'pages/Manager/ProfileView/Recruiter/sagas'
import * as MManagerView from 'pages/Manager/ProfileView/Manager/sagas'
import * as MCandidates from 'pages/Manager/Candidates/List/sagas'
import * as MCandidateView from 'pages/Manager/Candidates/View/sagas'
import * as MClients from 'pages/Manager/Clients/List/sagas'
import * as MDashboard from 'pages/Manager/Dashboard/sagas'
import * as MCalendar from 'pages/Manager/Calendar/sagas'
import * as MRecruiterClients from 'pages/Manager/Recruiters/ClientsList/sagas'
import * as MRecruitersJobs from 'pages/Manager/Recruiters/Jobs/sagas'
import * as MClientsJobs from 'pages/Manager/Clients/Jobs/sagas'
import * as CProfile from 'pages/Candidate/Profile/sagas'
import * as CJobsList from 'pages/Candidate/Jobs/List/sagas'
import * as CJobsView from 'pages/Candidate/Jobs/View/sagas'
import * as CManagerView from 'pages/Candidate/ProfileView/Manager/sagas'
import * as CRecruiterView from 'pages/Candidate/ProfileView/Recruiter/sagas'
import * as CCandidateView from 'pages/Candidate/ProfileView/Candidate/sagas'
import * as CCalendar from 'pages/Candidate/Calendar/sagas'
import * as CDashboard from 'pages/Candidate/Dashboard/sagas'
import * as RDashboard from 'pages/Recruiter/Dashboard/sagas'
import * as RProfile from 'pages/Recruiter/Profile/sagas'
import * as RJobsView from 'pages/Recruiter/Jobs/View/sagas'
import * as RJobsList from 'pages/Recruiter/Jobs/List/sagas'
import * as RManagerView from 'pages/Recruiter/ProfileView/Manager/sagas'
import * as RRecruiterView from 'pages/Recruiter/ProfileView/Recruiter/sagas'
import * as RCandidates from 'pages/Recruiter/Candidates/List/sagas'
import * as RResumes from 'pages/Recruiter/Resumes/List/sagas'
import * as RCandidateProfile from 'pages/Recruiter/Candidates/View/sagas'
import * as RCandidateProfileEdit from 'pages/Recruiter/Candidates/Edit/sagas'
import * as RCandidateProfileAdd from 'pages/Recruiter/Candidates/Add/sagas'
import * as Messages from 'pages/Messages/sagas'
import * as Auth from 'pages/Auth/sagas'
import * as GuestJobs from 'pages/Guest/Jobs/sagas'
import * as Notifications from 'pages/Notifications/sagas'
import * as SuperManagerProfile from 'pages/SuperManager/Profile/sagas'
import * as SuperManagerJobsView from 'pages/SuperManager/Jobs/View/sagas'
import * as SuperManagerJobs from 'pages/SuperManager/Jobs/List/sagas'
import * as SMManager from 'pages/SuperManager/ProfileView/Manager/sagas'
import * as SuperManagerClients from 'pages/SuperManager/Clients/List/sagas'
import * as SuperManagerDashboard from 'pages/SuperManager/Dashboard/sagas'
import * as SMCandidateProfile from 'pages/SuperManager/Candidates/View/sagas'
import * as SMCandidatesJobs from 'pages/SuperManager/Jobs/ClientsJobs/sagas'

const path = [
  { path: '/account/confirmation', fn: Auth.confirmationToken, exact: true },
  { path: '/log-in', fn: Auth.signInPage, exact: true },
  { path: '/sign-up', fn: Auth.signUpPage, exact: true },
  { path: '/account/password-reset', fn: Auth.updatePassword, exact: true },
  { path: '/reset-password', fn: Auth.recoveryPasswordPage, exact: true },
  { path: '/recruiter-invite', fn: Auth.recruiterAccept, exact: true },
  { path: '/guest/job/:id', fn: Auth.recommendJobAccept, exact: true },
  { path: '/notifications', fn: Notifications.notificationsPage, exact: true },
  { path: '/candidate-invite', fn: Auth.candidateInvite, exact: true },
  { path: '/', fn: Auth.signOut, exact: true },

  // Hiring Manager
  { path: '/manager/profile', fn: MProfile.profilePage, exact: true },
  { path: '/manager/dashboard', fn: MDashboard.dashboard, exact: true },
  { path: '/manager/jobs/view/:id', fn: MJobsView.jobsView, exact: true },
  {
    path: '/manager/jobs/:id/candidates/:candidateId',
    fn: MCandidateView.profilePage,
    exact: true
  },
  {
    path: '/manager/candidates/:candidateId',
    fn: MCandidateView.profilePage,
    exact: true
  },
  { path: '/manager/jobs/:id/candidates', fn: MCandidates.candidatesPage, exact: true },
  { path: '/manager/jobs/:id', fn: MJobsEdit.jobsEdit, exact: true },
  { path: '/manager/jobs/clone/:id', fn: MJobsEdit.jobsClone, exact: true },
  { path: '/manager/jobs', fn: MJobsList.jobsList, exact: true },
  { path: '/manager/recruiters-jobs', fn: MRecruitersJobs.jobsList, exact: true },
  { path: '/manager/clients-jobs', fn: MClientsJobs.jobsList, exact: true },
  { path: '/manager/clients', fn: MClients.clients, exact: true },
  { path: '/manager/recruiters/:id', fn: MRecruiterView.profilePage, exact: true },
  { path: '/manager/recruiters', fn: MRecruiters.recruitersList, exact: true },
  { path: '/manager/managers/:id', fn: MManagerView.profilePage, exact: true },
  { path: '/manager/calendar', fn: MCalendar.calendar, exact: true },
  { path: '/manager/recruiters-clients', fn: MRecruiterClients.clients, exact: true },

  // Recruiter
  { path: '/recruiter/profile', fn: RProfile.profilePage, exact: true },
  { path: '/recruiter/managers/:id', fn: RManagerView.profilePage, exact: true },
  { path: '/recruiter/recruiters/:id', fn: RRecruiterView.profilePage, exact: true },
  { path: '/recruiter/dashboard', fn: RDashboard.dashboard, exact: true },
  {
    path: '/recruiter/jobs/:jobsId/candidates/:id',
    fn: RCandidateProfile.profilePage,
    exact: true
  },
  { path: '/recruiter/jobs/view/:id', fn: RJobsView.jobsView, exact: true },
  { path: '/recruiter/jobs', fn: RJobsList.jobsList, exact: true },
  { path: '/recruiter/candidates/add', fn: RCandidateProfileAdd.profilePage, exact: true },
  { path: '/recruiter/candidates/:id/edit', fn: RCandidateProfileEdit.profilePage, exact: true },
  { path: '/recruiter/candidates/:id', fn: RCandidateProfile.profilePage, exact: true },
  { path: '/recruiter/candidates', fn: RCandidates.candidatesPage, exact: true },
  { path: '/recruiter/resumes', fn: RResumes.resumesPage, exact: true },
  { path: '/recruiter/messages', fn: Messages.messagesPage, exact: true },

  // Candidate
  { path: '/candidate/profile', fn: CProfile.profilePage, exact: true },
  { path: '/candidate/managers/:id', fn: CManagerView.profilePage, exact: true },
  { path: '/candidate/recruiters/:id', fn: CRecruiterView.profilePage, exact: true },
  { path: '/candidate/candidates/:id', fn: CCandidateView.profilePage, exact: true },
  { path: '/candidate/jobs/view/:id', fn: CJobsView.jobsView, exact: true },
  { path: '/candidate/jobs', fn: CJobsList.jobsList, exact: true },
  { path: '/candidate/calendar', fn: CCalendar.calendar, exact: true },
  { path: '/candidate/messages', fn: Messages.messagesPage, exact: true },
  { path: '/candidate/dashboard', fn: CDashboard.dashboard, exact: true },

  // guest
  { path: '/guest/jobs', fn: GuestJobs.jobsListGuest, exact: true },

  // Hiring Manager
  {
    path: '/super_manager/jobs/:id/candidates/:candidateId',
    fn: SMCandidateProfile.profilePage,
    exact: true
  },
  { path: '/super_manager/jobs/view/:id', fn: SuperManagerJobsView.jobView, exact: true },
  { path: '/super_manager/profile', fn: SuperManagerProfile.profilePage, exact: true },
  { path: '/super_manager/jobs', fn: SuperManagerJobs.jobsList, exact: true },
  { path: '/super_manager/clients-jobs', fn: SMCandidatesJobs.jobsList, exact: true },
  { path: '/super_manager/managers/:id', fn: SMManager.profilePage, exact: true },
  { path: '/super_manager/clients', fn: SuperManagerClients.clientsList, exact: true },
  { path: '/super_manager/dashboard', fn: SuperManagerDashboard.dashboard, exact: true }
]

let task: Task

function* routeMatcher({ payload: { location } }: LocationChangeAction) {
  const constants = yield select(constantsSelector)
  if (!constants || constants.isEmpty() || constants === State) yield call(ensureGetConstants)
  for (const item of path) {
    const match = matchPath(location.pathname, {
      exact: 'exact' in item ? item.exact : true,
      strict: false,
      ...item
    })
    if (task) yield cancel(task)
    const query = yield select(querySelector)
    if (match !== null) {
      task = yield fork(item.fn, { ...location, query }, match)
      break
    }
  }
}

export default function* navigator() {
  yield takeEvery(LOCATION_CHANGE, routeMatcher)
}
