import React, { FC } from 'react'
import injectSheet from 'react-jss'
import { Classes } from 'jss'
import cx from 'classnames'
import sheet from './sheet'

const Spinner: FC<{ classes: Classes; overrideClass: string }> = ({ classes, overrideClass }) => (
  <div className={cx(classes.loader, overrideClass)} />
)

export default injectSheet(sheet)(Spinner)
