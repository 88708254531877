import { put, call, select } from 'redux-saga/effects'
import { isLoadingCalendarEvent, modalIsLoading, pageIsLoading } from 'domain/loading'
import {
  prepareDataEvent,
  prepareDataUnavailability,
  prepareUpdateUnavailability,
  prepareEventsList
} from './helpers'
import { EventI, NewEventI } from 'types/calendarTypes'
import * as Actions from './actions'
import * as M from 'domain/env'
import Api from 'domain/api'
import I from 'immutable'
import { FormAction } from 'redux-form'

const API_URL = process.env.REACT_APP_API_PATH_DOWNLOADS

export function* ensureEventsList() {
  const headers = yield select(M.userToken)
  yield put({ type: pageIsLoading, payload: true })
  try {
    const {
      data: { data }
    } = yield call(Api.manager.getEventsList, {
      headers: { Authorization: `Bearer ${headers}` }
    })
    const eventsList = prepareEventsList(data)
    yield put({
      type: Actions.managerEventsList.success,
      payload: I.fromJS(eventsList.events)
    })
    yield put({
      type: Actions.nearestEventId.success,
      payload: eventsList.nearestEventId
    })
  } catch (err) {
    yield put({
      type: Actions.managerEventsList.failure,
      err
    })
  } finally {
    yield put({ type: pageIsLoading, payload: false })
  }
}

export function* ensureCandidatesList() {
  const headers = yield select(M.userToken)
  try {
    const {
      data: { data }
    } = yield call(Api.manager.getCandidatesList, {
      headers: { Authorization: `Bearer ${headers}` }
    })
    const candidatesList =
      data &&
      data.map((item: { [x: string]: number | string }) => ({
        value: item.id,
        label: [item.firstName, item.lastName].join(', '),
        avatar: item.avatar ? `${API_URL}/${item.avatar}` : undefined
      }))
    yield put({
      type: Actions.managerCandidatesList.success,
      payload: I.fromJS(candidatesList || [])
    })
  } catch (err) {
    yield put({
      type: Actions.managerCandidatesList.failure,
      err
    })
  }
}

export function* ensureAddEvent({ payload }: { payload: NewEventI; type: string }) {
  const headers = yield select(M.userToken)

  if (!payload) return

  try {
    yield put({ type: modalIsLoading, payload: true })
    const {
      data: { data }
    } = yield call(Api.manager.postEvent, {
      headers: { Authorization: `Bearer ${headers}` },
      data: prepareDataEvent(payload)
    })
    yield put({ type: Actions.managerEventAdd.SUCCESS, payload: I.fromJS(data) })
    yield put({ type: Actions.managerEventsList.type })
  } catch (err) {
    yield put({
      type: Actions.managerEventAdd.FAILURE,
      err
    })
  } finally {
    yield put({ type: modalIsLoading, payload: false })
  }
}

export function* ensureEvent({ payload }: { payload: EventI; type: string }) {
  yield put({ type: isLoadingCalendarEvent, payload: true })
  yield put({ type: pageIsLoading, payload: true })
  const headers = yield select(M.userToken)
  try {
    const {
      data: { data }
    } = yield call(Api.manager.getEvent, {
      headers: { Authorization: `Bearer ${headers}` },
      id: payload
    })
    yield put({ type: Actions.managerEvent.success, payload: I.fromJS(data) })
  } catch (err) {
    yield put({
      type: Actions.managerEvent.failure,
      err
    })
  } finally {
    yield put({ type: isLoadingCalendarEvent, payload: false })
    yield put({ type: pageIsLoading, payload: false })
  }
}

export function* ensureUpdateEvent({ payload }: { payload: NewEventI; type: string }) {
  const headers = yield select(M.userToken)
  if (!payload) return
  try {
    yield put({ type: modalIsLoading, payload: true })
    const {
      data: { data }
    } = yield call(Api.manager.putEvent, {
      headers: { Authorization: `Bearer ${headers}` },
      id: payload.id,
      data: prepareDataEvent(payload)
    })
    yield put({ type: Actions.managerEventUpdate.SUCCESS, payload: I.fromJS(data) })
    yield put({ type: Actions.managerEventsList.type })
  } catch (err) {
    yield put({
      type: Actions.managerEventUpdate.FAILURE,
      err
    })
  } finally {
    yield put({ type: modalIsLoading, payload: false })
  }
}

export function* ensureRemoveEvent({ payload }: { payload: number; type: string }) {
  const headers = yield select(M.userToken)
  try {
    yield put({ type: modalIsLoading, payload: true })
    const {
      data: { data }
    } = yield call(Api.manager.deleteEvent, {
      headers: { Authorization: `Bearer ${headers}` },
      id: payload
    })
    yield put({ type: Actions.managerEventDelete.SUCCESS, payload: I.fromJS(data) })
    yield put({ type: Actions.managerEventsList.type })
  } catch (err) {
    yield put({
      type: Actions.managerEventDelete.FAILURE,
      err
    })
  } finally {
    yield put({ type: modalIsLoading, payload: false })
  }
}

export function* ensureAddUnavailability({ payload }: { payload: NewEventI; type: string }) {
  const headers = yield select(M.userToken)
  if (!payload) return
  try {
    const {
      data: { data }
    } = yield call(Api.manager.postEventUnavailability, {
      headers: { Authorization: `Bearer ${headers}` },
      data: prepareDataUnavailability(payload)
    })
    yield put({ type: Actions.unavailabilityAdd.SUCCESS, payload: I.fromJS(data) })
    yield put({ type: Actions.managerEventsList.type })
  } catch (err) {
    yield put({
      type: Actions.unavailabilityAdd.FAILURE,
      err
    })
  }
}

export function* ensureUnavailability({ payload }: { payload: EventI; type: string }) {
  yield put({ type: isLoadingCalendarEvent, payload: true })
  yield put({ type: pageIsLoading, payload: true })
  const headers = yield select(M.userToken)
  try {
    const {
      data: { data }
    } = yield call(Api.manager.getEventUnavailability, {
      headers: { Authorization: `Bearer ${headers}` },
      id: payload
    })
    yield put({
      type: Actions.unavailability.success,
      payload: I.fromJS(data)
    })
  } catch (err) {
    yield put({
      type: Actions.unavailability.failure,
      err
    })
  } finally {
    yield put({ type: isLoadingCalendarEvent, payload: false })
    yield put({ type: pageIsLoading, payload: false })
  }
}

export function* ensureUpdateUnavailability({ payload }: { payload: NewEventI; type: string }) {
  const headers = yield select(M.userToken)
  if (!payload) return
  try {
    const {
      data: { data }
    } = yield call(Api.manager.putEventUnavailability, {
      headers: { Authorization: `Bearer ${headers}` },
      id: payload.id,
      data: prepareUpdateUnavailability(payload)
    })
    yield put({ type: Actions.unavailabilityUpdate.SUCCESS, payload: I.fromJS(data) })
    yield put({ type: Actions.managerEventsList.type })
  } catch (err) {
    yield put({
      type: Actions.unavailabilityUpdate.FAILURE,
      err
    })
  }
}

export function* ensureRemoveUnavailability({ payload }: { payload: number; type: string }) {
  const headers = yield select(M.userToken)
  if (!payload) return
  try {
    const {
      data: { data }
    } = yield call(Api.manager.deleteEventUnavailability, {
      headers: { Authorization: `Bearer ${headers}` },
      id: payload
    })
    yield put({ type: Actions.unavailabilityDelete.SUCCESS, payload: I.fromJS(data) })
    yield put({ type: Actions.managerEventsList.type })
  } catch (err) {
    yield put({
      type: Actions.unavailabilityDelete.FAILURE,
      err
    })
  }
}

export function* ensureCandidateJobsList(props: FormAction | { payload: { value: number } }) {
  const headers = yield select(M.userToken)
  if (!headers) return
  try {
    const {
      data: { data }
    } = yield call(Api.getJobsForCandidateCloseJobM, {
      headers: { Authorization: `Bearer ${headers}` },
      id: props.payload.value
    })
    yield put({ type: Actions.managerCandidateJobsList.success, payload: I.fromJS(data) })
  } catch (err) {
    yield put({
      type: Actions.managerCandidateJobsList.failure,
      err
    })
  }
}
