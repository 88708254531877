export const upper = (value: string) => value && value[0].toUpperCase() + value.slice(1)
export const onlyNumbers = (value: string | null) =>
  value ? (value === '0' ? value : value.replace(/(^[\D0]+)|(\D)/g, '')) : value
export const onlyNumbersWithFormat = (value: string) => value && value.replace(/(\D)|(^0+)/g, '')
export const onlyNumbersRange = (value: string) =>
  value && value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
export const normalizePhone = (value: string | null) => {
  const prefixLength = value ? value.replace(/[^\d]/g, '').length : 0
  switch (prefixLength) {
    case 1:
    case 2:
      return normalize10
    case 3:
      return normalize9
    case 4:
      return normalize8
    default:
      return normalize10
  }
}

const normalize10 = (value: string) => {
  if (!value) {
    return value
  }

  const onlyNums = value.replace(/[^\d]/g, '')
  if (onlyNums.length <= 3) {
    return onlyNums
  }
  if (onlyNums.length <= 6) {
    return `${onlyNums.slice(0, 3)} ${onlyNums.slice(3, 6)}`
  }
  if (onlyNums.length <= 8) {
    return `${onlyNums.slice(0, 3)} ${onlyNums.slice(3, 6)} ${onlyNums.slice(6, 8)}`
  }
  return `${onlyNums.slice(0, 3)} ${onlyNums.slice(3, 6)} ${onlyNums.slice(6, 8)} ${onlyNums.slice(
    8,
    10
  )}`
}

const normalize9 = (value: string) => {
  if (!value) {
    return value
  }

  const onlyNums = value.replace(/[^\d]/g, '')
  if (onlyNums.length <= 2) {
    return onlyNums
  }
  if (onlyNums.length <= 5) {
    return `${onlyNums.slice(0, 2)} ${onlyNums.slice(2, 5)}`
  }
  if (onlyNums.length <= 7) {
    return `${onlyNums.slice(0, 2)} ${onlyNums.slice(2, 5)} ${onlyNums.slice(5, 7)}`
  }
  return `${onlyNums.slice(0, 2)} ${onlyNums.slice(2, 5)} ${onlyNums.slice(5, 7)} ${onlyNums.slice(
    7,
    9
  )}`
}

const normalize8 = (value: string) => {
  if (!value) {
    return value
  }

  const onlyNums = value.replace(/[^\d]/g, '')
  if (onlyNums.length <= 1) {
    return onlyNums
  }
  if (onlyNums.length <= 4) {
    return `${onlyNums.slice(0, 1)} ${onlyNums.slice(1, 4)}`
  }
  if (onlyNums.length <= 6) {
    return `${onlyNums.slice(0, 1)} ${onlyNums.slice(1, 4)} ${onlyNums.slice(4, 6)}`
  }
  return `${onlyNums.slice(0, 1)} ${onlyNums.slice(1, 4)} ${onlyNums.slice(4, 6)} ${onlyNums.slice(
    6,
    8
  )}`
}
