import { call, put, select } from 'redux-saga/effects'
import * as M from 'domain/env'
import Api from 'domain/api'
import * as A from './actions'
import I from 'immutable'
import { pageIsLoading } from '../../loading'

export function* ensureCollaborationRequests() {
  const headers = yield select(M.userToken)
  if (!headers) return
  try {
    const { data } = yield call(Api.managerCollaborationRequests, {
      headers: { Authorization: `Bearer ${headers}` }
    })
    yield put({ type: A.managerCollaborationRequests.success, payload: I.fromJS(data) })
  } catch (err) {
    yield put({
      type: A.managerCollaborationRequests.failure,
      err
    })
  }
}

export function* ensureRejectCollaborationRequest(props: {
  payload: { id: number }
  type: string
}) {
  yield put({ type: pageIsLoading, payload: true })
  const headers = yield select(M.userToken)
  if (!headers) return
  try {
    yield call(Api.rejectManagerCollaborationRequest, {
      headers: { Authorization: `Bearer ${headers}` },
      id: props.payload.id
    })
    yield put({ type: A.rejectManagerCollaborationRequest.SUCCESS, payload: props.payload.id })
  } catch (err) {
    yield put({
      type: A.rejectManagerCollaborationRequest.FAILURE,
      err
    })
  } finally {
    yield put({ type: pageIsLoading, payload: false })
  }
}

export function* ensureAcceptCollaborationRequest(props: {
  payload: { id: number }
  type: string
}) {
  yield put({ type: pageIsLoading, payload: true })
  const headers = yield select(M.userToken)
  if (!headers) return
  try {
    yield call(Api.acceptManagerCollaborationRequest, {
      headers: { Authorization: `Bearer ${headers}` },
      id: props.payload.id
    })
    yield put({ type: A.acceptManagerCollaborationRequest.SUCCESS, payload: props.payload.id })
  } catch (err) {
    yield put({
      type: A.acceptManagerCollaborationRequest.FAILURE,
      err
    })
  } finally {
    yield put({ type: pageIsLoading, payload: false })
  }
}
