import React from 'react'
// components
import Header from './Header'
import Body from './Body'
import Footer from './Footer'

export default () => (
  <>
    <Header />
    <Body />
    <Footer />
  </>
)
