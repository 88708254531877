import { put, call, select } from 'redux-saga/effects'
import { pageIsLoading } from 'domain/loading'
import I from 'immutable'
import Api from 'domain/api'
import * as M from 'domain/env'
import * as Actions from './actions'
import * as J from 'domain/jobs'
import * as Router from 'domain/router'

export function* ensureChangeCandidateStatus(props: {
  payload: { candidateId: string; id: string; status: string }
  type: string
}) {
  const headers = yield select(M.userToken)
  if (!headers) return

  try {
    const { candidateId, id: jobId, status } = props.payload
    const {
      data: { data }
    } = yield call(Api.postStatus, {
      headers: { Authorization: `Bearer ${headers}` },
      candidateId,
      jobId,
      data: { status }
    })
    yield put({
      type: Actions.candidateStatus.success,
      payload: I.fromJS(data)
    })
    yield call(J.ensureGetCandidateJobNotes)
  } catch (err) {
    yield put({
      type: Actions.changeCandidateStatus.failure,
      err
    })
  } finally {
    yield put({ type: pageIsLoading, payload: false })
  }
}

export function* ensureGetCandidateStatus() {
  const headers = yield select(M.userToken)
  if (!headers) return

  const user = yield select(M.userSelector)
  const userRole = user.get('role')

  const {
    params: { id, candidateId, jobsId }
  } = yield select(Router.matchRoutes)

  const rightCandidateId = userRole === 'recruiter' ? id : candidateId
  const rightJobId = userRole === 'recruiter' ? jobsId : id
  if (!rightJobId) return

  try {
    yield put({ type: pageIsLoading, payload: true })
    const {
      data: { data }
    } = yield call(Api.getStatus, {
      headers: { Authorization: `Bearer ${headers}` },
      candidateId: rightCandidateId,
      jobId: rightJobId
    })
    yield put({
      type: Actions.candidateStatus.success,
      payload: I.fromJS(data)
    })
  } catch (err) {
    yield put({
      type: Actions.candidateStatus.failure,
      err
    })
  } finally {
    yield put({ type: pageIsLoading, payload: false })
  }
}
