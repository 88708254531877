import { put, call, select } from 'redux-saga/effects'
import { dateTimeRangeSelector, DateTimeRangeT } from 'domain/constants'
import { Sort } from 'types/common'
import I from 'immutable'
import Api from 'domain/api'
import * as A from './actions'
import * as M from 'domain/env'

interface Params {
  page?: number
  order?: Sort
  order_by?: string | null
  range?: string
  managerId?: string
}

interface Props {
  payload?: Params
  type?: string
}

export const parseParamsWithRange = (range: DateTimeRangeT, props?: Props) => {
  const payload = (props && props.payload) || ({} as Params)
  return {
    ...payload,
    range: payload.range === 'all' ? undefined : payload.range || range.get('week')
  }
}

export function* ensureClientsCounters(props?: Props) {
  const headers = yield select(M.userToken)
  if (!headers) return
  const params = props ? props.payload : undefined
  try {
    const { data } = yield call(Api.superManagerClientsCounters, {
      headers: { Authorization: `Bearer ${headers}` },
      params
    })
    yield put({ type: A.superManagerClientsCounters.success, payload: I.fromJS(data) })
  } catch (err) {
    yield put({
      type: A.superManagerClientsCounters.failure,
      err
    })
  }
}

export function* ensureJobsCounters(props?: Props) {
  const headers = yield select(M.userToken)
  if (!headers) return
  const params = props ? props.payload : undefined
  try {
    const { data } = yield call(Api.superManagerJobsCounters, {
      headers: { Authorization: `Bearer ${headers}` },
      params
    })
    yield put({ type: A.superManagerJobsCounters.success, payload: I.fromJS(data) })
  } catch (err) {
    yield put({
      type: A.superManagerJobsCounters.failure,
      err
    })
  }
}

export function* ensureStatistics(props?: Props) {
  const headers = yield select(M.userToken)
  if (!headers) return
  const range: DateTimeRangeT = yield select(dateTimeRangeSelector)
  const params = parseParamsWithRange(range, props)
  try {
    const { data } = yield call(Api.superManagerStatistics, {
      headers: { Authorization: `Bearer ${headers}` },
      params
    })
    yield put({ type: A.superManagerStatistics.success, payload: I.fromJS(data) })
  } catch (err) {
    yield put({
      type: A.superManagerStatistics.failure,
      err
    })
  }
}

export function* ensureClosedJobs(props?: Props) {
  const headers = yield select(M.userToken)
  if (!headers) return
  const range: DateTimeRangeT = yield select(dateTimeRangeSelector)
  const params = parseParamsWithRange(range, props)
  try {
    const { data } = yield call(Api.superManagerClosedJobs, {
      headers: { Authorization: `Bearer ${headers}` },
      params
    })
    yield put({ type: A.superManagerClosedJobs.success, payload: I.fromJS(data) })
  } catch (err) {
    yield put({
      type: A.superManagerClosedJobs.failure,
      err
    })
  }
}

export function* ensureClientClosureRate(props?: Props) {
  const headers = yield select(M.userToken)
  if (!headers) return
  const range: DateTimeRangeT = yield select(dateTimeRangeSelector)
  const params = parseParamsWithRange(range, props)
  try {
    const { data } = yield call(Api.superManagerClientClosureRate, {
      headers: { Authorization: `Bearer ${headers}` },
      params
    })
    yield put({ type: A.superManagerClientClosureRate.success, payload: I.fromJS(data) })
  } catch (err) {
    yield put({
      type: A.superManagerClientClosureRate.failure,
      err
    })
  }
}

export function* ensureTopRecruiters(props?: Props) {
  const headers = yield select(M.userToken)
  if (!headers) return
  const range: DateTimeRangeT = yield select(dateTimeRangeSelector)
  const params = parseParamsWithRange(range, props)
  try {
    const { data } = yield call(Api.superManagerTopRecruiters, {
      headers: { Authorization: `Bearer ${headers}` },
      params,
      id: params ? params.managerId : undefined
    })
    yield put({ type: A.superManagerTopRecruiters.success, payload: I.fromJS(data) })
  } catch (err) {
    yield put({
      type: A.superManagerTopRecruiters.failure,
      err
    })
  }
}

export function* ensureTopManagers(props?: Props) {
  const headers = yield select(M.userToken)
  if (!headers) return
  const range: DateTimeRangeT = yield select(dateTimeRangeSelector)
  const params = parseParamsWithRange(range, props)
  try {
    const { data } = yield call(Api.superManagerTopManagers, {
      headers: { Authorization: `Bearer ${headers}` },
      params
    })
    yield put({ type: A.superManagerTopManagers.success, payload: I.fromJS(data) })
  } catch (err) {
    yield put({
      type: A.superManagerTopManagers.failure,
      err
    })
  }
}
