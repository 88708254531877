import React, { ReactNodeArray, FC } from 'react'
// types
import { Classes } from 'jss'
// styles
import injectSheet, { Styles } from 'react-jss'
// utils
import cx from 'classnames'

const styles: Styles = {
  root: {
    display: 'block',
    marginTop: 60
  },
  '@media (max-width: 767.98px)': {
    root: {
      display: 'flex',
      flexDirection: 'column',
      '& > button': {
        marginRight: 0,
        marginBottom: 20
      }
    }
  }
}

interface Props {
  children: ReactNodeArray
  className?: string
  classes: Classes
}

const ActionsGroup: FC<Props> = ({ children, className, classes, ...rest }) => {
  return (
    <div className={cx(classes.root, 'form-group', className)} {...rest}>
      {children}
    </div>
  )
}

export default injectSheet(styles)(ActionsGroup)
