import React from 'react'
import Svg, { SvgType } from 'components/svgComponent/Svg'

export default function(props: SvgType) {
  return (
    <Svg width="30" height="30" viewBox="0 0 30 30" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.49041 17.5C7.17614 17.5 6.88849 17.6159 6.6701 17.8063C6.40376 18.0353 6.23864 18.3736 6.23864 18.7504V22.5004C6.23864 23.1903 6.79794 23.7496 7.49041 23.7496H11.2404C11.9276 23.7496 12.4869 23.1903 12.4869 22.5004V18.7504C12.4869 18.5667 12.4496 18.3922 12.375 18.2351C12.1779 17.801 11.7464 17.5 11.2404 17.5H7.49041ZM8.11364 19.375V21.8746H10.6119V19.375H8.11364Z"
        fill="#286EFA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.1431 0.894001C9.93004 0.724878 9.65838 0.625002 9.36541 0.625002C8.67827 0.626334 8.11896 1.18697 8.11896 1.87678L8.12429 5.00222L2.49396 4.99956C2.39808 4.99956 2.3022 5.01021 2.21165 5.03152C2.05185 5.06881 1.9027 5.13539 1.77486 5.22594C1.63636 5.32182 1.51918 5.44434 1.42862 5.58683C1.31143 5.77859 1.24219 6.00631 1.24219 6.25V27.4996C1.24219 27.8618 1.39666 28.188 1.64169 28.4171C1.86541 28.6235 2.16371 28.75 2.49396 28.75H27.4922C28.1847 28.75 28.744 28.1894 28.744 27.4996V6.25C28.744 5.55886 28.1847 4.99956 27.4922 4.99956L21.8725 4.99823L21.8672 1.87278C21.8672 1.18297 21.3079 0.623671 20.6154 0.625002C19.9283 0.626334 19.369 1.18697 19.369 1.87678L19.3743 5.00222L10.6225 4.99823L10.6172 1.87278C10.6172 1.47594 10.4308 1.12172 10.1431 0.894001ZM3.74041 26.2491V7.49911H26.2404L26.2457 10.6192L3.75107 10.6339V13.1334L26.2457 13.1188L26.2404 26.2491H3.74041Z"
        fill="#286EFA"
      />
    </Svg>
  )
}
