import { userSelector } from 'domain/env'
import { countriesSelector, phoneCodesSelector } from 'domain/locations'
import { SelectListT } from 'types/common'
import { StateInterface } from 'types/state'
import { buildLocationName } from 'lib/helpers'

export const collectiveInitialValues = (state: StateInterface) => {
  const userData = userSelector(state).toJS()
  const countriesList: SelectListT = countriesSelector(state).toJS()
  const phoneCodes: SelectListT = phoneCodesSelector(state).toJS()
  const phonePrefix = phoneCodes.find(code => code.label === userData.phonePrefix)
  const initialCity = userData.city && {
    value: userData.city.id,
    label: buildLocationName(userData)
  }

  return {
    firstName: userData.firstName,
    lastName: userData.lastName,
    country: countriesList.find(country => country.value === userData.countryId),
    city: initialCity,
    phonePrefix,
    phone: userData.phone,
    email: userData.email,
    birthday: userData.birthday ? new Date(userData.birthday) : null,
    notificationSettings: Object.keys(userData.notificationSettings).length
      ? userData.notificationSettings
      : { expiresIn: { value: 30 } }
  }
}

export const getPhoneCodes = (state: StateInterface) => {
  const phoneCodes: SelectListT = phoneCodesSelector(state).toJS()
  const userData = userSelector(state).toJS()
  return phoneCodes.find(code => code.label === userData.phonePrefix)
}

export const getCountryId = (state: StateInterface) => {
  const userData = userSelector(state).toJS()
  return userData && userData.country && userData.country.id
}
