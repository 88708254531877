import React from 'react'
// icons
import UseNotes from 'components/svgComponent/UseNotes'
import Simplicity from 'components/svgComponent/Simplicity'

interface Icon {
  name: string
  icon: JSX.Element
  text: string
}

export const icons: Icon[] = [
  {
    name: 'Use notes',
    icon: <UseNotes />,
    text: 'You always can write down your password in notes. Don’t show it!'
  },
  {
    name: 'Simplicity',
    icon: <Simplicity />,
    text: 'Keep your password easy to remember, but difficult to crack'
  }
]
