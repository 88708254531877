import React from 'react'
import Svg, { SvgType } from 'components/svgComponent/Svg'

export default function(props: SvgType) {
  return (
    <Svg width="30" height="30" viewBox="0 0 30 30" fill="none" {...props}>
      <path d="M23.1286 14.0625H16.2518V12.1875H23.1286V14.0625Z" fill="#286EFA" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.00178 12.5004C4.65554 12.5004 4.34659 12.6389 4.12287 12.8627C3.89382 13.089 3.75 13.4033 3.75 13.7509V20.0004C3.75 20.6903 4.3093 21.2509 5.00178 21.2509H11.25C11.9425 21.2509 12.5018 20.6903 12.5018 20.0004V13.7509C12.5018 13.0597 11.9425 12.5004 11.25 12.5004H5.00178ZM5.625 14.3754V19.3759H10.6268V14.3754H5.625Z"
        fill="#286EFA"
      />
      <path d="M16.2518 17.8125H25.6268V15.9375H16.2518V17.8125Z" fill="#286EFA" />
      <path d="M25.6268 21.5625H16.2518V19.6875H25.6268V21.5625Z" fill="#286EFA" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30 24.9996V7.5C30 6.81019 29.4407 6.24956 28.7482 6.24956H21.2482V5.00044C21.2482 4.3093 20.6889 3.75 20.0018 3.75H9.99822C9.31108 3.75 8.75178 4.3093 8.75178 5.00044V6.24956H1.25178C0.559304 6.24956 0 6.81019 0 7.5V24.9996C0 25.6907 0.559304 26.25 1.25178 26.25H28.7482C29.4407 26.25 30 25.6907 30 24.9996ZM11.25 6.24956H18.75V8.75044H27.5018V23.7504H2.49822V8.75044H11.25V6.24956Z"
        fill="#286EFA"
      />
    </Svg>
  )
}
