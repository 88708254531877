import React from 'react'
// components
import NotificationTitle from 'pages/Notifications/NotificationTitle'
// types
import { Classes } from 'jss'
import { ButtonTypes } from 'types/common'
// styles
import injectSheet from 'react-jss'
import { sheet } from './sheet'
// icons
import AvatarIcon from 'components/svgComponent/notification/AvatarIcon'
// utils
import cx from 'classnames'

interface Props {
  classes: Classes
  title: string
  text: string
  path: string
  viewedAt: boolean
  createdAt: string
}

const Index = ({ classes, title, text, path, viewedAt, createdAt }: Props) => {
  return (
    <div className={cx(classes.main, { [classes.unread]: viewedAt })}>
      <AvatarIcon />
      <div className={classes.root}>
        <NotificationTitle path={path} title={title} />
        <p className={classes.text}>{text}</p>
      </div>
      <p className="createdAt">{createdAt}</p>
    </div>
  )
}

export default injectSheet(sheet)(Index)
