import { put, call, select } from 'redux-saga/effects'
import I from 'immutable'
import Api from 'domain/api'
import * as Actions from './actions'
import * as M from 'domain/env'
import { pageIsLoading, skeleton } from 'domain/loading'
import { jobsSelector } from 'domain/recruiter/selectors'
import { Sort } from 'types/common'

interface Params {
  page?: number
  order?: Sort
  order_by?: string | null
  status?: string
  search?: string
}

export function* ensureGetParsedResumes(props?: { payload?: Params; type: string }) {
  const headers = yield select(M.userToken)
  if (!headers) return
  if (!props || !props.payload || props.payload.page === 1) {
    yield put({ type: skeleton, payload: true })
    yield put({ type: pageIsLoading, payload: true })
  }
  const params = (props && props.payload) || { status: 'uploaded' }
  try {
    const { data } = yield call(Api.getParseResumes, {
      headers: { Authorization: `Bearer ${headers}` },
      params
    })
    const page = params.page
    const total = data.meta.total
    const prevState = yield select(jobsSelector)
    if (!params || !page || page === 1 || total === prevState.size) {
      yield put({ type: Actions.newParsedResumesList.success, payload: I.fromJS(data) })
    } else {
      yield put({ type: Actions.parsedResumesList.success, payload: I.fromJS(data) })
    }
  } catch (err) {
    yield put({
      type: Actions.parsedResumesList.failure,
      err
    })
  } finally {
    yield put({ type: skeleton, payload: false })
    yield put({ type: pageIsLoading, payload: false })
  }
}
