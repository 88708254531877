import { createRoutine, promisifyRoutine } from 'redux-saga-routines'
import { asyncAction } from 'lib/actionCreators'

export const managersList = asyncAction('super-manager/MANAGERS_LIST')
export const managersSearch = asyncAction('super-manager/MANAGERS_SEARCH')
export const collaborationRequests = asyncAction('super-manager/COLLABORATION_REQUESTS')
export const collaborationRequestResend = asyncAction('super-manager/COLLABORATION_REQUEST_RESEND')
export const managerProfileView = asyncAction('super-manager/MANAGER_PROFILE_VIEW')

export const sendCollaborationRequest = createRoutine('super-manager/SEND_COLLABORATION_REQUEST')
export const sendCollaborationRequestPromiseCreator = promisifyRoutine(sendCollaborationRequest)
export const declineCollaborationRequest = createRoutine(
  'super-manager/DECLINE_COLLABORATION_REQUEST'
)
export const declineCollaborationRequestPromiseCreator = promisifyRoutine(
  declineCollaborationRequest
)
