import { all, call, fork, put, take, takeLatest } from 'redux-saga/effects'
import { pageIsLoading } from 'domain/loading'
import * as J from 'domain/candidate/jobs'
import * as RJ from 'domain/candidate/recommendJob'
import I from 'immutable'

export function* jobsView() {
  yield all([
    fork(getJob),
    fork(initialLoad),
    fork(saveJob),
    fork(assignJob),
    fork(acceptJob),
    fork(declineJob),
    fork(invite)
  ])
}

function* getJob() {
  yield takeLatest(J.jobData.type, J.ensureGetJob)
  yield take(J.jobData.success)
}

function* saveJob() {
  yield takeLatest(J.saveJob.type, J.ensureAddJobToFavorite)
  yield take(J.saveJob.success)
}

function* assignJob() {
  yield takeLatest(J.assignJob.type, J.ensureAssignJob)
  yield take(J.assignJob.success)
}

function* acceptJob() {
  yield takeLatest(J.acceptJob.type, J.ensureAcceptJob)
  yield take(J.acceptJob.success)
}

function* declineJob() {
  yield takeLatest(J.cancelJob.TRIGGER, J.ensureCancelJob)
  yield take(J.cancelJob.SUCCESS)
}

function* initialLoad() {
  yield put({ type: pageIsLoading, payload: true })
  try {
    yield call(J.ensureGetJob)
  } finally {
    yield put({ type: pageIsLoading, payload: false })
  }
}

function* invite() {
  yield put({ type: RJ.recommendJob.SUCCESS, payload: I.List() })
  yield takeLatest(RJ.recommendJob.TRIGGER, RJ.ensureRecommendJob)
  while (true) {
    yield take(RJ.recommendJob.SUCCESS)
  }
}
