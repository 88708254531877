import { all, call, take, takeLatest, fork, put } from 'redux-saga/effects'
import { isLoading } from 'domain/loading'
import * as J from 'domain/candidate/jobs'
import * as L from 'domain/locations'

export function* jobsList() {
  yield all([fork(getJobsList), fork(initialLoad), fork(saveJob)])
}

function* getJobsList() {
  yield takeLatest(J.jobsList.type, J.ensureGetJobs)
  yield take(J.jobsList.success)
}

function* saveJob() {
  yield takeLatest(J.saveJobFromList.type, J.ensureAddJobToFavoriteFromList)
  yield take(J.saveJobFromList.success)
}

function* initialLoad() {
  yield put({ type: isLoading, payload: true })
  try {
    yield all([call(J.ensureGetJobs), call(L.ensureGetCounties)])
    yield put({ type: 'profile/CLEAR_CITIES' })
  } finally {
    yield put({ type: isLoading, payload: false })
  }
}
