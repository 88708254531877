import { Styles } from 'react-jss'

export const fieldsClasses: Styles = {
  inputRoot: {},
  calendar: {},
  inputRow: {
    margin: '1em 0',
    position: 'relative',
    '& > $inputRoot': {
      position: 'relative',
      '& $calendar': {
        color: '#dc233b !important'
      }
    }
  },
  inputControl: {
    display: 'block',
    minWidth: 100,
    width: '100%',
    borderRadius: 2,
    color: '#080910',
    fontSize: 15,
    fontWeight: 400,
    border: '1px solid #E6E6E6',
    backgroundColor: '#FFFFFF',
    padding: 10,
    boxSizing: 'border-box',
    '&:focus, &:active': {
      outlineColor: '#85BFFF',
      border: '1px solid #85BFFF'
    },
    '&::placeholder': {
      color: '#989FA6'
    }
  },
  disabled: {
    '&:focus, &:active': {
      outlineColor: 'none',
      border: '1px solid #E6E6E6'
    }
  },
  labelText: {
    display: 'inline-block',
    fontWeight: 700,
    letterSpacing: '0.01em',
    fontSize: 15,
    color: '#080910'
  },
  wrapper: {
    fontSize: 12,
    display: 'flex',
    alignItems: 'center',
    marginBottom: 10,
    justifyContent: 'space-between'
  },
  fieldWrapper: {
    display: 'flex',
    '& > *': {
      width: 130
    },
    '& > *:not(:first-child)': {
      marginLeft: 10
    }
  },
  passwordIcon: {
    position: 'absolute',
    bottom: '30%',
    top: '50%',
    transform: 'translateY(-50%)',
    right: '3%',
    cursor: 'pointer'
  },
  inputBorder: {
    border: '1px solid #00AE23'
  },
  textAreaControl: {
    fontFamily: 'Roboto, sans-serif',
    resize: 'none',
    border: '1px solid #E6E6E6',
    display: 'block',
    minWidth: 100,
    width: '100%',
    borderRadius: 2,
    color: '#080910',
    fontSize: 15,
    fontWeight: 400,
    lineHeight: '20px',
    backgroundColor: 'inherit',
    padding: 10,
    boxSizing: 'border-box',
    '&:focus, &:active': {
      outlineColor: '#85BFFF',
      border: '1px solid #85BFFF'
    }
  },
  paddingInput: {
    paddingLeft: 40
  },
  iconInput: {
    position: 'absolute',
    top: '15%',
    left: '2%'
  },
  inputControlError: {
    border: '1px solid #dc233b',
    outlineColor: '#dc233b',
    '&:focus, &:active': {
      outlineColor: '#dc233b',
      border: '1px solid #dc233b'
    }
  },
  iconDP: {
    position: 'absolute',
    top: 15,
    right: 10
  },
  noBorder: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 40,
    lineHeight: '66px',
    color: '#080910',
    border: 'none',
    boxShadow: 'none',
    backgroundColor: 'inherit',
    paddingRight: '2em',
    '&:focus, &:active': {
      outlineColor: 'none',
      border: 'none'
    },
    '&::placeholder': {
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: 40,
      lineHeight: '66px',
      color: '#E6E6E6'
    }
  },
  rightIcon: {
    position: 'absolute',
    right: 10,
    top: 'calc(50% - 7px)',
    width: '14px',
    height: '14px'
  }
}
