import { put, call, select } from 'redux-saga/effects'
import I from 'immutable'
import * as M from 'domain/env'
import Api from 'domain/api'
import { error403, pageIsLoading, skeleton } from 'domain/loading'
import * as A from 'domain/superManager/managers/actions'
import * as Router from 'domain/router'
import { transformAvatar } from 'domain/manager/helpers'

export function* ensureGetManager() {
  yield put({
    type: A.managerProfileView.success,
    payload: I.fromJS({})
  })
  yield put({ type: error403, payload: false })
  const headers = yield select(M.userToken)
  if (!headers) return
  yield put({ type: pageIsLoading, payload: true })
  const {
    params: { id }
  } = yield select(Router.matchRoutes)
  try {
    const { data } = yield call(Api.getSMManagerProfile, {
      headers: { Authorization: `Bearer ${headers}` },
      id
    })
    yield put({
      type: A.managerProfileView.success,
      payload: transformAvatar(I.fromJS(data))
    })
  } catch (err) {
    const { statusCode } = err.response.data
    if (statusCode === 403 || statusCode === 404) {
      yield put({ type: error403, payload: true })
    }
    yield put({
      type: A.managerProfileView.failure,
      err
    })
  } finally {
    yield put({ type: pageIsLoading, payload: false })
  }
}

export function* ensureGetManagers() {
  yield put({ type: pageIsLoading, payload: true })
  yield put({ type: skeleton, payload: true })

  const headers = yield select(M.userToken)

  if (!headers) return
  try {
    const {
      data: { data }
    } = yield call(Api.getManagers, {
      headers: { Authorization: `Bearer ${headers}` }
    })
    yield put({ type: A.managersList.success, payload: I.fromJS(data) })
  } catch (err) {
    yield put({
      type: A.managersList.failure,
      err
    })
  } finally {
    yield put({ type: pageIsLoading, payload: false })
    yield put({ type: skeleton, payload: false })
  }
}

export function* ensureGetCollaborationRequests() {
  const headers = yield select(M.userToken)
  if (!headers) return
  try {
    const { data } = yield call(Api.getCollaborationRequests, {
      headers: { Authorization: `Bearer ${headers}` }
    })
    yield put({ type: A.collaborationRequests.success, payload: I.fromJS(data) })
  } catch (err) {
    yield put({
      type: A.collaborationRequests.failure,
      err
    })
  }
}

export function* ensureSearchManagers(props: { payload?: string; type: string }) {
  const headers = yield select(M.userToken)
  if (!headers) return
  try {
    const { data } = yield call(Api.getManagersSearch, {
      headers: { Authorization: `Bearer ${headers}` },
      params: { search: props.payload }
    })
    yield put({ type: A.managersSearch.success, payload: I.fromJS(data) })
  } catch (err) {
    yield put({
      type: A.managersSearch.failure,
      err
    })
  }
}

export function* ensureSendCollaborationRequest(props: {
  payload: { managerIds: number[] }
  type: string
}) {
  yield put({ type: pageIsLoading, payload: true })
  const headers = yield select(M.userToken)
  if (!headers) return
  try {
    yield call(Api.sendCollaborationRequest, {
      headers: { Authorization: `Bearer ${headers}` },
      data: props.payload
    })
    yield put({ type: A.sendCollaborationRequest.SUCCESS })
  } catch (err) {
    yield put({
      type: A.sendCollaborationRequest.FAILURE,
      err
    })
  } finally {
    yield put({ type: pageIsLoading, payload: false })
  }
}

export function* ensureDeclineCollaborationRequest(props: {
  payload: { id: number }
  type: string
}) {
  yield put({ type: pageIsLoading, payload: true })
  const headers = yield select(M.userToken)
  if (!headers) return
  try {
    yield call(Api.declineCollaborationRequest, {
      headers: { Authorization: `Bearer ${headers}` },
      id: props.payload.id
    })
    yield put({ type: A.declineCollaborationRequest.SUCCESS, payload: props.payload })
  } catch (err) {
    yield put({
      type: A.declineCollaborationRequest.FAILURE,
      err
    })
  } finally {
    yield put({ type: pageIsLoading, payload: false })
  }
}

export function* ensureResendCollaborationRequest(props: { payload: number; type: string }) {
  yield put({ type: pageIsLoading, payload: true })
  const headers = yield select(M.userToken)
  if (!headers) return
  try {
    const {
      data: { data }
    } = yield call(Api.resendCollaborationRequest, {
      headers: { Authorization: `Bearer ${headers}` },
      id: props.payload
    })
    yield put({ type: A.collaborationRequestResend.success, payload: I.fromJS(data) })
  } catch (err) {
    yield put({
      type: A.collaborationRequestResend.failure,
      err
    })
  } finally {
    yield put({ type: pageIsLoading, payload: false })
  }
}
