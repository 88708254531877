import { Action } from 'redux'
import I from 'immutable'
import * as J from 'domain/jobs'
import * as Env from 'domain/env'
import * as JSM from 'domain/superManager/jobs'

interface ActionType extends Action {
  payload: any
}

const State = I.fromJS({
  notes: I.List(),
  candidateNotes: I.List()
})

export const reducer = {
  jobs(state = State, action: ActionType) {
    switch (action.type) {
      case J.getJobNotes.success:
        return state.set('notes', action.payload)
      case J.getCandidateJobNotes.success:
        return state.set('candidateNotes', action.payload)
      case JSM.getJobNotes.success:
        return state.set('notes', action.payload)
      case Env.signOut.success:
        return State

      default:
        return state
    }
  }
}
