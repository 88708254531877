import React from 'react'
import cx from 'classnames'
import injectSheet from 'react-jss'
import { Classes } from 'jss'
import { sheet } from 'pages/Notifications/AcceptDeclineRecruitersInvite/sheet'
import NotificationTitle from 'pages/Notifications/NotificationTitle'

interface Props {
  classes: Classes
  createdAt: string
  viewedAt: boolean
}

const SuperManagerRoleApproved = ({ classes, viewedAt, createdAt }: Props) => {
  return (
    <div className={cx(classes.main, { [classes.unread]: viewedAt })}>
      <div className={classes.root}>
        <NotificationTitle title="Super Manager role was approved" />
        <p className={classes.text}>
          Your request to become a super hiring manager has been approved by admin.
        </p>
      </div>
      <p className="createdAt">{createdAt}</p>
    </div>
  )
}

export default injectSheet(sheet)(SuperManagerRoleApproved)
